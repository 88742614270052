import { render, staticRenderFns } from "./AlertModal.vue?vue&type=template&id=2b11573e&scoped=true&"
import script from "./AlertModal.vue?vue&type=script&lang=ts&"
export * from "./AlertModal.vue?vue&type=script&lang=ts&"
import style0 from "./AlertModal.vue?vue&type=style&index=0&id=2b11573e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b11573e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2065077497/src/dxflow-forum/packages/open-channel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b11573e')) {
      api.createRecord('2b11573e', component.options)
    } else {
      api.reload('2b11573e', component.options)
    }
    module.hot.accept("./AlertModal.vue?vue&type=template&id=2b11573e&scoped=true&", function () {
      api.rerender('2b11573e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/AlertModal.vue"
export default component.exports