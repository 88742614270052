






















import "../icomoon/style.css";
import { VK_UP, VK_DOWN } from './values/VirtualKey'
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class DepartmentsSearchBox extends Vue {
  name: string = '';
  searchBox: HTMLDivElement | null = null;
  searchBoxText: string = '';
  
  @Prop({ required: true }) readonly localized!: boolean;
  
  @Prop({ required: true }) readonly disabled!: boolean;
  
  @Prop({ required: true }) readonly nextFocusHolder!: string;
  
  @Prop({ required: true }) readonly keyword!: string;
  @Watch("keyword", { immediate: true }) onKeywordChanged(): void {
    this.searchBoxText = this.keyword;
  }

  get clearButtonVisibled(): boolean {
    return this.keyword.length !== 0;
  }

  onKeydown(event: KeyboardEvent): void {
    // Note: event.code はIE11非対応
    switch (event.keyCode) {
      case VK_DOWN:
        event.preventDefault()
        this.$emit('focusNext');
        break
      case VK_UP:
        event.preventDefault()
        this.$emit('focusPrev');
        break
    }
  }

  onClickedClearButton(): void {
    if (this.disabled) {
      return
    }
    this.$emit('change', '');
  }

  onBlur(): void {
    this.$emit('blur');
  }

  onChange(): void {
    this.$emit('change', this.searchBoxText);
  }
}
// 要注意: Haxe 経由での JQuery からのDOM変更が残っている
