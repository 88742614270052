import axios from "axios";
import { DirectUsersMeType } from "./direct-restapi-types";

export type Provider = "direct"|"direct-staging"|"direct-dev"

export type Domains = Direct.GetDomains;

export class DirectAccess {

    private static REST_API = {
        direct: {
            domainInfo: '/albero-app-server/domains',
            me: '/albero-app-server/users/me',
        }
    };

    private token: string;
    public constructor( token: string ) {
        this.token = token;
    }

    // providerの種別毎に REST API の Endpoint を解決する
    private restApiUrl(refPath: string, provider: Provider = "direct" ): string {
        let directApiUrl;
        switch( provider ) {
            case "direct-dev":
                directApiUrl = "https://api-directdev.feel-on.com";
                break;
            case "direct-staging":
                directApiUrl = "https://api-direct.feel-on.com";
                break;
            default:
                directApiUrl = "https://api.direct4b.com";
                break;
        }
        return new URL(refPath, directApiUrl).toString();
    }

    /**
     * direct API にアクセス
     * @param api REST API endpoint
     */
    private async accessApi<T>( api: string ): Promise<T|undefined> {
        if( !this.token ) return undefined;
        const response = await axios.get( api, {
            headers: { Authorization: `Bearer ${this.token}` }
        });
        return response.data as T;
    }

    /** directの組織情報を取得する */
    public async getDomains( provider: Provider ): Promise<Domains> {
        const api = this.restApiUrl( DirectAccess.REST_API.direct.domainInfo, provider );
        return await this.accessApi( api ) || [];
    }

    public async getMe( provider: Provider ): Promise<DirectUsersMeType|undefined> {
        const api = this.restApiUrl( DirectAccess.REST_API.direct.me, provider );
        return await this.accessApi( api );
    }
}
