var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "user-list", attrs: { fluid: "" } },
    _vm._l(_vm.userList, function(user) {
      return _c("b-row", { key: user.id, staticClass: "d-flex w-100" }, [
        _c(
          "div",
          {
            staticClass: "d-flex my-2 overflow-hidden",
            attrs: { "no-gutters": "" }
          },
          [
            _c("profile-icon", {
              attrs: {
                cols: "1",
                userName: user.name,
                id: user.directId,
                image: user.getProfileIconUrl(),
                size: "25"
              }
            }),
            _c("div", { staticClass: "user-label" }, [
              _vm._v(_vm._s(user.name))
            ])
          ],
          1
        )
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }