var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    attrs: {
      id: _vm.id,
      "hide-header": "",
      "hide-footer": "",
      centered: _vm.centered
    },
    on: { hide: _vm.hideEvent },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var ok = ref.ok
          return [
            _c(
              "b-row",
              { staticClass: "error-message", attrs: { "no-gutters": "" } },
              [_vm._v(" " + _vm._s(_vm.msg) + " ")]
            ),
            _c(
              "b-row",
              { staticClass: "error-footer", attrs: { "no-gutters": "" } },
              [
                _c("confirm", {
                  staticClass: "col-5",
                  attrs: { label: "OK" },
                  on: {
                    confirm: function($event) {
                      return _vm.clickedOK(ok)
                    }
                  }
                })
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }