var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "topic-side-menu pt-4" },
    [
      _vm.creatable
        ? _c(
            "div",
            { staticClass: "mb-3 px-4" },
            [
              _c(
                "b-button",
                {
                  staticClass: "create-btn",
                  attrs: { variant: "outline-secondary my-auto" },
                  on: { click: _vm.onTopicCreateClicked }
                },
                [
                  _c("b-icon-plus", { staticClass: "text-left mr-2" }),
                  _c("span", { staticClass: "mr-2" }, [_vm._v("新規話題作成")])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("search-form", { staticClass: "px-4" }),
      _c("div", { staticClass: "menu-group-title mt-4 px-4 mb-2" }, [
        _vm._v("カテゴリー絞り込み")
      ]),
      _c(
        "ul",
        { staticClass: "category-selector px-4" },
        [
          _c(
            "li",
            {
              staticClass: "category-all",
              class: _vm.selected("all"),
              on: {
                click: function($event) {
                  return _vm.onClick("all")
                }
              }
            },
            [_vm._v(" すべてのカテゴリー ")]
          ),
          _vm._l(_vm.categories, function(category) {
            return _c(
              "li",
              {
                key: category.id,
                class: _vm.selected(category),
                on: {
                  click: function($event) {
                    return _vm.onClick(category)
                  }
                }
              },
              [_vm._v(" " + _vm._s(category.title) + " ")]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }