/* tslint:disable */
/* eslint-disable */

export const onCreateTopic = /* GraphQL */ `
  subscription OnCreateTopic($domainId: String!) {
    onCreateTopic(domainId: $domainId) {
      id
      domainId
    }
  }
`;
export const onUpdateTopic = /* GraphQL */ `
  subscription OnUpdateTopic($domainId: String!) {
    onUpdateTopic(domainId: $domainId) {
      id
      domainId
      deleted
      deletedUser
      acl {
        id
        refId
        refIdType
        base {
          create
          read
          update
          delete
        }
        guest {
          id
          refId
          refIdType
          create
          read
          update
          delete
        }
        items {
          id
          refId
          refIdType
          create
          read
          update
          delete
        }
      }
    }
  }
`;
export const onDeleteTopic = /* GraphQL */ `
  subscription OnDeleteTopic($domainId: String!) {
    onDeleteTopic(domainId: $domainId) {
      id
      domainId
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($domainId: String!, $topicId: String!) {
    onCreateMessage(domainId: $domainId, topicId: $topicId) {
      id
      domainId
      topicId
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($domainId: String!, $topicId: String!) {
    onUpdateMessage(domainId: $domainId, topicId: $topicId) {
      id
      domainId
      topicId
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($domainId: String!, $topicId: String!) {
    onDeleteMessage(domainId: $domainId, topicId: $topicId) {
      id
      domainId
      topicId
    }
  }
`;

export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment(
    $domainId: String!
    $topicId: String!
    $messageId: String!
  ) {
    onCreateComment(
      domainId: $domainId
      topicId: $topicId
      messageId: $messageId
    ) {
      id
      domainId
      topicId
      messageId
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment(
    $domainId: String!
    $topicId: String!
    $messageId: String!
  ) {
    onUpdateComment(
      domainId: $domainId
      topicId: $topicId
      messageId: $messageId
    ) {
      id
      domainId
      topicId
      messageId
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment(
    $domainId: String!
    $topicId: String!
    $messageId: String!
  ) {
    onDeleteComment(
      domainId: $domainId
      topicId: $topicId
      messageId: $messageId
    ) {
      id
      domainId
      topicId
      messageId
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory($domainId: String!) {
    onCreateCategory(domainId: $domainId) {
      id
      domainId
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory($domainId: String!) {
    onUpdateCategory(domainId: $domainId) {
      id
      domainId
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory($domainId: String!) {
    onDeleteCategory(domainId: $domainId) {
      id
      domainId
    }
  }
`;
