var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      staticClass: "w-100",
      attrs: { action: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "b-input-group",
        { class: _vm.searchGroupClasses },
        [
          _c(
            "b-input-group-prepend",
            {
              staticClass: "search-input-form search-prepend",
              attrs: { "is-text": "", size: "sm" },
              on: { click: _vm.onSearchPrependClicked }
            },
            [_c("b-icon", { attrs: { icon: "search" } })],
            1
          ),
          _c("b-form-input", {
            ref: "searchForm",
            staticClass: "search-input-form w-100",
            style: _vm.searchInputFormStyle,
            attrs: { type: "search", placeholder: "検索", trim: "" },
            on: { change: _vm.onEnterKeyUp },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.onEnterKeyUp.apply(null, arguments)
              }
            },
            model: {
              value: _vm.text,
              callback: function($$v) {
                _vm.text = $$v
              },
              expression: "text"
            }
          }),
          _c("b-button", { staticClass: "d-none", attrs: { type: "submit" } }),
          _vm.text
            ? _c(
                "b-input-group-append",
                {
                  staticClass: "search-input-form search-append",
                  attrs: { "is-text": "" },
                  on: { click: _vm.onSearchClearClicked }
                },
                [_c("b-icon-x-circle-fill")],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }