var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "24",
        height: "24",
        viewBox: "0 0 24 24"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "グループ_999",
            "data-name": "グループ 999",
            transform: "translate(12 -4) rotate(45)"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "パス_256",
              "data-name": "パス 256",
              d:
                "M19,12.87a1.041,1.041,0,0,0-.8-.98A3,3,0,0,1,16,9V4h1a1,1,0,0,0,0-2H7A1,1,0,0,0,7,4H8V9a3,3,0,0,1-2.2,2.89,1.041,1.041,0,0,0-.8.98V13a1,1,0,0,0,1,1h4.98L11,21a1,1,0,0,0,2,0l-.02-7H18a1,1,0,0,0,1-1Z",
              fill: "inherit",
              "fill-rule": "evenodd"
            }
          })
        ]
      ),
      _c("title", { attrs: { id: _vm.iconName } }, [
        _vm._v(_vm._s(_vm.iconName) + " icon")
      ]),
      _c("g", { attrs: { fill: _vm.iconColor } }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }