import { Attachment as AttachmentDb } from "@/API";

export type Attachment = AttachmentDb;

export class AttachmentFactory {
    public static isAttachment( arg: File | string | Attachment ): arg is Attachment {
        if( typeof arg == "string" ) return false;
        if( "url" in arg && "mime" in arg ) return true;
        return false;
    }

    public static createFrom( path: string, mimeType: string ): Attachment {
        return {
            __typename: "Attachment",
            url: path,
            mime: mimeType,
        }
    }

    public static createFromFile( file: File ): Attachment {
        return {
            __typename: "Attachment",
            url: file.name,
            mime: file.type,
        }
    }

}
