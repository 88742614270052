















































































































































































































































import { mixins } from "vue-class-component";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import NavigationBar from "./NavigationBar.vue";
import CategoryModalDialog from "./CategoryModalDialog.vue";
import CategoryEdit from "./CategoryEdit.vue";
import { Attachment, Category, Topic, User, UNDEFINED_CATEGORY } from "../model";
import FileSelectionForm from "./FileSelectionForm.vue";
import { ActionPayload } from "vuex";
import { COLOR_PALETTE, DEFAULT_COLOR } from "./color-palette";
import ErrorModal from './ErrorModal.vue'
import Confirm from "./button/Confirm.vue";
import Cancel from "./button/Cancel.vue";
import TextProcessMixin from './mixin/TextProcessMixin';
import { Route, NavigationGuardNext } from "vue-router";
import EditDiscardMixin from "./mixin/EditDiscardMixin";
import DragAreaOverlay from './DropAreaOverlay.vue';
import Acl from "../model/acl"
import { allowCreateTopic, allowFeature, FeatureName } from "../direct-app-config";

import "./topic-edit.scss"
import AclManager from "../model/acl-manager";
import { v4 as uuidv4 } from "uuid";
import { clone } from "lodash";

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
])

// 制限値
// const TOPIC_TITLE_MAX_LENGTH = 100; // タイトル文字数100文字まで → titleMaxLength に移動
const TOPIC_DESC_MAX_LENGTH  = 500;

type PrevData = { title: string, desc: string, photos: Attachment[], category: Category|null, pinned: boolean, acl: Acl, pinnedSourceOptions: { notification: boolean } };

@Component({
    mixins: [
        EditDiscardMixin
    ],
    components: {
        NavigationBar, CategoryModalDialog, CategoryEdit, FileSelectionForm, ErrorModal, Confirm, Cancel, DragAreaOverlay,
    }
})
export default class TopicEdit extends mixins( Vue, EditDiscardMixin, TextProcessMixin ) {
    name: string = 'topic-edit';

    // ナビゲーションバーの設定
    items = [
        { label:"保存", click: this.onEditComplete, disabled: this.completeButtonDisabled }
    ]

    titleSource: string = "";               // タイトル
    descSource: string = "";                // 説明
    thumbSource: (File | Attachment)[] = [];// サムネ(file-selection-formのfilesと同期させる)
    categorySource: Category|null = null;   // 現在選択中のカテゴリー
    pinnedSource: boolean = false;          // 固定
    pinnedSourceOptions: { notification: boolean } = { notification: false };

    // ACL
    allowGuestSource: boolean = true;           // true: ゲストの閲覧許可
    allowWriteOthersSource: boolean = true;     // true: 作成者以外が変更できる

    replaceParam: { domainId: string, topicId: string } = { domainId: '', topicId: '' }; // router.replace用パラメータ

    prevData: PrevData = { title: "", desc: "", photos: [], category: null, pinned: false, acl: Acl.createDummy(), pinnedSourceOptions: { notification: false } };

    fileTypeErrorMsg: string = "下記の添付ファイルは対応しておりません。";

    submitLock: boolean = false;
    drag: boolean = false;

    topicIdByCreate: string = ''; // 新規作成時にあらかじめ作るtopicId

    // Vuexの変更監視
    unsubscribeMutation?: () => void;

    /** create: 新規作成 edit: 編集 */
    @Prop({ default: "edit" }) readonly type!: string;

    // 組織IDは偽装される場合があるためここでは設定しない
    // @Prop({ required: true }) readonly domainId!: string;

    // 初期データ：カテゴリー一覧
    @Prop( { default: () => [] }) readonly categories!: Category[];

    // 編集時の組織ID。未指定時は新規追加
    @Prop( { default: undefined }) readonly topicId?: string;
    @Prop( { default: "" } ) readonly domainId!: string;

    /** タイトル */
    @Prop({ default: "" }) readonly title!: string;
    @Watch( 'title', { immediate: true } ) onTitleChanged( value: string ): void {
        this.titleSource = value;
        this.saveTmpData();
    }

    /** 説明 */
    @Prop({ default: "" }) readonly desc!: string;
    @Watch( 'desc', { immediate: true } ) onDescChanged( value: string ): void {
        this.descSource = value;
        this.saveTmpData();
    }

    /** サムネイル */
    @Prop({ default: undefined }) readonly thumb?: Attachment;
    @Watch( 'thumb', { immediate: true } ) onThumbChanged( value: Attachment ): void {
        this.thumbSource = this.photos.slice();
        this.saveTmpData();
    }

    /** カテゴリー */
    @Prop({ default: null }) readonly category!: Category;
    @Watch( 'category', { immediate: true } ) onCategoryChanged( value: Category ): void {
        this.categorySource = value;
        this.saveTmpData();
    }

    /** 固定 */
    @Prop({ default: false }) readonly pinned!: boolean;
    @Watch( 'pinned', { immediate: true } ) onPinnedChanged( value: boolean ): void {
        this.pinnedSource = value;
        // 固定オプション
        if( this.pinnedSource ) {
            if( this.$store ) {
                const topic = this.$store.getters["topics/getOne"]( this.domainId, this.topicId );
                if( topic ) this.pinnedSourceOptions.notification = !!topic.notification;
            }
        }
        this.saveTmpData();
    }

    @Prop({ default: () => Acl.createDummy() }) readonly acl!: Acl;
    @Watch( 'acl', { immediate: true } ) onAclChanged( value: Acl ): void {
        this.allowGuestSource = value.readTopicByGuest();
        this.allowWriteOthersSource = value.createMessageByOthers();
        this.saveTmpData();
    }

    // Direct組織設定
    @Prop({ default: true }) readonly allow_attachment_type!: number;

    // Storbook用(ダイアログの自動表示)
    @Prop({ default: false }) show!: boolean;

    @Prop({ default: false }) isMobile!: boolean;

    // ログインユーザー情報
    @Prop({ default: () => User.createNotFoundUser() }) readonly me!: User;

    // 添付可能か
    get allow_attachment(): boolean { return this.allow_attachment_type > 0; }

    get isNew(): boolean { return this.topicId == undefined }

    get navTitle(): string { return this.isNew ? "新規話題" : "話題編集" }

    get selectedCategoryTitle(): string {
        if( this.categorySource ) return this.categorySource.title;
        return "（未選択）"
    }

    get selectedCategoryColor(): string {
        // カテゴリー未選択
        if( !this.categorySource ) return "";

        const color = this.categorySource.color;
        const index = COLOR_PALETTE.findIndex( c => c == color );
        return index < 0 ? DEFAULT_COLOR : color;
    }

    get categoryButtonVariant(): string {
        if( this.categorySource ) return this.categorySource.title ? "" : "danger";
        return "danger";
    }

    get completeButtonDisabled(): boolean {
        if( AclManager.createTopic( this.$store, this.domainId ) == false ) return false;    // 書き込み権無し
        return !this.validateTitle || !this.validateDesc || !this.categorySource?.title ;
    }

    get categoryErrorMsg(): string {
        return this.categorySource != null ? `カテゴリー「${this.categorySource.title}」は削除されています` : 'カテゴリーが未選択です';
    }

    get photos(): Attachment[] { return this.thumb ? [ this.thumb ] : [] }

    // タイトルのvalidate
    get validateTitle(): boolean {
        if( !this.titleSource ) return false;
        const tmp = this.titleSource.trim();
        return 0 < this.getLength(tmp) && this.getLength(tmp) <= this.titleMaxLength();
    }

    get validTitleMessage(): string {
        const tmp = this.titleSource.trim();
        const titleMaxLength = this.titleMaxLength();
        return this.getLength(tmp)? `タイトルは${titleMaxLength}文字以内です(${this.getLength(tmp)}/${titleMaxLength})`:'タイトルは必須項目です';
    }

    // 詳細のvalidate
    get validateDesc(): boolean {
        if( !this.descSource ) return false;
        const tmp = this.descSource.trim().replace(/\r?\n/g, '');
        return 0 < this.getLength(tmp) && this.getLength(tmp) <= TOPIC_DESC_MAX_LENGTH;
    }

    get validDescMessage(): string {
        const tmp = this.descSource.trim().replace(/\r?\n/g, '');
        return this.getLength(tmp)? `説明は${TOPIC_DESC_MAX_LENGTH}文字以内です(${this.getLength(tmp)}/${TOPIC_DESC_MAX_LENGTH})`:'説明は必須項目です';
    }

    // カテゴリーのvalidate
    get validateCategory(): boolean {
        return 0 < ( this.categorySource?.title || "" ).length
    }

    // true: 変更点が有る
    get editMode(): boolean {
        if( this.titleSource != this.prevData.title ) return true;
        if( this.descSource != this.prevData.desc ) return true;
        if( JSON.stringify(this.thumbSource) != JSON.stringify(this.prevData.photos) ) return true;
        if( this.categorySource != this.prevData.category ) return true;
        if( this.pinnedSource != this.prevData.pinned ) return true;
        if( this.pinnedSourceOptions.notification != this.prevData.pinnedSourceOptions.notification ) return true;
        // acl
        if( this.allowGuestSource != this.prevData.acl.guest.read ) return true;
        if( this.allowWriteOthersSource == true     && this.prevData.acl.base.createMessage != "allow" ) return true;
        if( this.allowWriteOthersSource == false    && this.prevData.acl.base.createMessage != "deny"  ) return true;
        /** acl.itemsの編集記録は編集モーダルを閉じても残っているため判定は行わない */

        return false;
    }

    /** @return true: ログインユーザが管理者ロール以上 */
    get isAdmin(): boolean { return this.me.isAdminRole( this.domainId ) || this.me.isOwnerRole( this.domainId ) }

    // prevDataに一時的にデータ保存する
    saveTmpData(): void {
        this.prevData = {
            title: this.title, desc: this.desc, photos: this.photos, category: this.category, pinned: this.pinned, acl: this.acl.clone(), pinnedSourceOptions: clone(this.pinnedSourceOptions),
        }
    }

    resetParam() {
        // パラメータの初期化
        this.titleSource = "";
        this.descSource = "";
        this.thumbSource = [];
        this.categorySource = null;
        this.pinnedSource = false;
        this.pinnedSourceOptions = { notification: false };
        this.allowGuestSource = true;
        this.allowWriteOthersSource = true;
        this.topicIdByCreate = Topic.createId();
        this.submitLock = false;
    }

    dropFiles(files: FileList): void {
        const form = this.$refs.fileSelectionForm as FileSelectionForm;
        form.dropFiles(files);
    }

    updateFile(files: (File | Attachment)[]): void {
        this.thumbSource = files;
        this.changeEditMode(this.editMode);
    }

    updateComplteButtonDisabled(): void {
        this.items[0].disabled = !(
                                    this.validateTitle
                                    && this.validateDesc
                                    && this.validateCategory
                                );
    }

    onChangePinnedSource(): void {
        // 固定ピンがオフになった場合はそのoptionも全てオフ
        if( !this.pinnedSource ) {
            this.pinnedSourceOptions.notification = false;
        }
    }

    // カテゴリ選択
    onCategorySelected( categories: Category[] ): void {
        if( 0 < categories.length ) {
            this.categorySource = categories[0];
            this.updateComplteButtonDisabled();
        }
        this.changeEditMode(this.editMode);
    }

    // 選択されたカテゴリーが存在するか(削除されていないか)
    existsSelectedCategory(): boolean {
        const categories = this.$store.getters["categories/get"]( this.domainId, false ) as Category[]; // 削除されたものも含めてカテゴリー取得
        const category = categories.find( c => this.categorySource != null && c.id == this.categorySource.id );
        return Category.isExists(category);
    }

    // キャンセルボタンが押されたときの処理
    onEditCancel(): void {
        this.closeModal();
    }

    // 完了ボタンが押された時の処理
    async onEditComplete(): Promise<void> {
        // 送信連打による連続送信を防止
        if( this.submitLock ) return;
        this.submitLock = true;

        // カテゴリーが削除された場合(またはカテゴリーが未選択)の場合にエラー
        if( !this.existsSelectedCategory() ) {
            this.$root.$emit('show-error-modal', {msg: this.categoryErrorMsg, afterProcess: ()=>{return} })
            this.submitLock = false;
            return;
        }

        // 新規作成前に作成上限を超えている場合はアラート
        if( this.isNew && allowCreateTopic( this.domainId, this.$store ) == false ) {
            this.$root.$emit('free-alert');
            this.submitLock = false;
            return;
        }

        const topicId = this.topicId || this.topicIdByCreate;

        const form = this.$refs.fileSelectionForm as FileSelectionForm;
        let file = null;
        if( form ) {
            // S3にアップロード
            const files = await form.uploadTopicFile( topicId );
            file = 1 <= files.length ? files[0] : null;
        }

        /* ACLの更新 */
        const storeAcl = this.$store.getters["getAclData"](topicId) as Acl;
        const aclItems = storeAcl ? storeAcl.items : this.acl.items;
        const acl = Acl.createByTopic( topicId, this.allowGuestSource, this.allowWriteOthersSource, aclItems );
        this.$store.dispatch("setAclData", {id: this.topicId, acl: acl }); // ゲスト/書き込み権も含めたACLに更新
        
        const notificationId = this.getNotificationId();
        const param: Partial<Topic> = {
            id: topicId,
            domainId: this.domainId,
            title: this.titleSource,
            desc: this.descSource,
            icon: file,
            category: this.categorySource || UNDEFINED_CATEGORY,
            pinned: this.pinnedSource,
            acl: acl,
            notification: notificationId,
        }
        const topic = this.isNew ? Topic.createTemp( param ) : param;
        const call = this.isNew ? "on-topic-create" : "on-topic-update"
        const obj = this.isNew ? { topic } : { param: topic };
        if( this.$root && this.$root.$emit ) {
            this.$root.$emit( call, obj );
        }

        this.changeEditMode(false);
        // 完了後は閉じる
        this.closeModal();
    }

    // close Event
    closeModal(): void {
        if( this.$router && this.isMobile ) {
            this.$router.back();
        } else if( !this.isMobile ) {
            this.$bvModal.hide('modal-topic');
        }
        if( this.topicId ) {
            // 編集したaclのリセット
            this.$store.dispatch("setAclData", {id: this.topicId, acl: undefined });
        }
    }

    // 削除後のイベント
    afterDeleteProcess(): void {
        this.$router.replace(`/${this.replaceParam.domainId}`);
    }

    // Feature
    hasFeature( keyword: FeatureName ): boolean { return allowFeature( keyword, this.$store ); }
    titleMaxLength(): number {
        return this.hasFeature( "topic-title-long" ) ? 100 : 20;
    }

    openAclEditModal(): void {
        if( !this.$store ) return;
        const topicId = this.topicId || this.topicIdByCreate;
        const storeAcl = this.$store.getters["getAclData"]( topicId );
        if( !storeAcl ) {
            this.$store.dispatch("setAclData", {id: topicId, acl: this.acl });
        }
        this.$root.$emit('open-acl-modal', { id: topicId, type: "TOPIC", acl: this.$store.getters["getAclData"](topicId) });
    }

    // 話題のnotification取得
    getNotificationId(): string | undefined {
        const notification = this.pinnedSourceOptions.notification;
        // 全体通知は行わない
        if( !notification ) { return undefined; }
        // 新規投稿: notificationId作成
        else if( this.isNew ) { return uuidv4(); }
        // storeがない場合は、「全体通知なし」で扱う
        else if( !this.$store ) { return undefined; }
        // 既に設定済みの場合は、そのID
        else {
            const topic = this.$store.getters["topics/getOne"]( this.domainId, this.topicId );
            if( topic ) {
                return topic.notification ? topic.notification : uuidv4();
            } else {
                return undefined;
            }
        }
    }

    // Lifecycle
    beforeDestroy(): void {
        if( this.unsubscribeMutation ) this.unsubscribeMutation()
    }
    created(): void {
        this.updateComplteButtonDisabled();
    }
    mounted(): void {
        if( this.show ) {
            this.$bvModal.show( 'modal-topic' );
        }
        if( !this.$store ) return;

        this.unsubscribeMutation = this.$store.subscribe( ( action: ActionPayload, state: unknown ) => {
            // 開いている話題が削除された -> 話題一覧に移動
            if( action.type == "topics/delete" ) {
                const domainId = this.domainId;
                const topicId = this.topicId;
                if( action.payload.topicId == topicId ) {
                    if(topicId) { this.replaceParam = { domainId: domainId, topicId: topicId }; }
                    this.$root.$emit('show-error-modal', { msg: "話題が削除されたため、話題一覧に移動します", afterProcess: () => this.afterDeleteProcess() })
                }
            }
        });
    }
    beforeRouteEnter( to: Route, from: Route, next: NavigationGuardNext ): void {
        // 編集画面でリロードされた場合、話題ページへリダイレクト
        if( from.name ) { next(); }
        else {
            const domainId = to.params.domainId;
            console.log(`* redirect: /${domainId}`);
            next( `/${domainId}` );
        }
    }
    beforeRouteLeave( to: Route, from: Route, next: NavigationGuardNext ): void {
        this.routeLeaveEvent(next);
    }
}
