













import { Component, Prop, Vue } from 'vue-property-decorator';
import { isDirectAppsUrl } from '../url/links';
import type { AppLinkItem } from './types';

@Component({
    components: {}
})
export default class NavbarMenuSolutionItem extends Vue {
    name: string = "navbar-menu-solution-item-class";


    @Prop({ default: () => [] })
    readonly appLinkItem!: AppLinkItem;

    @Prop({ default: () => "" })
    readonly domainId!: string;

    public getAppLinkItem() {
        return this.appLinkItem
    }

    get classes(): { [selector: string]: boolean } {
        return {
            'navbar-menu-solution-item-class' : true
        }
    }

    hasLoadingError = false

    onLoadingError(): void {
        this.hasLoadingError = true
    }

    iconUrl() {
        return this.hasLoadingError
            ? `/assets/images/solutions/app_icon_noload.svg`
            : this.appLinkItem.iconUrl
    }
    isDirectApp(): boolean {
        return isDirectAppsUrl(this.appLinkItem.url)
    }
}

