import { AppKeys, directAppUrl } from '@/direct-app-config'
import { Solution } from '@/model'
import { Store } from 'vuex'

export const getAppTarget = (solution?: Solution) => {
  if (!solution) {
    return 'direct-home'
  } else {
    return 'direct-apps'
  }
}

/**
 * Solutionリンク用のURLを返す
 * @param domainId 組織ID
 * @param solution Solution情報。「トーク」の場合は指定しない
 * @returns
 */
export const getSolutionUrl = ( $store: Store<unknown>, domainId: string, solution?: Solution,): string => {
  if (solution) {
    return directAppUrl( AppKeys.AUTO, domainId, $store, solution )
  } else {
    // トーク
    return directAppUrl( AppKeys.TALK, domainId, $store )
  }
}
