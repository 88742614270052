





























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class MessagePageHeader extends Vue {
    name: string = 'message-page-header';

    @Prop({ required: true }) readonly titleLabel!: string;
    @Prop({ required: true }) readonly domainId!: string; //!< 所属組織ID
    @Prop({ required: true }) readonly topicId!: string;  //!< 所属話題ID

    async back(): Promise<void> {
        if( this.$router ) {
            await this.$store.dispatch('messages/reset', {domainId: this.domainId, topicId: this.topicId, messageId: this.$route.params.messageId});
            this.$store.dispatch('setDomainId', this.domainId);
            this.$store.dispatch('setTopicId', ''); // topicIdのリセット
            this.$router.push({ path: `/${this.domainId}` })
        }
    }
}
