















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DepartmentsArea from "./Departments/DepartmentsArea.vue";
import { Department } from "./Departments/values/Department";
import { FilteredDepartment } from "./Departments/values/FilteredDepartment";
import cloneDeep from "lodash/cloneDeep";

@Component({
    components: {
       DepartmentsArea,
    }
})
export default class DepartmentsTree extends Vue {
    name: string = "departments-tree";
    filteredDepartments: FilteredDepartment[] = [];
    departmentSource: Department[] = [];

    // ユーザーを登録する際、複数部署登録にも対応させるために区別IDを用いる
    user_unique_number: number = 0;

    @Prop({ default: '', required: true }) readonly departments!: Department[];
    @Watch( 'departments', { immediate: true } ) onDepartmentsChanged( value: Department[] ): void {
        this.departmentSource.splice(0, this.departmentSource.length);
        this.departmentSource.push( ...cloneDeep(value) );
    }

    @Prop({ default: () => [] }) readonly denyIdList!: string[];

    // 部署を選択
    selectDepartment(id: string): void {
        const department = this.departments.find( dep => dep.departmentIdStr === id );
        this.$emit("selectDepartment", department);
    }

    // 開く
    expandDepartment(id: string): void {
        const index = this.departmentSource.findIndex( department => department.departmentIdStr == id );
        if(index < 0) return;

        const opened = this.departmentSource[index].opened;
        if( !opened ) {
            this.openDepartment(this.departmentSource[index]);
        }
    }

    // 閉じる
    collapseDepartment(id:string): void {
        const index = this.departmentSource.findIndex( department => department.departmentIdStr == id );
        if(index < 0) return;

        const opened = this.departmentSource[index].opened;
        if( opened ) {
            this.closeDepartment(this.departmentSource[index]);
        }
    }

    // 検索ボックス入力
    changeDepartmentsKeyword( value: string ): void {
        if( !value ) {
            this.filteredDepartments = [];
            return;
        }
        const hitDepartments = this.searchDepartments( value );
        this.setFilteredDepartments( hitDepartments );
    }

    // 開いた時の処理
    openDepartment( department: Department ): void {
        department.opened = true;

        const childDep = department.childIdStrList
            .map(id => { return this.departmentSource.find(dep => dep.departmentIdStr === id); })
            .filter( dep => dep !== undefined ) as Department[];
            
        childDep.forEach( child => child.visibled = true )
    }

    // 閉じた時の処理
    closeDepartment( department: Department ): void {
        department.opened = false;
        const target = this.sliceChildrenList( department );
        target.forEach( dep => {
            // 子リスト全てを閉じて、非表示化
            dep.opened = false;
            dep.visibled = false;
        })
    }

    searchDepartments( keyword: string ): Department[] {
        console.log(keyword);
        const hitDepartments = this.departmentSource.filter( dep => dep.name.indexOf(keyword) > -1 );
        return hitDepartments;
    }

    setFilteredDepartments( deps: Department[] ): void {
        this.filteredDepartments = deps.map( dep => {
            return {
                name: dep.name,
                departmentIdStr: dep.departmentIdStr,
                userCount: dep.userCount,
                depth: dep.depth,
                fullPathWithoutRoot: ''
            }
        })
    }

    // 該当部署の子リストを返す (子共の子供の...等も全て含む)
    sliceChildrenList( department: Department ): Department[]  {
        const index = this.departmentSource.findIndex( dep => dep.departmentIdStr == department.departmentIdStr );
        if( index < 0 ) return [];
        // 該当の部署より下のindexを切り出し
        const filtered = this.departmentSource.slice( index + 1 );

        // さらに子要素を切り出し
        const results: Department[] = [];
        for( let i = 0; i < filtered.length; i++ ) {
            const dep = filtered[i];
            if( dep.depth > department.depth ) {
                results.push(dep);
            } else {
                break;
            }
        }
        return results;
    }
    
}
