



















import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class Delete extends Vue {
    name = "delete";

    onClick(): void {
        this.$emit('deleteFile');
    }
}
