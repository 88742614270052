import Store from "@/store/index";
import { ServerApiAccess } from "../server-api-access";

export default class GetDepartmentsTask {
    public static async run( domainId: string, $store: typeof Store ): Promise<void> {
        const access = new ServerApiAccess();

        const task = async () => {
            try {
                const departments = await access.listDepartments(domainId);
                await $store.dispatch("domains/setDepartments", departments);
            } catch( error ) {
                console.error( "get-departments error:%O", error );
            }
        }

        await task();
    }
}