





































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({})
export default class CheckBox extends Vue {
  name = "check-box";
  checked: boolean = false;

  // 外部からの指定
  @Prop({ default: false }) readonly selected!: boolean;
  @Watch("selected")
    onChange() {
        if( this.checked !== this.selected ) {
            this.checked = this.selected;
        }
    }

  created() {
    this.checked = this.selected;
  }

  onClick(): void {
    this.checked = !this.checked;
    this.$emit("change", this.checked)
  }
}
