export enum OS_TYPE { IOS = "ios", ANDROID = "android", UNKNOWN = "unknown", Mac = "Mac", Win = "Win" }

/** URLクエリから、モバイルかどうかを判定する */
export function isMobile( device: string ): boolean {
    if( !device ) return false;
    switch( device ) {
        case "iPhone":
        case "iPad":
        case "Android":
            return true;
        default:
            return false;
    }
}

export function isDesktopApp( invoker: string ): boolean {
    // ブラウザ版の場合、invokerappは "browser-direct"
    return invoker == "desktop-direct";
}

export function isBrowserApp( invoker: string ): boolean {
    return invoker == "browser-direct";
}

export function osType( device: string ): OS_TYPE {
    if( !device ) return OS_TYPE.UNKNOWN;
    switch( device ) {
        case "iPhone":      return OS_TYPE.IOS;
        case "iPad":        return OS_TYPE.IOS;
        case "Android":     return OS_TYPE.ANDROID;
        case "Mac":         return OS_TYPE.Mac;
        case "Win":         return OS_TYPE.Win;
        default:            return OS_TYPE.UNKNOWN;
    }
}

export function isAndroid( arg: string ): boolean {
    const type = osType( arg );
    return type == OS_TYPE.ANDROID;
}

export function isIos( arg: string ): boolean {
    const type = osType( arg );
    return type == OS_TYPE.IOS;
}
