











































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class NotAllowedModal extends Vue {
    name: string = 'not-allowed-modal';

    @Prop({ default: '', required: true }) readonly msg!: string;

    @Prop({ default: false }) readonly show!: boolean;

    @Prop({ default: '/img/icon/1152921507291203198.png' }) readonly icon!: string;
}


