var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    {
      staticClass: "d-flex align-items-center",
      class: _vm.classes,
      attrs: { fluid: "", id: "tpc_" + _vm.id },
      on: { click: _vm.linkClicked }
    },
    [
      _c(
        "b-row",
        { staticClass: "d-inline-flex topic-list-item-body" },
        [
          _c(
            "b-col",
            {
              staticClass:
                "d-inline-flex align-items-center px-2 topic-list-item-icon-container",
              staticStyle: { "max-width": "80px" }
            },
            [
              _vm.inSidemenu == false
                ? _c("profile-icon", {
                    staticClass: "topic-list-item-icon rounded-circle m-auto",
                    attrs: {
                      topicId: _vm.id,
                      userName: _vm.title,
                      image: _vm.iconUrl,
                      size: "52"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "px-2 d-flex topic-list-item-container" },
            [
              _c("div", { staticClass: "d-inline-flex topic-header" }, [
                _c(
                  "span",
                  {
                    class:
                      "badge badge-secondary category notselect-pointer " +
                      _vm.categoryColor
                  },
                  [_vm._v(_vm._s(_vm.categoryLabel))]
                ),
                _vm.pinned
                  ? _c(
                      "div",
                      { staticClass: "ml-2 notselect-pointer" },
                      [_c("icon-pinned", { staticClass: "pinned" })],
                      1
                    )
                  : _vm._e(),
                !_vm.inSidemenu && _vm.notification
                  ? _c("span", {
                      staticClass: "dicon dicon-speaker-on topic-notification"
                    })
                  : _vm._e(),
                0 < _vm.messages
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "ml-2 d-inline-flex notselect-pointer messages-icon"
                      },
                      [
                        _c("b-icon-chat-left", { staticClass: "mr-1" }),
                        _vm._v(_vm._s(_vm.messages))
                      ],
                      1
                    )
                  : _vm._e(),
                0 < _vm.watches
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "ml-2 d-inline-flex notselect-pointer watches-icon"
                      },
                      [
                        _c("b-icon-star", { staticClass: "mr-1" }),
                        _vm._v(_vm._s(_vm.watches))
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.hasFeature("topic-guest-label") &&
                _vm.allowedGuest &&
                _vm.inSidemenu == false
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "ml-2 badge badge-secondary allow-guest notselect-pointer "
                      },
                      [_vm._v("ゲスト可")]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "pl-2 accessory notselect-pointer" }, [
                  _vm._v(_vm._s(_vm.updatedAtLabel))
                ])
              ]),
              _c(
                "div",
                { staticClass: "d-inline-flex topic-body" },
                [
                  _c("div", {
                    ref: "topic-title",
                    staticClass: "topic-title",
                    domProps: { innerHTML: _vm._s(_vm.normalizedTitleLabel) },
                    on: { click: _vm.onClick }
                  }),
                  _vm.starFlag && !_vm.search
                    ? _c(
                        "div",
                        {
                          staticClass: "accessory",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.onStarClick.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "b-iconstack",
                            {
                              staticClass: "star",
                              attrs: { "font-scale": "1" }
                            },
                            [
                              _c("b-icon-star-fill", {
                                staticClass: "has-star"
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : !_vm.search
                    ? _c(
                        "div",
                        {
                          staticClass: "accessory",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.onStarClick.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "b-iconstack",
                            {
                              staticClass: "star",
                              attrs: { "font-scale": "1" }
                            },
                            [
                              _c("b-icon-star-fill", {
                                staticClass: "not-star"
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("b-tooltip", {
                    attrs: {
                      target: function() {
                        return _vm.$refs["topic-title"]
                      },
                      title: _vm.normalizedTitleLabel
                    },
                    on: { show: _vm.onTooltipShow }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c("b-row", { ref: "topic-desc", staticClass: "topic-desc" }, [
        _c("div", {
          staticClass: "topic-title",
          domProps: {
            innerHTML: _vm._s(
              _vm.$sanitize(_vm.desciption, {
                allowedAttributes: { span: ["class"] }
              })
            )
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }