



























import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
    components: {
    }
})
export default class Fab extends Vue {
    name = "fab"

    @Prop() readonly to!: string;

    click(): void {
        if( this.to ) {
            if( this.$router ) {
                this.$router.push( this.to );
            }
        } else {
            this.$emit("fab-clicked");
        }
    }
}
