



































































import { Component, Vue } from "vue-property-decorator";
import IconNavigationBrand from "../components/icon/IconNavigatonBrand.vue";
import NavigationBar from '../components/NavigationBar.vue';

@Component({ components: { IconNavigationBrand, NavigationBar } })
export default class LoginErrorOidc extends Vue {
    public readonly name = "login-error-oidc";

    readonly appName = "掲示板";

    loginErrorOidcRestrict: boolean = false;

    get loginRestrictMessage(): string {
        return ( this.$route.query?.message || "unauthorized_client" ) as string
    }

    get loginRestrictDesc(): string {
        return (this.$route.query?.desc || "") as string;
    }

    get isMobile(): boolean {
        if( !this.$store ) return false;
        const isMobile = this.$store.getters[ "isMobile" ] as boolean;
        return isMobile;
    }
}
