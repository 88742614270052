

































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
    components: {
    }
})
export default class SearchForm extends Vue {
    name: string = "search-form";

    // model
    text: string = "";

    // searchクエリが変わった場合に検索窓へ反映
    @Watch("$route.query.search")
    onQueryChanged(search: string|undefined): void {
        this.text = search || "";
    }

    get searchGroupClasses(): Record<string, boolean> {
        return {
            "flex-nowrap": true,
            "searching": !!this.text,
        }
    }

    get searchInputFormStyle(): Record<string,string> {
      // 検索文字列があるかどうかで角丸スタイルを変更する
      if( this.text ) {
        return {};
      } else {
        return {
          "border-top-right-radius": "10px !important",
          "border-bottom-right-radius": "10px !important",
        }
      }
    }

    // 虫眼鏡アイコンをクリックしたとき、検索窓にフォーカス入れる
    onSearchPrependClicked(): void {
        const form = this.$refs.searchForm as HTMLInputElement;
        if( form ) {
            form.focus();
        }
    }

    // クリアボタン
    onSearchClearClicked(): void {
        this.submit( "" );
    }

    // 検索実行
    submit( search?: string | KeyboardEvent ): void {
        if( search != undefined && typeof search == "string" ) {
            this.text = search;
        }
        if( this.$root ) {
            const searchQuery = this.$route.query.search || "";
            if( searchQuery != this.text ) {
                // historyに載せる
                const query = this.text ? { search: this.text } : {};
                this.$router.push({ path: this.$route.path, query: query });
            }
        }
    }

    onEnterKeyUp() {
        if(!this.text) this.submit( "" );
    }

    created(): void {
        this.text = this.$route?.query?.search as string || "";
    }

}
