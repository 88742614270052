
























































import { Department } from './values/Department'
import { FilteredDepartment } from './values/FilteredDepartment'
import DepartmentsTreeNode from './DepartmentsTreeNode.vue'
import FilteredDepartmentsTreeNode from './FilteredDepartmentsTreeNode.vue'
import DepartmentsSearchBox from './DepartmentsSearchBox.vue'
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    DepartmentsSearchBox,
    DepartmentsTreeNode,
    FilteredDepartmentsTreeNode
  }
})
export default class DepartmentsArea extends Vue {
  keyword: string = '';
  nextFocusHolder: string = '';
  tree: HTMLDivElement | null = null;
  treeHeight: number = 0;

  @Prop({ required: true }) readonly localized!: boolean;
  @Prop({ required: true }) readonly disabled!: boolean;
  @Prop({ required: true }) readonly departments!: Department[];
  @Prop({ required: true }) readonly filteredDepartments!: FilteredDepartment[];
  @Prop({ required: true }) readonly selectedDepartmentIdStr!: string;
  @Prop({ default: false }) readonly useSmallMargin!: boolean;
  @Prop({ default: () => [] }) readonly denyIdList!: string[];

  get visibleDepartments(): Department[] {
    return this.departments.filter(department => department.visibled)
  }

  get isTreeVisible(): boolean {
    return this.departments.length !== 0 || this.filteredDepartments.length !== 0
  }

  get isNotFiltered(): boolean {
    return this.keyword.length === 0;
  }

    // methods
  expandDepartment(departmentIdStr: string): void {
    this.$emit('expandDepartment', departmentIdStr);
  }

  collapseDepartment(departmentIdStr: string): void{
    this.$emit('collapseDepartment', departmentIdStr); 
  }

  selectDepartment(departmentIdStr: string): void {
    this.$emit('selectDepartment', departmentIdStr);
  }

  onChangeDepartmentsKeyword(value: string): void {
    this.keyword = value
    this.$emit('changeDepartmentsKeyword', value);
  }

  onScroll(): void {
    this.$emit('requestToLoadUserCount');
  }

  onWindowResize(): void {
    const newTreeHeight = this.tree?.clientHeight ?? 0
    if (this.treeHeight === newTreeHeight) {
      return
    }
    this.$emit('requestToLoadUserCount');
  }

  getFocusHolderForNormalNodes(newIndex: number): string | undefined {
    if (newIndex < 0 || this.visibleDepartments.length - 1 < newIndex) {
      return undefined
    }
    return this.visibleDepartments[newIndex]?.departmentIdStr;
  }

  getFocusHolderForFilteredNodes(newIndex: number): string | undefined {
    if (newIndex < 0 || this.filteredDepartments.length - 1 < newIndex) {
      return undefined
    }
    return this.filteredDepartments[newIndex]?.departmentIdStr
  }

  updateFocusHolder(newIndex: number): void {
    const result = this.isNotFiltered
      ? this.getFocusHolderForNormalNodes(newIndex)
      : this.getFocusHolderForFilteredNodes(newIndex);
      this.nextFocusHolder = result ?? 'searchBox';
  }

  focusNextFromSearchBox(): void { this.updateFocusHolder(0); }

  focusPrevFromSearchBox(): void {
    const array = this.isNotFiltered
      ? this.visibleDepartments
      : this.filteredDepartments;
    this.updateFocusHolder(array.length - 1);
  }

  focusPrev(currentIndex: number): void { this.updateFocusHolder(currentIndex - 1); }

  focusNext(currentIndex: number): void { this.updateFocusHolder(currentIndex + 1); }

  onBlur() {
    this.nextFocusHolder = ''
  }

  clearDepartmentsKeyword() {
    this.keyword = ''
    this.$emit('changeDepartmentsKeyword', '');
  }

  // lifecycle
  mounted() {
    window.addEventListener('resize', this.onWindowResize);
  }

  beforeUnmount() { 
    window.removeEventListener('resize', this.onWindowResize);
  }
}
// 要注意: Haxe 経由での JQuery からのDOM変更が残っている
