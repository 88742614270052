import AppAuth, { PROVIDER } from "@/aws-config";
import sentry from "@/sentry";

// Mobileログインのリトライ回数
const RETRY_COUNT = parseInt( process.env.VUE_APP_MOBILE_LOGIN_RETRY_COUNT || "5" , 10 );

type MOBILE_OS = "ios" | "and"

let workflow: LoginMobileWorkflow;
async function timeout( timer: number ) { new Promise(resolve => setTimeout(resolve, timer)) } //

/**
 * モバイル向けのログインワークフロー
 * @param provider
 * @param option
 * @param progress
 */
export default async function mobileLoginWorkflow( provider: PROVIDER, option: MOBILE_OS, progress: ( msg: string ) => void, count: number = 0 ): Promise<void> {
    // OIDC認証の遷移先を取得する
    const next = await AppAuth.signInMobile( provider, option );

    if( next === undefined ) {
        // CognitoUserPoolでの処理成功によりこれ以上処理不要
        return
    } else if( IsValidURL( next ) == false ) {
        // 取得したモバイル向けOIDCログインURLが不正。
        // ここで再度モバイル向けOIDCログインURL取得処理に戻らせる。sentryにも通知する
        if( count <= RETRY_COUNT ) {
            // sentryに通知
            sentry.sendSentry( `MobileLoginError(workflow):${next}` )
            const error = MobileLoginError.createLoginUrlError( next )
            sentry.sendSentryError( error )

            await timeout( 1000 ); // 1秒待つ
            return await mobileLoginWorkflow( provider, option, progress, count +1 );
        } else {
            // 例外処理は関数利用側で行う
            throw MobileLoginError.createLoginUrlErrorRetryOver( next )
        }
    }

    // 以降、next は URLとしてはまともなもの
    console.log(`★ログイン(mobile)：${next}`)
    progress( "ログイン中です" )

    try {
        workflow = new LoginMobileWorkflow( provider, option, next, progress );
        workflow.login();
    } catch( e ) {
        // 例外処理は関数利用側で行う
        console.log(e)
        throw e;
    }
}

function IsValidURL(url: unknown): boolean {
    if( typeof url != "string" ) return false;  // 文字列型では無い時点で URL 文字列とは見なさない
    try {
        const urlObj = new URL(url);            // URL文字列では無い場合ここで例外が出る
        return urlObj.protocol === "http:" || urlObj.protocol === "https:";
    } catch (error) {
        return false;
    }
}

const MOBILE_LOGIN_ERROR_URL = "Mobile Login ERROR(INVALID URL)"
const MOBILE_LOGIN_ERROR_URL_RETRY = "Mobile Login ERROR(INVALID URL, RETRY OVER)"
const MOBILE_LOGIN_ERROR_RETRY = "Mobile Login ERROR(RETRY OVER)"
type MOBILE_LOGIN_ERROR_TYPE = typeof MOBILE_LOGIN_ERROR_URL | typeof MOBILE_LOGIN_ERROR_RETRY | typeof MOBILE_LOGIN_ERROR_URL_RETRY;

/** モバイルログイン系エラー */
class MobileLoginError extends Error {
    public static createLoginUrlError( url: string ) {
        return new MobileLoginError( MOBILE_LOGIN_ERROR_URL, "InvalidUrl", url );
    }
    public static createLoginUrlErrorRetryOver( url: string ) {
        return new MobileLoginError( MOBILE_LOGIN_ERROR_URL, "InvalidUrl(Retry Over)", url );
    }
    public static createLoginRetryError( url: string ) {
        return new MobileLoginError( MOBILE_LOGIN_ERROR_RETRY, "Retry Over", url );
    }

    public readonly url: string;
    public readonly cause: string;
    protected constructor( message: MOBILE_LOGIN_ERROR_TYPE, cause: string, url: string, ...params: any[] ) {
        super( message )
        this.name = "MobileLoginError";
        this.url = url;
        this.cause = cause;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, MobileLoginError);
        }
    }

    public toString(): string {
        let text = super.toString();
        return text += ` url: ${this.url}`;
    }
}

/** モバイル系ログインで利用 */
class LoginMobileWorkflow {

    // retryカウント
    private count: number;

    constructor( private provider: PROVIDER, private os: MOBILE_OS, private next: string, private progress: ( msg: string ) => void ) {
        this.count = 0;
    }

    // モバイル系ログイン処理
    public login(): void {
        if( IsValidURL( this.next ) == false ) {
            // 取得したモバイル向けOIDCログインURLが不正。
            // ここで再度モバイル向けOIDCログインURL取得処理に戻らせる。sentryにも通知する
            sentry.sendSentry( `MobileLoginError(login):${this.next}` )
            throw MobileLoginError.createLoginUrlError( this.next );
        }

        if( SigninMobile( this.os, this.next ) == false ) {
            // direct iOS/Android の初期化状態によってはハンドラが掴めない場合があるようなので、数秒後に再実行
            this.count++;
            if( this.count < RETRY_COUNT ) {
                // 1秒後にリトライ
                setTimeout( this.login, 1000 );
            } else {
                const msg = "ログイン処理に失敗しました:" + this.os + "next:" + this.next
                this.progress( msg );
                throw MobileLoginError.createLoginRetryError( this.next );
            }
        }
    }
}

/**
 * iOSにログインする
 * @param next directの singin URL
 * @return false direct iOS のログインハンドラーが無く、signin 実行できなかった場合
 */
function SigninIOS( next: string ): boolean {
    if(
        window.webkit?.messageHandlers?.DANHAuthenticateWebApp?.postMessage
        && typeof window.webkit.messageHandlers.DANHAuthenticateWebApp.postMessage === "function"
    ) {
        try {
            window.webkit.messageHandlers.DANHAuthenticateWebApp.postMessage( next );
            return true;
        } catch( error ) {
            sentry.sendSentryError( error )
            return false;
        }
    } else {
        return false;
    }
}

/**
 * Androidにログインする
 * @param next directの signin URL
 * @return false direct Android のログインハンドラーが無く、signin 実行できなかった場合
 */
function SigninAndroid( next: string ): boolean {
    if(
        window.directJsApi?.signin
        && typeof window.directJsApi.signin === "function"
    ) {
        try {
            window.directJsApi.signin( next );
            return true
        } catch( error ) {
            sentry.sendSentryError( error )
            return false;
        }
    } else {
        return false
    }
}

// モバイル用ログイン処理を行う
function SigninMobile( os: MOBILE_OS, next: string ): boolean {
    if( os == "ios" && SigninIOS( next ) ) {
        return true
    } else if ( os == "and" && SigninAndroid( next ) ) {
        return true
    } else {
        // OS指定が無い時もハンドラーがあれば挑戦する
        if( SigninIOS( next ) ) {
            return true
        } else if( SigninAndroid( next ) ) {
            return true
        }
    }
    return false    // モバイル向けログイン失敗
}
