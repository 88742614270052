import store from "@/store";
import { Comment } from "./comment";
import { Message } from "./message";
import { Topic } from "./topic";
import { User } from "./user";
import Acl, { AclCreateType } from "./acl";
import { AuthorityGroupPolicy, CreateTopicAuthorityGroupPolicyDefault, UpdateTopicAuthorityGroupPolicyDefault } from "@/support-acl-types";

enum Type {
    TOPIC, MESSAGE, COMMENT, REACTIONS,
}

type TopicType = Topic | {
    domainId: string,
    acl?: Acl,
}

export default class AclManager {

    /** read権限が許可されてるかどうか */
    public static readTopic( user: User, target: TopicType ): boolean {
        const topic = target;
        if( target.acl?.readTopicByGuest() ) {
            // ゲスト許可 → 全員許可 (2022/03)
            return true;
        } else {
            // ゲスト不許可なので判定する
            const isGuest = user.isGuestRole( topic.domainId );
            if( isGuest ) {
                return false;
            } else {
                return true;
            }
        }
    }

    public static readMessage( $storeOrMe: typeof store|User, topic: TopicType ): boolean { return this.read( $storeOrMe, topic )}
    public static readComment( $storeOrMe: typeof store|User, topic:TopicType ): boolean { return this.read( $storeOrMe, topic )}
    private static read( $storeOrMe: typeof store|User, target: TopicType ): boolean {
        if( !$storeOrMe ) return false;
        const _me = ( $storeOrMe instanceof User ) ? $storeOrMe : $storeOrMe.getters[ "users/me" ];
        if( this.readTopic( _me, target ) == false ) return false;
        return true;
    }


    /* ログインユーザーが作成権があるかどうかACL観点で判定する */
    public static createTopic(   $storeOrMe: typeof store|User, domainId: string ): boolean { return this.create( $storeOrMe, domainId, Type.TOPIC) }
    public static createMessage( $storeOrMe: typeof store|User, domainId: string ): boolean { return this.create( $storeOrMe, domainId, Type.MESSAGE) }
    public static createComment( $storeOrMe: typeof store|User, domainId: string ): boolean { return this.create( $storeOrMe, domainId, Type.COMMENT) }
    private static create( $storeOrMe: typeof store|User, domainId: string, type: Type ): boolean {
        if( type == Type.COMMENT ) return true;    // コメントはゲスト許可

        // あとはゲスト不可
        if( !$storeOrMe ) return false;
        const _me = ( $storeOrMe instanceof User ) ? $storeOrMe : $storeOrMe.getters[ "users/me" ];
        if( !_me ) return false;
        if( type == Type.TOPIC && !($storeOrMe instanceof User) && !this.createTopicAclByDomain($storeOrMe, domainId) ) { return false; }
        return _me.isGuestRole( domainId ) ? false : true;
    }

    /**
     * ログインユーザーが更新権があるかどうかACL観点で判定する
     * @param $storeOrMe VuexStore もしくは store.getters.me のオブジェクトを指定する
     */
    public static updateTopic(   $storeOrMe: typeof store|User, target: Topic|Message|Comment ): boolean { return this.update( $storeOrMe, target ) }
    public static updateMessage( $storeOrMe: typeof store|User, target: Topic|Message|Comment ): boolean { return this.update( $storeOrMe, target ) }
    public static updateComment( $storeOrMe: typeof store|User, target: Topic|Message|Comment ): boolean { return this.update( $storeOrMe, target ) }
    public static update(        $storeOrMe: typeof store|User, target: Topic|Message|Comment ): boolean { return this._change( $storeOrMe, target ) }

    /* ログインユーザーが削除権があるかどうかACL観点で判定する */
    public static deleteTopic(   $storeOrMe: typeof store|User, target: Topic|Message|Comment ): boolean { return this.delete( $storeOrMe, target ) }
    public static deleteMessage( $storeOrMe: typeof store|User, target: Topic|Message|Comment ): boolean { return this.delete( $storeOrMe, target ) }
    public static deleteComment( $storeOrMe: typeof store|User, target: Topic|Message|Comment ): boolean { return this.delete( $storeOrMe, target ) }
    public static delete(        $storeOrMe: typeof store|User, target: Topic|Message|Comment ): boolean { return this._change( $storeOrMe, target ) }

    private static _change( $storeOrMe: typeof store|User, target: Topic|Message|Comment ): boolean {
        if( !$storeOrMe ) return false;
        const _me: User = ( $storeOrMe instanceof User ) ? $storeOrMe : $storeOrMe.getters[ "users/me" ];
        if( !_me ) return false;

        const domainId = target.domainId;
        const update = target.acl?.base?.update;
        if( update === undefined ) {
            // 旧データ
            // Topicの場合は組織設定による処理
            // Messageの場合は所有者だけ許可(ゲスト以外OK)
            // Commentの場合は所有者だけ許可(ゲストもOK)
            if( target instanceof Comment ) {
                // 作成者ではない場合不可
                if( _me.id !== target.owner ) return false;
                else return true;        // コメントは更新OK
            } else if( _me.isGuestRole( target.domainId ) ) {
                // ゲストは更新不可
                return false;
            } else if( target instanceof Message ) { // 投稿
                if( _me.id !== target.owner ) return false;
                // ゲスト以外なら更新許可
                else return true;
            } else { // 話題
                if( !($storeOrMe instanceof User) && !this.updateTopicAclByDomain($storeOrMe, domainId, target) ) return false;
                return true;
            }
        } else {
            if( update ) {
                // Topicの場合は組織設定による処理
                // Messageの場合はTopicにcreateMessage許可があるかどうか見る
                // Commentの場合はMessage,TopicのcreateMessage許可を見る
                if( target instanceof Comment ) {
                    if( _me.id !== target.owner ) return false;
                    if( $storeOrMe instanceof User ) {
                        // storeが参照できない
                        return true;
                    } else {
                        const result = this.getCreateCommentAcl( $storeOrMe, target.domainId, target.topicId, target.domainId );
                        return result != "deny";
                    }
                } else if( _me.isGuestRole( target.domainId ) ) {
                    // ゲストは更新不可
                    return false;
                } else if( target instanceof Message ) {
                    if( _me.id !== target.owner ) return false;
                    if( $storeOrMe instanceof User ) {
                        // storeが参照できない
                        return true;
                    } else {
                        const result = this.getCreateMessageAcl( $storeOrMe, target.domainId, target.topicId );
                        return result != "deny";
                    }
                } else if( target instanceof Topic ) {
                    if( !($storeOrMe instanceof User) && !this.updateTopicAclByDomain($storeOrMe, domainId, target) ) return false;
                    return true;
                } else {
                    return false;
                }
            } else {
                // update:trueと同じ処理
                if( target instanceof Comment ) {
                    if( _me.id !== target.owner ) return false;
                    if( $storeOrMe instanceof User ) {
                        // storeが参照できない
                        return true;
                    } else {
                        const result = this.getCreateCommentAcl( $storeOrMe, target.domainId, target.topicId, target.domainId );
                        return result != "deny";
                    }
                } else if( _me.isGuestRole( target.domainId ) ) {
                    // ゲストは更新不可
                    return false;
                } else if( target instanceof Message ) {
                    if( _me.id !== target.owner ) return false;
                    if( $storeOrMe instanceof User ) {
                        // storeが参照できない
                        return true;
                    } else {
                        const result = this.getCreateMessageAcl( $storeOrMe, target.domainId, target.topicId );
                        return result != "deny";
                    }
                } else if( target instanceof Topic ) {
                    if( !($storeOrMe instanceof User) && !this.updateTopicAclByDomain($storeOrMe, domainId, target) ) return false;
                    return true;
                } else {
                    return false;
                }
            }
        }
    }

    /** ログインユーザーがゲストかどうか判定する */
    public static isGuest( $store: typeof store, domainId: string ): boolean {
        if( !$store ) return true;
        const me = $store.getters[ "users/me" ] as User | undefined;
        if( !me ) return true;
        return me.isGuestRole( domainId );
    }

    // Create〜 の状態を取得する

    /**
     * createMesssageAcl を解決する
     * Topic から解決する
     *
     * @param $store Vuex Store
     * @param domainId 組織ID
     * @param topicId 話題ID
     * @returns createMssageAclを解決した値
     */
    public static getCreateMessageAcl( $store: typeof store, domainId: string, topicId: string, ): AclCreateType {
        if( !$store ) return "allow"; // storybook用

        const me = $store.getters.me;
        if( !me ) return "deny";
        const topic = $store.getters.getTopic( domainId, topicId ) as Topic|undefined;
        if( !topic ) return "deny";
        const acl = topic.acl;
        const owner = topic.owner
        return this.createXAcl( me, domainId, { owner: owner, acl: acl }, Type.MESSAGE, )
    }

    /**
     * createCommentAcl を解決する
     * topicIdだけの場合は Topic から、 messageId も指定した場合は Message から解決する
     *
     * @param $store Vuex Store
     * @param domainId 組織ID
     * @param topicId 話題ID
     * @param messageId 投稿ID
     * @returns createCommentAclを解決した値
     */
    public static getCreateCommentAcl( $store: typeof store, domainId: string, topicId: string, messageId?: string ): AclCreateType {
        if( !$store ) return "allow"; // storybook用

        const me = $store.getters.me;
        if( !me ) return "deny";

        const topic = $store.getters.getTopic( domainId, topicId ) as Topic|undefined;
        if( !topic ) return "deny";
        const topicAcl = topic.acl;
        const topicOwner = topic.owner;
        const topicResult = this.createXAcl( me, domainId, { owner: topicOwner, acl: topicAcl }, Type.COMMENT, );
        if( !messageId ) {
            // 話題 のみでACL
            switch( topicResult ) {
                case "allow":
                case "inherit": {
                    // Topicでinheritの場合、許可に流す
                    return "allow";
                }
                case "deny":    return "deny";
                default:        return "inherit";
            }
        } else {
            
            switch( topicResult ) {
                case "allow":
                case "inherit": {
                    // allow | inherit の場合は投稿のACLで判断
                    break;
                }
                case "deny":    return "deny";
                default:        return "inherit";
            }
        }

        // 投稿のACLも考える
        const message = $store.getters.getMessage( domainId, topicId, messageId ) as Message|undefined;
        if( !message ) return "deny";
        const messageAcl = message.acl;
        const messageOwner = message.owner;
        const messageResult = this.createXAcl( me, domainId, { owner: messageOwner, acl: messageAcl }, Type.COMMENT, );
        switch( messageResult ) {
            case "allow":
            case "inherit": {
                // MessageでinheritなのでTopicに流す
                return this.getCreateCommentAcl( $store, domainId, topicId );
            }
            case "deny":    return "deny";
            default:        return "inherit";
        }
    }

    /**
     * createReactionAcl を解決する
     * topicIdだけの場合は Topic から、 messageId も指定した場合は Message から、更に commentId も指定した場合はCommentから解決する
     *
     * Reactionの場合、allow の場合でもコンテナ側で deny となっている場合は deny 判定とする
     *
     * @param $store Vuex Store
     * @param domainId 組織ID
     * @param topicId 話題ID
     * @param messageId 投稿ID
     * @param commentId コメントID
     * @returns createReactionAcl を解決した値
     */
    public static getCreateReactionAcl( $store: typeof store, domainId: string, topicId: string, messageId?: string, commentId?: string ): AclCreateType {
        if( !$store ) return "allow"; // storybook用

        const me = $store.getters.me;
        if( !me ) return "deny";

        // Topic or Message or Comment の取得
        let target: Topic|Message|Comment;
        let type: Type;
        if( commentId && messageId ) {
            target = $store.getters.getComment( domainId, topicId, messageId, commentId );
            type = Type.COMMENT;
        } else if( messageId ) {
            target = $store.getters.getMessage( domainId, topicId, messageId );
            type = Type.MESSAGE;
        } else {
            target = $store.getters.getTopic( domainId, topicId );
            type = Type.TOPIC;
        }
        if( !target ) return "deny";

        // createReactionAcl の解決
        const acl = target.acl;
        const owner = target.owner;
        const ownerIgnore = true;   // Onwer情報を無視(Reaction不可なら作者でも✕)
        const result = this.createXAcl( me, domainId, { owner: owner, acl: acl }, Type.REACTIONS, ownerIgnore )
        switch( result ) {
            case "inherit":
            case "allow": {
                // Reaction の allow は全コンテナで and 判定する
                let containerResult;
                if( type == Type.COMMENT ) {
                    // 親の結果取得
                    containerResult = this.getCreateReactionAcl( $store, domainId, topicId, messageId );
                } else if( type == Type.MESSAGE ) {
                    // 親の結果取得
                    containerResult = this.getCreateReactionAcl( $store, domainId, topicId );
                } else {
                    // Topicで allow or inherit の場合、許可に流す
                    containerResult = "allow";
                }
                return containerResult == "allow" ? "allow" : "deny";
            }
            case "deny":
                return "deny";
            default:
                return "deny";
        }
    }

    // ownerIgnore: true設定にすると owner 設定を無視する
    private static createXAcl( me: User, domainId: string, context: { owner: string, acl: Acl }, type: Type, ownerIgnore: boolean = false ): AclCreateType {
        if( !me || !context) return "deny";       // 不許可

        if( ownerIgnore == false ) {
            if( me.id === context.owner ) return "allow"; // 作成者は許可
        }

        const acl = context.acl;
        const guest = me.isGuestRole(domainId);
        if( guest ) {
            switch( type ) {
                case Type.TOPIC:    return acl.base.createTopic || "allow";
                case Type.MESSAGE:  return acl.base.createMessage || "allow";
                case Type.COMMENT:  return acl.guest.create && ( acl.base.createComment || "allow" ) == "allow" ? "allow" : "deny";
                case Type.REACTIONS:return acl.guest.create && ( acl.base.createReactions || "allow" ) == "allow" ? "allow" : "deny"; // コメントと同じ扱い
                default:            return "deny";
            }
        } else {
            switch( type ) {
                case Type.TOPIC:    return acl.base.createTopic || "allow";
                case Type.MESSAGE:  return acl.base.createMessage || "allow";
                case Type.COMMENT:  return acl.base.createComment || "allow";
                case Type.REACTIONS:return acl.base.createReactions || "allow";
                default:            return "deny";                              // 種別不明な場合は不許可
            }
        }
    }

    // 話題作成権限 組織設定 処理
    private static createTopicAclByDomain( $store: typeof store, domainId: string ): boolean {
        const me = store.getters["users/me"] as User;
        const createTopicAuthorityGroupPolicy = $store.getters["domains/getCreateTopicAuthorityGroupPolicy"] as AuthorityGroupPolicy;

        // 管理者で話題作成権限がない
        const createTopicAuthorityGroupPolicyByAdmin = createTopicAuthorityGroupPolicy.admin ?? CreateTopicAuthorityGroupPolicyDefault.admin;
        if( !createTopicAuthorityGroupPolicyByAdmin && me.isAdminRole(domainId) ) { return false; }
        
        // ユーザで話題作成権限がない
        const createTopicAuthorityGroupPolicyByUser = createTopicAuthorityGroupPolicy.user ?? CreateTopicAuthorityGroupPolicyDefault.user;
        if( !createTopicAuthorityGroupPolicyByUser && me.isUserRole(domainId) ) { return false; }
        
        return true;
    }

    // 話題更新権限 組織設定 処理
    private static updateTopicAclByDomain( $store: typeof store, domainId: string, topic: Topic ): boolean {
        const me = store.getters["users/me"] as User;
        
        // 所有者は話題を全て更新できる
        if( me.isOwnerRole(domainId) ) return true;
        
        const updateTopicAuthorityGroupPolicy = $store.getters["domains/getUpdateTopicAuthorityGroupPolicy"] as AuthorityGroupPolicy;

        // 管理者で話題更新権限がない
        const updateTopicAuthorityGroupPolicyByAdmin = updateTopicAuthorityGroupPolicy.admin ?? UpdateTopicAuthorityGroupPolicyDefault.admin;
        if( updateTopicAuthorityGroupPolicyByAdmin && me.isAdminRole(domainId) ) { return true; }
        
        // 実行者と作成者が同一ではない
        if( me.id !== topic.owner ) return false;
        else return true;
    }
}
