import { Vue } from 'vue-property-decorator';
import { Mixin } from "vue-mixin-decorator";
import { autoLink } from "../url/links";
import GraphemeSplitter from "grapheme-splitter";
const escape = require('escape-html');

@Mixin
export default class TextProcessMixin extends Vue {
    splitter: GraphemeSplitter = new GraphemeSplitter();
    directAppsUrls: string[] = [];

    // 文字数カウント
    getLength(str: string): number {
        const split = this.splitter.splitGraphemes(str);
        return split.length;
    }

    // 検索処理
    /* 検索文字の前処理(メタ文字のエスケープ) */
    processed(search: string): string {
        const regStr = escape(search).replace(/[.^$|\\[\](){}+*?]/g, '\\$&');
        return regStr;
    }

    /* 対象文字の検索 */
    searchWord(target: string, search: string): boolean {
        const regExp = new RegExp(this.processed(search), 'i');
        return regExp.test(escape(target));
    }

    // 検索結果の表示+URLリンク化処理
    /* URLリンク化 + 検索結果該当箇所ハイライト処理 */
    highLightStr(target: string, search: string|undefined): string {
        // URLハイパーリンク化
        const message: string = autoLink(target);
        if(!search) { return message; }
        const processed = this.processed(search);
        if(!processed || !processed.length) { return message; }

        const tmpMatches = message.match(/<a href=.*?>|<\/a>/ig);
        const tmpArray = message.replace(/<a href=.*?>|<\/a>/ig, '/**/').split(/\/\*\*\//g);
        const regExp = new RegExp(processed, 'ig');

        // ハイライト用のclass挿入
        const highlight = tmpArray.map(tmp => { return tmp.replace(regExp, "<span class='highlight'>$&</span>"); });

        let result = '';
        if(tmpMatches == null) {
            result = highlight[0];
        } else {
            for(let i = 0; i < tmpMatches.length; i++) {
                result += highlight[i] + tmpMatches[i];
            }
            result += highlight[tmpMatches.length];
        }
        return result;
    }

    // カタカナ→ひらがな変換
    katakanaToHiragana(str: string): string {
        return str.replace(/[\u30A1-\u30FA]/g, (ch) => {
            return String.fromCharCode(ch.charCodeAt(0) - 0x60);
        });
    }

}
