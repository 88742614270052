import { PDFDocumentProxy } from "pdfjs-dist";

let pdfLibjs: any = undefined;

// ライブラリをロード
const setup = () => {
  if (!pdfLibjs) {
    pdfLibjs = (globalThis as any)["pdfjs-dist/build/pdf"];
    pdfLibjs.workerSrc = '/lib/js/pdfjs-dist/build/pdf.worker.js';    
    pdfLibjs.PDFJS.isEvalSupported = false; // pdfjsの脆弱性回避
  }
}

/**
 * @param file  PDFファイル
 * @param type  サムネイルファイルのMIMEタイプ
 */
export const getThumbnail = async (
  file: File,
  type: string = "image/jpeg"
): Promise<File | undefined> => {
  setup();

  const pdfUrl = URL.createObjectURL(file);
  let pdf: PDFDocumentProxy | undefined = undefined
  try {
    pdf = await pdfLibjs.getDocument(pdfUrl).promise;
  }
  catch(err: any) {
    if (err.name === 'PasswordException') {
      console.log('パスワード付きPDFはサムネイルを作成することができません');
    } else {
      console.log(err);
    }
  }
  if (!pdf) return

  const canvas = document.createElement("canvas") as HTMLCanvasElement;
  const page = await pdf.getPage(1); // 1ページ目を取得
  const viewport = page.getViewport(1.0);
  const canvasContext = canvas.getContext("2d") as CanvasRenderingContext2D;
  canvas.style.display = "none";
  canvas.width = viewport.width;
  canvas.height = viewport.height;
  const renderContext = {
    canvasContext,
    viewport
  };
  await page.render(renderContext);
  const thumbUrl = canvas.toDataURL(type);
  const blob = await (await fetch(thumbUrl)).blob();
  const fileName = `${file.name + file.size}_tmb.jpg`;
  const thumbnailFile = new File([blob], fileName, { type: type });
  return thumbnailFile;
}