var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    {
      staticClass: "p-0 max-vh-100 d-flex flex-column flex-fill",
      attrs: { fluid: "" }
    },
    [
      _vm.isUploading && !_vm.isModal
        ? _c("loading-upload", { attrs: { msg: _vm.loadingMsg } })
        : _vm._e(),
      _vm.nav
        ? _c("navigation-bar", {
            attrs: {
              items: _vm.items,
              cancel: true,
              title: _vm.navTitle,
              fetchUserInfo: false
            },
            on: {
              "on-cancel": function($event) {
                return _vm.showEditDiscardAlert(_vm.onCancel)
              },
              "on-complete": _vm.onEditComplete
            }
          })
        : _vm._e(),
      _c(
        "b-container",
        {
          staticClass: "my-3 d-flex flex-column flex-fill message-edit-body",
          style: _vm.isModal ? "max-height: 75vh;" : "",
          on: {
            dragover: function($event) {
              $event.preventDefault()
              _vm.drag = true
            },
            dragleave: function($event) {
              $event.preventDefault()
              _vm.drag = false
            },
            drop: function($event) {
              $event.preventDefault()
              _vm.drag = false
            }
          }
        },
        [
          _c("drag-area-overlay", {
            attrs: { drag: _vm.drag },
            on: { dropFiles: _vm.dropFiles }
          }),
          _c("b-row", { attrs: { "no-gutters": "" } }, [
            _c("div", { staticClass: "title-label" }, [_vm._v("タイトル")])
          ]),
          _c(
            "b-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("b-form-input", {
                staticClass: "title-input",
                attrs: {
                  input: "text",
                  placeholder: "新しい投稿を作成します",
                  "aria-describedby": "title-feedback",
                  autofocus: "",
                  required: ""
                },
                on: {
                  update: function($event) {
                    return _vm.changeEditMode(_vm.editMode)
                  }
                },
                model: {
                  value: _vm.titleSource,
                  callback: function($$v) {
                    _vm.titleSource = $$v
                  },
                  expression: "titleSource"
                }
              }),
              _c(
                "b-form-text",
                {
                  class: _vm.titleState ? "success" : "warning",
                  attrs: { id: "title-feedback" }
                },
                [_vm._v(" " + _vm._s(_vm.validTitleMessage) + " ")]
              )
            ],
            1
          ),
          _c("b-row", { staticClass: "mt-3", attrs: { "no-gutters": "" } }, [
            _c("div", { staticClass: "content-label" }, [_vm._v("内容")])
          ]),
          _c(
            "b-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("b-form-textarea", {
                staticClass: "content-input",
                attrs: { rows: "5", "aria-describedby": "content-feedback" },
                on: {
                  update: function($event) {
                    return _vm.changeEditMode(_vm.editMode)
                  }
                },
                model: {
                  value: _vm.messageSource,
                  callback: function($$v) {
                    _vm.messageSource = $$v
                  },
                  expression: "messageSource"
                }
              }),
              _c(
                "b-form-text",
                {
                  class: _vm.contentState ? "success" : "warning",
                  attrs: { id: "content-feedback" }
                },
                [_vm._v(" " + _vm._s(_vm.validContentMessage) + " ")]
              )
            ],
            1
          ),
          _c("file-selection-form", {
            ref: "fileSelectionForm",
            attrs: {
              contentType: "MESSAGE",
              label: "ファイル",
              multiple: true,
              photos: _vm.photos,
              messageId: this.normalizeMessageId,
              allow_attachment_type: _vm.allow_attachment_type,
              "file-number-limit": 10
            },
            on: { onUpdateThumb: _vm.updateFile }
          }),
          _vm.hasFeature("deny-edit")
            ? _c(
                "b-row",
                {
                  staticClass: "d-flex flex-column",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c("b-form-group", {
                    staticClass: "d-flex",
                    attrs: {
                      "label-class": "d-flex",
                      label: "作成者以外のコメント・いいね"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var ariaDescribedby = ref.ariaDescribedby
                            return [
                              _c(
                                "b-form-radio-group",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    "padding-left": "1rem"
                                  },
                                  attrs: {
                                    options: _vm.radioOptions,
                                    "aria-describedby": ariaDescribedby,
                                    disabled: !_vm.topicAllowWriteOthersSource
                                  },
                                  model: {
                                    value: _vm.allowWriteAndLikeOtherSource,
                                    callback: function($$v) {
                                      _vm.allowWriteAndLikeOtherSource = $$v
                                    },
                                    expression: "allowWriteAndLikeOtherSource"
                                  }
                                },
                                [
                                  !_vm.topicAllowWriteOthersSource
                                    ? _c(
                                        "b-form-text",
                                        { staticClass: "warning mt-0" },
                                        [
                                          _vm._v(
                                            "話題で作成者以外の書き込みが許可されていません"
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4035590746
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { attrs: { "align-h": "between", "no-gutters": "" } },
            [
              _c("cancel", {
                staticClass: "col-5",
                attrs: { label: "キャンセル" },
                on: {
                  cancel: function($event) {
                    return _vm.showEditDiscardAlert(_vm.onCancel)
                  }
                }
              }),
              _c("confirm", {
                staticClass: "col-5",
                attrs: { label: "保存", disabled: _vm.confirmButtonDisabled },
                on: { confirm: _vm.onEditComplete }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }