var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0", attrs: { id: "app", fluid: "" } },
    [
      _vm.session
        ? _c(
            "div",
            { staticClass: "d-flex flex-column vh-100 app-container" },
            [
              _c("router-view", { attrs: { name: "nav" } }),
              _c("loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                attrs: {
                  msg: !!_vm.loadingMessage ? _vm.loadingMessage : undefined,
                  background: _vm.indicatorBackground
                }
              }),
              _c(
                "div",
                { staticClass: "d-flex flex-grow-1 app-body" },
                [
                  _vm.sideMenu
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "p-0 d-none d-md-block d-lg-block d-xl-block side-menu-container"
                        },
                        [_c("router-view", { attrs: { name: "sidemenu" } })],
                        1
                      )
                    : _vm._e(),
                  _vm.searchWord
                    ? _c("router-view", { attrs: { name: "search" } })
                    : _c("router-view")
                ],
                1
              )
            ],
            1
          )
        : _vm.loginErrorOidc
        ? _c("div", [_c("login-error-oidc")], 1)
        : _c(
            "div",
            [
              _c("router-view", {
                attrs: { name: "nav", fetchUserInfo: false }
              }),
              this.$route.name == "logout" ? _c("logout") : _c("login")
            ],
            1
          ),
      _vm.photoStreamModal
        ? _c("photo-stream", {
            attrs: {
              files: _vm.photoStreamArgs.files,
              param: {
                messageId: _vm.photoStreamArgs.messageId,
                commentId: _vm.photoStreamArgs.commentId
              },
              downloadableList: _vm.downloadable,
              allowSaveAttachments: _vm.allowSaveAttachments,
              clientType: _vm.clientType,
              isMobile: _vm.isMobile
            },
            on: { closeModal: _vm.closePhotoStream }
          })
        : _vm._e(),
      _vm.lazyLoad
        ? _c(
            "b-toast",
            {
              attrs: {
                id: "urlToast",
                variant: "info",
                solid: "",
                "no-close-button": "",
                "auto-hide-delay": "2000",
                "is-status": true
              }
            },
            [
              _c(
                "div",
                { staticClass: "url-copy-toast", attrs: { div: "" } },
                [
                  _c("b-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "info-circle", "font-scale": "3" }
                  }),
                  _vm._v(" URLをコピーしました")
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.lazyLoad
        ? _c("alert-modal", {
            attrs: {
              id: "category-update-alert",
              msg: "カテゴリーが編集されています。保存しますか？"
            },
            on: { clickedOK: _vm.saveCategoryEdit }
          })
        : _vm._e(),
      _vm.lazyLoad
        ? _c("alert-modal", {
            attrs: {
              id: "alert-modal",
              msg: _vm.alertModalArgs.msg,
              help: _vm.alertModalArgs.isHelp,
              helpContentArgs: _vm.alertModalArgs.helpContentArgs,
              hideCancel: _vm.alertModalArgs.hideCancel,
              okLabel: _vm.alertModalArgs.okLabel,
              okVariant: _vm.alertModalArgs.okVariant
            },
            on: {
              clickedOK: _vm.alertModalArgs.clickedOK,
              clickedCancel: _vm.alertModalArgs.clickedCancel
            }
          })
        : _vm._e(),
      _c("error-modal", {
        attrs: { id: "error-modal", msg: _vm.errorModalArgs.msg },
        on: { hideModal: _vm.errorModalArgs.afterProcess }
      }),
      _vm.lazyLoad
        ? _c(
            "not-allowed-modal",
            _vm._b({}, "not-allowed-modal", _vm.notAllowedModalArgs, false)
          )
        : _vm._e(),
      _vm.lazyLoad
        ? _c(
            "favorite-modal",
            _vm._b({}, "favorite-modal", _vm.favoriteModalArgs, false)
          )
        : _vm._e(),
      _vm.lazyLoad
        ? _c(
            "domain-setting",
            _vm._b({}, "domain-setting", _vm.domainSettingArgs, false)
          )
        : _vm._e(),
      _vm.lazyLoad
        ? _c(
            "acl-edit-modal",
            _vm._b({}, "acl-edit-modal", _vm.aclEditModalArgs, false)
          )
        : _vm._e(),
      _vm.lazyLoad ? _c("action-sheet") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }