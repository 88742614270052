












import NavbarMenuSolutionItem from './NavbarMenuSolutionItem.vue'
import { Prop, Component, Vue } from 'vue-property-decorator';
import { AppLinkItem } from './types';

const sendAnalyticsEvent = (...params: string[]) => {
    return undefined;
}



@Component({ components: {NavbarMenuSolutionItem} })
export default class NavbarMenuSolutionsPanel extends Vue {
  name = 'NavbarMenuSolutionsPanel'
  hasValidSolutionsRef = true

  dummy: AppLinkItem = {
    url: "dummy",
    iconUrl: "dummy",
    name: "dummy"
  }


  @Prop({ default: () => [] })
  appLinkItemsRef!: AppLinkItem[]

  @Prop({ default: () => "" })
  domainId!: string

    //DOM取得用
  dropdown = null


  updateAppLinkItems(appLinkItems: AppLinkItem[]){
      this.appLinkItemsRef = appLinkItems
  }
  updateSolutionsMenu(hasValidSolutions: boolean){
      this.hasValidSolutionsRef = hasValidSolutions
  }
  onClickMenu(){
      sendAnalyticsEvent('ナビゲーションバー', 'クリック', 'direct Apps')
  }

  listClass(){
    return 'list-column'
  }

}

