var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.category.deleted
    ? _c(
        "b-row",
        {
          class: _vm.selected
            ? "d-flex selected category-list-item"
            : "d-flex category-list-item",
          attrs: { "no-gutters": "" },
          on: { click: _vm.clickCategory }
        },
        [
          _c("div", { staticClass: "p-2 my-auto category-name" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _c(
            "div",
            {
              staticClass: "d-flex p-2 ml-auto flex-direction-row color-palette"
            },
            [
              _c(
                "div",
                { staticClass: "owner-icon", attrs: { id: _vm.category.id } },
                [
                  _c("profile-icon", {
                    staticClass: "rounded-circle m-auto",
                    attrs: {
                      userName: _vm.owner.name,
                      id: _vm.owner.directId,
                      image: _vm.owner.getProfileIconUrl(),
                      size: "25"
                    }
                  })
                ],
                1
              ),
              _c("b-popover", {
                attrs: {
                  target: _vm.category.id,
                  content: _vm.owner.name,
                  triggers: "hover",
                  placement: "top",
                  boundary: "viewport"
                }
              }),
              _vm.isEdit
                ? _c("b-button", {
                    staticClass: "edit-button",
                    attrs: { variant: "info" },
                    on: { click: _vm.clickEditButton }
                  })
                : _vm._e(),
              _vm.isEdit
                ? _c(
                    "b-dropdown",
                    {
                      staticClass: "category-color-picker",
                      attrs: {
                        "toggle-class": _vm.toggleClass,
                        "menu-class": "p-0",
                        right: ""
                      }
                    },
                    [
                      _c(
                        "b-dropdown-group",
                        { staticClass: "p-0" },
                        _vm._l(_vm.colorGroup, function(colors, i) {
                          return _c(
                            "b-list-group",
                            { key: i, attrs: { horizontal: "" } },
                            _vm._l(colors, function(color, j) {
                              return _c(
                                "b-list-group-item",
                                { key: j, staticClass: "color-item" },
                                [
                                  _c("b-button", {
                                    class: color + " color-item-button",
                                    attrs: {
                                      pressed: _vm.isPressed(i * 4 + j)
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.press(i * 4 + j)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }