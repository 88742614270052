var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    attrs: {
      id: "domain-setting",
      size: "lg",
      centered: "",
      "hide-header": "",
      scrollable: "",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _vm.showEditSetting
              ? _c(
                  "div",
                  { attrs: { id: "edit-setting-group" } },
                  [
                    _c(
                      "b-form-group",
                      {
                        staticClass: "m-0",
                        attrs: {
                          "label-size": "lg",
                          "label-class": "font-weight-bold",
                          id: "category-setting-form"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-directions-row"
                                    },
                                    [
                                      _c("div", [_vm._v("編集")]),
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "authority-badge admin"
                                        },
                                        [_vm._v("管理者以上")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1823121407
                        )
                      },
                      [
                        _c(
                          "b-form-group",
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                model: {
                                  value: _vm.allowCatEditExceptGuest,
                                  callback: function($$v) {
                                    _vm.allowCatEditExceptGuest = $$v
                                  },
                                  expression: "allowCatEditExceptGuest"
                                }
                              },
                              [_vm._v("カテゴリー編集をゲスト以外に許可")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          { staticClass: "mb-4" },
                          [
                            _c("category-edit", {
                              attrs: {
                                id: "category-edit-setting",
                                categories: _vm.categories,
                                isMobile: false,
                                domainId: _vm.domainId,
                                me: _vm.me,
                                attachCategories: []
                              },
                              on: { onCloseCategoryFilterDialog: function() {} }
                            }),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-modal",
                                    rawName: "v-b-modal.category-edit-setting",
                                    modifiers: { "category-edit-setting": true }
                                  }
                                ],
                                attrs: { size: "sm", variant: "info" }
                              },
                              [_vm._v(" カテゴリー設定を開く ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mb-0",
                            attrs: {
                              label: "初期値の設定",
                              "label-class": "font-weight-bold",
                              id: "init-setting"
                            }
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "pl-1",
                                attrs: { label: "話題:" }
                              },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    model: {
                                      value: _vm.allowTopicWriteOthers,
                                      callback: function($$v) {
                                        _vm.allowTopicWriteOthers = $$v
                                      },
                                      expression: "allowTopicWriteOthers"
                                    }
                                  },
                                  [_vm._v("作成者以外の書き込みを許可する")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              {
                                staticClass: "pl-1",
                                attrs: { label: "投稿:" }
                              },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: { id: "permit-message-edit" },
                                    model: {
                                      value: _vm.allowMessageWriteOthers,
                                      callback: function($$v) {
                                        _vm.allowMessageWriteOthers = $$v
                                      },
                                      expression: "allowMessageWriteOthers"
                                    }
                                  },
                                  [_vm._v("作成者以外の書き込みを許可する")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.showAclSetting
              ? _c(
                  "div",
                  { attrs: { id: "acl-setting-group" } },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          "label-size": "lg",
                          "label-class": "font-weight-bold"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-directions-row"
                                    },
                                    [
                                      _c("div", [_vm._v("組織設定")]),
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "authority-badge admin"
                                        },
                                        [_vm._v("管理者以上")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          313105334
                        )
                      },
                      [
                        _c(
                          "b-form-group",
                          { staticClass: "mb-0" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-modal",
                                    rawName: "v-b-modal.acl-edit",
                                    modifiers: { "acl-edit": true }
                                  }
                                ],
                                attrs: { variant: "info" }
                              },
                              [_vm._v("権限設定を開く")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.showCreateTopicAclSetting
              ? _c(
                  "div",
                  { attrs: { id: "topic-create-acl-setting-group" } },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          "label-size": "lg",
                          "label-class": "font-weight-bold"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-directions-row"
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.CREATE_TOPIC_TITLE))
                                      ]),
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "authority-badge owner"
                                        },
                                        [_vm._v("所有者のみ")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          766477415
                        )
                      },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "setting-description": true,
                              owner: _vm.me.isOwnerRole(_vm.domainId)
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.me.isOwnerRole(_vm.domainId)
                                  ? _vm.CREATE_TOPIC_DESC
                                  : _vm.DEFAULT_DESCRIPTION
                              )
                            )
                          ]
                        ),
                        _vm.me.isOwnerRole(_vm.domainId)
                          ? _c(
                              "b-form-group",
                              { staticClass: "mb-0" },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    model: {
                                      value: _vm.allowCreateTopicByAdmin,
                                      callback: function($$v) {
                                        _vm.allowCreateTopicByAdmin = $$v
                                      },
                                      expression: "allowCreateTopicByAdmin"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.CREATE_TOPIC_ADMIN_LABEL))]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    model: {
                                      value: _vm.allowCreateTopicByUser,
                                      callback: function($$v) {
                                        _vm.allowCreateTopicByUser = $$v
                                      },
                                      expression: "allowCreateTopicByUser"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.CREATE_TOPIC_USER_LABEL))]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.showUpdateTopicAclSetting
              ? _c(
                  "div",
                  { attrs: { id: "topic-update-acl-setting-group" } },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          "label-size": "lg",
                          "label-class": "font-weight-bold"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-directions-row"
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.UPDATE_TOPIC_TITLE))
                                      ]),
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "authority-badge owner"
                                        },
                                        [_vm._v("所有者のみ")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3921396722
                        )
                      },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "setting-description": true,
                              owner: _vm.me.isOwnerRole(_vm.domainId)
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.me.isOwnerRole(_vm.domainId)
                                  ? _vm.UPDATE_TOPIC_DESC
                                  : _vm.DEFAULT_DESCRIPTION
                              )
                            )
                          ]
                        ),
                        _vm.me.isOwnerRole(_vm.domainId)
                          ? _c(
                              "b-form-group",
                              { staticClass: "mb-0" },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    model: {
                                      value: _vm.allowUpdateTopicByAdmin,
                                      callback: function($$v) {
                                        _vm.allowUpdateTopicByAdmin = $$v
                                      },
                                      expression: "allowUpdateTopicByAdmin"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.UPDATE_TOPIC_ADMIN_LABEL))]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.showAttachSetting
              ? _c(
                  "div",
                  { attrs: { id: "attachment-restriction-group" } },
                  [
                    _c("restrict-type-setting", {
                      attrs: {
                        "form-label-name": "添付ファイル",
                        desc: _vm.ATTACH_DESC,
                        "default-restrict-type": _vm.attachRestrictType,
                        "default-custom-file-type": _vm.attachCustomFileType,
                        isPermitCustom: true,
                        isOwner: _vm.me.isOwnerRole(_vm.domainId)
                      },
                      on: { change: _vm.changeAttchRestrictType }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.showDownloadSetting
              ? _c(
                  "div",
                  { attrs: { id: "download-group" } },
                  [
                    _c("restrict-type-setting", {
                      attrs: {
                        "form-label-name": "添付ファイルのダウンロード",
                        desc: _vm.DOWNLOAD_DESC,
                        "default-restrict-type": _vm.downloadRestrictType,
                        "default-custom-file-type": _vm.downloadCustomFileType,
                        isPermitCustom: true,
                        isOwner: _vm.me.isOwnerRole(_vm.domainId),
                        filetypes: _vm.DONWLOAD_ALLOW_OPTIONS,
                        customOptionAlert: _vm.DOWNLOAD_CUSTOM_OPTION_ALERT
                      },
                      on: { change: _vm.changeDownloadRestrictType }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function(ref) {
          var close = ref.close
          return [
            _vm.me.isOwnerRole(_vm.domainId)
              ? _c(
                  "b-row",
                  {
                    staticClass: "w-100",
                    attrs: { "align-h": "around", "no-gutters": "" }
                  },
                  [
                    _c("cancel", {
                      staticClass: "col-5",
                      attrs: { label: "キャンセル" },
                      on: { cancel: close }
                    }),
                    _c("confirm", {
                      staticClass: "col-5",
                      attrs: { label: "保存", disabled: _vm.invalidSetting },
                      on: { confirm: _vm.showSaveAlert }
                    })
                  ],
                  1
                )
              : _c(
                  "b-row",
                  {
                    staticClass: "w-100",
                    attrs: { "align-h": "around", "no-gutters": "" }
                  },
                  [
                    _c("cancel", {
                      staticClass: "col-5",
                      attrs: { label: "閉じる" },
                      on: { cancel: close }
                    })
                  ],
                  1
                )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }