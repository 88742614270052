var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "comment-list", attrs: { fluid: "" } },
    [
      _vm.isUploading
        ? _c("loading-upload", { attrs: { msg: _vm.loadingMsg } })
        : _vm._e(),
      _vm._l(_vm.commentList, function(comment) {
        return _c("comment-list-item", {
          key: comment.id,
          attrs: {
            id: comment.id,
            user: comment.owner,
            createdAt: comment.createdAt,
            message: comment.message,
            like: comment.like,
            photos: comment.photos,
            messageId: _vm.id,
            users: _vm.users,
            viewer: _vm.viewer,
            deleted: comment.deleted,
            deletedUser: comment.deletedUser
          },
          on: {
            openModal: _vm.openModal,
            toEditComment: _vm.toEditComment,
            toDeleteComment: _vm.toDeleteComment
          }
        })
      }),
      _c(
        "div",
        {
          staticClass: "position-relative",
          on: {
            dragover: function($event) {
              $event.preventDefault()
              _vm.drag = true
            },
            dragleave: function($event) {
              $event.preventDefault()
              _vm.drag = false
            },
            drop: function($event) {
              $event.preventDefault()
              _vm.drag = false
            }
          }
        },
        [
          _c("drag-area-overlay", {
            attrs: { drag: _vm.drag },
            on: { dropFiles: _vm.dropFiles }
          }),
          _vm.commentAvailable
            ? _c("b-row", { staticClass: "comment-form" }, [
                _c(
                  "div",
                  { staticClass: "input-form" },
                  [
                    _c("b-form-textarea", {
                      ref: "commentForm",
                      staticClass: "input-box",
                      attrs: {
                        "max-rows": "10",
                        wrap: "hard",
                        "aria-describedby": "comment-feedback",
                        placeholder: "コメントを入力 (Enterで改行)"
                      },
                      on: { blur: _vm.blurEvent, focus: _vm.focusEvent },
                      nativeOn: {
                        keypress: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.enableSubmit.apply(null, arguments)
                        },
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.enableSubmit.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.text,
                        callback: function($$v) {
                          _vm.text = $$v
                        },
                        expression: "text"
                      }
                    }),
                    _c(
                      "b-button",
                      {
                        staticClass: "send-button",
                        attrs: { disabled: !_vm.contentState },
                        on: { click: _vm.addComment }
                      },
                      [_c("icon-enter")],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.text.length
            ? _c(
                "b-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "b-form-text",
                    {
                      class: _vm.contentState ? "success" : "warning",
                      attrs: { id: "comment-feedback" }
                    },
                    [_vm._v(" " + _vm._s(_vm.validCommentMessage) + " ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.commentAvailable
            ? _c("file-selection-form", {
                ref: "fileSelectionForm",
                attrs: {
                  contentType: "COMMENT",
                  label: "",
                  multiple: true,
                  photos: [],
                  messageId: _vm.id,
                  allow_attachment_type: _vm.allow_attachment_type,
                  "file-number-limit": 10
                },
                on: { onUpdateThumb: _vm.updateFile }
              })
            : _vm._e()
        ],
        1
      ),
      _c("delete-modal", {
        attrs: {
          id: _vm.deleteModalId,
          messageId: _vm.id,
          commentId: _vm.deleteId,
          viewer: _vm.viewer,
          type: "comment"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }