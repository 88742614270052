import { Department } from "@/components/acl/group-uesr-list/Departments/values/Department";
import { DB_STORE } from "./indexeddb_api";

const STORE_NAME = DB_STORE.DEPARTMENT;
const DUMMY_NAME = 'DATA3';

export class DepartmentIndexedDB {
    public static getStoreName(): DB_STORE {
        return STORE_NAME;
    }

    public static getDummyName(): string {
        return DUMMY_NAME;
    }

    // データを復元(仮)
    public static restore( departments: Partial<Department>[] ): Department[] {
        const results = departments as Department[];
        return results;
    }
}
