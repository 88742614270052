export type LowerUser = {
    id: string,
    name: string,
    departments?: string[],
}

export type LowerListItem = {
    id: string,
    type: "Department" | "User",
    name: string,
    isDeny: boolean,
}

export enum IModeOption {
    DEFAULT = "部署とユーザー",
    USER = "すべてのユーザー",
    SET = "閲覧制限対象の部署またはユーザー",
}