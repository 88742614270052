/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateTopic = /* GraphQL */ `subscription OnCreateTopic($domainId: String!) {
  onCreateTopic(domainId: $domainId) {
    id
    domainId
    title
    desc
    icon {
      url
      mime
      __typename
    }
    pinned
    categoryId
    messages
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    notification
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTopicSubscriptionVariables,
  APITypes.OnCreateTopicSubscription
>;
export const onUpdateTopic = /* GraphQL */ `subscription OnUpdateTopic($domainId: String!) {
  onUpdateTopic(domainId: $domainId) {
    id
    domainId
    title
    desc
    icon {
      url
      mime
      __typename
    }
    pinned
    categoryId
    messages
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    notification
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTopicSubscriptionVariables,
  APITypes.OnUpdateTopicSubscription
>;
export const onDeleteTopic = /* GraphQL */ `subscription OnDeleteTopic($domainId: String!) {
  onDeleteTopic(domainId: $domainId) {
    id
    domainId
    title
    desc
    icon {
      url
      mime
      __typename
    }
    pinned
    categoryId
    messages
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    notification
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTopicSubscriptionVariables,
  APITypes.OnDeleteTopicSubscription
>;
export const onCreateMessage = /* GraphQL */ `subscription OnCreateMessage($domainId: String!, $topicId: String!) {
  onCreateMessage(domainId: $domainId, topicId: $topicId) {
    id
    domainId
    topicId
    title
    message
    pinned
    owner
    commentId
    photos {
      url
      mime
      __typename
    }
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMessageSubscriptionVariables,
  APITypes.OnCreateMessageSubscription
>;
export const onUpdateMessage = /* GraphQL */ `subscription OnUpdateMessage($domainId: String!, $topicId: String!) {
  onUpdateMessage(domainId: $domainId, topicId: $topicId) {
    id
    domainId
    topicId
    title
    message
    pinned
    owner
    commentId
    photos {
      url
      mime
      __typename
    }
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMessageSubscriptionVariables,
  APITypes.OnUpdateMessageSubscription
>;
export const onDeleteMessage = /* GraphQL */ `subscription OnDeleteMessage($domainId: String!, $topicId: String!) {
  onDeleteMessage(domainId: $domainId, topicId: $topicId) {
    id
    domainId
    topicId
    title
    message
    pinned
    owner
    commentId
    photos {
      url
      mime
      __typename
    }
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMessageSubscriptionVariables,
  APITypes.OnDeleteMessageSubscription
>;
export const onCreateComment = /* GraphQL */ `subscription OnCreateComment(
  $domainId: String!
  $topicId: String!
  $messageId: String!
) {
  onCreateComment(
    domainId: $domainId
    topicId: $topicId
    messageId: $messageId
  ) {
    id
    domainId
    topicId
    messageId
    message
    photos {
      url
      mime
      __typename
    }
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCommentSubscriptionVariables,
  APITypes.OnCreateCommentSubscription
>;
export const onUpdateComment = /* GraphQL */ `subscription OnUpdateComment(
  $domainId: String!
  $topicId: String!
  $messageId: String!
) {
  onUpdateComment(
    domainId: $domainId
    topicId: $topicId
    messageId: $messageId
  ) {
    id
    domainId
    topicId
    messageId
    message
    photos {
      url
      mime
      __typename
    }
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCommentSubscriptionVariables,
  APITypes.OnUpdateCommentSubscription
>;
export const onDeleteComment = /* GraphQL */ `subscription OnDeleteComment(
  $domainId: String!
  $topicId: String!
  $messageId: String!
) {
  onDeleteComment(
    domainId: $domainId
    topicId: $topicId
    messageId: $messageId
  ) {
    id
    domainId
    topicId
    messageId
    message
    photos {
      url
      mime
      __typename
    }
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCommentSubscriptionVariables,
  APITypes.OnDeleteCommentSubscription
>;
export const onCreateIconReaction = /* GraphQL */ `subscription OnCreateIconReaction($domainId: String!) {
  onCreateIconReaction(domainId: $domainId) {
    type
    targetType
    domainId
    topicId
    messageId
    commentId
    userIdList
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateIconReactionSubscriptionVariables,
  APITypes.OnCreateIconReactionSubscription
>;
export const onUpdateIconReaction = /* GraphQL */ `subscription OnUpdateIconReaction($domainId: String!) {
  onUpdateIconReaction(domainId: $domainId) {
    type
    targetType
    domainId
    topicId
    messageId
    commentId
    userIdList
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateIconReactionSubscriptionVariables,
  APITypes.OnUpdateIconReactionSubscription
>;
export const onDeleteIconReaction = /* GraphQL */ `subscription OnDeleteIconReaction($domainId: String!) {
  onDeleteIconReaction(domainId: $domainId) {
    type
    targetType
    domainId
    topicId
    messageId
    commentId
    userIdList
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteIconReactionSubscriptionVariables,
  APITypes.OnDeleteIconReactionSubscription
>;
export const onCreateCategory = /* GraphQL */ `subscription OnCreateCategory($domainId: String!) {
  onCreateCategory(domainId: $domainId) {
    id
    title
    domainId
    color
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCategorySubscriptionVariables,
  APITypes.OnCreateCategorySubscription
>;
export const onUpdateCategory = /* GraphQL */ `subscription OnUpdateCategory($domainId: String!) {
  onUpdateCategory(domainId: $domainId) {
    id
    title
    domainId
    color
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCategorySubscriptionVariables,
  APITypes.OnUpdateCategorySubscription
>;
export const onDeleteCategory = /* GraphQL */ `subscription OnDeleteCategory($domainId: String!) {
  onDeleteCategory(domainId: $domainId) {
    id
    title
    domainId
    color
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCategorySubscriptionVariables,
  APITypes.OnDeleteCategorySubscription
>;
export const onCreateUser = /* GraphQL */ `subscription OnCreateUser($owner: String!) {
  onCreateUser(owner: $owner) {
    id
    directId
    name
    profileIcon
    domainIdList
    owner
    favorites
    likes
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser($owner: String!) {
  onUpdateUser(owner: $owner) {
    id
    directId
    name
    profileIcon
    domainIdList
    owner
    favorites
    likes
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser($owner: String!) {
  onDeleteUser(owner: $owner) {
    id
    directId
    name
    profileIcon
    domainIdList
    owner
    favorites
    likes
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onCreateDomainUser = /* GraphQL */ `subscription OnCreateDomainUser {
  onCreateDomainUser {
    domainId
    userDirectId
    user {
      id
      directId
      name
      profileIcon
      domainIdList
      owner
      favorites
      likes
      updatedAt
      createdAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDomainUserSubscriptionVariables,
  APITypes.OnCreateDomainUserSubscription
>;
export const onUpdateDomainUser = /* GraphQL */ `subscription OnUpdateDomainUser {
  onUpdateDomainUser {
    domainId
    userDirectId
    user {
      id
      directId
      name
      profileIcon
      domainIdList
      owner
      favorites
      likes
      updatedAt
      createdAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDomainUserSubscriptionVariables,
  APITypes.OnUpdateDomainUserSubscription
>;
export const onDeleteDomainUser = /* GraphQL */ `subscription OnDeleteDomainUser {
  onDeleteDomainUser {
    domainId
    userDirectId
    user {
      id
      directId
      name
      profileIcon
      domainIdList
      owner
      favorites
      likes
      updatedAt
      createdAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDomainUserSubscriptionVariables,
  APITypes.OnDeleteDomainUserSubscription
>;
export const onCreateNotifications = /* GraphQL */ `subscription OnCreateNotifications {
  onCreateNotifications {
    id
    domainId
    title
    desc
    users
    departments
    exclude_users
    exclude_departments
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNotificationsSubscriptionVariables,
  APITypes.OnCreateNotificationsSubscription
>;
export const onUpdateNotifications = /* GraphQL */ `subscription OnUpdateNotifications {
  onUpdateNotifications {
    id
    domainId
    title
    desc
    users
    departments
    exclude_users
    exclude_departments
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNotificationsSubscriptionVariables,
  APITypes.OnUpdateNotificationsSubscription
>;
export const onDeleteNotifications = /* GraphQL */ `subscription OnDeleteNotifications {
  onDeleteNotifications {
    id
    domainId
    title
    desc
    users
    departments
    exclude_users
    exclude_departments
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNotificationsSubscriptionVariables,
  APITypes.OnDeleteNotificationsSubscription
>;
