














































import { Component, Prop, Vue } from "vue-property-decorator";
import Confirm from "./button/Confirm.vue";
import Cancel from "./button/Cancel.vue";

// HelpContent が npmから使えるような仕組みになっていないため(Vueへの登録などが無い)、しばらくはコードコピーしたものを使うことにする
const HelpModalContent = require("@lisb/dx-flow-help")
import "@lisb/dx-flow-help/dist/dx-flow-help.css";

import { PRODUCT_STAGE } from "../aws-config";

const HELP_URL = "https://direct4b.com/ja/plan/";
const HELP_BUTTON_LABEL = "料金プラン";

@Component({
    components: { Confirm, Cancel, HelpModalContent, }
})
export default class AlertModal extends Vue {
    name: string = 'alert-modal';

    // ヘルプ関係
    helpUrl = HELP_URL;
    helpButtonText = HELP_BUTTON_LABEL;

    @Prop({ default: '', required: true }) readonly id!: string;

    @Prop({ default: '', required: true }) readonly msg!: string;

    @Prop({ default: 'キャンセル' }) readonly cancelLabel!: string;

    @Prop({ default: 'OK' }) readonly okLabel!: string;

    @Prop({ default: 'info' }) readonly okVariant!: string;

    @Prop({ default: true }) readonly centered!: boolean;

    /** true: キャンセルボタンを出さない false: キャンセルボタンを出す */
    @Prop({ default: false }) readonly hideCancel!: boolean;

    // true: ヘルプ文言を出す false: 出さない
    @Prop({ default: false }) readonly help!: boolean;

    @Prop({ default: () => { return { title: "", content: "", isError: true } } }) readonly helpContentArgs!: { title: string, content: string, isError: boolean };

    // Storybook向け
    @Prop({ default: false }) readonly show!: boolean;

    // HelpModalContent向け。環境設定
    getStage(): string {
        switch( PRODUCT_STAGE ) {
            case "PRD": return "production";
            case "STG": return "staging";
            case "DEV": return "develop";
            default:    return "production";
        }
    }

    // cancel clicked
    clickedCancel( cancelEvent: ()=>void ): void {
        this.$emit('clickedCancel');
        cancelEvent();
    }

    // OK clicked
    clickedOK( okEvent: ()=>void ): void {
        this.$emit('clickedOK');
        okEvent();
    }

    // モーダルが閉じた際の処理
    hideEvent():void {
        console.log(`hide modal: ${this.id}`);
        this.$emit('hideModal');
    }
    
    reload(): void {
        // URLを再取得する
        this.$root?.$emit("free-alert");
    }

    // Lifecycle
    mounted(): void {
        if( this.show ) {
            this.$bvModal.show(this.id)
        }
    }
}
