





















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import NavbarMenuSolutionsPanel from './NavbarMenuSolutionsPanel.vue';
import { AppLinkItem } from './types';

@Component({ components: { NavbarMenuSolutionsPanel } })
export default class NavBarSolutionButton extends Vue {
    name = 'NavBarSolutionButton'

    // 開閉状態の管理
    expanded = false;

    // ソリューションアイコン一覧
    @Prop({ default: () => [] }) readonly solutionLinks!: AppLinkItem[];

    @Prop({ default: () => "" }) readonly domainId!: string;

    // アプリ名
    get appLabel() { return "掲示板" }

    // アプリアイコン
    get appIcon() { return "https://direct4b.app/img/app_icon_forum.svg" }
}
