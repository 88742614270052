var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "searchbox-container" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.searchBoxText,
          expression: "searchBoxText"
        }
      ],
      ref: "searchBox",
      staticClass: "departments-searchbox searchbox form-control",
      attrs: {
        type: "text",
        placeholder: _vm.localized ? "部署を検索" : "部署を検索",
        disabled: _vm.disabled
      },
      domProps: { value: _vm.searchBoxText },
      on: {
        keydown: _vm.onKeydown,
        blur: _vm.onBlur,
        change: _vm.onChange,
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.searchBoxText = $event.target.value
        }
      }
    }),
    _c("span", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.clearButtonVisibled,
          expression: "clearButtonVisibled"
        }
      ],
      staticClass: "icon-x",
      on: { click: _vm.onClickedClearButton }
    }),
    _c("span", {
      staticClass: "icon-magnifying-glass",
      attrs: { icon: "search" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }