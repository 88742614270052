var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DepartmentsArea", {
    attrs: {
      localized: true,
      disabled: false,
      departments: _vm.departmentSource,
      filteredDepartments: _vm.filteredDepartments,
      selectedDepartmentIdStr: "",
      useSmallMargin: true,
      denyIdList: _vm.denyIdList
    },
    on: {
      selectDepartment: _vm.selectDepartment,
      expandDepartment: _vm.expandDepartment,
      collapseDepartment: _vm.collapseDepartment,
      changeDepartmentsKeyword: _vm.changeDepartmentsKeyword
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }