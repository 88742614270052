import { Store } from "vuex";
import { AppLinkItem } from "./types";
import { ServerApiAccess } from "@/server-api-access";
import { Solution, SolutionLinkResponse } from "@/model/solution";
import { getSolutionUrl } from "./solution-url";
export type { AppLinkItem } from "./types";

/** ソリューション一覧を返す */
export function getSolutionItems( domainId: string, $store: Store<any> ): AppLinkItem[] {
    const solutions = ( $store.getters['domains/getSolutions']( domainId ) || [] ) as Solution[]
    const directApps = ( $store.getters['domains/getDirectApps']( domainId ) || [] ) as Solution[]
    const links = [...directApps, ...solutions].map( app => {
        return {
            url: getSolutionUrl( $store, domainId, app ),
            iconUrl: app.icon_url,
            name: app.name.ja,
        }
    })

    // directを追加
    links.push( {
        url: getSolutionUrl( $store, domainId ),
        iconUrl: "/img/icon/icon_talk_2.svg",
        name: "トーク"
    })

    return links;
}

export async function fetchSolutionLinks( domainId: string ): Promise<SolutionLinkResponse|undefined|number> {
    const access = new ServerApiAccess()
    const result = await access.listApps( domainId );
    if( result && typeof result == "object" ) {
        // PhotoView を削除
        result.solutions = (result.solutions || [] ).filter( app => app.name.ja != "PhotoView" )

        // 掲示板 を削除
        result.direct_apps = (result.direct_apps || [] ).filter( app => app.unique_key != "forum" )
    }

    return result;
}
