var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    {
      class: {
        "common-btn": !_vm.isFlex,
        "flex-btn": _vm.isFlex,
        "default-color": _vm.isSecondary,
        radius: _vm.rounded,
        sm: _vm.size === "sm"
      },
      attrs: {
        "inline-block": !_vm.isFlex,
        disabled: _vm.disabled,
        size: _vm.size,
        variant: _vm.variant
      },
      on: { click: _vm.onClick }
    },
    [_vm._v(_vm._s(_vm.label))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }