var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { indicator: true, fullscreen: _vm.fullscreen },
      style: "background: " + _vm.backgroundColor + ";"
    },
    [
      _c("div", { staticClass: "indicator-loading-panel" }, [
        _c("div", { staticClass: "indicator-contents" }, [
          _vm._m(0),
          _c("div", { staticClass: "indicator-loading-message" }, [
            _vm._v(_vm._s(_vm.msg))
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "indicator-spinner" }, [
      _c("div", { staticClass: "rect1" }),
      _c("div", { staticClass: "rect2" }),
      _c("div", { staticClass: "rect3" }),
      _c("div", { staticClass: "rect4" }),
      _c("div", { staticClass: "rect5" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }