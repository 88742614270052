var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    attrs: {
      id: _vm.id,
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      scrollable: "",
      "no-close-on-backdrop": "",
      "body-class": "d-flex flex-column max-vh-100 position-relative"
    },
    on: { show: _vm.resetParam },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var close = ref.close
          return [
            _c(
              "div",
              {
                on: {
                  dragover: function($event) {
                    $event.preventDefault()
                    _vm.drag = true
                  },
                  dragleave: function($event) {
                    $event.preventDefault()
                    _vm.drag = false
                  },
                  drop: function($event) {
                    $event.preventDefault()
                    _vm.drag = false
                  }
                }
              },
              [
                _c("drag-area-overlay", {
                  attrs: { drag: _vm.drag },
                  on: { dropFiles: _vm.dropFiles }
                }),
                _vm.isUploading
                  ? _c("loading-upload", {
                      attrs: { msg: _vm.loadingMsg, fullscreen: false }
                    })
                  : _vm._e(),
                _c(
                  "b-row",
                  { staticClass: "d-flex", attrs: { "no-gutters": "" } },
                  [
                    _c("div", { staticClass: "comment-edit-label" }, [
                      _vm._v("コメント編集")
                    ])
                  ]
                ),
                _c(
                  "b-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c("b-form-textarea", {
                      staticClass: "content-input",
                      attrs: {
                        rows: "5",
                        "aria-describedby": "content-feedback"
                      },
                      on: {
                        update: function($event) {
                          return _vm.changeEditMode(_vm.editMode)
                        }
                      },
                      model: {
                        value: _vm.messageSource,
                        callback: function($$v) {
                          _vm.messageSource = $$v
                        },
                        expression: "messageSource"
                      }
                    }),
                    _c(
                      "b-form-text",
                      {
                        class: _vm.contentState ? "success" : "warning",
                        attrs: { id: "content-feedback" }
                      },
                      [_vm._v(" " + _vm._s(_vm.validCommentMessage) + " ")]
                    )
                  ],
                  1
                ),
                _c("file-selection-form", {
                  ref: "fileSelectionForm",
                  attrs: {
                    contentType: "COMMENT",
                    label: "ファイル",
                    multiple: true,
                    photos: _vm.photos,
                    messageId: _vm.messageId,
                    commentId: _vm.commentId,
                    allow_attachment_type: _vm.allow_attachment_type,
                    "file-number-limit": 10
                  },
                  on: { onUpdateThumb: _vm.updateFile }
                }),
                _c(
                  "b-row",
                  { attrs: { "align-h": "between", "no-gutters": "" } },
                  [
                    _c("cancel", {
                      staticClass: "col-5",
                      attrs: { label: "キャンセル" },
                      on: {
                        cancel: function($event) {
                          return _vm.showEditDiscardAlert(close)
                        }
                      }
                    }),
                    _c("confirm", {
                      staticClass: "col-5",
                      attrs: { label: "保存", disabled: !_vm.contentState },
                      on: {
                        confirm: function($event) {
                          return _vm.onEdit(close)
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }