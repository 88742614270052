import { Vue } from "vue-property-decorator";
import { Store } from "vuex";

// ローカル実行時に使うデモ用データ
import { Categories, Topics, Messages, Comments } from "@/model"

const categories: Categories = []
const topics: Topics = []
const messages: Messages = {}
const comments: Comments = {}

export {
    categories,
    topics,
    messages,
    comments,
}

/** デモデータ用のハンドラー */
export function prepareDemoHandler<T>( vue: Vue, $store: Store<T> ) {
    // 空
}


/** デモデータの登録作業 */
export async function registerDemoData<T>( store:Store<T>, type: "category"|"topic"|"message"|"comment" ): Promise<void> {
    // 空
}
