import { v4 as uuidv4 } from "uuid";
import { Attachment, Comment as DbComment, IconReaction } from "@/API";
import Acl from "./acl";

const DUMMY_DOMAIN_ID = "DUMMY_DOMAIN_ID";
const DUMMY_TOPIC_ID = "DUMMY_TOPIC_ID";
const DUMMY_MESSAGE_ID = "DUMMY_MESSAGE_ID";
export class Comment {
    public id: string;                  //<! コメントID
    public domainId: string;            //!< 組織ID
    public topicId: string;             //!< 話題ID
    public messageId: string;           //!< メッセージID

    public message: string;             //!< コメント本文
    public photos: Attachment[];            //!< 添付画像

    public owner: string;               //!< 投稿者ID
    public createdAt: Date;             //!< 作成日時
    public updatedAt: Date;             //!< 更新日時

    public deleted: boolean;
    public deletedUser: string;

    // ACL関係
    public acl: Acl;

    // DBに保存されない
    public like?: IconReaction;         //!< イイね！

    protected constructor( obj?: Partial<Comment> | DbComment ) {
        this.id         = obj?.id           || uuidv4();
        this.domainId   = obj?.domainId     || DUMMY_DOMAIN_ID;
        this.topicId    = obj?.topicId      || DUMMY_TOPIC_ID;
        this.messageId  = obj?.messageId    || DUMMY_MESSAGE_ID;

        this.message    = obj?.message      || "";
        this.photos     = obj?.photos       || [];

        this.owner      = obj?.owner        || "(loading...)";
        this.updatedAt  = obj?.updatedAt    ? new Date( obj.updatedAt ) : new Date();
        this.createdAt  = obj?.createdAt    ? new Date( obj.createdAt ) : new Date();

        this.deleted    = obj?.deleted    || false;
        this.deletedUser= obj?.deletedUser || '';

        // ACL標準では他人Create系許可
        this.acl        = ( obj?.acl ) ? Acl.clone( obj.acl ) : Acl.create( this.id );

        this.like       = obj instanceof Comment ? obj.like : undefined;

    }

    public static create( obj?: Partial<Comment> ): Comment;
    public static create( obj: DbComment ): Comment;
    public static create( obj?: Partial<Comment> | DbComment ): Comment {
        return new Comment( obj );
    }

    /**
     * from から以下を覗いたデータをコピーする
     * id, domainId, topicId, user, updatedAt, createdAt
     * @param from
     */
    public copyFrom( from: Comment ): void {
        // id
        // domainId
        // topicId
        // messageId

        this.message = from.message;
        this.photos = from.photos;

        this.like = from.like;
        this.deleted  = from.deleted;
        this.deletedUser = from.deletedUser;

        this.acl = Acl.clone( from.acl );

        // owner
        // createdAt
        // updatedAt
    }

    /** 組織ID,話題ID,投稿IDが未設定なら指定のもので上書きする */
    public setDomainAndTopicAndMessageIfNotSet( domainId: string, topicId: string, messageId: string ): void {
        if( this.domainId   && this.domainId    == DUMMY_DOMAIN_ID  && domainId     ) this.domainId     = domainId;
        if( this.topicId    && this.topicId     == DUMMY_TOPIC_ID   && topicId      ) this.topicId      = topicId;
        if( this.messageId  && this.messageId   == DUMMY_MESSAGE_ID && messageId    ) this.messageId    = messageId;
    }
}
