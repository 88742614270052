import { v4 as uuidv4 } from "uuid";
import { User as DbUser } from "@/API";
import { ProfileIconManager } from "@/profile-icon-manager";
import { DirectDomainMembersType } from "@/direct-restapi-types";

export class User {
    public id: string;              //!< cognito-user-id
    public directId: string;
    public name: string;
    public profileIcon?: string;
    public domainIdList: string[];
    public owner?: string;
    public favorites: string[];     //!< ★を設定した Topic/MessageのIDリスト
    public likes: string[];         //!< イイね！を設定した Message/CommentのIDリスト
    public updatedAt?: Date;
    public createdAt?: Date;

    // 保存しない項目
    private s3ProfileIconUrl: string|undefined = undefined;
    public domainRoles: { [ domainId: string ]: number };   //!< member REST API の設定値を保持する場所
    public departments: { [ domainId: string ]: string[] }; //!< member REST API の所属部署情報
    public email: string;

    private constructor( obj?: Partial<User>|DbUser ) {
        // nullを削除するフィルター
        const filterNull = <T>( list: (T|null)[] ): T[] => list ? list.filter( item => !!item ) as T[] : [];

        this.id = obj?.id || uuidv4();
        this.directId = obj?.directId || "";
        this.name = obj?.name || "";
        this.profileIcon = obj?.profileIcon || undefined;
        this.domainIdList = obj?.domainIdList || [];
        this.owner = obj?.owner || "";
        this.favorites = ( obj && obj.favorites ) ? filterNull( obj.favorites ) : [];
        this.likes = ( obj && obj.likes ) ? filterNull( obj.likes ) : [];
        this.updatedAt = ( obj && obj.updatedAt ) ? new Date( obj.updatedAt ) : new Date();
        this.createdAt = ( obj && obj.createdAt ) ? new Date( obj.createdAt ) : new Date();

        // 保存しない項目
        this.s3ProfileIconUrl = undefined;
        this.domainRoles = {};
        this.departments = {};
        this.email = "";
    }

    public static create( obj: Partial<User>|DbUser ): User {
        return new User( obj );
    }

    public static createNotFoundUser( id?: string, directId?: string ): User {
        return new User( {
            id: id,
            directId: directId || undefined,
            name: "ユーザー(非表示)"
        });
    }

    /**
     * favoritesにidを追加／削除する
     * @param to: true: 無ければ追加する。 false: 一覧にあれば削除する
     */
    public static setFavorite( id: string, to: boolean, favorites: string[] ): void { this.setItem( id, to, favorites ); }

    /**
     * likesにidを追加／削除する
     * @param to: true: 無ければ追加する。 false: 一覧にあれば削除する
     */
    public static setLike( id: string, to: boolean, favorites: string[] ): void { this.setItem( id, to, favorites ); }

    /** listにidを追加／削除する。Vuex用の反映メソッドを使うこと */
    public static setItem( id: string, to: boolean, idList: string[] ): void {
        if( to ) {   // → ON へ
            // 無ければ追加(既存なら何もしない)
            const index = idList.findIndex( itemId => itemId == id );
            if( index < 0 ) idList.push( id );
        } else {                    // → OFF へ
            // 既存の場合は削除
            const index = idList.findIndex( itemId => itemId == id );
            if( 0 <= index ) idList.splice( index, 1 );
        }

        // データ整合性を保つ(空配列は禁止されている)
        if( idList.length <= 0 ) {
            idList.push("");
        } else {
            // ゴミ掃除(""を削除)
            const index = idList.findIndex( itemId => itemId == "" );
            if( 0 <= index ) idList.splice( index, 1 );
        }
    }

    /**
     * ★一覧にidを追加／削除する
     * @param to: true: 無ければ追加する。 false: 一覧にあれば削除する
     */
    public setFavorite( id: string, to: boolean ): void {
        User.setItem( id, to, this.favorites );
    }

    /** イイね！一覧にidを追加／削除する @param to: true: 追加 false: 削除 */
    public setLike( id: string, to: boolean ): void {
        User.setItem( id, to, this.likes );
    }

    /** プロフィールアイコン（実際にとってこれるURL）を取得する */
    public getProfileIconUrl(): string {
        if( this.s3ProfileIconUrl !== undefined ) return this.s3ProfileIconUrl;
        // 取得に行くタスクを実行しておいて、こっちは空文字列を返す
        return ProfileIconManager.getProfileIconUrl( this.directId,( url: string|undefined ) => {
            // これで更新される
            this.s3ProfileIconUrl = url;
        });
    }

    /** 組織のロール情報を取得する */
    public getRoleId( domainId: string ): number|undefined { return this.domainRoles[ domainId ]; }

    /** 組織内の所属部署情報を取得する */
    public getDepartments( domainId: string ): string[]|undefined { return this.departments[ domainId ]; }

    /**
     * domainId組織内のユーザー名を取得する
     * @return 組織内のユーザー名。undefinedの場合は現在のログインユーザーからは見れない権限です
     */
    public getDomainUserName( domainId: string ): string|undefined {
        return this.getRoleId( domainId ) ? this.name : undefined;
    }

    /** domain組織内のロールIDを設定する */
    public setDomainRole( domainId: string, roleId: number ): void { this.domainRoles[ domainId ] = roleId; }

    /** 所属部署情報を設定する */
    public setDepartments( domainId: string, departments: string[] ): void { this.departments[ domainId ] = departments; }

    /** emailをセット */
    public setEmail( email: string ): void {
        this.email = email;
    }

    /** ゲストかどうかの判定。true:ゲスト */
    public isGuestRole( domainId: string ): boolean {
        const roleId = this.getRoleId( domainId );
        return User._isGuestRole( roleId );
    }

    /** ユーザーかどうかの判定。true: ユーザー */
    public isUserRole( domainId: string ): boolean {
        const roleId = this.getRoleId(domainId)
        return roleId === 3 || roleId === 14 || roleId === 19
    }

    /** 管理者かどうかの判定。true: 管理者 */
    public isAdminRole( domainId: string): boolean {
        const roleId = this.getRoleId(domainId)
        return roleId === 2 || roleId === 17 || roleId === 18
    }

    /** 所有者かどうかの判定。true: 所有者 */
    public isOwnerRole( domainId: string): boolean {
        const roleId = this.getRoleId(domainId)
        return roleId === 1
    }

    private static _isGuestRole( roleId: number|undefined ): boolean {
        switch( roleId ) {
            case 1:     // 所有者
            case 2:     // 管理者
            case 3:     // ユーザー
            case 4:     // 未使用
            case 14:    // ユーザー(DL禁止)
            case 17:    // 管理者(ゲストのみ操作可)
            case 18:    // 管理者(一斉送信送信不可)
            case 19:    // ユーザー(ゲスト非表示)
                return false;

            // ここからゲストとして判定
            case 5:     // Pユーザー
            case 6:     // スペシャルユーザー
            case 7:     // directプライムユーザー（仮）
            case 8:     // SBパートナー
            case 9:     // レイスグループ採用チーム
            case 10:    // ゲストユーザー
            case 11:    // ゲストユーザー
            case 12:    // ゲスト
            case 13:    // ゲスト(連絡先非表示)
            case 15:    // ゲスト(DL禁止)
            case 16:    // ゲスト(連絡先非表示)(DL禁止)
                return true;

            // その他のロールが追加された場合はゲストとして扱う
            default:
                return true;
        }
    }

    /**
     * userIdがゲストかどうかを判定する(members APIの結果から)
     * userIdが members 内に存在しない場合はゲスト扱いとする
     * ※ログインユーザーのロールによって発生する場合がある。詳細は下記
     *
     * ログインユーザーが下記の時、membersに存在しない userId のパターン
     * ユーザーロール(ただしRoleId:19を除く) ： 退会済みユーザー
     * ユーザーロール( RoleId: 19 )          ： 退会済みユーザー or ゲストユーザー
     * ゲストロール(ただしRoleId:13,16を除く)： 退会済みユーザー or ゲストユーザー
     * ゲストロール( RoleId: 13, 16 )        ： 全ユーザー
     *
     * このため、RoleId 13/16 の場合一般ユーザーでもゲストとみなされてしまう
     *
     * @return true: ゲスト false: ユーザー
     */
    public static memberIsGuestRole( members: DirectDomainMembersType, userId: string ): boolean {
        const data = members.contents.find( member => member.user_id_str == userId );
        const roleId = data?.role_id;
        return this._isGuestRole( roleId );
    }

    /** ダウンロード許可ユーザーかどうか。treu: ダウンロード許可ユーザー */
    public isDownloadableRole( domainId: string ): boolean {
        const roleId = this.getRoleId( domainId );
        switch( roleId ) {
            case 14:    // ユーザー（DL禁止）
            case 15:    // ゲスト（DL禁止）
            case 16:    // ゲスト（連絡先非表示）（DL禁止）
                return false;
        }
        return true;
    }



    /** 連絡先非表示かどうか。true: 連絡先非表示 */
    public isHiddenAddress( domainId: string ): boolean {
        const roleId = this.getRoleId( domainId );
        switch( roleId ) {
            case 13:    // ゲスト(連絡先非表示)
            case 16:    // ゲスト(連絡先非表示)(DL禁止)
                return true;

            // その他は違う
            default:
                return false;
        }
    }

    /** データの上書き(更新) */
    public override( obj: User ): void {
        // nullを削除するフィルター
        const filterNull = <T>( list: (T|null)[] ): T[] => list ? list.filter( item => !!item ) as T[] : [];

        if( obj?.id ) this.id = obj?.id;
        if( obj?.directId ) this.directId = obj?.directId;
        if( obj?.name ) this.name = obj?.name;
        if( obj?.profileIcon ) this.profileIcon = obj?.profileIcon;
        if( obj?.domainIdList ) this.domainIdList = obj?.domainIdList;
        if( obj?.owner ) this.owner = obj?.owner;
        if( obj && obj.favorites) this.favorites = filterNull( obj.favorites );
        if( obj && obj.likes ) this.likes = filterNull( obj.likes );
        if( obj && obj.updatedAt ) this.updatedAt = new Date( obj.updatedAt );
        if( obj && obj.createdAt ) this.createdAt = new Date( obj.createdAt );
        if( Object.keys(obj.domainRoles).length ) this.domainRoles = obj.domainRoles;
        if( Object.keys(obj.departments ).length ) this.departments = obj.departments;
        if( obj?.email ) this.email = obj?.email;
    }
}
