































































































































































import { mixins } from "vue-class-component";
import { Watch, Component, Prop, Vue } from 'vue-property-decorator';
import MessageListItem from './MessageListItem.vue';
import CommentListItem from './CommentListItem.vue';
import TextProcessMixin from "./mixin/TextProcessMixin";
import TopicListItem from './TopicListItem.vue';
import { User } from "../model";
import { Route, NavigationGuardNext } from "vue-router";
import store from "../store";
import { LoadingManager } from './loading/loading-manager';
import type { SearchResultItemData } from "../router/index";

Component.registerHooks([
    'beforeRouteUpdate',
])

@Component({
    mixins: [
        TextProcessMixin
    ],
    components: {
        MessageListItem, CommentListItem, TopicListItem
    }
})
export default class SearchResult extends mixins( Vue, TextProcessMixin ){
    name: string = 'search-result';
    searchResultList: SearchResultItemData[] = [];

    @Prop({ default: '' })
    readonly word!: string;

    @Prop({ default: () => [] })
    readonly allData!: SearchResultItemData[];
    @Watch( 'allData', { immediate: true } ) onAllDataChanged(): void {
        this.searchFromStore();
    }

    @Prop({ default: () => [] }) readonly users!: User[]; // 組織内ユーザー情報
    @Prop({ default: () => ( User.createNotFoundUser() ) }) readonly viewer!: User; // ログインユーザ情報

    @Prop({ required: true }) readonly domainId!: string; //!< 所属組織ID

    @Prop({ default: '' }) readonly topicId!: string;  //!< 所属話題ID

    mounted (): void {
        this.searchFromStore();
    }

    searchFromStore(): void {
        if( !this.allData.length || !this.word.length ) {
            this.searchResultList = [];
            return;
        }
        const list = this.allData.filter( (data: SearchResultItemData ) => {
            switch(data.type) {
                case "topic":
                    return this.searchWord(data.topicTitle, this.word) || this.searchWord(data.text, this.word);
                case "message":
                    return this.searchWord(data.messageTitle, this.word) || this.searchWord(data.text, this.word);
                case "comment":
                    return this.searchWord(data.text, this.word);
                default:
                    return false;
            }
        });

        const pinned = list.filter( data => data.pinned );      // ピンあり(固定)
        const notPinned = list.filter( data => !data.pinned );  // ピンなし

        const pinnedStar = pinned.filter( data => data.star );      // ピンあり星あり(固定+星)
        const pinnedNotStar = pinned.filter( data => !data.star );  // ピンあり星なし(固定)
        const star = notPinned.filter( data => data.star );         // ピンなし星あり(お気に入り)
        const normal = notPinned.filter( data => !data.star );      // ピンなし星なし(初期状態)
        // 更新日が 新→古 順でソート
        pinnedStar.sort( (l, r) => r.updatedAt.getTime() - l.updatedAt.getTime() );
        pinnedNotStar.sort( (l, r) => r.updatedAt.getTime() - l.updatedAt.getTime() );
        star.sort( (l, r) => r.updatedAt.getTime() - l.updatedAt.getTime() );
        normal.sort( (l, r) => r.updatedAt.getTime() - l.updatedAt.getTime() );

        this.searchResultList = pinnedStar.concat( pinnedNotStar, star, normal );
    }

    jumpPage(link: string): void {
        if(link) {
            this.$router.push(link);
        }
    }

    beforeRouteUpdate( to: Route, from: Route, next: NavigationGuardNext ): void {
        if( from.params.domainId != to.params.domainId
            || from.params.topicId != to.params.topicId
        ) {
            store.dispatch('fetchData', { domainId: to.params.domainId, topicId: to.params.topicId, prevDomainId: from.params.domainId } );
        }
        next();
    }
}

