















import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class Cancel extends Vue {
    name = "cancel";

    @Prop({ default: "キャンセル", required: true })
    readonly label!: string;

    onClick(): void {
        this.$emit('cancel');
    }
}
