import { Auth, Storage } from 'aws-amplify';
import { Message, Comment, Messages, Comments } from "@/model";
import path from 'path';
import store from "../store/index";
import { v4 as uuidv4 } from "uuid";
import { EventManager } from '@/events/event-manager';

/**
 * S3へのデータアクセスクラス
 * ファイルはS3の /domainId/topicId/messageId[/commentId]/ に保存される
 */
export default class S3AccessUtility {
    private static instance: S3AccessUtility;

    /* 現在設定されている組織ID。組織変更じに再設定されます */
    private domainId: string = '';

    /* 現在設定されている話題ID。話題変更時に再設定されます */
    private topicId: string = '';

    public static getInstance(): S3AccessUtility {
        if( !S3AccessUtility.instance ) {
            this.instance = new S3AccessUtility();
            this.instance.init();
        }
        return this.instance;
    }

    public static encodeName(fileName: string): string {
        return fileName.replace(/\./, `#${uuidv4()}.`);
    }

    public static decodeName(encode: string): string {
        const uuiodReg = /#([0-9a-fA-F]{8}[-][0-9a-fA-F]{4}[-][0-9a-fA-F]{4}[-][0-9a-fA-F]{4}[-][0-9a-fA-F]{12})/;
        const match = encode.match(uuiodReg);
        if( !match || match.length < 1 ) {
            // マッチしない場合はそのままのファイル名を返す
            return encode;
        }
        const uuid = match[1];
        return encode.replace(`#${uuid}`, "");
    }

    // デモのs3ファイル設置
    public static async setDemoFiles(demoMessages: Messages, demoComments: Comments ): Promise<void> {
        for( const domainId of Object.keys(demoMessages)) {
            try {
                const topics = demoMessages[ domainId ];
                for( const topicId of Object.keys( topics ) ) {
                    for( const message of topics[ topicId ] ) {
                        await this._setDemoFilesForMessage( message, demoComments, domainId, topicId );
                    }
                }

            } catch( err ) {
                console.log("Demo Files Create error:%O", err);
            }
        }
    }

    // Message毎の処理
    private static async _setDemoFilesForMessage( message: Message, demoComments: Comments, domainId: string, topicId: string ): Promise<void> {
        const messageId = message.id;
        const baseUrl = process.env.BASE_URL || "/";

        // 投稿の添付画像をS3にアップロード
        for( const photo of message.photos) {
            const fileName = path.basename(photo.url);
            const s3path = path.join( "uploads", domainId, topicId, messageId, fileName );
            const demoImgPath = path.join(baseUrl, "img/demo/", path.basename(photo.url));
            const response = await fetch( demoImgPath );
            const blob = await response.blob();
            // console.log("画像 S3 put: %s -> %s %s %s", demoImgPath, s3path, blob.size, blob.type );
            await Storage.put(s3path , blob, {
                contentType: blob.type
            });
        }

        const messages = demoComments[ topicId ] || {};
        const comments = messages[ messageId ] || [];
        for( const comment of comments ) {
            await this._setDemofilesForComment( comment, domainId, topicId, messageId );
        }
    }

    // Comment毎の処理(デモ用画像データをS3にアップロード)
    private static async _setDemofilesForComment( comment: Comment, domainId: string, topicId: string, messageId: string ) {
        const commentId = comment.id;
        const baseUrl = process.env.BASE_URL || "/";
        for( const photo of comment.photos ) {
            const fileName = path.basename(photo.url);
            const s3path = path.join( "uploads", domainId, topicId, messageId, commentId, fileName );
            const demoImgPath = path.join(baseUrl, "img/demo/", fileName);
            const response = await fetch( demoImgPath );
            const blob = await response.blob();
            // console.log("画像 S3 put: %s -> %s %s %s", demoImgPath, s3path, blob.size, blob.type );
            await Storage.put(s3path, blob, {
                contentType: blob.type
            });
        }
    }

    // デモ用に配置したファイルを削除する
    public static async resetDemoFiles(): Promise<void> {
        // domainId: 1, topicId: 0
        const files = await Storage.list('reads/1/0/');

        if( files.length ) {
            for( const file of files ) {
                try {
                    if( file?.key ) await Storage.remove(file.key);
                } catch(err) {
                    console.log("Demo Files Remove error:%O", err);
                }
            }
        }
    }


    public init(): void {
        this.domainId = '';
        this.topicId = '';
    }

    /* AWS接続チェック */
    private hasSession(): Promise<boolean> {
        return Auth.currentSession()
        .then( session => {
            return !!session;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        }).catch( ( error ) => {
            return false;
        });
    }

    private isAndroidDevice(): boolean {
        return store.getters["isAndroid"];
    }

    public setDomainId(domainId: string): void {
        this.domainId = domainId;
    }

    public setTopicId(topicId: string): void {
        this.topicId = topicId;
    }

    /**
     * PUT: ファイルをS3へアップロード
     * @param file ファイル
     * @param param { messsageId: 投稿ID, commentId: コメントID(メッセージPUTの場合は空文字) }
     */
    public async putFile( file: File, param: { topicId?: string, messageId: string, commentId: string } ): Promise<string> {
        if( await this.hasSession() == false ) return '';    // セッションが無いので更新不可
        const topicId = param.topicId || this.topicId;
        const upload_path = store.getters["domains/getUploadPath"] as string;
        const key = S3AccessUtility.encodeName(file.name);

        if(param.messageId && !param.commentId) {
            const s3path = path.join( upload_path, this.domainId, topicId, param.messageId, key );
            console.log(`*Put Message File ${s3path} ファイル名: ${file.name}`, );
            await Storage.put(s3path, file, {
                contentType: file.type
            });
            return key;
        } else if(param.messageId && param.commentId){
            const s3path = path.join( upload_path, this.domainId, topicId, param.messageId, param.commentId, key );
            console.log(`*Put Comment File ${s3path}  ファイル名: ${file.name}`, );
            await Storage.put(s3path, file, {
                contentType: file.type
            });
            return key;
        } else if( param.topicId && param.messageId == "" && param.commentId == "" ) {   // 話題のサムネイル
            const s3path = path.join( upload_path, this.domainId, topicId, key );
            console.log(`*Put Topic File ${s3path}  ファイル名: ${file.name}`, );
            await Storage.put(s3path, file, {
                contentType: file.type
            });
            return key;
        } else {
            console.log(`*Invalid Path. ${upload_path}/${this.domainId}/${topicId}/${param.messageId}/${param.commentId}/ ファイル名: ${file.name}`);
            return '';
        }
    }

    /**
     * @param file  ファイル
     * @param param { messsageId: 投稿ID, commentId: コメントID(メッセージPUTの場合は空文字) }
     * @param key   ファイル名 PDFサムネイル画像のファイル名のルールは${元のPDFファイル名}_tmb.jpg
     */
    public async putPdfThumbFile( file: File, param: { messageId: string, commentId: string }, key: string ): Promise<void> {
        if( await this.hasSession() == false ) return;    // セッションが無いので更新不可
        const upload_path = store.getters["domains/getUploadPath"] as string;

        if(param.messageId && !param.commentId) {
            const s3path = path.join( upload_path, this.domainId, this.topicId, param.messageId, key );
            console.log(`*Put Message PDF Thumbnail File ${s3path} ファイル名: ${file.name}`, );
            await Storage.put(s3path, file, {
                contentType: file.type
            });
        } else if(param.messageId && param.commentId){
            const s3path = path.join( upload_path, this.domainId, this.topicId, param.messageId, param.commentId, key );
            console.log(`*Put Comment PDF Thumbnail File ${s3path}  ファイル名: ${file.name}`, );
            await Storage.put(s3path, file, {
                contentType: file.type
            });
        } else {
            console.log(`*Invalid Path. ${upload_path}/${this.domainId}/${this.topicId}/${param.messageId}/${param.commentId}/ ファイル名: ${file.name}`);
        }
    }

    /**
     * GET: ファイルをS3から取得
     * @param fileName
     * @param param
     */
    public async getFile( fileName: string, param: { topicId?: string, messageId: string, commentId: string } ): Promise<unknown|string> {
        if( await this.hasSession() == false ) return fileName;    // セッションが無いのでアクセス不可
        fileName = path.basename( fileName );   // 余計なパラメータ類を削除
        const topicId = param.topicId || this.topicId;

        if(param.messageId && !param.commentId) {
            const s3path = path.join( "reads", this.domainId, topicId, param.messageId, fileName );
            console.log(`*Get Message File ${s3path}`);
            const url = await Storage.get(s3path);
            return url;
        } else if(param.messageId && param.commentId) {
            const s3path = path.join( "reads", this.domainId, topicId, param.messageId, param.commentId, fileName );
            console.log(`*Get Comment File ${s3path}`,);
            const url = await Storage.get(s3path);
            return url;
        } else if( topicId != "" && param.messageId == "" && param.commentId == "" ) {
            const s3path = path.join( "reads", this.domainId, topicId, fileName );
            console.log(`*Get Topic File ${s3path}`,);
            const url = await Storage.get(s3path);
            return url;
        } else {
            console.log(`*Invalid Path. reads/${this.domainId}/${topicId}/${param.messageId}/${param.commentId}/ ファイル名: ${fileName}` );
            return fileName;
        }
    }

    /**
     * GET: ファイルをS3から取得
     * @param fileName
     * @param param
     */
     public async getThumbFile( fileName: string, param: { topicId?: string, messageId: string, commentId: string } ): Promise<unknown|string> {
        if( await this.hasSession() == false ) return fileName;    // セッションが無いのでアクセス不可
        fileName = path.basename( fileName );   // 余計なパラメータ類を削除
        const topicId = param.topicId || this.topicId;
        const rootPath = "thumb";

        if(param.messageId && !param.commentId) {
            const s3path = path.join( rootPath, this.domainId, topicId, param.messageId, fileName );
            console.log(`*Get Message Thumb File ${s3path}`);
            const url = await Storage.get(s3path);
            return url;
        } else if(param.messageId && param.commentId) {
            const s3path = path.join( rootPath, this.domainId, topicId, param.messageId, param.commentId, fileName );
            console.log(`*Get Comment Thumb File ${s3path}`,);
            const url = await Storage.get(s3path);
            return url;
        } else if( topicId != "" && param.messageId == "" && param.commentId == "" ) {
            const s3path = path.join( rootPath, this.domainId, topicId, fileName );
            console.log(`*Get Topic Thumb File ${s3path}`,);
            const url = await Storage.get(s3path);
            return url;
        } else {
            console.log(`*Invalid Path. ${rootPath}/${this.domainId}/${topicId}/${param.messageId}/${param.commentId}/ ファイル名: ${fileName}` );
            return fileName;
        }
    }

    /**
     * DELETE: ファイルをS3から削除
     * @param fileName
     * @param param
     */
    public async removeFile( fileName: string, param: { topicId?: string, messageId: string, commentId: string } ): Promise<void> {
        if( await this.hasSession() == false ) return;
        const topicId = param.topicId || this.topicId;

        if(param.messageId && !param.commentId) {
            console.log(`*Remove Message File reads/${this.domainId}/${topicId}/${param.messageId}/  ファイル名: ${fileName}`, );
            await Storage.remove(`reads/${this.domainId}/${topicId}/${param.messageId}/${fileName}`);
        } else if(param.messageId && param.commentId) {
            console.log(`*Remove Comment File reads/${this.domainId}/${topicId}/${param.messageId}/${param.commentId}/  ファイル名: ${fileName}`, );
            await Storage.remove(`reads/${this.domainId}/${topicId}/${param.messageId}/${param.commentId}/${fileName}`);
        } else if( topicId != "" && param.messageId == "" && param.commentId == "" ) {
            console.log(`*Remove Topic File reads/${this.domainId}/${topicId}/${param.messageId}/${param.commentId}/  ファイル名: ${fileName}`, );
            await Storage.remove(`reads/${this.domainId}/${topicId}/${fileName}`);
        } else {
            console.log(`*Invalid Path. reads/${this.domainId}/${topicId}/${param.messageId}/${param.commentId}/ ファイル名: ${fileName}` );
        }
    }

    /**
     * DELETE: S3のthumbディレクトリからサムネイルファイルを削除
     * @param fileName
     * @param param
     */
    public async removeThumbFile( fileName: string, param: { topicId?: string, messageId: string, commentId: string } ): Promise<void> {
        if( await this.hasSession() == false ) return;
        const topicId = param.topicId || this.topicId;

        if(param.messageId && !param.commentId) {
            console.log(`*Remove Message File thumb/${this.domainId}/${topicId}/${param.messageId}/  ファイル名: ${fileName}`, );
            await Storage.remove(`thumb/${this.domainId}/${topicId}/${param.messageId}/${fileName}`);
        } else if(param.messageId && param.commentId) {
            console.log(`*Remove Comment File thumb/${this.domainId}/${topicId}/${param.messageId}/${param.commentId}/  ファイル名: ${fileName}`, );
            await Storage.remove(`thumb/${this.domainId}/${topicId}/${param.messageId}/${param.commentId}/${fileName}`);
        } else if( topicId != "" && param.messageId == "" && param.commentId == "" ) {
            console.log(`*Remove Topic File thumb/${this.domainId}/${topicId}/${param.messageId}/${param.commentId}/  ファイル名: ${fileName}`, );
            await Storage.remove(`thumb/${this.domainId}/${topicId}/${fileName}`);
        } else {
            console.log(`*Invalid Path. thumb/${this.domainId}/${topicId}/${param.messageId}/${param.commentId}/ ファイル名: ${fileName}` );
        }
    }


    /**
     * GET+DOWNLOAD: S3から取得したファイルのダウンロードを実行
     * @param fileName
     * @param param
     * @param downloadName // ダウンロード時のファイル名
     */
    public async downloadFile( fileName: string, param: { topicId?: string, messageId: string, commentId: string }, downloadName?: string ): Promise<void> {
        if( await this.hasSession() == false ) return;    // セッションが無いのでアクセス不可
        fileName = path.basename( fileName );   // 余計なパラメータ類を削除
        const topicId = param.topicId || this.topicId;

        let result = undefined;
        if(param.messageId && !param.commentId) {
            const s3path = path.join( "reads", this.domainId, topicId, param.messageId, fileName );
            console.log(`*Download Message File ${s3path}`);
            result = await Storage.get(s3path, { download: true });
        } else if(param.messageId && param.commentId) {
            const s3path = path.join( "reads", this.domainId, topicId, param.messageId, param.commentId, fileName );
            console.log(`*Download Comment File ${s3path}`,);
            result = await Storage.get(s3path, { download: true });
        } else if( topicId != "" && param.messageId == "" && param.commentId == "" ) {
            const s3path = path.join( "reads", this.domainId, topicId, fileName );
            console.log(`*Download Topic File ${s3path}`,);
            result = await Storage.get(s3path, { download: true });
        } else {
            console.log(`*Invalid Path. reads/${this.domainId}/${topicId}/${param.messageId}/${param.commentId}/ ファイル名: ${fileName}` );
            return;
        }

        if( !result || !result.Body ) return;

        if( this.isAndroidDevice() ) {
            // Androidのみ download handler でメモリ解放
            const eventListener = (event:any) => {
                if (event.url === url) {
                    URL.revokeObjectURL(url);
                    window.removeEventListener('directjsapidownloadfinished', eventListener);
                }
            }
            window.addEventListener('directjsapidownloadfinished', eventListener);
        }

        const blob = result.Body;
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = downloadName ?? fileName;
        a.click();
        // Androidだけダウンロード完了イベント
        if( this.isAndroidDevice() ) {
            EventManager.downloadingEvent();
        } else {
            // 他は即メモリ解放
            URL.revokeObjectURL(url);
        }
    }
}
