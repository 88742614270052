var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    attrs: {
      id: _vm.id,
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "body-class": "p-0",
      size: "xl",
      "no-close-on-backdrop": ""
    },
    on: {
      show: _vm.init,
      hide: function($event) {
        return _vm.changeEditMode(false)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var close = ref.close
          return [
            _c(
              "b-container",
              { staticClass: "acl-add-modal-container", attrs: { fluid: "" } },
              [
                _c(
                  "b-row",
                  { staticClass: "acl-add-modal-header" },
                  [
                    _c("div", { staticClass: "acl-modal-header-title" }, [
                      _vm._v(_vm._s(_vm.titleLabel))
                    ]),
                    _c("b-button-close", {
                      staticClass: "ml-auto p-3",
                      on: {
                        click: function($event) {
                          return _vm.cancel(close)
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "acl-add-modal-body" },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "acl-add-modal-left-panel",
                        attrs: { cols: "4", "no-gutters": "" }
                      },
                      [
                        _vm.departments.length || _vm.loading
                          ? _c(
                              "div",
                              { staticClass: "acl-add-modal-body-header" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "body-header-title ml-3 pt-2"
                                  },
                                  [_vm._v("部署絞込")]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.departments.length || _vm.loading
                          ? _c("departments-tree", {
                              staticClass: "department-tree",
                              attrs: {
                                departments: _vm.departments,
                                denyIdList: _vm.denyIdList
                              },
                              on: {
                                selectDepartment: function(value) {
                                  return _vm.loadEvent(function() {
                                    return _vm.clickedDepartmentTree(value)
                                  })
                                }
                              }
                            })
                          : _c(
                              "div",
                              { staticClass: "acl-add-modal-left-panel px-3" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "h-100 w-100",
                                    staticStyle: {
                                      border: "1px solid #8CA6B7",
                                      overflow: "scroll"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "p-2" }, [
                                      _c("h5", [_c("u", [_vm._v("使い方")])]),
                                      _vm._v(
                                        " 話題を閲覧できるユーザー単位で設定できます。"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " 話題の閲覧権限設定は所有者と管理者のみ設定可能です。"
                                      ),
                                      _c("br"),
                                      _c("br"),
                                      _vm._v(
                                        " ①部署・ユーザーの絞込項目はユーザーを選択してください"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " ➁ユーザーをチェックしてください。チェックされているユーザーは話題の閲覧が可能です。"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " ③全解除ボタンですべてのユーザー閲覧不可となります。"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " ④プルダウンから「すべてのユーザー」を選択すると、すべてのユーザーの閲覧設定の状況が表示されます。"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " ⑤プルダウンから「閲覧制限部署またはユーザー」を選択すると、閲覧が制限されている部署やユーザーのみが表示されます。"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " ⑥確認できたら、保存を押してください。"
                                      ),
                                      _c("br")
                                    ])
                                  ]
                                )
                              ]
                            )
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "acl-add-modal-right-panel",
                        attrs: { cols: "8" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "acl-add-modal-body-header" },
                          [
                            _c("div", { staticClass: "body-header-title" }, [
                              _vm._v("部署・ユーザの絞込")
                            ]),
                            _c(
                              "div",
                              { staticClass: "d-flex mt-auto" },
                              [
                                _c("dropdown", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    activeIndex: _vm.modeIndex,
                                    options: _vm.searchOptions
                                  },
                                  on: { click: _vm.changeSearchMode }
                                }),
                                _c("search-window", {
                                  on: {
                                    update: _vm.updateSearchWindowInput,
                                    change: _vm.search
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c("lower-list", {
                          class: "lower-list-component no-footer",
                          attrs: {
                            lowerList: _vm.lowerList,
                            routePath: _vm.routePath,
                            denyIdList: _vm.denyIdList,
                            searching: _vm.searching,
                            selectDepartmentIdStr: _vm.selectDepartmentIdStr,
                            activeIndex: _vm.activeIndex,
                            modeOptions: _vm.modeOptions,
                            aclController: _vm.aclController,
                            searchMode: _vm.searchMode
                          },
                          on: {
                            clickedEditButton: function(value) {
                              return _vm.loadEvent(function() {
                                return _vm.changeStatus(value)
                              })
                            },
                            selectedAll: function(value) {
                              return _vm.loadEvent(function() {
                                return _vm.selectedAll(value)
                              })
                            },
                            selectDepartment: function(value) {
                              return _vm.loadEvent(function() {
                                return _vm.selectDepartment(value)
                              })
                            },
                            changeFilterMode: function(value) {
                              return _vm.loadEvent(function() {
                                return _vm.changeFilterMode(value)
                              })
                            },
                            resetAll: function() {
                              return _vm.loadEvent(function() {
                                return _vm.resetAll()
                              })
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("b-row", { staticClass: "acl-add-modal-footer" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-direction-row my-auto ml-auto mr-2"
                    },
                    [
                      _c("custom-button", {
                        staticClass: "mr-2",
                        attrs: {
                          label: "キャンセル",
                          variant: "secondary",
                          size: "sm",
                          rounded: true
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.cancel(close)
                          }
                        }
                      }),
                      _c("custom-button", {
                        attrs: { label: "保存", size: "sm", rounded: true },
                        on: {
                          confirm: function($event) {
                            return _vm.save(close)
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              ],
              1
            ),
            _c("b-overlay", {
              attrs: {
                show: _vm.loading,
                "no-wrap": "",
                opacity: "0",
                spinner: "",
                "spinner-variant": "primary"
              },
              on: {
                hidden: function($event) {
                  _vm.loading = false
                }
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }