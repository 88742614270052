<template>

<svg id="グループ_225" data-name="グループ 225" xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 14 14">
  <g id="長方形_128" data-name="長方形 128" transform="translate(8)" fill="#10c2cf" stroke="#10c2cf" stroke-linejoin="round" stroke-width="1" opacity="0.499">
    <rect width="6" height="6" stroke="none"/>
    <rect x="0.5" y="0.5" width="5" height="5" fill="none"/>
  </g>
  <g id="長方形_129" data-name="長方形 129" fill="#10c2cf" stroke="#10c2cf" stroke-linejoin="round" stroke-width="1">
    <rect width="6" height="6" stroke="none"/>
    <rect x="0.5" y="0.5" width="5" height="5" fill="none"/>
  </g>
  <g id="長方形_130" data-name="長方形 130" transform="translate(8 8)" fill="#10c2cf" stroke="#10c2cf" stroke-linejoin="round" stroke-width="1">
    <rect width="6" height="6" stroke="none"/>
    <rect x="0.5" y="0.5" width="5" height="5" fill="none"/>
  </g>
  <g id="長方形_131" data-name="長方形 131" transform="translate(0 8)" fill="#10c2cf" stroke="#10c2cf" stroke-linejoin="round" stroke-width="1" opacity="0.499">
    <rect width="6" height="6" stroke="none"/>
    <rect x="0.5" y="0.5" width="5" height="5" fill="none"/>
  </g>
</svg>
</template>
<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -1px; /* yes, I'm that particular about formatting */
}
</style>
<script>
export default {
    props: {
        iconName: { type: String, default: "iconChecker" },
        width: { type: [Number, String], default: 14, },
        height: { type: [Number, String], default: 14, },
        iconColor: { type: String, default: 'currentColor' },
    }
}
</script>