







































import { Component, Prop, Vue } from "vue-property-decorator";
import SearchForm from "./SearchForm.vue";
import { Category } from "../model";
import "./topic-side-menu.scss"
import { allowCreateTopic } from "../direct-app-config";
import AclManager from "../model/acl-manager";

@Component({
    components: {
        SearchForm,
    }
})
export default class TopicSideMenuPanel extends Vue {
    name: string = 'topic-side-menu-panel';

    // カテゴリフィルタ
    filter: Category[] = [];

    @Prop( {} ) readonly id!: string;

    // 組織ID
    @Prop( { required: true } ) readonly domainId!: string;

    // カテゴリー一覧
    @Prop( { default: () => [] }) readonly categories!: Category[];

    // フィルタ設定されているカテゴリー一覧
    @Prop( { default: () => [] } ) readonly categoryFilter!: Category[];

    get newTopicUrl(): string {
        return `/${this.domainId}/new-topic`
    }

    get creatable(): boolean { return AclManager.createTopic( this.$store, this.domainId ) }

    // 項目の選択状態
    // フィルタが空 → 「すべて」のみON
    // ファイルに何か設定 → 「すべて」OFF、該当項目がON
    selected( category: Category | "all" ): { [selector: string]: boolean } {
        if( this.filter.length === 0 ) {
            return { 'selected': category === 'all' }
        } else if( category == 'all' ) {
            return { 'selected': this.filter.length === 0 }
        } else {
            return { 'selected': 0 <= this.filter.findIndex( item => item.id == category.id ) }
        }
    }

    // カテゴリーの選択処理
    // 「すべて」が選択された場合、「すべて」のみになる → filter が空
    // その他の項目が選択された場合は、該当項目のON/OFF
    onClick( category: Category | "all" ): void {
        if( category === 'all' ) {
            if( 0 === this.filter.length ) {
                // 何もしない
            } else {
                this.filter.splice( 0, this.filter.length );
            }
        } else {
            this._onClick( category );
        }
        if( this.$store ) {
            this.$store.dispatch("categories/setFilter", { domainId:this.domainId, filter:this.filter } );
        }
    }

    // 話題の新規作成ボタンを押した
    onTopicCreateClicked(): void {
        if( allowCreateTopic( this.domainId, this.$store ) == false ) {
            this.$root.$emit('free-alert');
            return;
        }
        this.$bvModal.show("modal-topic");
    }

    private _onClick( category: Category ): void {
        // 複数選択有り
        const index = this.filter.findIndex( item => item.id == category.id );
        if( 0 <= index ) {
            this.filter.splice( index, 1 );
        } else {
            this.filter.push( category );
        }
    }

    created(): void {
        this.filter.push( ...this.categoryFilter )
    }

}
