


































































































































































































































































import { User } from "@/model/user";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Department } from "./group-uesr-list/Departments/values/Department";
import { LowerListItem, IModeOption } from "./lower-list-type";
import Dropdown from "../common/CustomDropdown.vue";
import CheckBox from "../common/CheckBox.vue";
import CustomButton from "../button/CustomButon.vue";
import AclController from "./acl-controller";
import { SearchMode } from "./AclEditModal.vue";

@Component({
    components: {
        Dropdown,
        CheckBox,
        CustomButton
    }
})
export default class LowerList extends Vue {
    name: string = "lower-list";
    filteredList: LowerListItem[] = [];

    @Prop({ default: () => [], required: true }) readonly lowerList!: LowerListItem[];
    @Watch("lowerList", { immediate: true }) 
    onLowerListChange() {
        this.filteredList = this.lowerList.filter( item => { return !this.isExcept(item.id); });
    }
    @Prop({ default: () => [], required: true }) readonly routePath!: Department[];
    @Prop({ default: '' }) readonly selectDepartmentIdStr!: string;

    @Prop({ default: true }) readonly editMode!: boolean;
    @Prop({ default: () => [] }) readonly denyIdList!: string[];

    @Prop({ default: false }) readonly searching!: boolean;

    @Prop({ default: 0 }) readonly activeIndex!: number;

    @Prop({ default: [IModeOption.DEFAULT, IModeOption.USER, IModeOption.SET] }) readonly modeOptions!: IModeOption[];

    @Prop({ default: new AclController() }) readonly aclController!: AclController;

    @Prop({ default: undefined }) readonly searchMode!: SearchMode | undefined;

    get emptyLabel(): string {
        switch( this.searchMode ) {
            case SearchMode.ALL: {
                return "該当する部署・ユーザーが見つかりませんでした";
            }
            case SearchMode.DEPARTMENT: {
                return "該当する部署が見つかりませんでした";
            }
            case SearchMode.USER: {
                return "該当するユーザーが見つかりませんでした";
            }
            default: {
                if( this.isSET ) { return "閲覧制限対象のユーザーは選択されていません"; }
                return this.selectDepartmentIdStr ? "選択された部署に所属ユーザーが見つかりませんでした" : "部署が選択されていません";
            }
        }
    }

    /** フィルターmode判定 */
    get isDEFAULT(): boolean {
        return this.modeOptions[this.activeIndex] === IModeOption.DEFAULT;
    }
    get isUSER(): boolean {
        return this.modeOptions[this.activeIndex] === IModeOption.USER;
    }
    get isSET(): boolean {
        return this.modeOptions[this.activeIndex] === IModeOption.SET;
    }

    get setPathHeader(): boolean {
        return !this.isSET && this.routePath.length > 0;
    }

    isExcept(id: string): boolean {
        return this.aclController.isExcept(id);
    }

    isOwner(id: string): boolean {
        if( !this.$store ) return false;
        const me = this.$store.getters["users/me"] as User;
        return me.directId === id;
    }

    changeFilterMode(index: number, select?: string): void {
        this.$emit("changeFilterMode", {index: index, select: select });
    }

    clickRoutePath(department: Department): void {
        if( this.selectDepartmentIdStr === department.departmentIdStr ) return;
        this.$emit("selectDepartment", department.departmentIdStr);
    } 

    selectDepartment(item: LowerListItem): void {
        if( item.type === "Department" ) { 
            if( this.isSET ) { 
                this.changeFilterMode(0, item.id);
                return;
            }
            if( this.selectDepartmentIdStr === item.id ) return;
            this.$emit("selectDepartment", item.id);
        }
    }

    backDepartment(): void {
        if( this.routePath.length > 1 ) {
            const department = this.routePath[this.routePath.length - 2]
            this.$emit("selectDepartment", department.departmentIdStr);
        }
    }

    clickedEditButton(item: LowerListItem): void {
        this.$emit('clickedEditButton', item);
    }

    isIncreased(item: LowerListItem): boolean {
        const itemId = item.id;
        return !this.denyIdList.includes(itemId);
    }

    selectedAll(checked: boolean): void {
        this.$emit('selectedAll', checked);
    }

    resetAll(): void {
        this.$emit('resetAll');
    }

}
