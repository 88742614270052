<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="グループ_999" data-name="グループ 999" transform="translate(12 -4) rotate(45)">
    <path id="パス_256" data-name="パス 256" d="M19,12.87a1.041,1.041,0,0,0-.8-.98A3,3,0,0,1,16,9V4h1a1,1,0,0,0,0-2H7A1,1,0,0,0,7,4H8V9a3,3,0,0,1-2.2,2.89,1.041,1.041,0,0,0-.8.98V13a1,1,0,0,0,1,1h4.98L11,21a1,1,0,0,0,2,0l-.02-7H18a1,1,0,0,0,1-1Z" fill="inherit" fill-rule="evenodd"/>
  </g>
    <title :id="iconName">{{ iconName }} icon</title>
    <g :fill="iconColor">
        <slot />
    </g>
</svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'iconPinned'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  height: 24px;
}
</style>
