import { User, Topic } from "@/model";
import { Department } from "@/components/acl/group-uesr-list/Departments/values/Department";

export const INDEXEDDB_INFO = {
    NAME: 'openchannel',
}

// indexedDB/store のテーブル名 
export const DB_STORE = {
    USERS: "USERS",
    TOPIC: "TOPIC",
    DEPARTMENT: "DEPARTMENT",
} as const;

export type DB_STORE = typeof DB_STORE[keyof typeof DB_STORE];

// DB_STOREのテーブル名 から store の型を返す
export type DB_STORE_CLASS_TYPE<T extends DB_STORE> = 
    T extends "USERS" ? User : 
        T extends "TOPIC" ? Topic : 
            T extends "DEPARTMENT" ? Department : any;

//  DB_STOREのテーブル名 と連動する store の型一覧
export type DB_STORE_CLASS_TYPES = User | Topic | Department;