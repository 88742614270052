import { DirectDomainMembersType } from "@/direct-restapi-types";
import sentry from "@/sentry";
import { ServerApiAccess } from "@/server-api-access";
import Store from "@/store/index";

const MAX_RETRY_WAIT = 60000;   // リトライ間隔の上限
// １度に取得する量
const GET_MEMBERS_LIMIT = parseInt( process.env.VUE_APP_GET_MEMBERS_LIMIT || "1000", 10 );

export default class GetDomainMembersTask {
    public static async run( domainId: string, $store: typeof Store ): Promise<void> {
        const access = new ServerApiAccess();
        let offset = 0;
        const limit = GET_MEMBERS_LIMIT;
        const wait = 500;       // direct側のREST-API呼び出し間隔に500ms必要とのこと（早すぎると500エラーになる）
        let retryWait = wait;   // 結果がJSON形式で返ってきていない場合のリトライ間隔
        const domainMembers: DirectDomainMembersType = { contents: [] };

        const task = async () => {
            try {
                const result = await access.getDomainMembers( domainId, offset, limit );
                if( result && typeof( result ) !== "number" ) {
                    domainMembers.contents.push( ...result.contents );
                    if( result.contents.length < limit || $store.getters["domainId"] !== domainId ) {
                        // 取れてきたのが50件以下 または 処理中に別組織へ遷移 の場合終了へ
                        return 0;
                    } else {
                        // 次のループへ
                        offset += limit;
                        return wait;
                    }
                } else {
                    // 401 or 500
                    console.error( "domain-members error:%O", result );
                    // 5xxエラーの場合はリトライ
                    if ( typeof result === "number" && 500 <= result && result < 600 ) {
                        // ループ毎にリトライ間隔をx2する（最大1分間隔）
                        retryWait = Math.min(retryWait * 2, MAX_RETRY_WAIT);
                        return retryWait;
                    } else if ( typeof result === "number" && result == 401 ) {
                        // 再認証が必要（不正では無い）
                        return 0;
                    } else {
                        sentry.sendSentryError( result )
                        return -1;
                    }
                }
            } catch( error ) {
                console.error( "domain-members error:%O", error );
                sentry.sendSentryError( error )
                return -1;
            }
        }

        let resultWait = 0;
        do {
            resultWait = await task();
            if( resultWait > 0 ) { await new Promise(resolve => setTimeout(resolve, resultWait)) }
        } while ( resultWait > 0 )
        if( resultWait == 0 ) {
            await $store.dispatch('users/setDomainMembers', { domainId: domainId, domainMembers: domainMembers });
        }
    }
}