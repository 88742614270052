import { API } from "aws-amplify";
import {
    IconReaction, IconReactionType,
    OnCreateIconReactionSubscription, OnCreateIconReactionSubscriptionVariables,
    OnDeleteIconReactionSubscription, OnDeleteIconReactionSubscriptionVariables,
    OnUpdateIconReactionSubscription, OnUpdateIconReactionSubscriptionVariables,
    ReactionTargetType,
} from "@/API";
import { Comment, Message, Topic } from "@/model";
import { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import Observable from "zen-observable-ts";
import { ZenObservable } from "zen-observable-ts";
import { AWSAppSyncRealTimeProvider } from "@aws-amplify/pubsub"
import { onCreateIconReaction, onDeleteIconReaction, onUpdateIconReaction } from "@/graphql/subscriptions";
import { ServerApiAccess } from "@/server-api-access";
import { isSpecialPath } from "../direct-app-config";

type SubscriptionResult<T> = Observable<{
    provider: AWSAppSyncRealTimeProvider,
    value: GraphQLResult<T>
}>

export type ReactionReadResult = {
    reactions: IconReaction[],
    nextToken?: string|null|undefined,
}

export default class ReactionDao {
    /** domain毎のSubscription */
    private static subscriptions: {
        [ domainId: string ]: ZenObservable.Subscription[]
    } = {}

    private static keySettings( target: Topic|Message|Comment ) {
        if( target instanceof Topic ) {
            return {
                targetType: ReactionTargetType.TOPIC,
                domainId: target.domainId,
                topicId: target.id,
                messageId: "",
                commentId: "",
            }
        } else if( target instanceof Message ) {
            return {
                targetType: ReactionTargetType.MESSAGE,
                domainId: target.domainId,
                topicId: target.topicId,
                messageId: target.id,
                commentId: "",
            }
        } else {
            return {
                targetType: ReactionTargetType.COMMENT,
                domainId: target.domainId,
                topicId: target.topicId,
                messageId: target.messageId,
                commentId: target.id,
            }
        }
    }

    /**
     * 作成/更新
     * @param target 
     * @param type 
     * @param value 
     * @returns 
     */
    public static async upsert(target: Topic|Message|Comment, type: IconReactionType, value: boolean): Promise<IconReaction|undefined> {
        const keys = this.keySettings( target );
        const reaction: IconReaction = {
            type: type,
            ...keys,
            // 下記項目は仮置き(実際の数値はサーバー側でセット)
            userIdList: [],
            __typename: "IconReaction",
            createdAt: "",
            updatedAt: "",
        }
        try {
            const api = new ServerApiAccess();
            const result = await api.upsertReaction(reaction, value);
            return result;
        } catch( error ) {
            console.error("IconReaction Upsert Error :%O", error );
            return undefined;
        }
    }

    /**
     * 話題のリアクション一覧を取得
     * @param domainId 
     * @returns 
     */
    public static async readTopicReactions( domainId: string ): Promise<IconReaction[]|undefined> {
        try {
            const api = new ServerApiAccess();
            const results = await api.listReactions(domainId);
            if( results ) {
                return results.topicReactions;
            } else {
                console.error("Read Topic Reactions Error :%O", results );
                return undefined;
            }
        } catch( error ) {
            console.error("Read Topic Reactions Error :%O", error );
            return undefined;
        }
    }

    /**
     * 投稿とコメントのリアクション一覧を取得
     * @param domainId 
     * @param topicId 
     * @returns 
     */
    public static async readMessageAndCommentReactions(domainId: string, topicId: string): Promise<{ messageReactions: IconReaction[], commentReactions: IconReaction[] }|undefined> {
        try {
            const api = new ServerApiAccess();
            const results = await api.listReactions(domainId, topicId);
            if( results ) {
                return {
                    messageReactions: results.messageReactions,
                    commentReactions: results.commentReactions
                };
            } else {
                console.error("Read Message And Comment Reactions Error :%O", results );
                return undefined;
            }
        } catch( error ) {
            console.error("Read Message And Comment Reactions Error :%O", error );
            return undefined;
        }
    }

    private static prepareSubscription( domainId: string ) {
        const tmp = this.subscriptions[ domainId ];
        if( tmp == undefined ) this.subscriptions[ domainId ] = [];
    }

    public static async registerSubscriptions( domainId: string, callback: SubscriptionCallback ): Promise<void> {
        this.prepareSubscription( domainId );

        const subscriptions = this.subscriptions[ domainId ];
        if( 0 < subscriptions.length ) return;  // 登録済み

        if( isSpecialPath( domainId ) ) return;

        console.log("☆REGISTER REACTION SUB:", domainId )

        // create
        {
            const input: OnCreateIconReactionSubscriptionVariables = {
                domainId: domainId,
            }
            const graphql = await API.graphql(
                graphqlOperation( onCreateIconReaction, input )
            ) as SubscriptionResult<OnCreateIconReactionSubscription>;

            const subscription = graphql.subscribe( {
                next:( { value } ) => {
                    const data = value.data;
                    if( data?.onCreateIconReaction ) {
                        const reaction = data.onCreateIconReaction;
                        callback.onCreate( reaction );
                    }
                },
                error: error => {
                    console.error( "IconReacton onCreate Subscription Error:%O", error );
                },
                complete: () => {
                    // unsubscribeした時
                }
            } )
            subscriptions.push( subscription );
        }

        // update
        {
            const input: OnUpdateIconReactionSubscriptionVariables = {
                domainId: domainId,
            }
            const graphql = await API.graphql(
                graphqlOperation( onUpdateIconReaction, input )
            ) as SubscriptionResult<OnUpdateIconReactionSubscription>;
            const subscription = graphql.subscribe( {
                next:( { value } ) => {
                    const data = value.data;
                    if( data?.onUpdateIconReaction ) {
                        const reaction = data.onUpdateIconReaction;
                        callback.onUpdate( reaction );
                    }
                },
                error: error => {
                    console.error( "IconReacton onUpate Subscription Error:%O", error );
                },
                complete: () => {
                    // unsubscribeした時
                }
            })
            subscriptions.push( subscription );
        }

        // delete
        {
            const input: OnDeleteIconReactionSubscriptionVariables = {
                domainId: domainId,
            }
            const graphql = await API.graphql(
                graphqlOperation( onDeleteIconReaction, input )
            ) as SubscriptionResult<OnDeleteIconReactionSubscription>;
            const subscription = graphql.subscribe( {
                next:( { value } ) => {
                    const data = value.data;
                    if( data?.onDeleteIconReaction ) {
                        const reaction = data.onDeleteIconReaction;
                        callback.onUpdate( reaction );
                    }
                },
                error: error => {
                    console.error( "IconReacton onDelete Subscription Error:%O", error );
                },
                complete: () => {
                    // unsubscribeした時
                }
            })
            subscriptions.push( subscription );
        }

    }

    /** サブスクリプションを閉じる */
    public static closeSubscription( domainId: string ) {
        const tmp = this.subscriptions[ domainId ] || [];
        tmp.forEach( tmp => tmp.unsubscribe() );
        this.subscriptions[ domainId ] = [];
    }
}

export type SubscriptionCallback = {
    onCreate: ( reaction: IconReaction ) => void,
    onUpdate: ( reaction: IconReaction ) => void,
    onDelete: ( reaction: IconReaction ) => void,
}
