var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-row", { staticClass: "message-page-header" }, [
    _c(
      "div",
      { staticClass: "back-to-messagelist px-4", on: { click: _vm.back } },
      [_c("b-icon-chevron-left"), _vm._v("話題一覧に戻る")],
      1
    ),
    _c("div", { staticClass: "my-auto" }, [
      _vm._v(_vm._s("話題: " + _vm.titleLabel))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }