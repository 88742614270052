var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0", attrs: { fluid: "" } },
    [
      _c("navigation-bar", {
        staticClass: "error-nav",
        attrs: { back: true, appClose: _vm.isMobile }
      }),
      _c("div", { staticClass: "login-error-oidc-body" }, [
        _c(
          "div",
          { staticClass: "logo-box" },
          [_c("icon-navigation-brand", { staticClass: "logo-box-body" })],
          1
        ),
        _c("div", { staticClass: "text-left oidc-error-message" }, [
          _c("p", [_vm._v(_vm._s(_vm.appName) + "の利用が制限されています。")]),
          _c("p", [
            _vm._v("所属するアカウント管理グループの管理者にご連絡ください。")
          ])
        ]),
        _c("div", { staticClass: "text-left error-info" }, [
          _c("div", [_vm._v("Error : " + _vm._s(_vm.loginRestrictMessage))]),
          _c("div", [_vm._v("Description : " + _vm._s(_vm.loginRestrictDesc))])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }