






















import { Component, Prop, Vue } from "vue-property-decorator";
import { createDummyDirectUser } from "../direct-utility";
import { User } from "../model";
import type { UpdateCommentPayload } from "../store"
import Confirm from "./button/Confirm.vue";
import Cancel from "./button/Cancel.vue";

@Component({ 
    components: {
        Confirm, Cancel
    }
})
export default class DeleteModal extends Vue {
    name: string = "delete-modal";

    @Prop({ default: '', required: true })
    readonly id!: string;

    /** Topicを削除する場合に指定 */
    @Prop({ default: undefined })
    readonly topicId?: string;

    @Prop({ default: '' })
    readonly messageId!: string;

    @Prop({ default: '' })
    readonly commentId!: string;

    @Prop({ default: () => createDummyDirectUser() })
    readonly viewer!: User;

    @Prop({ default: "message" })
    readonly type!: string;

    get content(): string {
        switch(this.type) {
            case "topic":
                return "話題を削除します。<br>削除すると投稿やコメントも削除されます。<br>よろしいですか？"
            case "message":
                return "投稿を削除します。<br>削除すると投稿内のコメントも削除されます。<br>よろしいですか?"
            case "comment":
                return "コメントを削除します。<br>よろしいですか?";
            default:
                return "";
        }
    }

    /* 削除処理 */
    onClick(closeEvent: ()=>void ): void {
        const param: { [key: string]: any } = {
            deleted: true,
            deletedUser: this.viewer.directId
        }

        let obj: any, event: string;
        switch(this.type) {
            case "topic":
                obj = { topicId: this.topicId, param: param };
                event = "on-topic-delete";
                break;
            case "message":
                obj = { messageId: this.messageId, param: param };
                event = "on-message-edit";
                break;
            case "comment":
                obj = { messageId: this.messageId, commentId: this.commentId, param: param } as UpdateCommentPayload
                event = "on-comment-edit";
                break;
            default:
                obj = {};
                event = "";
                break;
        }
        if( this.$root && this.$root.$emit && event ) {
            this.$root.$emit( event, obj );
        }
        if( this.$emit && event ) {
            this.$emit( event, obj );
        }
        closeEvent();
    }
}
