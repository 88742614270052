// import * as AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { FederatedResponse, FederatedUser } from '@aws-amplify/auth/lib-esm/types';

const COGNITO_ID_POOL_REGION = "ap-northeast-1";
const COGNITO_ID_POOL_ID = 'ap-northeast-1:e0d3e8ae-9dd3-461b-b66f-ace8b0699b90';

// CognitoIdPoolに登録したOIDC認証ドメイン
const CognitoIdPoolOidcDomain = "direct4b.com";

export type User = {
    id: string,
    exp: number,
    name: string,
    idToken: string,
}

export class AuthCogintoIdPool {

    /**
     * /userData API にアクセスした結果から
     * @param user
     */
    public constructor() {
        // AWS.config.region = COGNITO_ID_POOL_REGION
        // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        //     IdentityPoolId: COGNITO_ID_POOL_ID,
        // });
    }

    /**
     * Cognito Id Pool への認証を行う
     * @returns false: 認証失敗。再度ログインからやり直して下さい。
     */
    public async auth( user: User ): Promise<any> {
        try {
            const authUser = await Auth.currentAuthenticatedUser();
            if( authUser ) {    // 認証済みユーザーがいた場合は、まずログアウトする @TODO: グローバルのログアウト処理に流す
                console.log("★RESULT:認証済みユーザー有り")
                throw "signout";
            }
        } catch( err ) {
            // OK
        }
        const response: FederatedResponse = {
            token: user.idToken,    // OIDC id token
            expires_at: user.exp,   // OIDC id token の有効期限(ms)
        }
        const userObj: FederatedUser = {
            name: user.name,        // OIDC jtwClaims.name
        }

        // federatedサインインする。
        // Auth currentAuthentiateUser はこれで有効になるが、CurrentUser は undefined
        console.log( "try cognito id pool signin" );
        const credentials = await Auth.federatedSignIn(
            CognitoIdPoolOidcDomain,
            response,
            userObj,
        );
        return credentials;
    }

}
