



















import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class Confirm extends Vue {
    name = "confirm";

    @Prop({ default: "確認", required: true })
    readonly label!: string;

    @Prop({ default: false }) readonly disabled!: boolean;

    @Prop({ default: "info" }) readonly variant!: string;

    onClick(): void {
        this.$emit('confirm');
    }

}
