var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      style: "height:" + (_vm.isMobile ? 256 : 225) + "px; overflow-y:scroll;",
      attrs: { "body-class": "p-0 my-2" }
    },
    _vm._l(_vm.categories, function(category, i) {
      return _c("category-list-item", {
        key: category.id,
        attrs: {
          category: category,
          selected: _vm.selectedIndex == i,
          isMobile: _vm.isMobile,
          viewer: _vm.me,
          owner: _vm.findUser(category.owner)
        },
        on: { clickCategory: _vm.selectedCategory }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }