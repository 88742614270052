<template>
<svg id="グループ_1075" data-name="グループ 1075" xmlns="http://www.w3.org/2000/svg" width="152.006" height="36.231" viewBox="0 0 152.006 36.231">
  <g id="グループ_1074" data-name="グループ 1074">
    <path id="パス_295" data-name="パス 295" d="M265.991,1211.516c10.273-2.915,17.034-10.411,17.034-20.614,0-8.953-7.15-15.617-15.969-15.617a15.8,15.8,0,0,0-15.969,15.617,15.607,15.607,0,0,0,15.118,15.617Z" transform="translate(-251.086 -1175.285)" fill="#00b7c6"/>
    <path id="パス_296" data-name="パス 296" d="M285.471,1188.913v6.708h-6.117a4.519,4.519,0,0,0-1.833.378,4.7,4.7,0,0,0-2.465,2.487,4.579,4.579,0,0,0-.375,1.824v5.349a4.556,4.556,0,0,0,.376,1.839,4.728,4.728,0,0,0,2.464,2.472,4.513,4.513,0,0,0,1.833.378h3.6a4.516,4.516,0,0,0,1.833-.378,4.928,4.928,0,0,0,1.472-.994,4.579,4.579,0,0,0,1.007-1.488,4.673,4.673,0,0,0,.361-1.829v-16.746Zm0,8.867v7.879a2.383,2.383,0,0,1-.2.974,2.7,2.7,0,0,1-.544.816,2.482,2.482,0,0,1-1.782.739h-3.6a2.361,2.361,0,0,1-.971-.2,2.6,2.6,0,0,1-1.355-1.359,2.381,2.381,0,0,1-.195-.974v-5.349a2.386,2.386,0,0,1,.195-.974,2.6,2.6,0,0,1,1.355-1.359,2.366,2.366,0,0,1,.971-.2Z" transform="translate(-265.912 -1183.849)" fill="#fff"/>
  </g>
  <path id="パス_297" data-name="パス 297" d="M433.613,1205.114l.5-1.524h-4.832l-4.34,10.645,5.076-.035,2.121-5.072h10.456l-6.443,16.76h4.795l7.908-20.774Zm22.025,8.5,3.223-8.5h-5.048l-7.908,20.774h5.048l3.214-8.144h12.9l1.562-4.126Zm-36-8.785h-5.11L406.495,1226l19.344-.109,1.521-3.98-14.023.134Zm-42.592.281H365.589l.264-1.151h-5.372l-4.1,10.283h5.372l2.2-5.3h11.6l-1.954,5.123h-8.4l-1.557,3.834h8.5l-3.1,7.985h5.127l6.55-16.942h-5.1Zm.076-.027h5.156l1.455-4.111h-5.156Zm16.043-4.181-4.809.05-1.536,4.242h4.763Zm16.671,4.2-4.917.05L403.343,1209l-17.891.044-1.473,3.978,9.153.123-4.261,12.744h5.275l4.5-12.817,8.029-.018Z" transform="translate(-316.622 -1195.851)" fill="#5c5c5c"/>
</svg>
</template>
<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'iconNavigationBrand'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}
</style>
