















































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ProfileIcon from "../ProfileIcon.vue";
import NavBarSolutionButton from "@/components/solutions/NavBarSolutionButton.vue"
import type { IDirectUserInfo } from "../../direct-utility";
import { createDummyDirectUser } from '../../direct-utility';
import { allowFeature, FeatureName } from '../../direct-app-config';

export type NavigationItem = { to: string, label: string, click?: string };
import { User } from '@/model';
import { AppLinkItem } from '../solutions/types';
import { ClientType } from '@/store/domain-store';


/**
 * ナビゲーションバー（右側）
 *
 * ok         OKボタン
 * complete   完了ボタン
 * items      指定メニュー
 * preset     プリセットメニュー（ユーザーアイコンから始まるメニュー）
 * close      ✕ボタン
 *
 */
@Component({
    components: { ProfileIcon, NavBarSolutionButton }
})
export default class NavBarRight extends Vue {
    name = "nav-bar-right";
    clientTypeCheckValue: ClientType = "unknown";

    @Prop({ default: () => User.createNotFoundUser() }) readonly user!: User;

    /** ナビゲーションの右側メニューアイテム */
    @Prop({ default: () => false }) readonly ok!: boolean;
    @Prop({ default: () => false }) readonly complete!: boolean;
    @Prop({ default: () => [] }) readonly items!: NavigationItem[];
    @Prop({ default: () => false }) readonly preset!: boolean;
    @Prop({ default: () => false }) readonly close!: boolean;
    @Prop({ default: () => false }) readonly appClose!: boolean;
    @Prop({ default: () => "" }) readonly domainId!: string;

    // ソリューションアイコン一覧
    @Prop({ default: () => [] }) readonly solutionLinks!: AppLinkItem[];

    get clientType(): ClientType {
        return this.$store.getters["domains/getClientType"];
    }

    // NODE_ENV
    get env(): string {
        console.log(process.env.NODE_ENV);
        return process.env.NODE_ENV || "dev";
    }

    get empty(): boolean    {
        return this.ok == false
                && this.complete == false
                && this.items.length == 0
                && this.preset == false
                && this.close == false
                && this.appClose == false
    }

    get userId(): string    { return this.user.directId; }
    get userEmail(): string { return this.user.email; }
    get userName(): string  { return this.user.name; }
    get userImage(): string { return this.user.getProfileIconUrl(); }

    get isEditDomainSetting(): boolean {
        const me: User =  this.$store.getters["users/me"]
        return me.isOwnerRole(this.domainId)
                || me.isAdminRole(this.domainId)
    }
    // デモ関連ハンドラ
    prepareDemoCategory()   { if( this.$root ) this.$root.$emit("prepare-demo-category"); }
    prepareDemoTopic()      { if( this.$root ) this.$root.$emit("prepare-demo-topic"); }
    prepareDemoMessage()    { if( this.$root ) this.$root.$emit("prepare-demo-message"); }
    prepareDemoComment()    { if( this.$root ) this.$root.$emit("prepare-demo-comment"); }

    prepareDemoImages() {
        if( this.$root ) this.$root.$emit("prepare-demo-images");
    }
    clearDemoImages() {
        if( this.$root ) this.$root.$emit("clear-demo-images");
    }

    // Feature
    hasFeature( keyword: FeatureName ): boolean { return allowFeature( keyword, this.$store ); }

    // 組織設定ダイアログ表示
    showDomainSetting() {
        this.$root.$emit("show-domain-setting")
    }

    // 同期する(PC)
    syncLoad() {
        this.$root.$emit('sync-data-alert');
    }

    logout() {
        this.$router.push("/logout");
    }

    // sentryテスト用のAPI呼び出し
    async onClickDebugSentry() {
        const { ServerApiAccess } = await import( '@/server-api-access' );
        ServerApiAccess.debugSentry();
    }

    // debug用 クライアントタイプ変更(query書き換え)
    debugChangeClientType() {
        let device: string = '';
        let invoker: string = '';
        switch( this.clientTypeCheckValue ) {
            case "web": {
                device = '';
                invoker = 'browser-direct';
                break;
            }
            case "desktop": {
                device = '';
                invoker = 'desktop-direct';
                break;
            }
            case "ios": {
                device = 'iPhone';
                invoker = '';
                break;
            }
            case "android": {
                device = 'Android';
                invoker = '';
                break;
            }
            default: {
                device = '';
                invoker = '';
                break;
            }
        }
        this.$store.dispatch("setDevice", device);
        this.$store.dispatch("setInvokerApp", invoker);
    }
}
