


































import { Component, Prop, Vue } from "vue-property-decorator";
import { User } from "../model";
import ProfileIcon from "./ProfileIcon.vue";

@Component({
    components: {
        ProfileIcon
    }
})
export default class FavoriteModal extends Vue {
    name: string = "user-list";

    @Prop({ default: ()=>[] }) readonly userList!: User[];

}
