import { API, graphqlOperation } from "aws-amplify";
import { createDomainSettings, deleteDomainSettings, updateDomainSettings } from "@/graphql/mutations";
import { Domain } from "@/model"
import { ServerApiAccess } from "@/server-api-access"
import {
    CreateDomainSettingsMutation, ListDomainSettingsQueryVariables, ListDomainSettingsQuery, GetDomainSettingsQueryVariables, GetDomainSettingsQuery,
    DomainSettings as DbDomainSettings,
    CreateDomainSettingsMutationVariables,
    CreateDomainSettingsInput,
    AttachmentPolicy,
    AttachmentFileType,
    AttachmentFileTypeInput,
    AuthorityGroupPolicy,
    DownloadPolicy,
    UpdateDomainSettingsMutationVariables,
    UpdateDomainSettingsMutation,
    DeleteDomainUserMutationVariables,
    DeleteDomainSettingsMutationVariables,
    DeleteDomainSettingsMutation,
} from "@/API"
import { getDomainSettings, listDomainSettings } from "@/graphql/queries";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { DownloadDeviceTypes } from "@/suppport-attachment-types";

export default class DomainDao {
    private constructor(){
        /* インスタンス化させない */
    }

    /**
     * DomainをDBに登録する
     * @param domain 組織データ
     * @returns 登録されたデータ
     */
    public static async create( domain: Domain ): Promise<Domain|undefined> {
        const variables: CreateDomainSettingsMutationVariables = {
            input: {
                id: `${domain.domainId}`,
                createTopicAuthorityGroupPolicy: domain.createTopicAuthorityGroupPolicy as AuthorityGroupPolicy,
                updateTopicAuthorityGroupPolicy: domain.updateTopicAuthorityGroupPolicy as AuthorityGroupPolicy,
                attachmentPolicy: domain.attachmentPolicy as AttachmentPolicy,
                attachmentFileType: domain.attachmentFileTypes as AttachmentFileTypeInput,
                downloadPolicy: domain.downloadPolicy as DownloadPolicy,
                downloadDeviceType: domain.downloadDeviceTypes as DownloadDeviceTypes,
            }
        }
        ServerApiAccess.logging( "domain-settings.create", variables.input );   // logging
        const op = graphqlOperation( createDomainSettings, variables );
        try {
            const result = await ( API.graphql( op ) as Promise< { data: CreateDomainSettingsMutation } > );
            if( result.data.createDomainSettings ) {
                return Domain.createFrom( result.data.createDomainSettings );
            } else {
                return undefined;
            }
        } catch( error ) {
            console.error( "DomainSettings Add Error:%O", error );
            throw error;
        }
    }

    /**
     * 組織設定を取得する(単一)
     * @param domainId
     * @returns
     */
    public static async read( domainId: string ): Promise<Domain> {
        ServerApiAccess.logging( "domain-settings.read", { domainId: domainId } );
        const input: GetDomainSettingsQueryVariables = {
            id: domainId,
        }
        const op = graphqlOperation( getDomainSettings, input );
        const result = await ( API.graphql( op ) as Promise< GraphQLResult<GetDomainSettingsQuery> > );
        if( result.data?.getDomainSettings ) {
            const domain = result.data.getDomainSettings as DbDomainSettings;
            return Domain.createFrom( domain );
        } else {
            return Domain.create({ domainId: domainId });
        }
    }

    public static async upsert( domain: Domain ): Promise<Domain|undefined> {
        try {
            return await this.update( domain );
        } catch( error ) {
            return await this.create( domain );
        }
    }

    public static async update( domain: Domain ): Promise<Domain|undefined> {
        const variables: UpdateDomainSettingsMutationVariables = {
            input: {
                id: domain.domainId,
                createTopicAuthorityGroupPolicy: domain.createTopicAuthorityGroupPolicy as AuthorityGroupPolicy,
                updateTopicAuthorityGroupPolicy: domain.updateTopicAuthorityGroupPolicy as AuthorityGroupPolicy,
                attachmentPolicy: domain.attachmentPolicy as AttachmentPolicy,
                attachmentFileType: {
                    image: domain.attachmentFileTypes.image,
                    movie: domain.attachmentFileTypes.movie,
                    pdf: domain.attachmentFileTypes.pdf,
                    office: domain.attachmentFileTypes.office,
                },
                downloadPolicy: domain.downloadPolicy as DownloadPolicy,
                downloadDeviceType: {
                    ios: domain.downloadDeviceTypes.ios,
                    web: domain.downloadDeviceTypes.web,
                    android: domain.downloadDeviceTypes.android,
                    desktop: domain.downloadDeviceTypes.desktop,
                }
            }
        }
        ServerApiAccess.logging( "domain-settings.update", variables.input );    // logging
        const op = graphqlOperation( updateDomainSettings, variables );
        try {
            const result = ( await API.graphql( op ) ) as GraphQLResult< UpdateDomainSettingsMutation >;
            if( result.data?.updateDomainSettings ) {
                return Domain.createFrom( result.data.updateDomainSettings );
            } else {
                console.error( "DomainSettings Update Error :%O", result );
                throw result.errors;
            }
        } catch( error ) {
            console.error( "DomainSettings Update Error :%O", error );
            throw error;
        }
    }

    public static async delete( domain: Domain ): Promise<Domain[]> {
        const variables: DeleteDomainSettingsMutationVariables = {
            input: {
                id: domain.domainId,
            }
        }
        ServerApiAccess.logging( "domain-settings.delete", variables.input );    // logging
        const op = graphqlOperation( deleteDomainSettings, variables );
        try {
            await ( API.graphql( op ) as Promise<{data: DeleteDomainSettingsMutation}> );
        } catch( error ) {
            console.error( "DomainSettings Delete Error:%O", error );
        }

        // 未実装
        return [];
    }
}
