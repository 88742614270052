var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", { class: _vm.classes, attrs: { fluid: "" } }, [
    _c("div", { staticClass: "modal" }, [
      _c(
        "div",
        { staticClass: "close-button", on: { click: _vm.closePhotoStream } },
        [
          _c("icon-photostream-close-button", {
            staticClass: "x",
            attrs: { width: "42", height: "42" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pt-2 photostream-image-list" },
        _vm._l(_vm.files, function(file, index) {
          return _c(
            "div",
            { key: index, staticClass: "file" },
            [
              _c("attachment-img", {
                attrs: {
                  image: file,
                  param: _vm.param,
                  photostream: true,
                  downloadable: _vm.downloadable(file),
                  isMobile: _vm.isMobile,
                  allowSaveAttachments: _vm.allowSaveAttachments,
                  clientType: _vm.clientType
                }
              })
            ],
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }