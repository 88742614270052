import { Vue } from "vue-property-decorator";

/** ロード中表示管理 */
export class LoadingManager {

    private static $root?: Vue;

    private static isInitialLoaded: boolean;

    // タスク一覧
    private static tasks: { key:string, msg: string, background?: string }[] = [];

    // setTimeout中のtimerIDとtask.keyを保持
    private static blockedInfo: { timer: number|undefined, key: string } = { timer: undefined, key: "" }; // 現在処理待ちのもの

    public static init( $root: Vue ): void {
        this.$root = $root;
        this.isInitialLoaded = false;
    }

    private static updateMessage() {
        if( !this.isBlocked && this.tasks.length == 1 ) {
            this.registerTimer(() => this.$root?.$emit("on-loading", { value: true, msg: this.tasks[0].msg, background: this.tasks[0].background } ), this.tasks[0].key );
        } else if( !this.isBlocked && this.tasks.length > 1 ) {
            this.$root?.$emit("on-loading", { value: true, msg: this.tasks[0].msg, background: this.tasks[0].background } );
        }
    }

    /** タスク開始 */
    public static start( key: string, msg: string = "", background: string = "" ): void {
        const index = this.tasks.findIndex( task => task.key == key );
        if( index < 0 ) {
            this.tasks.push( { key, msg, background } );
        } else {
            this.tasks[ index ].msg = msg;
            this.tasks[ index ].background = background;
        }
        this.updateMessage();
    }

    /** タスクから削除 */
    public static stop( key: string ): void {
        const index = this.tasks.findIndex( task => task.key == key );
        if( index >= 0 ) this.tasks.splice( index, 1 );
        if( this.blockedInfo.key == key ) this.clearTimer();
        if( this.tasks.length == 0 ) {
            this.$root?.$emit("on-loading", { value: false } );
        } else {
            this.updateMessage();
        }
    }

    public static isLoading( key: string ): boolean {
        const index = this.tasks.findIndex( task => task.key == key );
        return index > -1 ? true : false
    }

    public static initalLoad(): void {
        this.isInitialLoaded = true;

        // 初期データロード後、検索結果を初期表示する場合は検索ワードをセットする
        const route = this.$root?.$route.name;
        const search = this.$root?.$route.query.search;
        if( search && ( route == "topic-list" || route == "message-list" ) ) {
            const searchParam = { word: search as string };
            this.$root?.$emit("on-set-search-param", searchParam );
        }
    }

    public static resetInitalLoad(): void {
        this.isInitialLoaded = false;
    }

    public static isEndInitialLoad(): boolean {
        return this.isInitialLoaded;
    }

    private static registerTimer(func: () => void, key: string): void {
        if( this.blockedInfo.timer ) this.clearTimer(); 
        this.blockedInfo.timer = window.setTimeout(func, 500);
        this.blockedInfo.key = key;
    }

    public static clearTimer(): void {
        if( this.blockedInfo.timer ) {
            clearTimeout(this.blockedInfo.timer);
            this.blockedInfo.timer = undefined;
            this.blockedInfo.key = '';
        }
    }

    private static get isBlocked(): boolean {
        return this.blockedInfo.timer !== undefined;
    }
}
