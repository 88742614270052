var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "login-form w-75 mx-auto mt-5" },
      [
        _vm.useLoadingView
          ? _c("loading", { attrs: { msg: _vm.status } })
          : _c(
              "b-card",
              { staticClass: "overflow-hidden", attrs: { "no-body": "" } },
              [
                _c("b-card-header", [_c("div", [_vm._v(_vm._s(_vm.status))])]),
                _c(
                  "b-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "mt-3 mt-sm-0 d-flex align-items-center user-select-none",
                        attrs: { xs: "2", sm: "4", md: "3" }
                      },
                      [
                        _c("b-img", {
                          attrs: {
                            center: "",
                            src: "/img/app_icon_forum.svg",
                            width: "135px",
                            height: "135px"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { xs: "10", sm: "8", md: "9" } },
                      [
                        _c(
                          "b-card-body",
                          { staticClass: "pl-sm-1 py-xs-0 mt-xs-3" },
                          [
                            _vm.stage == "PRD"
                              ? _c(
                                  "b-row",
                                  { staticClass: "mx-sm-1" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "w-100 login-button",
                                        attrs: {
                                          variant: "outline-info",
                                          disabled: _vm.loginContinue
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.signIn("direct")
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(" ログイン"),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              position: "absolute",
                                              right: "4px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "(direct " +
                                                _vm._s(_vm.stage) +
                                                ")"
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.stage == "STG"
                              ? _c(
                                  "b-row",
                                  { staticClass: "mx-sm-1" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "w-100 login-button",
                                        attrs: {
                                          variant: "outline-info",
                                          disabled: _vm.loginContinue
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.signIn("direct-staging")
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(" ログイン"),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              position: "absolute",
                                              right: "4px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "(direct " +
                                                _vm._s(_vm.stage) +
                                                ")"
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.stage == "DEV"
                              ? _c(
                                  "b-row",
                                  { staticClass: "mx-sm-1" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "w-100 login-button",
                                        attrs: {
                                          variant: "outline-info",
                                          disabled: _vm.loginContinue
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.signIn("direct-dev")
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(" ログイン"),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              position: "absolute",
                                              right: "4px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "(direct " +
                                                _vm._s(_vm.stage) +
                                                ")"
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "b-row",
                              { staticClass: "mx-sm-1" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "w-100 login-button",
                                    attrs: {
                                      variant: "outline-info",
                                      disabled: _vm.loginContinue
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.signIn("ios")
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(" ログイン"),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          right: "4px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "(iOS(direct " +
                                            _vm._s(_vm.stage) +
                                            "))"
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mx-sm-1" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "w-100 login-button",
                                    attrs: {
                                      variant: "outline-info",
                                      disabled: _vm.loginContinue
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.signIn("android")
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(" ログイン"),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          right: "4px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "(Android(direct " +
                                            _vm._s(_vm.stage) +
                                            "))"
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-card-footer",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mx-5" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "w-100",
                            attrs: { variant: "outline-info" },
                            on: { click: _vm.signOut }
                          },
                          [
                            _vm.loginContinue
                              ? [_vm._v("キャンセル")]
                              : [_vm._v("ログアウト")]
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }