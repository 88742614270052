var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "px-0 pt-0 topic-list-container", attrs: { fluid: "" } },
    [
      _c("category-modal-dialog", {
        attrs: {
          id: "modal-center",
          categories: _vm.categories,
          filter_init: _vm.categoryFilter
        },
        on: { onCloseCategoryFilterDialog: _vm.onCloseCategoryFilterDialog }
      }),
      _c(
        "b-row",
        { staticClass: "topic-list-header p-2 mx-0" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-center",
                  modifiers: { "modal-center": true }
                }
              ],
              staticClass:
                "category-selection-button d-md-none d-lg-none d-xl-none topic-list-category-selector",
              attrs: { variant: "outline-info my-auto", pill: "" }
            },
            [
              _c(
                "div",
                { staticStyle: {} },
                [
                  _c("icon-checker", { staticClass: "mr-1" }),
                  _c(
                    "span",
                    {
                      staticClass: "category-filter-label",
                      staticStyle: {
                        flex: "1 1 0",
                        "text-overflow": "ellipsis"
                      }
                    },
                    [_vm._v(_vm._s(_vm.categoryFilterButtonLabel))]
                  ),
                  _c("span", [_vm._v(_vm._s(_vm.categoryFilterButtonSuffix))])
                ],
                1
              )
            ]
          ),
          _c(
            "b-container",
            {
              staticClass:
                "d-none d-md-flex d-lg-flex d-xl-flex topic-list-label"
            },
            [
              _c(
                "b-row",
                {
                  staticClass: "font-weight-bold",
                  attrs: { "align-v": "center" }
                },
                [_vm._v("話題一覧")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", {
        staticClass: "w-100",
        staticStyle: { height: "1px", "background-color": "#DDD" }
      }),
      !_vm.topicList.length
        ? _c("div", { staticClass: "no-topics" }, [
            _vm._v(_vm._s(_vm.noItemsError))
          ])
        : _vm._e(),
      _vm._l(_vm.topicPinnedSource, function(topic) {
        return _c("topic-list-item", {
          key: topic.id,
          attrs: {
            id: topic.id,
            icon: topic.icon,
            category: _vm.mergedCategory(topic.category),
            title: topic.title,
            desc: topic.desc,
            "updated-at": topic.updatedAt,
            pinned: topic.pinned,
            star: topic.star,
            messages: topic.messages,
            "domain-id": _vm.domainId,
            users: _vm.users,
            viewer: _vm.me,
            acl: topic.acl,
            notification: topic.notification,
            active: _vm.activeTopicId == topic.id,
            "in-sidemenu": _vm.inSidemenu
          }
        })
      }),
      _vm.topicList.length
        ? _c("div", {
            staticClass: "w-100 d-md-none d-lg-none d-xl-none",
            staticStyle: { height: "12px", "background-color": "#DDD" }
          })
        : _vm._e(),
      _vm._l(_vm.topicStarSource, function(topic) {
        return _c("topic-list-item", {
          key: topic.id,
          attrs: {
            id: topic.id,
            icon: topic.icon,
            category: _vm.mergedCategory(topic.category),
            title: topic.title,
            desc: topic.desc,
            "updated-at": topic.updatedAt,
            pinned: topic.pinned,
            star: topic.star,
            messages: topic.messages,
            "domain-id": _vm.domainId,
            users: _vm.users,
            viewer: _vm.me,
            acl: topic.acl,
            active: _vm.activeTopicId == topic.id,
            "in-sidemenu": _vm.inSidemenu
          }
        })
      }),
      _vm._l(_vm.topicListSource, function(topic) {
        return _c("topic-list-item", {
          key: topic.id,
          attrs: {
            id: topic.id,
            icon: topic.icon,
            category: _vm.mergedCategory(topic.category),
            title: topic.title,
            desc: topic.desc,
            "updated-at": topic.updatedAt,
            pinned: topic.pinned,
            star: topic.star,
            messages: topic.messages,
            "domain-id": _vm.domainId,
            users: _vm.users,
            viewer: _vm.me,
            acl: topic.acl,
            active: _vm.activeTopicId == topic.id,
            "in-sidemenu": _vm.inSidemenu
          }
        })
      }),
      _vm.fab ? _c("fab", { on: { "fab-clicked": _vm.fabClicked } }) : _vm._e(),
      _vm.fab
        ? _c("topic-edit", {
            attrs: {
              id: "modal-topic",
              type: "create",
              categories: _vm.categories,
              domainId: _vm.domainId,
              allow_attachment_type: _vm.allow_attachment_type,
              me: _vm.me,
              isMobile: false
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }