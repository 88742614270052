var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    { attrs: { label: _vm.label, "label-align": "left" } },
    [
      _vm.isMobile || _vm.contentType == "COMMENT"
        ? _c(
            "div",
            { staticClass: "d-flex flex-row" },
            [
              _vm.showCameraButton
                ? _c(
                    "b-button",
                    {
                      class: _vm.attachmentLinkClass + " mr-2 pl-0",
                      attrs: {
                        disabled: _vm.disabled,
                        variant: "outline",
                        size: "sm"
                      }
                    },
                    [
                      _c(
                        "label",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top",
                              value: _vm.allow_attachment
                                ? ""
                                : "この組織ではファイル添付が禁止されています",
                              expression:
                                "allow_attachment? '' : 'この組織ではファイル添付が禁止されています'",
                              modifiers: { hover: true, top: true }
                            }
                          ],
                          staticClass: "d-flex align-items-center",
                          style: _vm.attachementLabelStyle,
                          attrs: { for: _vm.cameraInputId },
                          on: { click: _vm.resetFile }
                        },
                        [
                          _c("img", {
                            staticClass: "mr-2",
                            attrs: { src: require("../assets/camera.svg") }
                          }),
                          _vm._v(" 写真を撮って添付 ")
                        ]
                      ),
                      _c("b-form-file", {
                        ref: _vm.cameraInputId,
                        staticStyle: { display: "none" },
                        attrs: {
                          id: _vm.cameraInputId,
                          capture: "",
                          accept: _vm.captureAccept(),
                          disabled: _vm.disabled
                        },
                        on: { change: _vm.addFile },
                        model: {
                          value: _vm.target,
                          callback: function($$v) {
                            _vm.target = $$v
                          },
                          expression: "target"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-button",
                {
                  class: _vm.attachmentLinkClass + " mr-2 pl-0",
                  attrs: {
                    disabled: _vm.disabled,
                    variant: "outline",
                    size: "sm"
                  }
                },
                [
                  _c(
                    "label",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top",
                          value: _vm.allow_attachment
                            ? ""
                            : "この組織ではファイル添付が禁止されています",
                          expression:
                            "allow_attachment? '' : 'この組織ではファイル添付が禁止されています'",
                          modifiers: { hover: true, top: true }
                        }
                      ],
                      staticClass: "d-flex align-items-center",
                      attrs: { for: _vm.fileInputId },
                      on: { click: _vm.resetFile }
                    },
                    [
                      _c("b-icon-paperclip", {
                        staticClass: "mr-1",
                        staticStyle: { color: "black" },
                        attrs: { "font-scale": "1.5" }
                      }),
                      _vm._v(_vm._s(_vm.fileFormLabel) + " ")
                    ],
                    1
                  ),
                  _c("b-form-file", {
                    ref: _vm.fileInputId,
                    staticStyle: { display: "none" },
                    attrs: {
                      id: _vm.fileInputId,
                      multiple: _vm.multiple ? true : undefined,
                      accept:
                        _vm.contentType === "TOPIC"
                          ? _vm.accept(["image/*"])
                          : _vm.accept(),
                      disabled: _vm.disabled
                    },
                    on: { change: _vm.addFile },
                    model: {
                      value: _vm.target,
                      callback: function($$v) {
                        _vm.target = $$v
                      },
                      expression: "target"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c("div", { staticClass: "d-flex flex-column" }, [
            _c(
              "div",
              {
                class: "form-frame " + (_vm.disabled ? "not-allowed" : ""),
                attrs: { for: "fileInput" }
              },
              [
                _c(
                  "label",
                  {
                    class:
                      "form-file-label " + (_vm.disabled ? "not-allowed" : ""),
                    attrs: { for: "fileInput" },
                    on: { click: _vm.resetFile }
                  },
                  [_vm._v("ファイルをドロップまたは")]
                ),
                _c(
                  "b-button",
                  {
                    class:
                      "pc-file-form-button " +
                      (_vm.disabled ? "not-allowed" : ""),
                    attrs: { disabled: _vm.disabled, size: "sm" }
                  },
                  [
                    _c(
                      "label",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top",
                            value: _vm.allow_attachment
                              ? ""
                              : "この組織ではファイル添付が禁止されています",
                            expression:
                              "allow_attachment? '' : 'この組織ではファイル添付が禁止されています'",
                            modifiers: { hover: true, top: true }
                          }
                        ],
                        staticClass: "d-flex align-items-center mb-0 p-1",
                        style: _vm.attachementLabelStyle,
                        attrs: { for: "fileInput" },
                        on: { click: _vm.resetFile }
                      },
                      [_vm._v(" 選択 ")]
                    )
                  ]
                ),
                _c("b-form-file", {
                  ref: "fileInput",
                  staticStyle: { display: "none" },
                  attrs: {
                    id: "fileInput",
                    multiple: _vm.multiple ? true : undefined,
                    accept:
                      _vm.contentType === "TOPIC"
                        ? _vm.accept(["image/*"])
                        : _vm.accept(),
                    disabled: _vm.disabled
                  },
                  on: { change: _vm.addFile },
                  model: {
                    value: _vm.target,
                    callback: function($$v) {
                      _vm.target = $$v
                    },
                    expression: "target"
                  }
                })
              ],
              1
            )
          ]),
      _vm.fileNumberValidation
        ? _c(
            "div",
            { class: "number-limit-label " + _vm.fileNumberValidation.class },
            [_vm._v(" " + _vm._s(_vm.fileNumberValidation.label) + " ")]
          )
        : _vm._e(),
      _vm.files.length
        ? _c("file-list-viewer", {
            staticClass: "my-2 file-list-viewer-style",
            attrs: {
              files: _vm.files,
              pdfThumbStore: _vm.pdfThumbStore,
              param: _vm.fileListViewerParams
            },
            on: { deleteFile: _vm.deleteFile }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }