<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="1 0.616 17.29 20">
        <path id="_266_zi" d="M 10.37944221496582 1.959658145904541 L 6.092140197753906 6.246961116790771 C 5.805803775787354 6.533297538757324 5.643289089202881 6.927977561950684 5.643289089202881 7.338133811950684 L 5.643289089202881 15.06921005249023 C 5.643289089202881 15.9204797744751 6.339782238006592 16.61697387695312 7.191051959991455 16.61697387695312 L 14.15598487854004 16.61697387695312 C 14.77508926391602 16.61697387695312 15.33228588104248 16.24551010131836 15.57992649078369 15.68057823181152 L 18.10277938842773 9.791338920593262 C 18.75284004211426 8.259053230285645 17.63071250915527 6.556514263153076 15.96686744689941 6.556514263153076 L 11.59443759918213 6.556514263153076 L 12.32962512969971 3.012136936187744 C 12.40701389312744 2.625196218490601 12.29093074798584 2.23051643371582 12.01233386993408 1.951919436454773 C 11.55574417114258 1.503067970275879 10.828293800354 1.503067970275879 10.37944221496582 1.959658145904541 Z M 2.547763109207153 16.61697387695312 C 3.399032592773438 16.61697387695312 4.095525741577148 15.9204797744751 4.095525741577148 15.06921005249023 L 4.095525741577148 8.878158569335938 C 4.095525741577148 8.026888847351074 3.399032592773438 7.330394744873047 2.547763109207153 7.330394744873047 C 1.69649350643158 7.330394744873047 1 8.026888847351074 1 8.878158569335938 L 1 15.06921005249023 C 1 15.9204797744751 1.69649338722229 16.61697387695312 2.547763109207153 16.61697387695312 Z"></path>
        <title :id="iconName">{{ iconName }} icon</title>
       
    </svg>
</template>

<script>
export default {
    props: {
        iconName: {
            type: String,
            default: 'iconLike'
        },
        width: {
            type: [Number, String],
            default: 18.309
        },
        height: {
            type: [Number, String],
            default: 16
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>
<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -5px; 
}

#_261_zi {
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
</style>