var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-overlay", {
    attrs: { show: _vm.drag, "no-wrap": "", "no-center": "" },
    scopedSlots: _vm._u([
      {
        key: "overlay",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass: "overlay-box",
                on: {
                  drop: function($event) {
                    $event.preventDefault()
                    return _vm.onDrop.apply(null, arguments)
                  }
                }
              },
              [
                _c("div", { staticClass: "overlay-border" }, [
                  _c("div", { staticClass: "overlay-label" }, [
                    _vm._v("ファイルをドロップ")
                  ])
                ])
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }