var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        centered: "",
        "hide-footer": _vm.hideFooter,
        "content-class": "category-content",
        "header-class": "category-header",
        "body-class": "category-body",
        title: _vm.title,
        "ok-disabled": !_vm.validate,
        "ok-variant": _vm.validate ? "primary" : "secondary"
      },
      on: { ok: _vm.onOK, hide: _vm.onHide }
    },
    [
      _c(
        "ul",
        { staticClass: "category-selector" },
        [
          _vm.withAllCategory
            ? _c(
                "li",
                {
                  staticClass: "category-all",
                  class: _vm.selected("all"),
                  on: {
                    click: function($event) {
                      return _vm.onClick("all")
                    }
                  }
                },
                [_vm._v(" すべてのカテゴリー ")]
              )
            : _vm._e(),
          _vm._l(_vm.categories, function(category) {
            return _c(
              "li",
              {
                key: category.id,
                class: _vm.selected(category),
                on: {
                  click: function($event) {
                    return _vm.onClick(category)
                  }
                }
              },
              [_vm._v(" " + _vm._s(category.title) + " ")]
            )
          }),
          _vm.withCreate
            ? _c(
                "li",
                {
                  class: _vm.selected("new"),
                  on: {
                    click: function($event) {
                      return _vm.onClick("new")
                    }
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: "新規作成するカテゴリー名",
                      state: _vm.newCategoryInputState,
                      trim: ""
                    },
                    model: {
                      value: _vm.newCategory.title,
                      callback: function($$v) {
                        _vm.$set(_vm.newCategory, "title", $$v)
                      },
                      expression: "newCategory.title"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }