import type { AttachmentFileTypes, DownloadDeviceTypes } from "./suppport-attachment-types";

export const RestrictType = {
    omit: 0,
    permit: 1,
    direct: 2,
} as const;

export type RestrictType = typeof RestrictType[keyof typeof RestrictType];
export type CustomFileType = keyof AttachmentFileTypes
export type CustomDeviceType = keyof DownloadDeviceTypes;
