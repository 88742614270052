// import * as AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { CognitoUser } from "amazon-cognito-identity-js";
import { config } from "../aws-config";

const COGNITO_ID_POOL_REGION = "ap-northeast-1";


type User = {
    id: string,
    idToken: string,
}

export class AuthCognitoUserPool {
    private provider: string;
    private get cognitoIdPoolId() {
      return config().Auth.identityPoolId;
    }

    public constructor( provider: string ) {
        this.provider = provider;
        // AWS.config.region = COGNITO_ID_POOL_REGION
        // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        //     IdentityPoolId: this.cognitoIdPoolId,
        // });
    }

    public async auth( user: User ): Promise<CognitoUser|undefined> {
        try {
            const authUser = await Auth.currentAuthenticatedUser();
            if( authUser ) {    // 認証済みユーザーがいた場合は、まずログアウトする @TODO: グローバルのログアウト処理に流す
                console.log("★RESULT:認証済みユーザー有り")
                throw "signout";
            }
        } catch( err ) {
            // OK
        }

        console.log( `Auth.signIn(${this.provider}_${user.id})`);
        const obj = await Auth.signIn(
            `${this.provider}_${user.id}`, undefined
        );
        if( obj.challengeName !== 'CUSTOM_CHALLENGE' ) {
            console.log("★カスタムチャレンジではない。Auth.authenticationFlowTypeをチェックして下さい")
            // Auth.signIn で CUSTOM_CHALLENGE ではないということは・・・
            // コンフィグの Auth.authenticationFlowType が "CUSTOM_AUTH" になっていない
            // or Cognito側 の 認証チャレンジ定義 Lambda Trigger の設定不備
            // or ユーザーが UserPool に実際に存在しないので作成が必要
            return undefined;
        }

        try {
            const cognitoUser = await Auth.sendCustomChallengeAnswer( obj, user.idToken )
            console.log("★cognitoUser:%O", cognitoUser)
            return cognitoUser as CognitoUser;
        } catch( err ) {
            // idTokenが有効期限切れの場合がある
            // この場合、ログアウトからの再認証処理が必要になる
            console.log( err );
            throw err;
        }
    }


}
