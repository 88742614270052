
















































































































import { Component, Vue } from 'vue-property-decorator';
import { Action } from "vuex-class";
import { Route } from "vue-router";

import AppAuth from "./aws-config";
// import * as AmplifyModules from "aws-amplify";

// @ts-ignore
// import { AmplifyPlugin } from "aws-amplify-vue";
// Vue.use( AmplifyPlugin, AmplifyModules )

import { Category, Topic, Message, Comment, User, Domain } from "./model";

import Login from "./views/Login.vue";
import Logout from "./views/Logout.vue";
import DomainSetting from "./views/DomainSetting.vue";
import LoginErrorOidc from "./views/LoginErrorOidc.vue";
import MessageList from './components/MessageList.vue';
import MessageListItem from './components/MessageListItem.vue';
import CommentList from './components/CommentList.vue';
import CommentListItem from './components/CommentListItem.vue';
import PhotoStream, { PhotoStreamArgs } from './components/PhotoStream.vue';
// import { prepareDemoHandler } from './store/demo-data';
import { ISearchParam } from './store/index';
import type { ClientType, IDomainSetting, SetPayload } from "./store/domain-store";
import { AuthorityGroupPolicy, CreateTopicAuthorityGroupPolicyDefault, UpdateTopicAuthorityGroupPolicyDefault } from "@/support-acl-types";
import type { UpdateCommentPayload } from "./store"
import { ActionPayload } from 'vuex';
import Loading from "./components/loading/Loading.vue"
import { LoadingManager } from "./components/loading/loading-manager";
import { EventManager } from "./events/event-manager";
import AlertModal from './components/AlertModal.vue';
import HistoryUtility from "./history";
import NotAllowedModal from "./components/NotAllowedModal.vue";
import FavoriteModal from "./components/FavoriteModal.vue";
import AclEditModal from "./components/acl/AclEditModal.vue";
import { DirectDomainMembersType } from "./direct-restapi-types";
import Acl from "./model/acl"
import { isSpecialPath } from './direct-app-config';
import ErrorModal from './components/ErrorModal.vue';
import ActionSheet from './components/common/ActionSheet.vue';
import { AttachmentFileType, AttachmentPolicy, DownloadPolicy } from './API';
import { CustomDeviceType, CustomFileType, RestrictType } from './domain-setting-types';
import { Auth } from 'aws-amplify';
import { DownloadDeviceTypes } from './suppport-attachment-types';
import { ServerApiAccess } from './server-api-access';

const APP_NAME = "掲示板";

declare global {
    // iOS向け関数 https://lisb.myjetbrains.com/youtrack/issue/dxflow-13
    export function DAJSHGetDomainId(): void;
    export interface Window {
        webkit: {
            messageHandlers: {
                DANHAuthenticateWebApp: {
                    postMessage: ( authUrl: string ) => void;
                },
                DANHCloseWebView: {
                    postMessage: ( dummy: string ) => void;
                },
            },
        },
        directJsApi: {
            signin: ( authUrl: string ) => void;
            setCurrentDomainId: ( domainId: string ) => void;
            closeWindow: () => void;
            hideToolbar: () => void;
        }
    }
}

type DomainSettingArgs = {
    categories: Category[],
    domainId: string,
    me: User,
    initalCreateTopicAuthorityGroupPolicy: AuthorityGroupPolicy,
    initalUpdateTopicAuthorityGroupPolicy: AuthorityGroupPolicy,
    initalAttachRestrictType: RestrictType,
    initalAttachCustomFileType: CustomFileType[],
    initalDownloadRestrictType: RestrictType,
    initalDownloadCustomFileType: CustomDeviceType[],
};

@Component({
  components: {
    Login,
    Logout,
    DomainSetting,
    LoginErrorOidc,
    MessageList,
    MessageListItem,
    CommentList,
    CommentListItem,
    Loading,
    AlertModal,
    PhotoStream,
    NotAllowedModal,
    FavoriteModal,
    AclEditModal,
    ErrorModal,
    ActionSheet,
  },
})
export default class App extends Vue {
    loginErrorOidc: boolean = false;

    hasSession: boolean = false;
    topicList!: Topic[];
    categories!: Category[];
    me: User = User.createNotFoundUser();
    sideMenu: boolean = true;               //!< true: サイドメニューを表示可能

    // ロード中
    loading: boolean = false;               //!< true: ロード中表示
    loadingMessage: string = "";            //!< ロード中メッセージ
    indicatorBackground: string = "transparent"; // インジケーターの背景

    photoStreamModal: boolean = false;
    photoStreamArgs: PhotoStreamArgs = {files: [], messageId: '', commentId: '' };
    // downloadable
    get downloadable(): string[] {
        // ユーザーのroleを見て、ダウンロード可能かどうかを判断後 downloadTypeList を返す
        const domainId = this.$store.getters["domainId"];
        const me = this.$store.getters[ "users/me" ] as User | undefined;
        if( me && me.isDownloadableRole( domainId ) ) {
            return this.$store.getters["domains/getDownloadTypeList"]
        } else {
            return [];
        }
    }

    get clientType(): ClientType {
        return this.$store.getters["domains/getClientType"] as ClientType;
    }

    get allowSaveAttachments(): DownloadDeviceTypes {
        return this.$store.getters["domains/getAllowSaveAttachments"] as DownloadDeviceTypes;
    }

    // いいね一覧の引数
    favoriteModalArgs: { id: string, userList: User[], messageId: string, commentId: string } = { id: "favorite-modal", userList: [], messageId: '', commentId: '' }

    // 禁止モーダルの引数
    notAllowedModalArgs: { msg: string, show: boolean, icon: string, } = { msg: '', show: false, icon: "", };

    domainSettingArgs: DomainSettingArgs = { categories: [], me: User.createNotFoundUser(), ...Domain.create( { domainId: "" }), initalCreateTopicAuthorityGroupPolicy: CreateTopicAuthorityGroupPolicyDefault, initalUpdateTopicAuthorityGroupPolicy: UpdateTopicAuthorityGroupPolicyDefault, initalAttachRestrictType: RestrictType.direct, initalAttachCustomFileType:[], initalDownloadRestrictType:RestrictType.omit, initalDownloadCustomFileType:[] }

    /** aclモーダル用 */
    aclEditModalArgs: { id: string, acl: Acl, domainUsers: DirectDomainMembersType, targetId: string, targetType: "TOPIC"|"MESSAGE"|"COMMENT" } = { id: "acl-edit-modal", acl: Acl.createDummy(), domainUsers: { contents: [] }, targetId: '', targetType: "TOPIC" };
    /*
    departments: Department[] = [];
    acl: Acl = Acl.createDummy();
    aclTargetId: string = "";
    aclTargetType: "TOPIC"|"MESSAGE"|"COMMENT" = "TOPIC";
    */

    alertModalArgs: { msg: string, isHelp: boolean, helpContentArgs: { title: string, content: string, isError: boolean }, hideCancel: boolean, clickedOK: ()=>void, clickedCancel: ()=>void, okLabel?: string, okVariant?: string } = { msg: "", isHelp: false, helpContentArgs: { title: "", content: "", isError: true }, hideCancel: false, clickedOK: ()=>{return}, clickedCancel: ()=>{return} };
    errorModalArgs: { msg: string, afterProcess: () => void } = { msg: "", afterProcess: () => {return} };

    // lazyLoadを開始するフラグ
    lazyLoad: boolean = false;

    unsubscribeMutation?: () => void;       // Vuexの変更監視

    nextProcess?: ()=>void;

    get session(): boolean { return this.hasSession; }

    get domainUsers(): DirectDomainMembersType {
        if( !this.$store ) { return { contents: [] }; }
        return this.$store.getters["users/getDomainMembers"]( this.$store.getters["domainId"] );
    }

    get searchWord(): string {
        if( !this.$store ) { return ""; }
        return this.$store.getters["searchParam"].word;
    }

    get isMobile(): boolean {
        if( !this.$store ) { return false; }
        return this.$store.getters["isMobile"];
    }

    // Vuex Actions
    @Action( "categories/create" ) addCategory!: ( envelop: { data: Category } ) => void;
    @Action( "categories/update" ) updateCategory!: ( envelope: Partial<Category> ) => void;
    @Action( "categories/delete" ) deleteCategory!: ( envelope: { domainId: string, id: string } ) => void;
    @Action( "topics/create" ) addTopic!: ( envelope: { topic: Topic } ) => void;
    @Action( "topics/update" ) updateTopic!: ( param: Partial<Topic> ) => void;
    @Action( "topics/delete" ) deleteTopic!: ( param: Partial<Topic> ) => void;
    @Action( "setDomainId" ) setDomainId!: ( domainId: string ) => void;
    @Action( "setTopicId" ) setTopicId!: ( topicId: string ) => void;
    @Action( "fetchData" ) fetchData!: () => Promise<void>;
    @Action( "messages/create" ) addMessage!: ( envelope: { message: Message } ) => void;
    @Action( "messages/update" ) editMessage!: ( envelope: { messageId: string, param: { [key: string]: any } } ) => void;
    @Action( "comments/create" ) addComment!: ( envelope: { comment: Comment, messageeId: string } ) => void;
    @Action( "comments/update" ) editComment!: ( envelope: UpdateCommentPayload ) => void;
    @Action( "setSearchParam" ) setSearchParam!: ( param: ISearchParam ) => void;
    @Action( "getSearchParam" ) getSearchParam!: () => ISearchParam;
    @Action( "domains/setDomainSetting" ) setDomainSetting!: ( setting: SetPayload ) => void;
    @Action( "domains/get" ) getDomainSetting!: () => IDomainSetting;
    @Action( "setNavigationGuard" ) setNavigationGuard!: (navigationGuard: boolean ) => void;

    // サイドメニューの表示状態を更新
    private updateSidemenuVisibility( route: Route ) {
        if( route.name == "edit-topic" || route.name == "new-topic" || route.name == "edit-message" || route.name == "post-message" || route.name == "maintenance" ) {
            this.sideMenu = false;
        } else {
            this.sideMenu = true;
        }
    }

    // PhotoStream close
    closePhotoStream(): void {
        this.photoStreamArgs = {files: [], messageId: '', commentId: '' };
        this.photoStreamModal = false;
    }

    // on-app-close のハンドラーを登録
    registerCloseAppHandler(): void {
        this.$root.$on( 'on-app-close', () => {
            if( window.directJsApi?.closeWindow != undefined ) {
                window.directJsApi.closeWindow();
            }
            if( window.webkit?.messageHandlers?.DANHCloseWebView ) {
                window.webkit.messageHandlers.DANHCloseWebView.postMessage( "" );
            }
        } )
    }

    // セッションやデータロード、イベントハンドラ設定 などを初回に行う
    created(): void {
        if(this.$route.path == "/maintenace") { return; }

        // localStorageデータの復元
        const splits = this.$route.path.split('/'); // TODO: domainIDの取得方法を確実なものに or 使わない方向の検討
        const domainId = splits.length > 1 ? splits[1] : '';
        this.$store.dispatch("recreateData", domainId);


        // cacheにsearchParamが保存され、searchQueryがついてない場合にsearchQueryがついたURLにreplace
        if( this.$store.getters["searchParam"].word && this.$store.getters["searchParam"].word !== this.$route.query.search  ) {
            let word = undefined;
            // search query とキャッシュ済み検索文がある場合は、 search query を優先する
            word = this.$route.query.search || this.$store.getters["searchParam"].word;
            const query = {
                ...this.$route.query,
                search: word,
            }
            this.$store.dispatch( "setSearchParam", { word: word} ).then( () => {
                this.$router.replace({ path: this.$route.path, query: query })
            })
        }

        // iOS向け設定
        global.DAJSHGetDomainId = () => {
            return this.$store.getters["domainId"];
        }
        // Android向けツールバー設定
        if( window.directJsApi && typeof window.directJsApi.hideToolbar == "function" ) {
            window.directJsApi.hideToolbar();
        }

        // OIDC制限エラー時
        if(this.$route.path == "/login-error-oidc") {
            this.loginErrorOidc = true;
            HistoryUtility.init();
            this.registerCloseAppHandler();
            return;
        }

        EventManager.init(this.$root);

        // カテゴリーのハンドラ
        this.$root.$on( 'on-category-create', this.addCategory );
        this.$root.$on( 'on-category-update', this.updateCategory );
        this.$root.$on( 'on-category-delete', this.deleteCategory );

        // 話題の追加ハンドラ
        this.$root.$on( 'on-topic-create', this.addTopic );
        this.$root.$on( 'on-topic-update', this.updateTopic );
        this.$root.$on( 'on-topic-delete', this.deleteTopic );
        // 投稿の追加ハンドラ
        this.$root.$on( 'on-message-create', this.addMessage );
        // 投稿の更新ハンドラ
        this.$root.$on( 'on-message-edit', this.editMessage );

        this.$root.$on( 'on-comment-create', this.addComment ); // コメントの追加ハンドラ
        this.$root.$on( 'on-comment-edit', this.editComment );  // コメントの更新ハンドラ

        // 組織選択ハンドラ
        this.$root.$on( 'on-domain-selected', this.onDomainSelected.bind( this ) );

        // 検索パラメータ用ハンドラ
        this.$root.$on( 'on-set-search-param', async(param: ISearchParam) => {
            this.setSearchParam(param);
        });
        this.$root.$on( 'on-get-search-param', this.getSearchParam );

        // Direct組織設定用ハンドラ
        this.$root.$on( 'on-set-domain-setting', async ( payload: SetPayload ) => {
            await this.setDomainSetting( payload );

            if( this.$store.getters["domains/isEnable"]( payload.domainId ) ) {
                // 利用不可が表示されていた場合、無効化する
                this.$root.$emit("on-not-allowed-modal", { show: false });
            } else {
                // 掲示板が無効設定されている
                this.$root.$emit("on-not-allowed-modal", { show: true });
            }
        });
        this.$root.$on( 'on-get-domain-setting', this.getDomainSetting );

        // photo-streamイベント
        this.$root.$on("open-photo-stream", (param: PhotoStreamArgs) => {
            this.photoStreamArgs = param;
            this.photoStreamModal = true;
        });

        // ロード中ハンドラ
        this.$root.$on( 'on-loading', ( {value, msg, background}:{value:boolean, msg?:string, background?:string } ) => {
            this.loading = value;
            this.loadingMessage = msg || "";
            this.indicatorBackground = background || "";

            this.$store.dispatch("setLoading", value);
        });

        // アプリクローズハンドラを登録
        this.registerCloseAppHandler();

        // 再ログインハンドラ
        this.$root.$on( 'on-relogin', () => { this.$router.replace( '/logout' ) } )

        this.$root.$on( 'on-set-navigation-guard', this.setNavigationGuard );

        this.$root.$on( 'on-set-next-route-event', (next: ()=>void) => {
            this.nextProcess = next;
        })

        // 「掲示板の表示が禁止されている」モーダルの表示／非表示設定
        this.$root.$on( 'on-not-allowed-modal', async (args: {show: boolean}) => {
            const isValidDomain = !(args.show);
            const domainId = this.$store.getters["domainId"];

            /* Amplify側で組織が有効化されているかをsessionのpayloadから判定 */
            const session = await Auth.currentSession();
            const { payload } = session.getIdToken();
            const groups = payload["cognito:groups"];
            const isValidCognitoDomain = ( groups || [] ).includes( domainId );

            const show = !( isValidDomain && isValidCognitoDomain );

            const errorMessage =
                !isValidDomain ? `この組織では${APP_NAME}を利用できません。` :
                    !isValidCognitoDomain ? `再度ログインしてください。` : "";

            const data = {
                icon: show ? "/img/icon/1152921507291203198.png" : "",
                msg: errorMessage,
                show: show
            };
            this.notAllowedModalArgs = data;

            // モバイルUIの検索窓にoverlayをかける
            this.$root.$emit( 'on-overlay-with-search-window', args.show );
        })

        // いいね一覧の表示
        this.$root.$on( 'on-favorite-modal', (args: {userList: User[], messageId: string, commentId: string}) => {
            this.favoriteModalArgs.userList = args.userList;
            this.favoriteModalArgs.messageId = args.messageId;
            this.favoriteModalArgs.commentId = args.commentId;
            this.$bvModal.show("favorite-modal")
        })

        this.$root.$on('show-domain-setting', () => {
            const domainId = this.$store.getters["domainId"] as string;
            const categories = this.$store.getters["categories/get"]( domainId ) as Category[];
            this.domainSettingArgs.categories = categories;
            this.domainSettingArgs.me = this.$store.getters["users/me"] as User;
            this.domainSettingArgs.domainId = domainId as string;

            const createTopicAuthorityGroupPolicy = this.$store.getters["domains/getCreateTopicAuthorityGroupPolicy"] as AuthorityGroupPolicy;
            this.domainSettingArgs.initalCreateTopicAuthorityGroupPolicy = createTopicAuthorityGroupPolicy;

            const updateTopicAuthorityGroupPolicy = this.$store.getters["domains/getUpdateTopicAuthorityGroupPolicy"] as AuthorityGroupPolicy;
            this.domainSettingArgs.initalUpdateTopicAuthorityGroupPolicy = updateTopicAuthorityGroupPolicy;

            const attachmentPolicy = this.$store.getters["domains/getAttachmentPolicy"] as AttachmentPolicy;
            switch( attachmentPolicy ) {
                case AttachmentPolicy.DENY: this.domainSettingArgs.initalAttachRestrictType = RestrictType.omit; break;
                case AttachmentPolicy.ALLOW: this.domainSettingArgs.initalAttachRestrictType = RestrictType.permit; break;
                default: this.domainSettingArgs.initalAttachRestrictType = RestrictType.direct; break;
            }

            const attachmentFileTypes = this.$store.getters["domains/getAttachmentFileTypes"] as AttachmentFileType;
            this.domainSettingArgs.initalAttachCustomFileType = [];
            if( attachmentFileTypes.image ) this.domainSettingArgs.initalAttachCustomFileType.push( "image" );
            if( attachmentFileTypes.movie ) this.domainSettingArgs.initalAttachCustomFileType.push( "movie" );
            if( attachmentFileTypes.pdf   ) this.domainSettingArgs.initalAttachCustomFileType.push( "pdf" );
            if( attachmentFileTypes.office) this.domainSettingArgs.initalAttachCustomFileType.push( "office" );

            const downloadPolicy = this.$store.getters["domains/getDownloadPolicy"];
            switch( downloadPolicy ) {
                case DownloadPolicy.DENY: this.domainSettingArgs.initalDownloadRestrictType = RestrictType.omit; break;
                case DownloadPolicy.ALLOW: this.domainSettingArgs.initalDownloadRestrictType = RestrictType.permit; break;
                default: this.domainSettingArgs.initalDownloadRestrictType = RestrictType.direct; break;
            }

            const downloadFileTypes = this.$store.getters["domains/getDownloadFileTypes"] as DownloadDeviceTypes;
            this.domainSettingArgs.initalDownloadCustomFileType = [];
            if( downloadFileTypes.ios ) this.domainSettingArgs.initalDownloadCustomFileType.push( "ios" );
            if( downloadFileTypes.web ) this.domainSettingArgs.initalDownloadCustomFileType.push( "web" );
            if( downloadFileTypes.android ) this.domainSettingArgs.initalDownloadCustomFileType.push( "android" );
            if( downloadFileTypes.desktop ) this.domainSettingArgs.initalDownloadCustomFileType.push( "desktop" );

            this.$bvModal.show("domain-setting");
        })

        this.$root.$on('open-acl-modal', async (param: {id: string, type: "TOPIC"|"MESSAGE"|"COMMENT", acl: Acl}) => {
            const acl = param.acl;
            const targetId = param.id;
            const targetType = param.type;
            const domainUsers = this.$store ? this.$store.getters["users/getDomainMembers"]( this.$store.getters["domainId"] ) : { contents: [] };
            this.aclEditModalArgs = {
                id: "acl-edit-modal",
                acl: acl,
                targetId: targetId,
                targetType: targetType,
                domainUsers: domainUsers
            }
            this.$bvModal.show("acl-edit-modal")
        })

        this.$root.$on('show-error-modal', (args: { msg: string, afterProcess: () => void }) => {
            this.errorModalArgs = args;
            this.$bvModal.show("error-modal");
        })

        // urlコピー失敗エラー
        this.$root.$on("failed-url-copy", () => {
            this.errorModalArgs = { msg: "URLコピーに失敗しました", afterProcess: () => { return }};
            this.$bvModal.show("error-modal");
        })

        // 編集破棄アラート
        this.$root.$on("edit-discard-alert", () => {
            this.alertModalArgs = { msg: "編集内容が失われますが実行しますか", isHelp: false, helpContentArgs: { title: "", content: "", isError: true }, hideCancel: false, clickedOK: this.leaveEvent, clickedCancel: this.leaveCancel };
            this.$bvModal.show("alert-modal");
        })

        // 新規作成アラート
        this.$root.$on("free-alert", async () => {
            LoadingManager.start("free-alert", "通信中...");
            const api = new ServerApiAccess();
            const result = await api.getHelpContent();
            const helpContentArgs = result ? { title: result.title, content: result.content, isError: false } : { title: "", content: "", isError: true };
            LoadingManager.stop("free-alert");
            this.alertModalArgs = { msg: "", isHelp: true, helpContentArgs: helpContentArgs, hideCancel: true, clickedOK: ()=>{return}, clickedCancel: ()=>{return} };
            this.$bvModal.show("alert-modal");
        })

        // 同期アラート
        this.$root.$on("sync-data-alert", () => {
            this.alertModalArgs = {
                msg: "サーバーと同期しますか?\n問題が生じた場合のみご利用ください。",
                isHelp: false,
                helpContentArgs: { title: "", content: "", isError: true },
                hideCancel: false,
                clickedOK: async() => {
                    try {
                        await this.$store.dispatch("syncLoad");
                    } catch( error ) {
                        if( typeof( error ) === "number" && error === 401 ) {
                            // 認証エラー発生：再認証処理に流す
                            EventManager.relogin()
                        }
                    }
                },
                clickedCancel: ()=>{ return },
                okLabel: '同期',
                okVariant: 'danger'
            };
            this.$bvModal.show("alert-modal");
        })

        // ログアウト
        this.$root.$on("logout", () => {
            this.$router.push("/logout");
        })

        // デモデータの用意
        // if( process.env.NODE_ENV == 'development' ) {
        //     prepareDemoHandler( this, this.$store );
        // }

        // Query情報を覚えておく
        const query = this.$route.query;
        if( query ) this.$store.dispatch( "setFromQuery", query );

        // 検索ワードを覚えておく
        const search = this.$route.query.search as string || "";
        if( search ) this.$store.dispatch( "setSearchParam", { word: search} );

        // デバイス情報を覚えておく
        const device = this.$route.query.device;
        if( device ) this.$store.dispatch("setDevice", device );

        // invokerを覚えておく
        const invoker = this.$route.query.invokerapp;
        if( invoker ) this.$store.dispatch( "setInvokerApp", invoker );

        // 最終アクセスパスを覚えておく
        this.saveLastAccessPath();

        // Userチェック
        this.checkUser().then( changeUser => {
            if( changeUser ) {
                // ユーザーが変更された場合はログアウトするため、後続の処理は行わない
                // ロード中表示
                LoadingManager.init( this.$root );
                LoadingManager.start( "startApp", "ロード中..." );
            } else {
                // history
                HistoryUtility.init();

                LoadingManager.init( this.$root );

                LoadingManager.start( "startApp", "ロード中..." );
                AppAuth.hasSession().then( async ( session ) => {
                    this.hasSession = session;
                    /*
                    // cognitoのログインは現状使用する必要がなく、
                    // loadAllowFeatureは fetchDataへ移行したので
                    // コメントアウト
                    // cognitoログインが必要な時に解除
                    if( this.hasSession ) {
                        const user = await AppAuth.authenticatedUser();
                        await this.$store.dispatch("users/fetchMe", { id: user.getUsername() } )
                        this.me = this.$store.getters["users/me"];
                        await this.$store.dispatch( "loadAllowFeature" ); //  α／β機能表ロード
                    }
                    */
                    this.$store.dispatch("setSession", session );
                }).catch( () => {
                    this.hasSession = false;
                    this.$store.dispatch("setSession", false );
                }).finally( () => {
                    LoadingManager.stop( "startApp" );
                    this.$nextTick(() => {
                        this.lazyLoad = true;
                    })
                });
            }
        })
    }

    mounted(): void {
        // query.domain_idを指定された時
        if( this.$route.query.domain_id && typeof this.$route.query.domain_id == "string" ) {
            const domainId = this.$route.query.domain_id || "";
            this.$router.replace(domainId);
        }
        // マウント時に組織IDがパスに無い場合
        else if( this.$route.path == "/" ) {
            const lastAccess: string = this.$store.getters["lastAccessPath"];
            const paths = lastAccess.split("/").filter( path => !!path );
            if( paths.length > 0 ) {
                const searchParam: ISearchParam = this.$store.getters["searchParam"];
                const query = searchParam.word ? { search: searchParam.word } : {};
                this.$store.dispatch("fetchData", { domainId: paths[0], topicId: paths[1] } )
                this.$router.replace( { path: lastAccess, query: query } );
            }
        }

        // Android向けツールバー設定
        if( window.directJsApi && typeof window.directJsApi.hideToolbar == "function" ) {
            window.directJsApi.hideToolbar();
        }

        this.updateHtmlTitle();

        if( !this.unsubscribeMutation ) {
            this.unsubscribeMutation = this.$store.subscribe( ( action: ActionPayload ) => {
                // 組織選択Mutationイベント
                if( action.type == "setDirectDomainId" ) {
                    const domainId = action.payload;
                    if( domainId && window.directJsApi?.setCurrentDomainId && isSpecialPath( domainId ) == false ) {
                        window.directJsApi.setCurrentDomainId( domainId );
                    }
                }
            })
        }
    }

    beforeUpdate(): void {
        this.updatePage();
        this.updateSidemenuVisibility( this.$route );
        this.updateHtmlTitle();
    }

    beforeDestroy(): void {
        if( this.unsubscribeMutation ) this.unsubscribeMutation();
    }

    // 組織IDがパス上に無い場合の解決
    private saveLastAccessPath(): void {
        let lastAccessPath = this.$route.path;

        // domain_idクエリがURLに入っているかチェック
        // 入っている場合は {protocol}://{host}/?domain_id={組織ID}&{その他クエリ} の形式
        // このため、/{組織ID}を lastAccessPath として保存する
        const domainId = this.$route.query.domain_id;
        if( typeof domainId == "string" ) {
            lastAccessPath = `/${domainId}`
        }

        if( isSpecialPath( lastAccessPath ) == false ) {
            this.$store.dispatch("setLastAccessPath", lastAccessPath);
        }
    }

    private updateHtmlTitle() {
        // HTMLタイトル
        const domainName = this.$store.getters["domains/getDomainName"] as string;
        const title = ( domainName ? `${domainName} | ` : "" ) + "掲示板";
        document.title = title;
    }

    async signIn(): Promise<void> {
        await AppAuth.signIn('direct');
    }

    async signOut(): Promise<void> {
        await AppAuth.signOut();
    }

    /** 組織選択時の処理 */
    private onDomainSelected( domainId: string ): void {
        const path = `/${domainId}`;
        if( path == this.$route.path ) return;  // 遷移先が同じ
        this.setDomainId( domainId );
        this.setTopicId( '' ); // topicIdのリセット
        this.$router.push( path );
    }

    /**
     * ユーザーが変更されたかどうかを判定する
     * @return true: 変更された。ただし新規の場合はfalse判定になります
     * @return false: 変更されてない。新規の場合もこちら
     */
    private async checkUser(): Promise<boolean> {
        const getUserId = ( userId: unknown ) => {
            // 配列だったら先頭を使う
            if( Array.isArray( userId ) ) userId = userId[0];
            if( typeof( userId ) == "string" && 0 < userId.length ) {
                return userId;
            } else {
                return undefined;
            }
        }

        const userId = getUserId( this.$route.query.user_id );
        if( !userId ) return false;   // userId無しの場合は以前のユーザーとみなす

        const old = getUserId( this.$store.getters["userId"] );
        await this.$store.dispatch( "setUserId", userId );
        // 保存してからユーザーチェック
        // ここで保存してないと再ログイン時に以前のユーザーIDが残ったままになる
        if( old && userId && old != userId ) {
            // 別ユーザーのログインとみなす。ログアウト
            this.$router.replace( "/logout" );
            return true;
        }
        return false;
    }

    leaveEvent(): void {
        this.setNavigationGuard(false);
        if( this.nextProcess ) { this.nextProcess(); }
    }

    leaveCancel(): void {
        this.nextProcess = undefined;
    }

    // => CategoryEdit
    saveCategoryEdit(): void {
        this.$root.$emit("save-category-edit");
    }

    // ページ遷移時のモーダルイベント
    // (各種モーダルのページ遷移時のイベントはここに入れる)
    private updatePage(): void {
        this.$bvModal.hide('favorite-modal'); // ページ遷移でfavoriteModal閉じる
    }

}
