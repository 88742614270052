var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    attrs: {
      id: _vm.id,
      topicId: _vm.topicId,
      messageId: _vm.messageId,
      commentId: _vm.commentId,
      viewer: _vm.viewer,
      "hide-header": "",
      "hide-footer": "",
      centered: ""
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var close = ref.close
          return [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } }),
            _c("div", {
              staticStyle: {
                "background-color": "#F4F4F4",
                height: "2px",
                width: "100%"
              }
            }),
            _c(
              "b-row",
              {
                staticClass: "d-flex my-3",
                staticStyle: { "justify-content": "space-around" }
              },
              [
                _c("cancel", {
                  attrs: { label: "キャンセル" },
                  on: { cancel: close }
                }),
                _c("confirm", {
                  attrs: { label: "削除" },
                  on: {
                    confirm: function($event) {
                      return _vm.onClick(close)
                    }
                  }
                })
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }