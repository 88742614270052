import { v4 as uuidv4 } from "uuid";
import { Attachment, Comment } from "@/model";
import Acl from "./acl";
import { IconReaction, Message as DbMessage } from "@/API";

const DUMMY_DOMAIN_ID = "DUMMY_MESSAGE_DOMAIN_ID";
const DUMMY_TOPIC_ID = "DUMMY_MESSAGE_TOPIC_ID";

const isMessage = ( value?: Partial<Message> | DbMessage ): value is Partial<Message> => {
    if( value == undefined ) return true;   // undefinedの場合は DbMessage では無い
    return !!value && ( "__typename" in value ) == false;  // __typename は DB側オブジェクトが持つ
}
export class Message {
    public id: string;                  // ID。uuidv4()
    public domainId: string;            //!< 所属組織ID
    public topicId: string;             //!< 所属話題ID
    public title: string;               //!< 投稿タイトル
    public message: string;             //!< 投稿メッセージ
    public pinned: boolean;             //!< true: ピン留め
    public owner: string;               //!< 作成者ユーザーID (Cognito User ID)
    public updatedAt: Date;             //!< 更新日時
    public comments: Comment[];
    public photos: Attachment[];
    public deleted: boolean;
    public deletedUser: string;

    // ACL関係
    public acl: Acl;

    // DBに保存されない項目
    public star?: IconReaction;         //!< ★の設定
    public like?: IconReaction;         //!< イイね！の設定

    protected constructor( obj?: Partial<Message> | DbMessage, comments?: Comment[] ) {
        this.id         = obj?.id         || uuidv4() ;
        this.domainId   = obj?.domainId   || DUMMY_DOMAIN_ID;
        this.topicId    = obj?.topicId    || DUMMY_TOPIC_ID;
        this.title      = obj?.title      || "";
        this.message    = obj?.message    || "";
        this.pinned     = obj?.pinned     || false;
        this.owner      = obj?.owner      || "";
        this.updatedAt  = obj?.updatedAt ? new Date( obj.updatedAt ) : new Date();
        this.comments   = ( isMessage( obj ) ? obj.comments : comments ) || [];
        this.photos     = obj?.photos     || [];
        this.deleted    = obj?.deleted    || false;
        this.deletedUser= obj?.deletedUser || '';

        // ACL標準では他人Create系許可
        this.acl        = ( obj?.acl ) ? Acl.clone( obj.acl ) : Acl.create( this.id );

        this.star       = obj instanceof Message ? obj.star : undefined;
        this.like       = obj instanceof Message ? obj.like : undefined;
    }

    public static create( obj?: Partial<Message> ): Message;
    public static create( obj: DbMessage, comments: Comment[] ): Message;
    public static create( obj?: Partial<Message> | DbMessage, comments?: Comment[] ): Message {
        return new Message( obj, comments );
    }

    /** message の存在チェック */
    public static isExists( message: Message ): boolean {
        if( !message ) return false;
        if( message.deleted == undefined ) return true;   // 削除フラグがOFF -> 存在
        return message.deleted == true ? false : true;    // deletedが ON -> 削除, OFF -> 存在
    }

    /**
     * from から以下を覗いたデータをコピーする
     * id, domainId, topicId, user, updatedAt, createdAt
     * @param from
     */
    public copyFrom( from: Message ): void {
        // id
        // domainId
        // topicId
        this.title = from.title;
        this.pinned = from.pinned;
        // owner
        // updatedAt
        this.message = from.message;
        this.comments = from.comments;
        this.photos = from.photos;
        this.deleted  = from.deleted;
        this.deletedUser = from.deletedUser;

        this.acl = Acl.clone( from.acl );

        this.star = from.star;
        this.like = from.like;
    }

    public setDomainAndTopicIfNotSet( domainId: string, topicId: string ) {
        if( this.domainId && this.domainId == DUMMY_DOMAIN_ID && domainId ) this.domainId = domainId;
        if( this.topicId  && this.topicId  == DUMMY_TOPIC_ID  && topicId  ) this.topicId  = topicId;
    }
}
