import { API, graphqlOperation } from "aws-amplify";
import { listAllowFeatures, getAllowFeature } from "@/graphql/queries";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { ListAllowFeaturesQuery } from "@/API";
import { ServerApiAccess } from "@/server-api-access";

export default class FeatureDao {

    private constructor(){
        // インスタンス作成不可
    }

    /** α／β機能表の取得 */
    public static async list(): Promise<{ [keyword: string ]: string }>{
        ServerApiAccess.logging( "feature.list", {} );
        const op = graphqlOperation( listAllowFeatures );
        try {
            const result = await ( API.graphql( op ) as Promise< GraphQLResult<ListAllowFeaturesQuery> > );
            if( result.data?.listAllowFeatures?.items ) {
                const allowFeature: { [keyword: string]: string } = {};
                result.data.listAllowFeatures.items.forEach( item => {
                    if (item) {
                        allowFeature[item.keyword] = item.releasePhase;
                    }
                });
                return allowFeature;
            } else {
                return {};
            }
        } catch( error ) {
            console.error("AllowFeature List Error:%O", error );
            throw error;
        }
    }
}
