


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from "vue-router";
import './photo-stream.scss'
import AttachmentImg from './AttachmentImg.vue';
import IconPhotostreamCloseButton from "./icon/IconPhotostreamCloseButton.vue";
import { Attachment } from '../model';
import { CustomFileType } from '@/domain-setting-types';
import { getFileType } from './file-utility';
import { ClientType } from '@/store/domain-store';
import { DownloadDeviceTypes } from '@/suppport-attachment-types';

// PhotoStreamで引数で利用するためのtype
export type PhotoStreamArgs = { files: Attachment[], messageId: string, commentId: string }
Component.registerHooks([ 'beforeRouteUpdate' ])
@Component({
    components: {
        AttachmentImg, IconPhotostreamCloseButton
    }
})
export default class PhotoStream extends Vue{
    name: string = 'photo-stream';

    @Prop({ default: [] })
    readonly files!: Attachment[]

    @Prop({ default: () => ( { messageId: '', commentId: '' } ) })
    readonly param!: { messageId: string, commentId: string };

    // ダウンロード許可されたファイルの種類 (空=DL禁止)
    @Prop({ default: () => [] })
    readonly downloadableList!: CustomFileType[];

    // モバイルかの判定 (*引数経由でなくstore経由でも可能)
    @Prop({ default: false })
    readonly isMobile!: boolean;

    @Prop({ default: false, required: false })
    readonly clientType!: ClientType;

    @Prop({ default: () => ({ web: true, ios: true, android: true, desktop: true }), required: false })
    readonly allowSaveAttachments!: DownloadDeviceTypes;

    get classes(): { [selector: string]: boolean } {
        return {
            'photo-stream' : true
        }
    }

    downloadable(file: Attachment): boolean {
        const type = getFileType(file);
        let customFileType: CustomFileType = 'image';
        switch( type ) {
            case 'image':   { customFileType = 'image'; break; }
            case 'video':   { customFileType = 'movie'; break;}
            case 'pdf':     { customFileType = 'pdf'; break; }
            case 'office':  { customFileType = 'office'; break; }
            default: { break; }
        }
        return this.downloadableList.includes(customFileType);

    }

    closePhotoStream(): void {
        this.$emit('closeModal');
        this.controlUserScale(false);
    }

    // viewportの制御によるユーザの拡大縮小を制御
    controlUserScale( allow: boolean ): void {
        const viewportContent = `width=device-width,initial-scale=1.0,${!allow?'user-scalable=no':''}`
        document.querySelector("meta[name='viewport']")?.setAttribute("content", viewportContent);
    }

    beforeDestroy() {
        window.removeEventListener("popstate", this.closePhotoStream);
    }
    mounted() {
        // ブラウザバックでphotostreamが消えるように
        window.addEventListener("popstate", this.closePhotoStream);
        this.controlUserScale(true);
    }
    beforeRouteUpdate( to: Route, from: Route, next: NavigationGuardNext ): void {
        if( from.params.domainId != to.params.domainId
            || from.params.domainId != to.params.domainId
            || from.params.topicId != to.params.topicId
        ) {
            // vue-routerでの画面遷移時にphotoStreamを閉じる
            this.closePhotoStream();
        }
        next();
    }

}
