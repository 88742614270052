import { Topic } from "@/model";
import { DB_STORE } from "./indexeddb_api";

const STORE_NAME = DB_STORE.TOPIC;
const DUMMY_NAME = 'DATA2';

export class TopicIndexedDB {
    public static getStoreName(): DB_STORE {
        return STORE_NAME;
    }

    public static getDummyName(): string {
        return DUMMY_NAME;
    }

    // データを復元
    public static restore( topics: Partial<Topic>[] ): Topic[] {
        const results: Topic[] = topics.map( item => {
            const topic = Topic.create(item);
            return topic;
        })

        return results;
    }
}