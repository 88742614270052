var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: _vm.vObserveVisibilityProps,
          expression: "vObserveVisibilityProps"
        }
      ]
    },
    [
      _vm.hasIcon == false
        ? _c(
            "div",
            {
              staticClass: "profile-icon",
              style: _vm.style,
              attrs: { hover: _vm.useHoverStyle }
            },
            [_c("div", [_vm._v(_vm._s(_vm.label))])]
          )
        : _vm.bgImageLoad
        ? _c(
            "div",
            {
              staticClass: "profile-icon-image",
              style: _vm.profileIconSize,
              attrs: { hover: _vm.useHoverStyle }
            },
            [
              _c("img", {
                style: _vm.bgImageStyle,
                attrs: { src: require("../assets/blank.gif") }
              })
            ]
          )
        : _c(
            "div",
            {
              staticClass: "profile-icon",
              style: _vm.style,
              attrs: { hover: _vm.useHoverStyle }
            },
            [_c("div", [_vm._v(_vm._s(_vm.label))])]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }