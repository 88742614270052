import { IconReaction } from "@/API";
import { v4 as uuidv4 } from "uuid";
import { User } from "./user";
import { Attachment } from "./attachment";

import { Category, UNDEFINED_CATEGORY } from "./category"
import Acl from "./acl";

const UNDEFINED_DOMAINID = "dummy";
export class Topic {
    public id: string;
    public domainId: string;            //!< 所属 direct id
    public title: string;               //!< タイトル
    public desc: string;                //!< 詳細
    public icon: Attachment | null | undefined; //!< 話題アイコンURL
    public pinned: boolean;             //!< ピン留め状態かどうか
    public category: Category;          //!< カテゴリー
    public messages: number;            //!< 投稿数？コメント数？
    public owner: string;               //!< 作成者 Cognito User Id
    public updatedAt: Date;             //!< 更新日
    public createdAt: Date;             //!< 作成日

    public deleted?: boolean;           //!< true: 削除済み
    public deletedUser?: string;        //!< 削除実行した direct user id

    // ACL関係
    public acl: Acl;         //!< true: ゲスト許可
    public notification?: string;       //!< 通知ID

    // DBに保存されないもの
    public star?: IconReaction;         //!< true: Star状態
    private isTemp: boolean;            //!< true: 一時オブジェクト

    protected constructor( obj?: Partial<Topic>, isTemp = false, category: Category = UNDEFINED_CATEGORY ) {
        this.id = obj?.id || uuidv4();                      // 優先度: obj.id > uuid4()
        this.category = obj?.category || category;          // 優先度: obj.category > category > UNDEFINED_CATEGORY
        this.domainId = obj?.domainId || UNDEFINED_DOMAINID;
        this.title = obj?.title || "";
        this.desc = obj?.desc || "";
        this.icon = obj?.icon || undefined;
        this.pinned = obj?.pinned || false;
        this.messages   = obj?.messages || 0;
        this.owner      = obj?.owner    || "";
        this.updatedAt  = obj && obj.updatedAt ? new Date( obj.updatedAt ) : new Date();
        this.createdAt  = obj && obj.createdAt ? new Date( obj.createdAt ) : new Date();
        this.deleted     = obj?.deleted || undefined;
        this.deletedUser = obj?.deletedUser || undefined;

        // ACL 標準ではゲスト閲覧可、他人Create系許可
        this.acl        = ( obj?.acl ) ? Acl.clone( obj.acl ) : Acl.createByTopic( this.id, true, true );
        this.notification = obj?.notification || undefined;

        this.star = obj instanceof Topic ? obj.star : undefined;
        this.isTemp = isTemp;
    }

    public copyFrom( from: Partial<Topic> ) {
        // id はコピーしない
        if( from.category != undefined ) this.category = from.category;
        // domainId はコピーしない
        if( from.title != undefined ) this.title = from.title;
        if( from.desc != undefined ) this.desc = from.desc;
        this.icon = from.icon;
        if( from.pinned != undefined ) this.pinned = from.pinned;
        if( from.messages != undefined ) this.messages = from.messages;
        // owner はコピーしない
        if( from.updatedAt != undefined ) this.updatedAt = from.updatedAt;
        if( from.deleted != undefined ) this.deleted = from.deleted;
        if( from.deletedUser != undefined ) this.deletedUser = from.deletedUser;
        if( from.acl != undefined ) this.acl = from.acl.clone();
        this.notification = from.notification;
        // createdAt はコピーしない

        // star, isTemp はコピーしない
    }

    public hasStar( viewer:User ): boolean {
        return this.star?.userIdList.includes( viewer.directId ) || false
    }
    public starCount(): number {
        return this.star?.userIdList.length || 0;
    }

    /**
     *
     * @param obj
     * @param category  カテゴリー
     * @returns
     */
    public static create( obj?: Record<string, unknown>, category?: Category ): Topic {
        return new Topic( obj, false, category );
    }

    public static createTemp( obj?: Partial<Topic> ): Topic {
        return new Topic( obj, true );
    }

    public static isTemporary( topic: Topic ): boolean {
        return topic.isTemp;
    }

    /** topicの存在チェック */
    public static isExists( topic?: Topic ): boolean {
        if( !topic ) return false;
        if( topic.deleted == undefined ) return true;   // 削除フラグがOFF -> 存在
        return topic.deleted == true ? false : true;    // deletedが ON -> 削除, OFF -> 存在
    }

    /** DBに登録できる状態にする */
    public static normalize( topic: Topic, domainId: string ): void {
        if( !topic.domainId || topic.domainId == UNDEFINED_DOMAINID ) { // falsy
            topic.domainId = domainId;
        }
    }

    /** 話題IDを生成します */
    public static createId(): string {
        return uuidv4();
    }
}
