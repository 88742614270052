import { Topic } from "./topic"
import { Message } from "./message";
import { Category } from "./category";
import { Comment } from "./comment";
import { Domain } from "./domain";
import Acl from "./acl";
import { Solution } from "./solution";

export * from "./topic";
export * from "./category";
export * from "./message";
export * from "./comment";
export * from "./user";
export * from "./reaction";
export * from "./domain";
export * from "./user";
export * from "./attachment";
export { Acl }
export { Solution }

export type Categories = Category[];
export type Topics = Topic[];

export type Messages = {
    [ domainId: string ]: {
        [ topicId: string ]: Message[]
    }
}

export type Comments = {
    [ topicId: string ]: {
        [ messageId: string ]: Comment[],
    }
}
