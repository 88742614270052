
































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import UserList from "./UserList.vue";
import { User } from "../model";
import { ActionPayload } from 'vuex';
import { Message, Comment } from "../model";

@Component({
    components: {
        UserList
    }
})
export default class FavoriteModal extends Vue {
    name: string = "favorite-modal";
    shown: boolean = false; // モーダルの開閉状態
    userListSource: User[] = [];

    // Vuexの変更監視
    unsubscribeMutation?: () => void;

    @Prop({ default: '', required: true }) readonly id!: string;

    @Prop({ default: ()=>[] }) readonly userList!: User[];
    @Watch("userList", { immediate: true }) onUserlistChanged(): void {
        this.userListSource = this.userList;
    }

    // Storybook向け
    @Prop({ default: false }) readonly show!: boolean;

    @Prop({ default: '', required: true }) readonly messageId!: string;  //!< 投稿ID
    @Prop({ default: '' }) readonly commentId!: string; //!< コメントID

    get sortedUserList(): User[] {
        // ソート部分
        return this.userListSource;
    }

    // Lifecycle
    mounted(): void {
        // Storybook
        if( this.show ) {
            this.$bvModal.show(this.id)
        }

        if( !this.$store ) return;

        this.unsubscribeMutation = this.$store.subscribe( ( action: ActionPayload, state: unknown ) => {
            if( !this.shown ) { return; }

            // お気に入りユーザーの更新
            if( action.type == "reactions/set" ) {
                const reaction = action.payload.data.reaction;
                if( reaction.type != "LIKE" ) { return; }
                const userIdList = reaction.userIdList as string[];
                if(userIdList.length == this.userListSource.length ) return;

                const domainId = this.$store.getters["domainId"];
                const users = this.$store.getters["users/getByDomainId"]( domainId ) as User[];
                this.userListSource = users.filter( user => userIdList.find( id => id == user.directId ) );
            }

            // 投稿が削除された時、モーダルを閉じる (*エラーモーダルは今入れてない)
            if( action.type == "messages/add" ) {

                if( action.payload.messages.length > 1 ) { return; }
                const msg = action.payload.messages[0] as Message;
                if( msg.deleted == true && msg.id == this.messageId ) {
                    this.$bvModal.hide(this.id);
                }
            }

            // 投稿が削除された時、モーダルを閉じる (*エラーモーダルは今入れてない)
            if( action.type == "comments/add" ) {

                if( action.payload.comments.length > 1 ) { return; }
                const comment = action.payload.comments[0] as Comment;
                if( comment.deleted == true && comment.id == this.commentId && comment.messageId == this.messageId ) {
                    this.$bvModal.hide(this.id);
                }
            }
        });
    }

    // Lifecycle
    beforeDestroy(): void {
        if( this.unsubscribeMutation ) this.unsubscribeMutation()
    }
}
