






























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { SetPayload } from "../../store/domain-store";
import type { AppKeys } from "../../direct-app-config";
import { DefaultAllowAttachmentTypeByClient, DownloadDeviceTypes, DownloadDeviceTypesDefault } from '@/suppport-attachment-types';
import { Domains } from '@/direct-access';

@Component({

})
export default class DirectDomainDropdown extends Vue {
    name: string = "direct-domain-dropdown";

    /** 選択された組織ID */
    selectedDomainId: string = "";

    // 開閉状態の管理
    expanded = false;

    @Prop({ default: () => "" }) readonly domainId!: string; // 選択組織名初期ロード用
    @Watch( "domainId", { immediate: true } ) onDomainIdChanged( value: string ): void {
        this.selectedDomainId = value
    }

    @Prop({ default: () => [] }) readonly domains!: Domains;

    get selectedDomainName(): string {
        if( this.selectedDomainId ) {
            const domain = this.domains.find( domain => domain.domain_id_str == this.selectedDomainId );
            if( domain ) return domain.domain_name;
        }
        return ""
    }

    get disabled(): boolean {
        return this.$store.getters["isLoading"];
    } 

    /** @return true: 掲示板がON false: 掲示板がOFF */
    isAppOn( domain: any ): boolean {
        return 0 <= ( domain?.contract?.direct_app_keys || [] ).findIndex( (key: string) => key == "forum" );
    }

    onDomainSelected( domainIdStr: string ): void {
        this.selectedDomainId = domainIdStr;
        this.$root.$emit( "on-domain-selected", domainIdStr );

        const domain = this.domains.find( domain => domain.domain_id_str == this.selectedDomainId );
        if( domain ) {
            // dropdownから組織変更する際に検索条件をリセット
            this.$root.$emit( "on-set-search-param", { word: '' } );
            const allow_attachment_type = domain.setting.allow_attachment_type || 0; // 0, null: false, 1~: true
            const allow_save_attachment = ( domain.setting.allow_save_attachments || DownloadDeviceTypesDefault ) as DownloadDeviceTypes;
            const allow_attachment_type_by_client = domain.setting.allow_attachment_type_by_client || DefaultAllowAttachmentTypeByClient;
            // アップロード先 設定場所 (domainSetting の upload_pathにセット)
            // const upload_path = "uploads";
            const domainSetting: SetPayload = {
                allow_attachment_type: allow_attachment_type,
                allow_save_attachments: allow_save_attachment,
                allow_attachment_type_by_client: allow_attachment_type_by_client,
                domainId: domain.domain_id_str,
                domainName: domain.domain_name,
                domainRole: domain.role || undefined,
                solution_ids: domain.contract.solution_ids || [],
                direct_app_keys: (domain.contract.direct_app_keys || []) as AppKeys[],
            };
            this.$root.$emit( "on-set-domain-setting", domainSetting );
            this.$nextTick( () => {
                const domainId = this.$store.getters["domainId"];
                const topicId = this.$store.getters["topicId"];
                this.$store.dispatch("fetchData", { domainId, topicId } );
            })
        }
    }
}
