var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dropdown",
    {
      attrs: {
        "no-caret": "",
        size: "sm",
        variant: "light",
        "toggle-class": "radius",
        disabled: _vm.options.length <= 1
      },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "d-flex flex-direction-row" },
                [
                  _c("div", { staticClass: "dropdown-content" }, [
                    _vm._v(_vm._s(_vm.options[_vm.index] || ""))
                  ]),
                  _c("b-icon-chevron-down", { staticClass: "my-auto" })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    _vm._l(_vm.options, function(label, index) {
      return _c(
        "b-dropdown-item",
        {
          key: index,
          on: {
            click: function($event) {
              return _vm.onClick(index)
            }
          }
        },
        [_vm._v(_vm._s(label))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }