import { Category } from "@/model/category";
import { API, graphqlOperation } from "@aws-amplify/api";
import { listCategories } from "@/graphql/queries";
import {
    CreateCategoryMutation, CreateCategoryMutationVariables,
    ListCategoriesQuery, ListCategoriesQueryVariables,
    UpdateCategoryMutation, UpdateCategoryMutationVariables,
    OnCreateCategorySubscription, OnCreateCategorySubscriptionVariables,
    OnDeleteCategorySubscription, OnDeleteCategorySubscriptionVariables,
    OnUpdateCategorySubscription, OnUpdateCategorySubscriptionVariables,
} from "@/API";
import { createCategory, updateCategory } from "@/graphql/mutations";
import { onCreateCategory, onDeleteCategory, onUpdateCategory } from "@/graphql-custom/subscriptions";

import { GraphQLResult } from "@aws-amplify/api-graphql";
import { AWSAppSyncRealTimeProvider } from "@aws-amplify/pubsub"

import Observable from "zen-observable-ts";
import { ZenObservable } from "zen-observable-ts";
import { ServerApiAccess } from "@/server-api-access";

export type CategoryReadResult = {
    categories: Category[],
    nextToken?: string|null|undefined,
}

type SubscriptionResult<T> = Observable<{
    provider: AWSAppSyncRealTimeProvider,
    value: GraphQLResult<T>
}>

export type SubscriptionCallback = {
    onCreate: ( domainId: string ) => void,
    onUpdate: ( domainId: string, deleted?: boolean|null, deletedUser?: string|null ) => void,
    onDelete: ( domainId: string ) => void,
}

export default class CategoryDao {
    /** domain毎のSubscription */
    private static subscriptions: {
        [ domainId: string ]: ZenObservable.Subscription[]
    } = {}

    private constructor(){
        // インスタンス作成不可
    }

    /** カテゴリーの追加(無ければ) */
    public static async create( category: Category ): Promise<Category|undefined> {
        ServerApiAccess.logging( "category.create", category );
        try {
            const api = new ServerApiAccess();
            const result = await api.createCategory(category);
            if( result ) {
                return Category.create( result );
            } else {
                return undefined;
            }
        } catch( error ) {
            console.log("Category Add error:%O", error)
            throw error;
        }
    }

    /**
     * カテゴリーデータの取得
     * @param domainId 取得する組織ID情報。未指定の場合は所属する全組織情報を取得すｒ
     */
    public static async read( domainId: string ): Promise<Category[]|undefined> {
        ServerApiAccess.logging( "category.read", domainId );
        try {
            const api = new ServerApiAccess();
            const results = await api.listCategories(domainId);
            if( results ) {
                const categories = results.map( result => {
                    const category = Category.create(result);
                    return category;
                })
                return categories;
            } else {
                return undefined;
            }
        } catch( error ) {
            console.log("Category Read error:%O", error)
            throw error;
        }
    }

    /**
     * DBのデータ更新
     * @param data 更新するデータリスト
     */
    public static async update( category: Category ): Promise<Category|undefined> {
        ServerApiAccess.logging( "category.update", category );
        try {
            const api = new ServerApiAccess();
            const result = await api.updateCategory(category);
            if( result ) {
                return Category.create( result );
            } else {
                console.log("Category Add error:%O", result);
                return undefined;
            }
        } catch( error ) {
            console.error( "Category Update Error :%O", error );
            return undefined;
        }
    }

    /**
     * DBから削除実行
     * 実際は削除フラグを付けるだけ
     * @param data 削除するデータリスト
     */
    public static async delete( category: Category ): Promise<Category[]> {
        category;   // eslint指定避け。実装時削除
        return [];
    }

    private static prepareSubscription( domainId: string ) {
        const tmp = this.subscriptions[ domainId ];
        if( tmp == undefined ) this.subscriptions[ domainId ] = [];
    }

    /** サブスクリプションを作成する */
    public static async registerSubscrptions( domainId: string, callback: SubscriptionCallback ): Promise<void> {
        this.prepareSubscription( domainId );

        const subscriptions = this.subscriptions[ domainId ];
        if( 0 < subscriptions.length ) return;  // 登録済み
        // create
        {
            const input: OnCreateCategorySubscriptionVariables = { domainId: domainId, }
            const graphql = await API.graphql(
                graphqlOperation( onCreateCategory, input )
            ) as SubscriptionResult<OnCreateCategorySubscription>;

            const subscription = graphql.subscribe({
                next: ( { value } ) => {
                    const data = value.data?.onCreateCategory;
                    if( data ) {
                        callback.onCreate( data.domainId );
                    }
                },
                error: error => {
                    console.error( "Category Register onCreate Subscription Error:%O", error );
                },
                complete: () => {
                    // unsubscribeした時
                },
            })
            subscriptions.push( subscription );
        }

        // update
        {
            const input: OnUpdateCategorySubscriptionVariables = { domainId: domainId, }
            const graphql = await API.graphql(
                graphqlOperation( onUpdateCategory, input )
            ) as SubscriptionResult<OnUpdateCategorySubscription>;
            const subscription = graphql.subscribe({
                next: ({ value }) => {
                    const data = value?.data?.onUpdateCategory;
                    if( data ) {
                        callback.onUpdate( data.domainId, data.deleted, data.deletedUser );
                    }
                },
                error: error => {
                    console.error( "Category Register onUpdate Subscription Error:%O", error );
                },
                complete: () => {
                    // unsubscribeした時
                },
            })
            subscriptions.push( subscription );
        }

        // delete
        {
            const input: OnDeleteCategorySubscriptionVariables = { domainId: domainId, }
            const graphql = await API.graphql(
                graphqlOperation( onDeleteCategory, input )
            ) as SubscriptionResult<OnDeleteCategorySubscription>;
            const subscription = graphql.subscribe( {
                next: ({value}) => {
                    const data = value?.data?.onDeleteCategory;
                    if( data ) {
                        callback.onDelete( data.domainId );
                    }
                },
                error: error => {
                    console.error( "Category Register onDelete Subscription Error:%O", error );
                },
                complete: () => {
                    // unsubscribeした時
                },
            })
            subscriptions.push( subscription );
        }
    }

    /** サブスクリプションを閉じる */
    public static closeSubscription( domainId: string ) {
        const tmp = this.subscriptions[ domainId ] || [];
        tmp.forEach( tmp => tmp.unsubscribe() );
        this.subscriptions[ domainId ] = [];
    }
}
