var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0 search-result", attrs: { fluid: "" } },
    [
      !_vm.searchResultList.length
        ? _c("div", { staticClass: "no-search-result" }, [
            _vm._v("検索結果はありませんでした")
          ])
        : _vm._e(),
      _vm.searchResultList.length
        ? _c("div", { staticClass: "complement-border" })
        : _vm._e(),
      _vm._l(_vm.searchResultList, function(item, index) {
        return _c("div", { key: index }, [
          item.type == "topic"
            ? _c(
                "div",
                {
                  staticClass: "search-item",
                  on: {
                    click: function($event) {
                      return _vm.jumpPage(item.link)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "d-flex" }, [
                    _c("div", { staticClass: "item-title" }, [
                      _c("div", { staticClass: "d-flex my-1 mx-3" }, [
                        _c(
                          "div",
                          {
                            staticClass: "mr-2",
                            staticStyle: { "font-weight": "normal" }
                          },
                          [_vm._v(_vm._s(index + 1 + "件目 "))]
                        ),
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(item.topicTitle))
                        ])
                      ])
                    ])
                  ]),
                  _c("topic-list-item", {
                    staticStyle: { "pointer-events": "none" },
                    attrs: {
                      id: item.item.id,
                      icon: item.item.icon,
                      category: item.item.category,
                      title: item.item.title,
                      desc: item.item.desc,
                      "updated-at": item.item.updatedAt,
                      pinned: item.item.pinned,
                      star: item.item.star,
                      comments: item.item.comments,
                      watches: item.item.watches,
                      messages: item.item.messages,
                      viewer: _vm.viewer,
                      search: _vm.word,
                      acl: item.acl
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          item.type == "message"
            ? _c(
                "div",
                {
                  staticClass: "search-item",
                  on: {
                    click: function($event) {
                      return _vm.jumpPage(item.link)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "d-flex mr-auto" }, [
                    _c("div", { staticClass: "item-title" }, [
                      _c("div", { staticClass: "d-flex my-1 mx-3" }, [
                        _c(
                          "div",
                          {
                            staticClass: "mr-2",
                            staticStyle: { "font-weight": "normal" }
                          },
                          [_vm._v(_vm._s(index + 1 + "件目 "))]
                        ),
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(item.topicTitle))
                        ])
                      ])
                    ])
                  ]),
                  _c("message-list-item", {
                    staticStyle: { "pointer-events": "none" },
                    attrs: {
                      id: item.item.id,
                      domainId: item.item.domainId,
                      topicId: item.item.topicId,
                      title: item.item.title,
                      message: item.item.message,
                      star: item.item.star,
                      pinned: item.item.pinned,
                      icon: item.item.icon,
                      owner: item.item.owner,
                      updatedAt: item.item.updatedAt,
                      comments: item.item.comments,
                      photos: item.item.photos,
                      likes: item.item.likes,
                      like: item.item.like,
                      users: _vm.users,
                      viewer: _vm.viewer,
                      search: _vm.word,
                      acl: item.acl
                    }
                  })
                ],
                1
              )
            : item.type == "comment"
            ? _c(
                "div",
                {
                  staticClass: "search-item",
                  on: {
                    click: function($event) {
                      return _vm.jumpPage(item.link)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "d-flex mr-auto" }, [
                    _c("div", { staticClass: "item-title" }, [
                      _c("div", { staticClass: "d-flex my-1 mx-3" }, [
                        _c(
                          "div",
                          {
                            staticClass: "mr-2",
                            staticStyle: { "font-weight": "normal" }
                          },
                          [_vm._v(_vm._s(index + 1 + "件目 "))]
                        ),
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(item.topicTitle))
                        ]),
                        item.topicTitle
                          ? _c("div", { staticClass: "mx-1" }, [_vm._v("/")])
                          : _vm._e(),
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(item.messageTitle))
                        ])
                      ])
                    ])
                  ]),
                  _c("comment-list-item", {
                    staticStyle: { "pointer-events": "none" },
                    attrs: {
                      id: item.item.id,
                      icon: item.item.icon,
                      user: item.item.owner,
                      updatedAt: item.item.updatedAt,
                      message: item.item.message,
                      likes: item.item.likes,
                      like: item.item.like,
                      photos: item.item.photos,
                      messageId: item.item.messageId,
                      users: _vm.users,
                      viewer: _vm.viewer,
                      search: _vm.word
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      }),
      _vm.searchResultList.length
        ? _c("div", { staticClass: "complement-border" })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }