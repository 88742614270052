var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "d-flex justify-content-center align-items-center mt-3" },
    [
      _c("div", { staticStyle: { "text-align": "left" } }, [
        _vm.isMobile
          ? _c("div", [_vm._v("データをロード中です")])
          : _c("div", [
              _vm._v("表示する組織を左上のドロップダウンから選択して下さい")
            ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }