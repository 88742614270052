var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    {
      staticClass: "confirm-btn",
      attrs: {
        "inline-block": "",
        disabled: _vm.disabled,
        variant: _vm.variant
      },
      on: { click: _vm.onClick }
    },
    [_vm._v(_vm._s(_vm.label))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }