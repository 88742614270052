









































































































import { mixins } from "vue-class-component";
import { ActionPayload } from "vuex";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FileListViewer from "./FileListViewer.vue";
import type { UpdateCommentPayload } from "../store"
import Confirm from "./button/Confirm.vue";
import Cancel from "./button/Cancel.vue";
import ErrorModal from './ErrorModal.vue'
import { Attachment, AttachmentFactory, Comment } from "../model";
import TextProcessMixin from './mixin/TextProcessMixin';
import EditDiscardMixin from "./mixin/EditDiscardMixin";
import LoadingUpload from './loading/LoadingUpload.vue';
import DragAreaOverlay from './DropAreaOverlay.vue';
import FileSelectionForm from "./FileSelectionForm.vue";
import { AttachmentFileTypes, AttachmentFileTypesDefault, AttachmentFileTypesNone } from "@/suppport-attachment-types";

const COMMENT_CONTENT_MAX_LENGTH = 300;

@Component({
    mixins: [
        EditDiscardMixin
    ],
    components: {
        FileListViewer, Confirm, Cancel, ErrorModal, LoadingUpload, DragAreaOverlay, FileSelectionForm,
    }
})
export default class CommentEditModal extends mixins( Vue, EditDiscardMixin, TextProcessMixin ) {
    name: string = "comment-edit-modal";
    target: File | null = null ;
    files: (File | Attachment)[] = [];
    deletedFiles: Attachment[] = []; // 削除するファイル

    messageSource: string = "";

    replaceParam: { domainId: string, topicId: string } = { domainId: '', topicId: '' }; // router.replace用パラメータ

    prevData: { message: string, files: (File | Attachment)[] } = { message: "", files: [] };
    /* アップローディング関係 */
    isUploading: boolean = false;
    uploadFiles: number = 0;
    nowuploads: number = 0;

    fileTypeErrorMsg: string = "下記の添付ファイルは対応しておりません。";

    submitLock: boolean = false;

    drag: boolean = false;

    pdfThumbStore: File[] = []

    // Vuexの変更監視
    unsubscribeMutation?: () => void;

    @Prop({ default: '', required: true })
    readonly id!: string;

    @Prop({ default: '', required: true }) readonly commentId!: string;

    @Prop({ default: '', required: true  }) readonly messageId!: string;

    /** 内容 */
    @Prop({ default: "" }) readonly message!: string;
    @Watch( 'message', { immediate: true } ) onMessageChanged(value: string): void {
        this.messageSource = value;
        this.prevData = { message: this.message, files: this.photos, }
    }

    /** ファイル */
    @Prop({ default: () => [] }) readonly photos!: Attachment[];
    @Watch( 'photos', { immediate: true } ) onPhotosChanged( value: Attachment[] ): void {
        this.files = value.slice();
        this.prevData = { message: this.message, files: this.photos, }
    }

    // このモーダルが表示されているかのフラグ
    @Prop({ default: false }) readonly shown!: boolean;

    // Storybook向け
    @Prop({ default: false }) readonly show!: boolean;

    // ファイル添付設定
    @Prop({ default: () => AttachmentFileTypesDefault }) readonly allow_attachment_type!: AttachmentFileTypes;

    // 添付可能か
    get allow_attachment(): boolean { return this.allow_attachment_type != AttachmentFileTypesNone; }

    get fileListViewerParams():{ messageId: string, commentId: string } {
        return { messageId: this.messageId, commentId: this.commentId }
    }

    get paperclipClass(): string {
        return this.allow_attachment ? "paperclip" : "paperclip not-allowed";
    }

    get attachmentLabelClass(): Record<string, boolean> {
        return {
            "d-flex attachement-label": true,
            "allow": this.allow_attachment,
            "not-allowed": !this.allow_attachment,
        }
    }

    get showCameraButton(): boolean {
        if( !this.$store ) return false;
        return this.$store.getters["isAndroid"];
    }

    get contentState(): boolean {
        if( !this.messageSource ) return false;
        const tmp = this.messageSource.trim().replace(/\r?\n/g, '');
        return this.getLength(tmp) > 0 && this.getLength(tmp) <= COMMENT_CONTENT_MAX_LENGTH;
    }

    get validCommentMessage(): string {
        const tmp = this.messageSource.trim().replace(/\r?\n/g, '');
        return this.getLength(tmp)? `コメントは${ COMMENT_CONTENT_MAX_LENGTH }文字以内です(${this.getLength(tmp)}/${COMMENT_CONTENT_MAX_LENGTH})`:'内容は必須項目です'
    }

    get editMode(): boolean {
        if( this.messageSource != this.prevData.message ) return true;
        if( JSON.stringify(this.files) != JSON.stringify(this.prevData.files) ) return true;
        return false;
    }

    get loadingMsg(): string {
        return `添付ファイルを送信中です (${this.nowuploads}/${this.uploadFiles})`;
    }

    updateFile(files: (File | Attachment)[]): void {
        this.files = files;
        this.changeEditMode(this.editMode);
    }

    dropFiles(files: FileList): void {
        const form = this.$refs.fileSelectionForm as FileSelectionForm;
        form.dropFiles(files);
    }

    resetParam(): void {
        this.submitLock = false;
    }

    /* 投稿処理 */
    async onEdit(closeEvent: ()=>void): Promise<void> {
        // 送信連打による連続送信を防止
        if( this.submitLock ) return;
        this.submitLock = true;

        const form = this.$refs.fileSelectionForm as FileSelectionForm;
        const deleteFilePromises = this.deletedFiles.map(file => form.removeCommentFile(file, this.messageId, this.commentId))
        await Promise.all(deleteFilePromises)

        this.deletedFiles = []

        const promise = this.files.map( async(file) => {
            if( !AttachmentFactory.isAttachment( file ) ) {
                const result = await form.uploadCommentFile(file, this.messageId, this.commentId);
                this.nowuploads++;
                return result;
            } else {
                return file;
            }
        });
        this.uploadFiles = this.files.filter( f => !AttachmentFactory.isAttachment( f ) ).length;
        this.isUploading = true;
        const files = await Promise.all(promise)
        .catch( err => {
            console.log(err);
            this.$root.$emit('show-error-modal', { msg: "添付ファイルの送信に失敗しました。", afterProcess: ()=>{return} })
            return undefined;
        })
        .finally( () => {
            this.isUploading = false;
            this.nowuploads = 0;
            form.resetFileList();
        });

        if( !files ) return;
        const param: Partial<Comment> = {
            message: this.messageSource,
            photos: files
        };

        const obj: UpdateCommentPayload = { commentId: this.commentId, messageId: this.messageId, param: param };
        if( this.$root && this.$root.$emit ) {
            this.$root.$emit( 'on-comment-edit', obj );
        }
        if( this.$emit ) {
            this.$emit( 'on-comment-edit', obj );
        }

        this.changeEditMode(false);
        closeEvent();
    }

    /* モーダルが閉じられた際の処理 */
    onClose(closeEvent: ()=>void): void {
        this.$emit('closeEditModal');
        closeEvent();
    }

    // 削除後のイベント
    afterDeleteProcess( deletedType: string) {
        switch( deletedType ) {
            case "topic":
                console.log("interruption process");
                // modal close => $router.replace
                //this.$emit("interruptionComment", { id: 'modal-comment', path: `/${this.replaceParam.domainId}` });
                this.$bvModal.hide('modal-comment');
                this.$router.replace(`/${this.replaceParam.domainId}`);
                break;
            case "message":
                console.log("interruption process");
                // modal close
                this.$bvModal.hide('modal-comment');
                break;
            default:
                break;
        }
    }

    // Lifecycle
    beforeDestroy() {
        if( this.unsubscribeMutation ) this.unsubscribeMutation()
    }
    mounted() {
        if( this.show ) {
            this.$bvModal.show(this.id)
        }

        if( !this.$store ) return;

        this.unsubscribeMutation = this.$store.subscribe( ( action: ActionPayload, state: unknown ) => {
            if( !this.shown ) return;

            // 「コメント編集中」に「話題」削除 -> 「話題一覧」に移動
            if( action.type == "topics/delete" ) {
                const domainId = this.$store.getters.domainId;
                const topicId = this.$store.getters.topicId;
                if( action.payload.topicId == topicId ) {
                    this.replaceParam = { domainId: domainId, topicId: topicId };
                    this.$root.$emit('show-error-modal', { msg: "話題が削除されたため、話題一覧に移動します", afterProcess: () => this.afterDeleteProcess('topic') })
                }
            }
            //「コメント編集中」に「投稿」削除 -> 「投稿一覧」に移動
            else if( action.type == "messages/add" && action.payload.messages.length == 1 && action.payload.messages[0].deleted == true ) {
                const msg = action.payload.messages[0];
                if( msg && msg.id == this.messageId ) {
                    this.$root.$emit('show-error-modal', { msg: "投稿が削除されたため、投稿一覧に移動します", afterProcess: () => this.afterDeleteProcess('message') })
                }
            }
        });
    }

}
