import { render, staticRenderFns } from "./MessageListItem.vue?vue&type=template&id=3d501bf8&"
import script from "./MessageListItem.vue?vue&type=script&lang=ts&"
export * from "./MessageListItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2065077497/src/dxflow-forum/packages/open-channel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d501bf8')) {
      api.createRecord('3d501bf8', component.options)
    } else {
      api.reload('3d501bf8', component.options)
    }
    module.hot.accept("./MessageListItem.vue?vue&type=template&id=3d501bf8&", function () {
      api.rerender('3d501bf8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/MessageListItem.vue"
export default component.exports