










































import { Component, Prop, Vue } from "vue-property-decorator";
import SearchForm from "./SearchForm.vue";
import { Category, Topic, User } from "../model";
import TopicList from "./TopicList.vue";
import "./message-side-menu.scss"
import { allowCreateMessage, allowFeature, FeatureName } from "../direct-app-config";
import AclManager from "../model/acl-manager";

@Component({
    components: {
        TopicList, SearchForm,
    }
})
export default class TopicSideMenuPanel extends Vue {
    name: string = 'topic-side-menu-panel';

    filter: Category[] = [];

    @Prop( {} )
    id!: string;

    /** true: 複数選択モード */
    @Prop( { default: true } )
    selectMulti!: boolean;

    // カテゴリー一覧
    @Prop( { default: () => [] })
    categories!: Category[];

    // フィルタ設定されているカテゴリー一覧
    @Prop( { default: () => [] } )
    filter_init!: Category[];

    // topicList用
    @Prop( { default: "" } ) readonly domainId!: string;
    @Prop( { default: () => [] }) readonly topicList!: Topic[];
    @Prop( { default: "" } ) readonly topicId!: string; // アクティブな話題Id
    @Prop( { default: () => [] }) readonly users!: User[]; // 組織内ユーザー情報
    @Prop( { default: () => ( User.createNotFoundUser() ) }) readonly me!: User; // ログインユーザ情報
    @Prop({ default: () => [] }) readonly categoryFilter!: Category[];  // カテゴリフィルタ

    // 新規作成ボタンが有効かどうか
    get creatable(): boolean {
        if( allowFeature('deny-edit', this.$store) == false ) return true;  // 未対応組織
        return AclManager.createMessage( this.$store, this.domainId )                               // 契約観点
            && AclManager.getCreateMessageAcl( this.$store, this.domainId, this.topicId ) != "deny" // Acl観点
    }

    // Feature
    hasFeature( keyword: FeatureName ): boolean { return allowFeature( keyword, this.$store ); }


    // 項目の選択状態
    // フィルタが空 → 「すべて」のみON
    // ファイルに何か設定 → 「すべて」OFF、該当項目がON
    selected( category: Category | "all" ): { [selector: string]: boolean } {
        if( this.filter.length === 0 ) {
            return { 'selected': category === 'all' }
        } else if( category == 'all' ) {
            return { 'selected': this.filter.length === 0 }
        } else {
            return { 'selected': 0 <= this.filter.findIndex( item => item.id == category.id ) }
        }
    }

    // カテゴリーの選択処理
    // 「すべて」が選択された場合、「すべて」のみになる → filter が空
    // その他の項目が選択された場合は、該当項目のON/OFF
    onClick( category: Category | "all" ): void {
        if( category === 'all' ) {
            if( 0 === this.filter.length ) {
                // 何もしない
            } else {
                this.filter.splice( 0, this.filter.length );
            }
            return;
        } else {
            this._onClick( category );
        }
    }

    // 話題の新規作成ボタンを押した
    onMessageCreateClicked(): void {
        if( allowCreateMessage( this.domainId, this.topicId, this.$store ) == false ) {
            this.$root.$emit('free-alert');
            return;
        }
        this.$bvModal.show("modal-message");
    }

    private _onClick( category: Category ): void {
        // 複数選択有り
        const index = this.filter.findIndex( item => item.id == category.id );
        if( 0 <= index ) {
            this.filter.splice( index, 1 );
        } else {
            this.filter.push( category );
        }
    }

    created(): void {
        this.filter.push( ...this.filter_init )
    }

    back(): void {
        if( this.$router ) {
            this.$store.dispatch('setDomainId', this.domainId);
            this.$store.dispatch('setTopicId', ''); // topicIdのリセット
            this.$router.push({ path: `/${this.domainId}` })
        }
    }
}
