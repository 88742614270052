var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar-nav",
    { staticClass: "nav-left" },
    [
      _vm.back
        ? _c(
            "b-navbar-nav",
            { staticClass: "nav-back" },
            [
              _c(
                "b-nav-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("on-back")
                    }
                  }
                },
                [_c("b-icon", { attrs: { icon: "chevron-left" } })],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.cancel
        ? _c(
            "b-navbar-nav",
            [
              _c(
                "b-nav-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("on-cancel")
                    }
                  }
                },
                [_vm._v("キャンセル")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.brand
        ? _c(
            "b-navbar-nav",
            { staticClass: "nav-brand h-100" },
            [_vm.brand ? _c("icon-navigation-brand") : _vm._e()],
            1
          )
        : _vm._e(),
      _vm.domains
        ? _c(
            "b-navbar-nav",
            { staticClass: "domains" },
            [
              _c("direct-domain-dropdown", {
                attrs: { domainId: _vm.domainId, domains: _vm.domainList }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.leftItems && _vm.leftItems.length != 0
        ? _c(
            "b-navbar-nav",
            _vm._l(_vm.leftItems, function(item) {
              return _c(
                "b-nav-item",
                {
                  key: item.label,
                  attrs: { disabled: item.disabled },
                  on: {
                    click: function($event) {
                      return _vm.$emit(item.click)
                    }
                  }
                },
                [
                  item.icon
                    ? _c("b-icon", { attrs: { icon: item.icon } })
                    : _c("span", [_vm._v(" " + _vm._s(item.label) + " ")])
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.leftEmpty
        ? _c(
            "b-navbar-nav",
            [
              _c(
                "b-nav-item",
                { staticClass: "invisible", attrs: { disabled: "" } },
                [_c("b-icon", { attrs: { icon: "arrow-left" } })],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }