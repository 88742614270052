import sanitize from "sanitize-html";

// i-reporterで使用するスキーム
// NOTE: links-regexp.tsで定義されているスキームの正規表現
//   const SCHEME = `(jp\\.co\\.cimtops\\.)?ireporter(\\.(openreport|createreport|setcluster|downloadreport|downloaddefinition))?`
const IREPORTER_SCHEME = [
    "ireporter",
    "ireporter.openreport",
    "ireporter.createreport",
    "ireporter.setcluster",
    "ireporter.downloadreport",
    "ireporter.downloaddefinition",
    "jp.co.cimtops.ireporter",
    "jp.co.cimtops.ireporter.openreport",
    "jp.co.cimtops.ireporter.createreport",
    "jp.co.cimtops.ireporter.setcluster",
    "jp.co.cimtops.ireporter.downloadreport",
    "jp.co.cimtops.ireporter.downloaddefinition",
];

// directで使用するスキーム
// NOTE: links-regexp.tsで定義されているスキームの正規表現
//   const SCHEME = `(direct4b|direct4b-rc|direct4b-dev|direct4b-ep|logochat)`
const DIRECT_SCHEME = [
    "direct4b",
    "direct4b-rc",
    "direct4b-dev",
    "direct4b-ep",
    "logochat"
];

// i-reporterとdirectで使用するスキームも許可
const allowedSchemes = [ ...sanitize.defaults.allowedSchemes, ...IREPORTER_SCHEME, ...DIRECT_SCHEME ];
sanitize.defaults.allowedSchemes = allowedSchemes;

export default sanitize;
