var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      attrs: { "label-size": "lg", "label-class": "font-weight-bold" },
      scopedSlots: _vm._u([
        {
          key: "label",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "d-flex flex-directions-row" },
                [
                  _c("div", [_vm._v(_vm._s(_vm.formLabelName))]),
                  _c("b-badge", { class: "authority-badge " + _vm.editAuth }, [
                    _vm._v(_vm._s(_vm.authlityBadgeLabel))
                  ])
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.settingDescription
        ? _c(
            "div",
            { class: { "setting-description": true, owner: _vm.isOwner } },
            [_vm._v(_vm._s(_vm.settingDescription))]
          )
        : _vm._e(),
      _vm.isOwner
        ? _c(
            "b-form-radio-group",
            {
              attrs: { stacked: "" },
              on: { input: _vm.updateValue },
              model: {
                value: _vm.restrictType,
                callback: function($$v) {
                  _vm.restrictType = $$v
                },
                expression: "restrictType"
              }
            },
            [
              _c(
                "b-form-radio",
                { attrs: { value: 0 }, on: { change: _vm.updateRadioValue } },
                [_vm._v(_vm._s(_vm.omitLabelName))]
              ),
              _c(
                "b-form-radio",
                { attrs: { value: 1 }, on: { change: _vm.updateRadioValue } },
                [
                  _c("div", { staticClass: "d-flex flex-direction-row" }, [
                    _c("div", [_vm._v(_vm._s(_vm.permitLableName))]),
                    _vm.isInvalidPermit
                      ? _c("div", { staticClass: "invalid-checkbox-label" }, [
                          _vm._v(_vm._s(_vm.customOptionAlert))
                        ])
                      : _vm._e()
                  ])
                ]
              ),
              _vm.isPermitCustom
                ? _c("b-form-checkbox-group", {
                    staticClass: "file-type-checkbox",
                    attrs: {
                      disabled: _vm.restrictType !== 1,
                      options: _vm.filetypes
                    },
                    on: { input: _vm.updateValue },
                    model: {
                      value: _vm.customFileType,
                      callback: function($$v) {
                        _vm.customFileType = $$v
                      },
                      expression: "customFileType"
                    }
                  })
                : _vm._e(),
              _c(
                "b-form-radio",
                { attrs: { value: 2 }, on: { change: _vm.updateRadioValue } },
                [_vm._v(_vm._s(_vm.directLabelName))]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }