import { v4 as uuidv4 } from "uuid";
import {Mutable} from "type-fest";

export class Category {
    private static TEMP_CATEGORY_ID = "temp";

    public readonly id: string;
    public readonly title: string;

    public readonly owner: string;
    public readonly updatedAt: Date;
    public readonly createdAt: Date;

    public readonly domainId: string;
    public readonly color: string;

    public readonly deleted?: boolean;      //!< true: 削除済み
    public readonly deletedUser?: string;   //!< 削除実行した direct user id

    // DB保存しない
    private readonly isTemp: boolean;

    /** 一時的なカテゴリを作成する */
    public static createTemp( title: string ): Category;
    public static createTemp( obj: { [ name: string ]: string  } ): Category;
    public static createTemp( arg1: string | Partial<Category> ): Category {
        if( typeof arg1 == "string" ) {
            return new Category( { title: arg1 }, true );
        } else {
            return new Category( arg1, true );
        }
    }

    public static isTemporary( category: Category ): boolean {
        return category.isTemp;
    }

    /** categoryの存在チェック */
    public static isExists( category?: Category ): boolean {
        if( !category ) return false;
        if( category.deleted == undefined ) return true;   // 削除フラグがOFF -> 存在
        return category.deleted == true ? false : true;    // deletedが ON -> 削除, OFF -> 存在
    }

    public static isDummy( category: Category ): boolean {
        return category.id === "dummy";
    }

    /** カテゴリーを作成する */
    public static create( obj?: Record<string, unknown> | string ): Category {
        if( typeof obj === "string" ) {
            return new Category( { title: obj } )
        } else {
            return new Category( obj );
        }
    }

    protected constructor( obj?: Partial<Category>, isTemp: boolean = false ) {
        this.id = obj && obj.id ? obj.id : uuidv4();
        this.title = obj && obj.title ? obj.title : "";
        this.domainId = obj && obj.domainId ? obj.domainId : "";
        this.color = obj && obj.color ? obj.color : "0";

        this.owner = obj && obj.owner ? obj.owner : "";
        this.updatedAt = obj && obj.updatedAt ? new Date( obj.updatedAt ) : new Date();
        this.createdAt = obj && obj.createdAt ? new Date( obj.createdAt ) : new Date();

        this.deleted = obj?.deleted || undefined;
        this.deletedUser = obj?.deletedUser || undefined;

        this.isTemp = isTemp;
    }

    /**
     * domainId, id, owner を除いたデータを新データにコピーする
     * @param from コピーするデータが入ったもの
     * @returns fromのデータがコピーされた新インスタンス
     */
    public copyFrom( from: Partial<Category> ): Category {
        const data: Partial<Mutable<Category>> = {};
        data.id             = this.id;          // idはコピーしない
        data.title          = from.title        !== undefined ? from.title : this.title;
        data.updatedAt      = from.updatedAt    !== undefined ? from.updatedAt : this.updatedAt;
        data.createdAt      = from.createdAt    !== undefined ? from.createdAt : this.createdAt;
        data.owner          = this.owner;       // ownerはコピーしない
        data.domainId       = this.domainId;    // domainIdはコピーしない
        data.color          = from.color        !== undefined ? from.color : this.color;
        data.deleted        = from.deleted      !== undefined ? from.deleted : this.deleted;
        data.deletedUser    = from.deletedUser  !== undefined ? from.deletedUser : this.deletedUser;

        // isTempはコピーしない

        return new Category( data );
    }
}

export const UNDEFINED_CATEGORY: Category = Category.create( {
    id: "dummy",
    title: "dummy",
    domainId: "dummy",
    color: "0",
    owner: "dummy",
    updatedAt: new Date( 0 ),
    createdAt: new Date( 0 ),
} );
