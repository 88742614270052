import cloneDeep from "lodash/cloneDeep";
import { Comment, Comments } from "@/model";
import CommentDao from "./comment-dao";
import type { CommentReadResult } from "./comment-dao";
import Vue from "vue";
import { Module } from "vuex";
import { ServerApiAccess } from "@/server-api-access";

type State = { comments: Comments, }
type RootState = { domainId: string, topicId: string, messageId: string };

type UpdateMutation = { comment: Comment };

type AddPayload = { comments: Comment[], messageId: string };
type CreatePayload = { comment: Comment, domainId?: string, topicId?: string,  messageId?: string };
export type UpdatePayload = { commentId: string, messageId: string, param: Partial<Comment>, }
type FetchPayload = { topicId: string, messageId?: string };

export type PayloadRegisterSubscription = { domainId: string, topicId: string, messageId: string, };

// 投稿に紐付いたコメントを取得します
const findMessageComments = ( state: State, topicId: string, messageId: string ): Comment[] => {
    let topicCommentList = state.comments[ topicId ];
    if( topicCommentList == undefined ) {   // 箱を用意
        topicCommentList = {};
        Vue.set( state.comments, topicId, topicCommentList );
    }

    let messageCommentList = topicCommentList[ messageId ];
    if( messageCommentList == undefined ) { // 箱を用意
        messageCommentList = [];
        Vue.set( state.comments[ topicId ], messageId, messageCommentList );
    }
    return messageCommentList;
}

const commentModule: Module<State, RootState> = {
    namespaced: true,
    state: {
        comments: {},
    },
    getters: {
        /** 投稿IDからコメント一覧を取得する */
        get: ( state: State ) => ( topicId: string, messageId: string ): Comment[] | undefined => {
            const topicComments = state.comments[ topicId ];
            if( !topicComments ) return undefined;
            return topicComments[ messageId ];
        },

        /** Topic内のコメント全取得 */
        getByTopic: ( state: State ) => ( topicId: string ): { [ messageId: string ]: Comment[] | undefined } => {
            return state.comments[ topicId ];
        }
    },
    mutations: {
        // コメントを追加する。messageIdは確定済みであること
        add( state, { comments }: { comments: Comment[] } ) {
            comments.forEach( comment => {
                const msgComments = findMessageComments( state, comment.topicId, comment.messageId );
                const index = msgComments.findIndex( c => c.id == comment.id );
                if( index < 0 ) {
                    msgComments.push( comment );
                } else {
                    msgComments.splice( index, 1, comment );
                }
            })
        },

        // コメントを更新します
        update( state, { comment }: UpdateMutation ): void {
            const comments = findMessageComments( state, comment.topicId, comment.messageId );
            const index = comments.findIndex( c => c.id == comment.id );

            if( index > -1 ) {
                const target = comments[index];
                const updated = cloneDeep( target );
                updated.copyFrom( comment );
                comments.splice( index, 1, updated )
            }
        },
    },
    actions: {

        async add( { commit },{ comments }: AddPayload ) {
            if( comments && 0 < comments.length ) {
                commit( 'add', { comments: comments } );
            }
        },

        /** コメントを新規作成する */
        async create({ commit, rootState, rootGetters }, { comment, domainId, topicId, messageId }: CreatePayload ) {
            comment.setDomainAndTopicAndMessageIfNotSet(
                ( domainId || rootState.domainId ),
                ( topicId || rootState.topicId ),
                ( messageId || rootState.messageId ),
            )
            // DBに保存
            const register = await CommentDao.create( comment );
            if( register ) {
                commit( 'add', { comments: [register] } );
            }
        },

        /** コメントを更新する */
        async update({ commit, rootState, rootGetters }, envelope: UpdatePayload ): Promise<void> {
            const commentId = envelope.commentId;
            const messageId = envelope.messageId;
            const domainId = rootState.domainId;
            const topicId = rootState.topicId;
            const param = envelope.param;

            const commentObject: Partial<Comment> = { ...param, id: commentId, domainId, topicId, messageId };
            const comment = Comment.create(commentObject);

            const register = await CommentDao.update(comment);
            if( register ) {
                commit( 'update', { comment: register } );
            }
        },

        /** DBからコメントデータをロードして Store に保存する */
        async fetch( { commit, rootState }, { topicId, messageId }: FetchPayload ): Promise<void> {
            const domainId = rootState.domainId;
            const comments = await CommentDao.read( domainId, topicId, messageId  );
            if( comments ) {
                commit( 'add', { comments: comments } );
            }
        },

        /** Subscriptionを登録する */
        async registerSubscription( {commit, dispatch, }, { domainId, topicId, messageId, }: PayloadRegisterSubscription ): Promise<void> {
            await CommentDao.registerSubscrptions( domainId, topicId, messageId, {
                onCreate:( domainId: string, topicId: string, messageId: string, commentId: string, ) => {
                    dispatch("fetch", { topicId, messageId, commentId, })
                },
                onUpdate: ( domainId: string, topicId: string, messageId: string, commentId: string, ) => {
                    dispatch("fetch", { topicId, messageId, commentId, })
                },
                onDelete: ( domainId: string, topicId: string, messageId: string, commentId: string, ) => {
                    commit( 'delete', { topicId, messageId, commentId, } );
                },
            } );
            return;
        },

        /** Subscriptionを登録解除 */
        async removeSubscription( { commit }, payload: { messageId: string } ): Promise<void> {
            const messageId = payload.messageId;
            await CommentDao.closeSubscription( messageId );
        },
    }
}

export default commentModule;
