import { IconReaction, IconReactionType } from "@/API";
import { Store } from "vuex";
import { User, Topic, Message, Comment } from "@/model"
import { FindByComment, FindByMessage, FindByTopic, FindReaction } from "@/store/reaction-store";
import { PayloadReaction } from "@/store";

export class ReactionController {

    private $store:Store<any>
    public constructor( store: Store<any> ){
        this.$store = store;
    }

    /**
     * リアクションを取得するためのパラメータを取得します
     * @param target 取得予定のベースとなるもの
     * @param container "parent": ベースの所属元を取得する "root": 話題を取得 指定なし: baseのまま
     * @returns
     */
    public static getReactionParam( base: Topic|Message|Comment, container?: "parent"|"root" ): FindReaction {
        if( base instanceof Topic ) {
            const domainId = base.domainId;
            const topicId = base.id;
            return  { domainId, topicId, } as FindByTopic;
        } else if( base instanceof Message ) {
            const domainId = base.domainId;
            const topicId = base.topicId;
            const messageId = base.id;
            switch( container ) {
                case "parent":  return { domainId, topicId, } as FindByTopic;
                case "root":    return { domainId, topicId, } as FindByTopic;
                default:        return { domainId, topicId, messageId, } as FindByMessage;
            }
        } else if( base instanceof Comment ) {
            const domainId = base.domainId;
            const topicId = base.topicId;
            const messageId = base.messageId;
            const commentId = base.id;
            switch( container ) {
                case "parent":  return { domainId, topicId, messageId, } as FindByMessage;
                case "root":    return { domainId, topicId, } as FindByTopic;
                default:        return { domainId, topicId, messageId, commentId } as FindByComment;
            }
        }
        return { domainId: "", topicId: "", messageId: "", commentId: "" }
    }

    /** リアクションの現在値を取得 */
    public reactionValue( target: Topic|Message|Comment, reactionType: IconReactionType, owner: User ): boolean {
        if( !this.$store ) return false;

        const reaction = this.getReaction( target, reactionType );
        const value = reaction ? reaction.userIdList.includes( owner.directId ) || false : false;
        return value;
    }

    /** 話題ID から Topic を検索 */
    public findTopic( topicId: string ): Topic|undefined {
        if( !this.$store ) return undefined;
        const domainId = this.$store.getters["domainId"];
        const list = this.$store.getters["topics/get"]( domainId ) as Topic[] | undefined;
        return list ? list.find( l => l.id == topicId ) : undefined;
    }

    /** 投稿ID から Message を検索 */
    public findMessage( messageId: string ): Message|undefined {
        if( !this.$store ) return undefined;
        const domainId = this.$store.getters["domainId"];
        const topicId = this.$store.getters["topicId"];
        const list = this.$store.getters["messages/get"]( domainId, topicId ) as Message[] | undefined;
        return list ? list.find( l => l.id == messageId ) : undefined;
    }

    /** 投稿ID、コメントID から Comment を検索 */
    public findComment( messageId: string, commentId: string ): Comment|undefined {
        if( !this.$store ) return undefined;
        const topicId = this.$store.getters["topicId"];
        const list = this.$store.getters["comments/get"]( topicId, messageId ) as Comment[] | undefined
        return list ? list.find( l => l.id == commentId ) : undefined;
    }

    /** コメントのリアクションを取得します */
    public getReaction( target: Topic|Message|Comment|undefined, reactionType: IconReactionType ): IconReaction | undefined {
        if( !this.$store || !target ) return undefined;
        const type = ReactionController.getReactionParam( target );
        return this.$store.getters["reactions/get"]( reactionType, type );
    }

    /**
     * リアクションの切り替え処理
     * @pavam value 現在値。無ければ検索します
     */
    public onReactionClicked( target: Topic|Message|Comment|undefined, reactionType: IconReactionType, owner: User, value?: boolean ): void {
        if( !this.$store || !target ) return;

        // 現在値
        if( value == undefined ) {
            value = this.reactionValue( target, reactionType, owner );
        }
        const payload: PayloadReaction = { target, type: reactionType, value: !value };
        this.$store.dispatch( "setReaction", payload );
    }

}
