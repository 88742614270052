var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex m-2", staticStyle: { "flex-direction": "column" } },
    _vm._l(_vm.files, function(file, index) {
      return _c("div", { key: index }, [
        _c(
          "div",
          { staticClass: "file-info" },
          [
            _c("div", { staticClass: "file-preview" }, [
              _vm.getFileTypeWrapper(file) == "file"
                ? _c("img", { attrs: { src: _vm.getImagePath(file) } })
                : _vm.getFileTypeWrapper(file) == "image"
                ? _c("img", { attrs: { src: _vm.createURL(file) } })
                : _vm.getFileTypeWrapper(file) == "video"
                ? _c("img", { attrs: { src: "/img/icon/icon_file_movie.svg" } })
                : _vm.getFileTypeWrapper(file) == "pdf"
                ? _c("img", {
                    attrs: { src: _vm.createURL(file) },
                    on: { error: _vm.setDefaultIcon, load: _vm.onLoadThumbnail }
                  })
                : _vm.getFileTypeWrapper(file) == "office"
                ? _c("img", { attrs: { src: _vm.getOfficeURL(file) } })
                : _vm._e(),
              _vm.getFileTypeWrapper(file) == "image"
                ? _c("p", { staticClass: "type-icon image" }, [_vm._v("IMAGE")])
                : _vm.getFileTypeWrapper(file) == "pdf"
                ? _c("p", { staticClass: "type-icon pdf" }, [_vm._v("PDF")])
                : _vm._e()
            ]),
            _c("div", { staticClass: "file-name" }, [
              _vm._v(_vm._s(_vm.getFileName(file)))
            ]),
            _c("delete", {
              on: {
                deleteFile: function($event) {
                  return _vm.onDeleteClick(index)
                }
              }
            })
          ],
          1
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }