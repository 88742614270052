import { API, graphqlOperation } from "aws-amplify";
import { Topic as DbTopic,
    CreateTopicMutation, DeleteTopicMutation,
    ListTopicsByDomainIdQuery, ListTopicsByDomainIdQueryVariables,
    ListTopicsQuery, ListTopicsQueryVariables,
    OnCreateTopicSubscription, OnCreateTopicSubscriptionVariables,
    OnUpdateTopicSubscription, OnUpdateTopicSubscriptionVariables,
    OnDeleteTopicSubscription, OnDeleteTopicSubscriptionVariables, UpdateTopicMutationVariables, UpdateTopicMutation, ModelTopicFilterInput,
    Acl as DbAcl,
    DeleteTopicMutationVariables,
    CreateTopicMutationVariables,
} from "@/API"
import { Category, Topic, UNDEFINED_CATEGORY, User } from "@/model";
import { createTopic, deleteTopic, updateTopic } from "@/graphql/mutations";
import Observable from "zen-observable-ts";
import { ZenObservable } from "zen-observable-ts";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { AWSAppSyncRealTimeProvider } from "@aws-amplify/pubsub"
import { onCreateTopic, onDeleteTopic, onUpdateTopic } from "@/graphql-custom/subscriptions";
import AclManager from "@/model/acl-manager";
import Acl from "@/model/acl";
import { ServerApiAccess } from "@/server-api-access";
import { EventManager } from "@/events/event-manager";

type SubscriptionResult<T> = Observable<{
    provider: AWSAppSyncRealTimeProvider,
    value: GraphQLResult<T>
}>

type TopicReadResultRaw = {
  topics: DbTopic[],
  nextToken?: string | null | undefined,
}

export type TopicReadResult = {
  topics: Topic[],
  nextToken?: string | null | undefined,
}

export default class TopicDao {

    /** domain毎のSubscription */
    private static subscriptions: {
        [ domainId: string ]: ZenObservable.Subscription[]
    } = {}

    private constructor(){
        // インスタンス作成不可
    }

    /**
     * TopicをDBに登録する
     * @param topic
     */
    public static async create( topic: Topic ): Promise<Topic|undefined>{
        ServerApiAccess.logging( "topic.create", topic );    // logging
        try {
            const api = new ServerApiAccess();
            const result = await api.createTopic(topic);
            if( result ) {
                return Topic.create( result, topic.category );
            } else {
                return undefined;
            }
        } catch( error ) {
            console.error("Topic Add Error:%O", error );
            throw error;
        }
    }

    /**
     * 組織内の全トピックデータを取得する
     * @param categories 組織横断した全カテゴリーデータ
     * @param domainId 組織ID
     * @returns トピックデータ(組織横断)。undefinedが返されたらエラー
     */
     public static async read( categories: Category[], domainId: string ): Promise<{ topics: Topic[], totalCount: number }|undefined> {
        ServerApiAccess.logging( "topic.read", { domainId: domainId } );

        const api = new ServerApiAccess();
        const results = await api.getTopics(domainId);
        if( results && typeof results !== "number" ) {
            const data = results.topics;
            const topics = data.map( result => {
                const category = categories.find( category => category.id == result.categoryId ) || UNDEFINED_CATEGORY;
                const translated = Topic.create( result, category );
                return translated
            })
            return {
                topics: topics,
                totalCount: results.totalCount
            }
        } else if( typeof results == "number" ) {
            if( results == 401 ) {  // 再認証に流す
                EventManager.relogin()
            } else {
                // 403や500などのエラーはエラー(undefined)として処置
                return undefined;
            }
        } else {
            return undefined;
        }
    }

    /**
     * DBのデータ更新
     * @param topic 更新するデータリスト
     */
    public static async update( topic: Topic ): Promise<Topic|undefined> {
        ServerApiAccess.logging( "topic.update", topic );    // logging
        try {
            const api = new ServerApiAccess();
            const result = await api.updateTopic(topic);
            if( result ) {
                return Topic.create( result, topic.category );
            } else {
                console.error( "Topic Update Error :%O", result);
                return undefined;
            }
        } catch( error ) {
            console.error( "Topic Update Error :%O", error );
            return undefined;
        }
    }

    /**
     * DBから削除実行
     * @param data 削除するデータリスト
     */
    public static async delete( me: User, topic: Topic ): Promise<Topic[]> {
        // ACLチェック
        if( AclManager.deleteTopic( me, topic ) == false ) return [];

        const variables: DeleteTopicMutationVariables = {
            input: {
                domainId: topic.domainId,
                id: topic.id,
            }
        }

        ServerApiAccess.logging( "topic.delete", variables.input );    // logging

        const op = graphqlOperation( deleteTopic, variables );

        try {
            await ( API.graphql( op ) as Promise<{data: DeleteTopicMutation}> );
        } catch( error ) {
            console.error("Topic Delete Error:%O", error );
        }

        // 未実装
        return [];
    }

    public static async registerES( topic: DbTopic ) {
        const input: UpdateTopicMutationVariables = {
            input: {
                id: topic.id,
                domainId: topic.domainId,

                title: topic.title,
                desc: topic.desc,
                icon: topic.icon ? {
                    url: topic.icon.url,
                    mime: topic.icon.mime,
                } : null,
                pinned: topic.pinned,
                categoryId: topic.categoryId,
                messages: topic.messages,
                owner: topic.owner,
                // updatedAt: topic.updatedAt,
                createdAt: topic.createdAt,
                deleted: topic.deleted,
                deletedUser: topic.deletedUser,
                acl: topic.acl,
                notification: topic.notification || '',
                // DB非保存
                // star
            }
        }

        const op = graphqlOperation( updateTopic, input );
        try {
            const result = ( await API.graphql( op ) ) as GraphQLResult< UpdateTopicMutation >;
            console.log(result);
        } catch( error ) {
            console.error( "Topic Update Error :%O", error );
        }

        const input2: UpdateTopicMutationVariables = {
            input: {
                id: topic.id,
                domainId: topic.domainId,

                title: topic.title,
                desc: topic.desc,
                icon: topic.icon ? {
                    url: topic.icon.url,
                    mime: topic.icon.mime,
                } : undefined,
                pinned: topic.pinned,
                categoryId: topic.categoryId,
                messages: topic.messages,
                owner: topic.owner,
                updatedAt: topic.updatedAt,
                createdAt: topic.createdAt,
                deleted: topic.deleted,
                deletedUser: topic.deletedUser,
                acl: topic.acl,
                notification: topic.notification || '',
                // DB非保存
                // star
            }
        }

        const op2 = graphqlOperation( updateTopic, input2 );
        try {
            const result = ( await API.graphql( op2 ) ) as GraphQLResult< UpdateTopicMutation >;
            console.log(result);
        } catch( error ) {
            console.error( "Topic Update Error :%O", error );
        }
    }

    private static prepareSubscription( domainId: string ) {
        const tmp = this.subscriptions[ domainId ];
        if( tmp == undefined ) this.subscriptions[ domainId ] = [];
    }

    /** サブスクリプションを作成する */
    public static async registerSubscrptions( domainId: string, callback: SubscriptionCallback ): Promise<void> {
        this.prepareSubscription( domainId );

        const subscriptions = this.subscriptions[ domainId ];
        if( 0 < subscriptions.length ) return;  // 登録済み
        // create
        {
            const input: OnCreateTopicSubscriptionVariables = { domainId: domainId, }
            const graphql = await API.graphql(
                graphqlOperation( onCreateTopic, input )
            ) as SubscriptionResult<OnCreateTopicSubscription>;

            const subscription = graphql.subscribe({
                next: ( { value } ) => {
                    const data = value.data;
                    if( data?.onCreateTopic ) {
                        const topic = data.onCreateTopic;
                        callback.onCreate( topic.domainId, topic.id );
                    }
                },
                error: error => {
                    console.error( "Topic Register onCreate Subscription Error:%O", error );
                },
                complete: () => {
                    // unsubscribeした時
                },
            })
            subscriptions.push( subscription );
        }

        // update
        {
            const input: OnUpdateTopicSubscriptionVariables = { domainId: domainId, }
            const graphql = await API.graphql(
                graphqlOperation( onUpdateTopic, input )
            ) as SubscriptionResult<OnUpdateTopicSubscription>;
            const subscription = graphql.subscribe({
                next: ({ value }) => {
                    if( value?.data?.onUpdateTopic ) {
                        const topic = value.data.onUpdateTopic;
                        const acl = Acl.createByDbAcl( topic.acl as DbAcl );
                        callback.onUpdate( topic.domainId, topic.id, topic.deleted, topic.deletedUser, acl, );
                    }
                },
                error: error => {
                    console.error( "Topic Register onUpdate Subscription Error:%O", error );
                },
                complete: () => {
                    // unsubscribeした時
                },
            })
            subscriptions.push( subscription );
        }

        // delete
        {
            const input: OnDeleteTopicSubscriptionVariables = { domainId: domainId, }
            const graphql = await API.graphql(
                graphqlOperation( onDeleteTopic, input )
            ) as SubscriptionResult<OnDeleteTopicSubscription>;
            const subscription = graphql.subscribe( {
                next: ({value}) => {
                    if( value?.data?.onDeleteTopic ) {
                        const topic = value.data.onDeleteTopic;
                        callback.onDelete( topic.domainId, topic.id );
                    }
                },
                error: error => {
                    console.error( "Topic Register onDelete Subscription Error:%O", error );
                },
                complete: () => {
                    // unsubscribeした時
                },
            })
            subscriptions.push( subscription );
        }
    }

    /** サブスクリプションを閉じる */
    public static closeSubscription( domainId: string ) {
        const tmp = this.subscriptions[ domainId ] || [];
        tmp.forEach( tmp => tmp.unsubscribe() );
        this.subscriptions[ domainId ] = [];
    }

}

export type SubscriptionCallback = {
    onCreate: ( domainId: string, topicId: string ) => void,
    onUpdate: ( domainId: string, topicId: string, deleted?: boolean|null, deletedUser?: string|null, acl?: Acl ) => void,
    onDelete: ( domainId: string, topicId: string ) => void,
}
