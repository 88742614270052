import { API } from "aws-amplify";
import { CreateUserMutation, CreateUserMutationVariables, DeleteUserMutation, DeleteUserMutationVariables, User as DbUser, DomainUsersByDomainIdQuery, DomainUsersByDomainIdQueryVariables, GetDomainUserQuery, GetDomainUserQueryVariables, GetUserQueryVariables, ListDomainUsersQuery, ListDomainUsersQueryVariables, UpdateUserMutation, UpdateUserMutationVariables, UserByCognitoUserIdQuery, UserByCognitoUserIdQueryVariables } from "@/API";
import { DomainUsersByDomainId as domainUsersByDomainId, UserByCognitoUserId as userByCognitoUserId } from "@/graphql/queries";
import { createUser, deleteUser, updateUser } from "@/graphql/mutations";
import { User } from "@/model";
import { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { ServerApiAccess } from "@/server-api-access";
import sentry from "@/sentry";

export type UserReadResult = {
    users: User[],
    nextToken?: string|null|undefined,
}

export default class UserDao {
    public static async create( user: User ): Promise<User|undefined> {
        const data: CreateUserMutationVariables = {
            input: {
                id: user.id,
                directId: user.directId,
                name: user.name,
                profileIcon: user.profileIcon,
                domainIdList: user.domainIdList,
                // owner: user.owner,
                favorites: user.favorites,
                likes: user.likes,
                // updatedAt: user.updatedAt,
                // createdAt: user.createdAt,
            }
        }
        ServerApiAccess.logging( "user.create", data.input );
        const op = graphqlOperation( createUser, data );
        try {
            const apiResult = await ( API.graphql( op ) as Promise< GraphQLResult<CreateUserMutation> > );
            if( apiResult.data?.createUser ) {
                return User.create( apiResult.data.createUser );
            } else {
                return undefined;
            }
        } catch( error ) {
            sentry.sendSentryError({ type: "User Add error", error: JSON.stringify(error) })
            throw error;
        }
    }

    /**
     * 組織内ユーザー一覧の取得
     * @param domainid 取得する組織ID情報
     */
    public static async read( domainId: string ): Promise<User[]|undefined> {
        ServerApiAccess.logging( "user.read", domainId );
        try {
            const api = new ServerApiAccess();
            const users = await api.listUsers(domainId);
            return users;
        } catch( error ) {
            sentry.sendSentryError({
                type: "[DB]DOMAINUSER READ ERROR",
                message: ( error !== null && typeof error == "object" && "message" in error ) ? error.message : "",
                error: JSON.stringify(error)
            })
            return undefined;
        }
    }

    /**
     * 自身の取得
     * @returns
     */
    public static async getMe(): Promise<User|undefined> {
        ServerApiAccess.logging( "user.getMe", {} );
        try {
            const api = new ServerApiAccess();
            const me = await api.getMe();
            return me;
        } catch( error ) {
            sentry.sendSentryError({ type: "getMe ERROR", error: JSON.stringify(error) })
            throw error
        }
    }

    public static async update( user: User, updateAttr?: { favorites?: string[], likes?: string[] } ): Promise<User> {
        const data: UpdateUserMutationVariables = {
            input: {
                directId: user.directId,
                name: user.name,
                profileIcon: user.profileIcon,
                domainIdList: user.domainIdList,
                // owner: user.owner,
                favorites: updateAttr?.favorites || user.favorites,
                likes: updateAttr?.likes || user.likes,
                // updatedAt: user.updatedAt,
                // createdAt: user.createdAt,
            }
        }
        ServerApiAccess.logging( "user.update", data.input );
        const op = graphqlOperation( updateUser, data );
        const result = await ( API.graphql( op ) as Promise< GraphQLResult<UpdateUserMutation> > );
        if( result.data?.updateUser ) {
            return User.create( result.data.updateUser );
        } else {
            return user;
        }
    }

    public static async delete( user: User ): Promise<boolean> {
        const data: DeleteUserMutationVariables = {
            input: {
                directId: user.directId,
            }
        }
        const op = graphqlOperation( deleteUser, data );
        const result = await ( API.graphql( op ) as Promise< GraphQLResult<DeleteUserMutation> > );
        return result.data?.deleteUser ? true : false;
    }
}
