


























































































































import { Watch, Component, Prop, Vue } from "vue-property-decorator";
import { Category, User } from "../model";
import { COLOR_PALETTE, DEFAULT_COLOR } from "./color-palette";
import ProfileIcon from "./ProfileIcon.vue";
import './color-palette.scss';

@Component({
    components: {
        ProfileIcon
    }
})
export default class CategoryListItem extends Vue {
    name: string = "category-list-item";

    // 色選択パレット関連
    colorPalette: string[] = COLOR_PALETTE;
    defaultColor: string = DEFAULT_COLOR;
    pressedIndex: number = -1; // -1 : デフォルトカラーになる, それ以外: そのボタンが押されている

    @Prop( { default: Category.createTemp(""), required: true }) category!: Category;
    @Watch("category", { immediate: true }) onCategoryChanged( value: Category ): void {
        const color = value.color || this.defaultColor;
        const index = this.colorPalette.findIndex( c => c == color);
        if( index < 0 ) {
            const defaultIndex = this.colorPalette.findIndex( c => c == this.defaultColor);
            this.pressedIndex = defaultIndex;
        }
        else {
            this.pressedIndex = index;
        }
    }

    @Prop( { default: false }) selected!: boolean;

    @Prop({ default: false, required: true }) readonly isMobile!: boolean;

    // ログインユーザー情報
    @Prop({ default: () => User.createNotFoundUser() }) readonly viewer!: User;
    // カテゴリ作成者情報
    @Prop({ default: () => User.createNotFoundUser() }) readonly owner!: User;

    get title(): string {
        return this.category.title;
    }

    get colorGroup():  string[][] {
        const rows = Math.ceil( this.colorPalette.length / 4 );
        const group = new Array(rows).fill([]);
        const result = group.map((_,i) => {
            return this.colorPalette.slice( i * 4, (i+1) * 4);
        })
        return result;
    }

    get toggleClass(): string {
        return this.colorPalette[this.pressedIndex] || this.defaultColor;
    }

    get isEdit(): boolean {
        const role = this.$store.getters["domains/getDomainRole"];
        const roleId = role ? role.type : 30; // 30:ユーザー
        return this.viewer.id == this.category.owner || roleId == 10 || roleId == 20; // role.type 10:所有者 20:管理者
    }

    isPressed(index: number): boolean {
        return index == this.pressedIndex;
    }

    press(index: number) : void {
        this.pressedIndex = index;
        this.onEditColor();
    }

    // カテゴリーの選択
    // => CategoryList
    clickCategory(): void {
        this.$emit("clickCategory", this.category.id);
    }

    // カテゴリー編集ボタンクリック
    // => CategoryEdit (=>CategoryTitleEdit)
    clickEditButton(): void {
        this.$root.$emit('edit-category-title', {id: this.category.id});
    }

    // カラーの選択
    // => CategoryEdit
    onEditColor(): void {
        this.$root.$emit('edit-category-color', {id: this.category.id, color: this.toggleClass});
    }
}
