


































import { Component, Prop, Vue } from "vue-property-decorator";
import Confirm from "./button/Confirm.vue";

@Component({
    components: { Confirm }
})
export default class ErrorModal extends Vue {
    name: string = 'error-modal';

    @Prop({ default: '', required: true }) readonly id!: string;

    @Prop({ default: '', required: true }) readonly msg!: string;

    @Prop({ default: true }) readonly centered!: boolean;

    // Storybook向け
    @Prop({ default: false }) readonly show!: boolean;

    // OK clicked
    clickedOK( okEvent: ()=>void ): void {
        this.$emit('clickedOK');
        okEvent();
    }

    // モーダルが閉じた際の処理
    hideEvent():void {
        console.log(`hide modal: ${this.id}`);
        this.$emit('hideModal');
    }

    // Lifecycle
    mounted(): void {
        if( this.show ) {
            this.$bvModal.show(this.id)
        }
    }
} 
