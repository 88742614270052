var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", { staticClass: "p-0", attrs: { fluid: "" } }, [
    _vm.show
      ? _c("div", { staticClass: "not-allowed-backdrop" }, [
          _c(
            "div",
            {
              staticClass:
                "not-allowed-modal d-flex justify-content-center align-items-center"
            },
            [
              _c("img", {
                staticStyle: { "vertical-align": "middle", height: "60px" },
                attrs: { src: _vm.icon }
              }),
              _c("div", { staticClass: "not-allowed-message" }, [
                _vm._v(_vm._s(_vm.msg))
              ])
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }