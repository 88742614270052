var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-nav-item-dropdown", {
    staticClass: "solution-menu",
    attrs: { right: "", lazy: "", "toggle-class": "solution-menu-toggle" },
    on: {
      shown: function($event) {
        _vm.expanded = true
      },
      hidden: function($event) {
        _vm.expanded = false
      }
    },
    nativeOn: {
      click: function($event) {
        $event.stopPropagation()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "button-content",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "solution-menu-bar" },
              [
                _c("img", {
                  staticClass: "solution-menu-image",
                  attrs: { src: _vm.appIcon }
                }),
                _c("span", { staticClass: "solution-menu-label" }, [
                  _vm._v(_vm._s(_vm.appLabel))
                ]),
                _vm.expanded
                  ? _c("b-icon-chevron-up", {
                      staticClass: "solution-menu-toggle-icon"
                    })
                  : _c("b-icon-chevron-down", {
                      staticClass: "solution-menu-toggle-icon"
                    })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("navbar-menu-solutions-panel", {
              attrs: {
                "app-link-items-ref": _vm.solutionLinks,
                "domain-id": _vm.domainId
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }