import { Category, User } from "@/model";
import CategoryDao from "./category-dao";
import type { CategoryReadResult } from "./category-dao";
import Vue from "vue";
import { ActionContext, Module } from "vuex";
import { State } from "vuex-class";

type Categories = {
    [ domainId: string ]: Category[],
}

type State = { categories: Categories, filter: { [ domainId: string ]: Category[], } }
type RootState = { domainId: string, topicId: string, messageId: string };
type CategoryActionContext = ActionContext< State, RootState >;

interface Payload<T> {
    data: T;            // カテゴリー
}
type PayloadAdd = Payload<Category>;
type PayloadAddAll = Payload<Category[]>;

function normalizeDomainId( category: Category ): string|undefined;
function normalizeDomainId( category: Category, domainId: string ): string;
function normalizeDomainId( category: Category, domainId?: string ): string|undefined {
    if( category.domainId && Category.isDummy( category ) == false ) return category.domainId;
    return domainId;
}

const getDomainCategories = ( state: State, domainId: string ) => {
    let domainCategories = state.categories[ domainId ];
    if( !domainCategories ) {
        domainCategories = [];
        Vue.set( state.categories, domainId, domainCategories );
    }
    return domainCategories;
}

function addOrUpdateCategory( state: State, category: Category ) {
    const _domainId = normalizeDomainId( category );
    if( !_domainId ) return;
    const domainCategories = getDomainCategories( state, _domainId )
    const index = domainCategories.findIndex( c => c.id == category.id );
    if( index < 0 ) {
        domainCategories.push( category );
    } else {
        domainCategories.splice( index, 1, category );
    }
}

function sortTitle( state: State, category: Category ) {
    const _domainId = normalizeDomainId( category );
    if( !_domainId ) return;
    const domainCategories = getDomainCategories( state, _domainId );
    // タイトルでsort
    domainCategories.sort((p, n) => {
        if(p.title > n.title) return 1;
        else return -1;
    });
}

const categoryModule: Module< State, RootState > = {
    namespaced: true,
    state: {
        categories: {},
        filter:{},
    } as State,
    getters: {
        get: ( state: State, getters: unknown, rootState: RootState ) => ( domainId: string, exists: boolean = true  ): Category[] => {
            domainId = domainId || rootState.domainId;
            const categories = state.categories[ domainId ];
            if( !categories ) return categories;
            // ※ デフォルトで削除されていないカテゴリーのみ取得
            return exists ? categories.filter( c => Category.isExists(c) ) : categories;
        },
        getOne: ( state: State, getters: unknown, rootState: RootState ) => ( domainId: string, id: string, exists: boolean = false ): Category|undefined => {
            domainId = domainId || rootState.domainId;
            const domainCategories = state.categories[ domainId ];
            return domainCategories ? domainCategories.find( c => c.id == id ) : undefined;
        },
        getAll: ( state: State ) : Category[] => {
            const result = Object.keys( state.categories ).reduce( ( prev: Category[], domainId ) => {
                const domainCategories = state.categories[ domainId ];
                prev.push( ...domainCategories );
                return prev;
            }, [] );
            return result;
        },
        filter: ( state: State ) => ( domainId: string ): Category[] => {
            let filter = state.filter[ domainId ];
            if( !filter ) {
                filter = [];
                Vue.set( state.filter, domainId, filter );
            }
            return filter;
        }
    },
    mutations: {
        /**
         * Storeに追加する
         * @param state
         * @param payload
         * @returns
         */
        add( state: State, payload: PayloadAdd | PayloadAddAll ): void {
            if( Array.isArray( payload.data ) ) {
                ( payload.data || [] ).forEach( category => {
                    addOrUpdateCategory( state, category );
                    sortTitle( state, category );
                });
            } else {
                const category = payload.data;
                addOrUpdateCategory( state, category );
                sortTitle( state, category );
            }
        },

        /** カテゴリフィルタを設定する */
        setFilter( state: State, payload: { domainId: string, filter: Category[] } ): void {
            const filter = state.filter[ payload.domainId ];
            if( filter ) {
                if( 0 < filter.length ) filter.splice(0, filter.length );
                filter.push( ...payload.filter );
            } else {
                Vue.set( state.filter, payload.domainId, payload.filter );
            }
        },

        /** カテゴリーを更新する */
        update( state: State, payload: Category ): void {
            addOrUpdateCategory( state, payload );
            sortTitle( state, payload );
        },

        /** カテゴリーを削除する */
        delete( state: State, payload: Category ): void {
            // 実質的には deleted フラグを付けた更新
            addOrUpdateCategory( state, payload );
            sortTitle( state, payload );
        },

    },
    actions: {

        /**
         * カテゴリーを新規追加する（Storeには追加されません）
         * @param data 新規追加するカテゴリー。isTemporaryであること
         * @return 追加されたカテゴリー。undefined: temporaryではない or 何かしらエラーが発生
         */
        async create( { commit, rootState }: CategoryActionContext, payload: Payload<Category> ): Promise<Category|undefined> {
            if( Category.isTemporary( payload.data ) === false ) return undefined;

            const category = payload.data;
            const register = await CategoryDao.create( category );
            if( register ) {
                commit( "add", { data: register } );
            }
        },

        /**
         * Storeにカテゴリーを追加する.
         * data: 追加するカテゴリー
         */
        async add( { commit }: CategoryActionContext, payload: PayloadAdd ): Promise<void> {
            commit( "add", payload );
        },

        /**
         * カテゴリーフィルタを設定する
         * @param payload 設定するカテゴリフィルタ
         */
        async setFilter( { commit }: CategoryActionContext, payload: { domainId: string, filter: Category[] } ): Promise<void> {
            commit( "setFilter", payload );
        },

        /**
         * DBからカテゴリー一覧を取得する
         * @param data 取得するドメインID。未指定の場合は全組織取得
         */
        async fetch( { commit }: CategoryActionContext, payload: Payload<string> ): Promise<void> {
            const results = await CategoryDao.read( payload.data );
            if( results ) {
                commit( 'add', { data: results } );
            }
        },

        /** 更新 */
        async update( { getters, commit }, payload: Partial<Category> ): Promise<void> {
            const category = getters[ "getOne" ]( payload.domainId, payload.id ) as Category|undefined;
            if( !category ) {
                console.error( "category is not found:", payload.domainId, payload.id );
                return;
            }
            const update = category.copyFrom( payload );
            const register = await CategoryDao.update( update );
            if( register ) {
                commit( "update", register );
                // flowへの登録はカテゴリーの場合は無い
            }
        },

        /** 削除 */
        async delete( { getters, commit, rootGetters }, payload: { domainId: string, id: string } ): Promise<void> {
            // 実行ユーザーの取得
            const me = rootGetters[ "users/me" ] as User;

            const category = getters[ "getOne" ]( payload.domainId, payload.id ) as Category|undefined;
            if( !category ) {
                console.error( "category is not found:", payload.domainId, payload.id );
                return;
            }
            const update = category.copyFrom( {
                deleted: true,
                deletedUser: me.id,
            } );
            const register = await CategoryDao.update( update );  // 削除フラグを付けて更新
            if( register ) {
                commit( "delete", register );
                // flowへの登録はカテゴリーの場合は無い
            }
        },

        async registerSubscription( {commit, dispatch }: CategoryActionContext, payload: { domainId: string, me: User } ): Promise<void> {
            const domainId = payload.domainId;
            await CategoryDao.registerSubscrptions( domainId, {
                onCreate:( domainId: string ) => {
                    dispatch("fetch", { data: domainId, })
                },
                onUpdate: ( domainId: string, deleted?: boolean|null ) => {
                    dispatch("fetch", { data: domainId, })
                },
                onDelete: ( domainId: string ) => {
                    commit( 'delete', { domainId, } );
                },
            } );
            return;
        },

        async removeSubscription( context: CategoryActionContext, payload: { domainId: string } ): Promise<void> {
            const domainId = payload.domainId;
            await CategoryDao.closeSubscription( domainId );
        }
    }
}

export default categoryModule;
