










































































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: { }
})
export default class Loading extends Vue {
    name: string = 'loading';

    @Prop({ default: "Now Loading..." }) readonly msg!: string;
    @Prop({ default: true }) readonly fullscreen!: boolean;
    @Prop({ default: "transparent" }) readonly background!: string;

    get backgroundColor(): string {
        return this.background || "transparent";
    }
}
