






































import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class DropAreaOverlay extends Vue {
    name: string = "drop-area-overlay";
    
    /** 
     *  ドラッグ状態
     *  ドラッグ状態はoverlay下の要素で制御
     *  (@dragover.prevent="drag = true" @dragleave.prevent="drag = false" v-on:drop.prevent="drag = false" を付与する)
     */ 
    @Prop({ default: false }) readonly drag!: boolean;

    /* ファイル追加(D&D) */
    onDrop(e: { dataTransfer: DataTransfer }): void {

        const files = e.dataTransfer.files;

        this.$emit("dropFiles", files);
    }
}
