



































































































































































import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Category, User } from "../model";
import CategoryList from "./CategoryList.vue";
import CustomButton from "./button/CustomButon.vue";
import Confirm from "./button/Confirm.vue";
import Cancel from "./button/Cancel.vue";
import CategoryTitleEdit from "./CategoryTitleEdit.vue";
import TextProcessMixin from './mixin/TextProcessMixin';
import EditDiscardMixin from './mixin/EditDiscardMixin';

// カテゴリータイトルの最大文字数
const CATEGORY_TITLE_MAX_LENGTH = 20;

@Component({
    mixins: [
        TextProcessMixin, EditDiscardMixin,
    ],
    components: {
        CategoryList, CustomButton, Confirm, Cancel, CategoryTitleEdit,
    }
})
export default class CategoryEdit extends mixins(Vue, TextProcessMixin, EditDiscardMixin) {
    name: string = "category-edit";

    categoriesStr: string = "";
    categorySource: Category[] = [];

    selectedId: string = '';

    editCategory: Category = Category.createTemp("");

    clickedAddCategory: boolean = false;
    alertCategoryStr: string = "";

    // 条件を通過しなかったカテゴリー文字列(アラート用)
    duplicatedInput: string = '';
    duplicated: string = '';
    overed: string = '';

    isEditing: boolean = false; // 編集中か

    @Prop({ default: '', required: true }) readonly id!: string;

    @Prop({ default: () => [], required: true }) readonly categories!: Category[];

    @Prop({ default: false, required: true }) readonly isMobile!: boolean;

    @Prop({ default: () => [] }) readonly attachedCategories!: Category[];

    @Prop( { default: "" } ) readonly domainId!: string;

    // ログインユーザー情報
    @Prop({ default: () => User.createNotFoundUser() }) readonly me!: User;

    // Storybook向け
    @Prop({ default: false }) readonly show!: boolean;

    get validTitleMessage(): string {
        return `カテゴリー名は${CATEGORY_TITLE_MAX_LENGTH}文字以内です`;
    }

    // 編集あったか
    get editMode(): boolean {
        const diff = this.categorySource.filter( category => {
            const exists = this.categories.find( c => c.id == category.id );
            if( exists ) {
                // 既存カテゴリー更新/削除
                return exists.title != category.title || exists.color != category.color || exists.deleted != category.deleted;
            } else {
                // 新規カテゴリー追加
                return true;
            }
        });

        return !!diff.length;
    }

    mounted(): void {
        if( this.show ) {
            this.$bvModal.show(this.id)
        }

        // カテゴリー名編集モーダル(CategoryTitleEdit)の展開
        // イベント発火箇所: CategoryListItemの編集ボタン
        this.$root.$on('edit-category-title', (param: { id :string }) => {
           const id = param.id;
           const category = this.categorySource.find( c => c.id == id );
           if(category) {
               this.editCategory = category;
               this.$bvModal.show("category-title-edit");
           }
        })

        // カテゴリー名の編集を反映させる
        // イベント発火箇所: CategoryTitleEditの保存ボタン
        this.$root.$on('edited-category', (param: { id: string, title: string}) => {
            const id = param.id;
            const title = param.title;
            const index = this.categorySource.findIndex( c => c.id == id );
            if( index > -1 ) {
                const category = this.categorySource[index];
                if( !category ) return;
                if( Category.isTemporary( category ) === false ) {
                    const updated = category.copyFrom({ title: title, color: category.color });
                    this.categorySource.splice( index, 1, updated);
                } else {
                    // 新規追加したカテゴリー(DB反映はまだ)の編集は再作成
                    const updated = Category.createTemp({ id: category.id, domainId: this.domainId, owner: this.me.id, title: title, color: category.color });
                    this.categorySource.splice( index, 1, updated);
                }
            }
        });

        // カテゴリーの削除を反映させる
        // イベント発火箇所: CategoryTitleEditの削除ボタン
        this.$root.$on('deleted-category', (param: { id: string}) => {
            const id = param.id;
            const index = this.categorySource.findIndex( c => c.id == id );
            if( index > -1 ) {
                const category = this.categorySource[index];
                if( !category ) return;
                if( Category.isTemporary( category ) === false ) {
                    // 削除
                    const deleted = category.copyFrom({ deleted: true, deletedUser: this.me.directId });
                    this.categorySource.splice( index, 1, deleted);
                } else {
                    // 新規追加したカテゴリー(DB反映はまだ)の削除はカテゴリーから取り除く
                    this.categorySource.splice( index, 1 );
                }
            }
        });

        // カテゴリーの色編集を反映させる
        // イベント発火箇所: CategoryListItemの色選択popupが閉じた時
        this.$root.$on('edit-category-color', (param: { id: string, color: string}) => {
            const id = param.id;
            const color = param.color;
            const index = this.categorySource.findIndex( c => c.id == id );
            if( index > -1 ) {
                const category = this.categorySource[index];
                if( !category ) return;
                if( Category.isTemporary( category ) === false ) {
                    const updated = category.copyFrom({ title: category.title, color: color });
                    this.categorySource.splice( index, 1, updated);
                } else {
                    // 新規追加したカテゴリー(DB反映はまだ)の編集は再作成
                    const updated = Category.createTemp({ id: category.id, domainId: this.domainId, owner: this.me.id, title: category.title, color: color });
                    this.categorySource.splice( index, 1, updated);
                }
            }
        });

        // カテゴリーの編集をDBに保存
        // イベント発火箇所: App.vueの保存アラートでOKを押した時
        this.$root.$on("save-category-edit", async() => {
            await this.onEditComplete();
        });
    }

    // 編集情報のリセット
    resetParam(): void {
        this.categorySource = this.categories.slice();
        this.categoriesStr = "";
        this.isEditing = false;
    }

    created(): void {
        this.categorySource = this.categories.slice();
    }

    // 選択カテゴリーの反映
    // イベントルート: CategoryListItem => CategoryList => CategoryEdit
    selected(param: {id: string}): void {
        const category = this.categorySource.find( c => c.id == param.id);
        if( category ) { this.selectedId = param.id; }
    }

    /**
     * 入力されたカテゴリー追加
     */
    addCategories(): void {
        const categoriesArray = this.categoriesStr.split(/\n/).map( str => str.trim() ).filter( str => !!str );
        if( !categoriesArray.length ) return;

        const pushedCategories = this.pushCategories(categoriesArray);

        // カテゴリーpush作業後のカテゴリー数 と push前のカテゴリー数+入力されたタイトル数が一致しているかどうか
        // 一致している = 入力された全カテゴリー名が条件をクリア(カテゴリー名被りなし,文字制限通過)
        // 一致していない = 入力されたカテゴリー名の中に条件をクリアしていないものが一つ以上ある
        if( pushedCategories.length == this.categorySource.length + categoriesArray.length ) {
            this.clickedAddCategory = false;
            this.categorySource = pushedCategories;
            this.categoriesStr = "";
        } else {
            this.clickedAddCategory = true;
            this.alertCategoryStr = this.categoriesStr;
            this.$bvModal.show("category-add-alert");
        }
    }

    /**
     * タイトル文字列から作成したカテゴリーをpushしたものを出力
     * 条件判定もここで行う
     */
    pushCategories(titleArray: string[]): Category[] {
        const tempCategories = this.categorySource.slice();
        this.duplicatedInput = '';
        this.overed = '';
        this.duplicated = '';

        // 入力内の重複排除
        const noDuplicationArray = [...new Set(titleArray)];
        if( noDuplicationArray.length != titleArray.length ) {
            const duplicatedInputArrray: string[] = []; // 重複したカテゴリー名の配列
            noDuplicationArray.map( str => {
                const exist = titleArray.filter( title => title === str );
                if( exist.length > 1 ) { duplicatedInputArrray.push( str ); }
            })
            this.duplicatedInput = duplicatedInputArrray.join('\n');
        }

        // 入力されたカテゴリーが既に登録されていないか と 文字数制限のチェック
        const duplicatedArray: string[] = []; // 重複したカテゴリー名の配列
        const overedArray: string[] = []; // 文字数オーバーしたカテゴリー名の配列
        const results = noDuplicationArray.reduce((p: Category[], c: string): Category[] => {
            if( this.getLength(c) <= CATEGORY_TITLE_MAX_LENGTH && !p.find( category => category.title == c ) ) {
                const tempPrev = p.slice();
                // 追加したカテゴリーも編集できるようにownerを付与しておく
                tempPrev.push( Category.createTemp({ title: c, domainId: this.domainId, owner: this.me.id }) );
                return tempPrev;
            } else {
                if( this.getLength(c) > CATEGORY_TITLE_MAX_LENGTH ) {
                    overedArray.push(c);
                }
                if( p.find( category => category.title == c ) ) {
                    duplicatedArray.push(c);
                }
                return p;
            }
        }, tempCategories );
        this.duplicated = duplicatedArray.join('\n');
        this.overed = overedArray.join('\n');

        return results;
    }

    showCancelAlert(close: () => void ): void {
        this.changeEditMode(this.editMode);
        this.showEditDiscardAlert(close);
    }

    // 編集完了
    async onEditComplete(): Promise<void> {
        // 編集中(DB反映)フラグ
        if( this.isEditing ) { return; }
        else { this.isEditing = true; }

        // 追加処理
        this.categorySource.filter( category => {
            const exists = this.categories.some( c => c.id == category.id );
            return !exists;
        }).forEach( category => {
            this.$root.$emit('on-category-create', { data: category });
        })

        // 更新処理
        this.categorySource.filter( category => {
            if( category.deleted ) return false; // 削除されたものはスキップ

            const before = this.categories.find( c => c.id == category.id );
            if( !before ) return false;
            else return before.title != category.title || before.color != category.color;
        }).forEach( category => {
            this.$root.$emit('on-category-update', category);
        })

        // 削除処理
        this.categorySource.filter( category => {
            const before = this.categories.find( c => c.id == category.id );
            if( !before ) return false;
            else return before.deleted != category.deleted;
        }).forEach( category => {
            this.$root.$emit('on-category-delete', { domainId: this.domainId, id: category.id });
        })

        // ※ 削除されたカテゴリーが選択されていた場合は、「未選択」とする
        const selectedCategories = this.categorySource.filter( category => category.id == this.selectedId && !category.deleted );
        if( selectedCategories ) {
            // カテゴリーの選択を反映
            // CategoryEdit => TopicEdit
            this.$emit('onCloseCategoryFilterDialog', selectedCategories);
        }
        this.$bvModal.hide(this.id);
    }

}
