import { Acl as DbAcl } from "@/API";

const GUEST_ROLE_TYPE = "40";

export enum RefidType {
    TOPIC_ID = "TOPIC_ID",
    MESSAGE_ID = "MESSAGE_ID",
    COMMENT_ID = "COMMENT_ID",
    USER_ID = "USER_ID",
    DEPARTMENT_ID = "DEPARTMENT_ID",
    ROLE_GROUP_ID = "ROLE_GROUP_ID",

    DUMMY_ID = "DUMMY_ID",
}

export type AclItem = {
    refId: string,
    refIdType: RefidType,
    create: boolean,
    read: boolean,
    update: boolean,
    delete: boolean,

    createTopic?: AclCreateType,      // allow: 話題作成許可     deny: 不許可 inherit: 親コンテナから引継
    createMessage?: AclCreateType,    // allow: 投稿作成許可     deny: 不許可 inherit: 親コンテナから引継
    createComment?: AclCreateType,    // allow: 湖面作成許可     deny: 不許可 inherit: 親コンテナから引継
    createReactions?: AclCreateType,  // allow: Reaction作成許可 deny: 不許可 inherit: 親コンテナから引継
}

export type AclCreateType = "allow" | "deny" | "inherit";

type AclBaseItem = {
    create: boolean,
    read: boolean,
    update: boolean,
    delete: boolean,
    createTopic?: AclCreateType,      // allow: 話題作成許可     deny: 不許可 inherit: 親コンテナから引継
    createMessage?: AclCreateType,    // allow: 投稿作成許可     deny: 不許可 inherit: 親コンテナから引継
    createComment?: AclCreateType,    // allow: 湖面作成許可     deny: 不許可 inherit: 親コンテナから引継
    createReactions?: AclCreateType,  // allow: Reaction作成許可 deny: 不許可 inherit: 親コンテナから引継
}

type AclSettings = {
    refId: string,                  // 参照先ID(TopicID/MessageID/ComentID)
    allowGuest: boolean,            // true: ゲスト閲覧を許可する
    base?: {                            // ベース設定
        createTopic?: AclCreateType,      // allow: 話題作成許可     deny: 不許可 inherit: 親コンテナから引継
        createMessage?: AclCreateType,    // allow: 投稿作成許可     deny: 不許可 inherit: 親コンテナから引継
        createComment?: AclCreateType,    // allow: 湖面作成許可     deny: 不許可 inherit: 親コンテナから引継
        createReactions?: AclCreateType,  // allow: Reaction作成許可 deny: 不許可 inherit: 親コンテナから引継
    },
    items?: AclItem[],
}

export default class Acl {
    public readonly refId: string;       // 参照先トピックID(2022/03では参照先はTopicのみ)
    public readonly refIdType: RefidType;// TopicID(2022/03では固定)
    public readonly base: AclBaseItem;   // ベース設定
    public readonly guest: AclItem;      // ゲストの設定
    public readonly items: AclItem[];    // 個人やグループ、部署単位の設定

    private constructor( obj: AclSettings ) {
        this.refId = obj.refId;
        this.refIdType = RefidType.TOPIC_ID,

        // ベースは全許可。allow〜系はobj設定に沿う
        this.base = {
            create: true,
            read: true,
            update: true,
            delete: true,
            createTopic:    obj.base?.createTopic       ?? "inherit",
            createMessage:  obj.base?.createMessage     ?? "inherit",
            createComment:  obj.base?.createComment     ?? "inherit",
            createReactions:obj.base?.createReactions   ?? "inherit",
        }

        // ゲストの権限
        this.guest = {
            refId: GUEST_ROLE_TYPE,    // ゲストを表す
            refIdType: RefidType.ROLE_GROUP_ID,
            create: obj.allowGuest ? true : false,
            read: obj.allowGuest ? true : false,
            update: false,
            delete: false,
        }

        // 各ユーザー／部署／グループレベル設定は空
        this.items = obj.items || [];
    }

    /** ダミー用のACLを作成する */
    public static createDummy(): Acl { return Object.freeze( this.createByTopic( "", true, true, ) ); }

    /**
     * トピックについての設定
     * @allowGuest true: ゲストにも書き込みを許可する
     * @allowOthers true: 作成者以外にも書き込みを許可する
     * @items 既存のacl.items
     */
    public static createByTopic( topicId: string, allowGuest: boolean, allowWriteOthers: boolean = true, items?: AclItem[] ): Acl {
        const base: AclSettings['base'] = {
            createTopic:    this.normalizeCreateAcl( allowWriteOthers ),
            createMessage:  this.normalizeCreateAcl( allowWriteOthers ),
            createComment:  this.normalizeCreateAcl( allowWriteOthers ),
            createReactions:this.normalizeCreateAcl( allowWriteOthers ),
        }
        return new Acl( {
            refId: topicId,
            allowGuest: allowGuest,
            base: base,
            items: items,
        } );
    }

    /**
     * 
     * @param messageId 投稿ID
     * @param alowWriteAndLikeOtherSource コメント・いいねを許可するか
     * ALLOW:    コメント・いいねを許可(ゲストも含む)
     * USERONLY: コメント・いいねを許可(ゲストを除く)
     * DENY:     コメント・いいねを禁止
     */
    public static createByMessage( messageId: string, alowWriteAndLikeOtherSource: "ALLOW" | "USERONLY" | "DENY" ): Acl {
        const allowWriteOthers = alowWriteAndLikeOtherSource != "DENY";
        const allowGuest = alowWriteAndLikeOtherSource == "ALLOW";
        const base: AclSettings['base'] = {
            createTopic:    this.normalizeCreateAcl( allowWriteOthers ),
            createMessage:  this.normalizeCreateAcl( allowWriteOthers ),
            createComment:  this.normalizeCreateAcl( allowWriteOthers ),
            createReactions:this.normalizeCreateAcl( allowWriteOthers ),
        }
        return new Acl( {
            refId: messageId,
            allowGuest: allowGuest,
            base: base,
        } );
    }

    public static create( refId: string, allowWriteOthers?: boolean ): Acl {
        const base: AclSettings['base'] = {
            createTopic:    this.normalizeCreateAcl( allowWriteOthers ),
            createMessage:  this.normalizeCreateAcl( allowWriteOthers ),
            createComment:  this.normalizeCreateAcl( allowWriteOthers ),
            createReactions:this.normalizeCreateAcl( allowWriteOthers ),
        }
        return new Acl( {
            refId: refId,
            allowGuest: true,
            base: base,
        } );
    }

    /** DBからデータロード */
    public static createByDbAcl( obj: DbAcl ): Acl {
        const base: AclSettings['base'] = {
            createTopic:    this.normalizeCreateAcl( obj.base?.createTopic ),
            createMessage:  this.normalizeCreateAcl( obj.base?.createMessage ),
            createComment:  this.normalizeCreateAcl( obj.base?.createComment ),
            createReactions:this.normalizeCreateAcl( obj.base?.createReactions ),
        }
        return new Acl( {
            refId: obj.refId,
            allowGuest: obj.guest.read || false,    // 見せない方をデフォとする
            base: base,
        })
    }

    public static editAclItems( acl: Acl, items: AclItem[] ): Acl {
        return new Acl({
            refId: acl.refId,
            allowGuest: acl.guest.read,
            base: acl.base,
            items: items,
        })
    }

    public static clone( acl: Acl | DbAcl ) {
        if( acl instanceof Acl ) {
            return new Acl({
                refId: acl.refId,
                allowGuest: acl.guest.read,
                base: acl.base,
                items: acl.items,
            })
        } else {
            const base = {
                createTopic:        this.normalizeCreateAcl( acl.base.createTopic ),
                createMessage:      this.normalizeCreateAcl( acl.base.createMessage ),
                createComment:      this.normalizeCreateAcl( acl.base.createComment ),
                createReactions:    this.normalizeCreateAcl( acl.base.createReactions ),
            }
            const items = acl.items as AclItem[] || undefined;
            return new Acl({
                refId: acl.refId,
                allowGuest: acl.guest.read ?? true,
                base: base,
                items: items,
            })
        }

    }
    public clone(): Acl {
        return Acl.clone( this );
    }

    private static normalizeCreateAcl = ( entry: string | boolean | null | undefined ): AclCreateType => {
        if( entry === null || typeof entry === "undefined" ) return "inherit";
        if( typeof entry === "boolean" ) return entry ? "allow" : "deny";       // booleanの場合は allow or deny
        switch( entry ) {
            case "allow":       return entry;
            case "deny":        return entry;
            case "inherit":     return entry;
            default:            return "inherit";
        }
    }

    /* 話題のパラメータ */

    /* ゲストの閲覧を許可するか */
    public readTopicByGuest(): boolean {
        return this.guest.read;
    }

    /* 話題作成者以外の投稿を許可するか */
    public createMessageByOthers(): boolean {
        const createMessage = this.base.createMessage || "allow";
        return createMessage == "allow";
    }
}
