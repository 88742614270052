









































































































import { mixins } from "vue-class-component";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import './message-list-item.scss';
import IconPinned from './icon/IconPinned.vue';
import IconLike from './icon/IconLike.vue';
import ProfileIcon from "./ProfileIcon.vue";
import AttachmentImg from './AttachmentImg.vue';
import TextProcessMixin from "./mixin/TextProcessMixin";
import ClipboardCopyMixin from "./mixin/ClipboardCopyMixin";
import { CommentListItemData } from './CommentListItem.vue';
import CommentList from './CommentList.vue';
import { User, Acl } from "../model";
import moment from 'moment';
import sanitizeHTML from '../sanitize';
import { IconReaction, IconReactionType } from "../API";
import { ReactionController } from "../model/reaction-controller";
import { BPopover } from "bootstrap-vue";
import AclManager from "../model/acl-manager";
import { allowFeature } from "@/direct-app-config";
import { AttachmentFileTypes, AttachmentFileTypesDefault, AttachmentFileTypesNone } from "@/suppport-attachment-types";

Vue.prototype.$sanitize = sanitizeHTML;

export type MessageListItemData = {
    id: string,
    title: string,
    pinned: boolean,
    icon: string,
    owner: string,
    updatedAt: Date,
    message: string,
    photos: string[],
    comments: CommentListItemData[],
    search?: string,

    star?: IconReaction, // ★
    like?: IconReaction, // イイね！
}

@Component({
    mixins: [
        ClipboardCopyMixin, TextProcessMixin
    ],
    components: {
        IconPinned, IconLike, AttachmentImg, CommentList, ProfileIcon,
    }
})
export default class MessageListItem extends mixins( Vue, ClipboardCopyMixin, TextProcessMixin ){
    name: string = 'message-list-item';
    showCommentList: boolean = true; // 初期コメント[開]状態

    @Prop({ default: '', required: true })
    readonly id!: string;

    @Prop({ default: 'タイトル', required: true })
    readonly title!: string;

    @Prop({ default: false })
    readonly pinned!: boolean;

    @Prop({ default: 'about:blank' })
    readonly icon!: string;

    // cognito user-id
    @Prop({ default: '' })
    readonly owner!: string;

    @Prop({ default: () => new Date(), required: true })
    readonly updatedAt!: Date;

    @Prop({ default: '' })
    readonly message!: string;

    @Prop({ default: () => [] })
    readonly photos!: string[];

    @Prop({ default: () => [] })
    readonly comments!: CommentListItemData[];

    @Prop({ default: '' })
    readonly search?: string;

    @Prop({ required: true })
    readonly topicId!: string;

    // ユーザー情報
    @Prop({ default: () => [] }) readonly users!: User[]; // 組織内ユーザー情報
    @Prop({ default: () => ( User.createNotFoundUser() ) }) readonly viewer!: User; // ログインユーザ情報

    // Reaction関係
    @Prop({ default: () => undefined }) readonly star: IconReaction|undefined;
    @Prop({ default: () => undefined }) readonly like: IconReaction|undefined;

    // ファイル添付設定
    @Prop({ default: () => AttachmentFileTypesDefault }) readonly allow_attachment_type!: AttachmentFileTypes;

    // 添付可能か
    get allow_attachment(): boolean { return this.allow_attachment_type != AttachmentFileTypesNone; }

    @Prop({ default: () => Acl.createDummy() })
    readonly acl!: Acl;

    get classes (): { [selector: string]: boolean } {
        return {
            'message-list-item': true
        }
    }

    get commentSource (): CommentListItemData[] {
        if( !this.$store ) return [];
        const src = this.$store.getters["comments/get"]( this.topicId, this.id );
        return src || [];
    }

    get titleLabel (): string {
        return this.highLightStr(this.title, this.search);
    }

    // 作成者情報
    get contributor (): User { return this.findUser( this.owner ) }
    get contributorId(): string { return this.contributor.directId; }
    get contributorIcon(): string { return this.contributor.getProfileIconUrl(); }


    get timeStamp (): string {
        const dt = moment(this.updatedAt)
        if (moment().startOf('day') < dt) {
            return dt.format('HH:mm')
        } else if (moment().startOf('year') < dt) {
            return dt.format('MM/DD')
        } else {
            return dt.format('YYYY/MM/DD')
        }
    }
    get contents (): string {
        return this.highLightStr(this.message, this.search);
    }
    get likeStamp (): string {
        return `${this.likeCount}人 `
    }
    get commentLink (): string {
        const comments = this.commentSource.filter( comment => !( comment.deleted ) ); // 削除済みは無視する
        return `コメント ${comments.length}件`
    }

    get domainId(): string { return this.$store?.getters["domainId"] || ""; }
    getMessage() {
        return ( this.$store && "getMessage" in this.$store.getters )
                ? this.$store.getters["getMessage"]( this.domainId, this.topicId, this.id )
                : undefined
                ;
    }
    get editable(): boolean { return this.viewer.id == this.owner && AclManager.updateMessage( this.$store, this.getMessage() )}
    get deletable(): boolean { return this.viewer.id == this.owner && AclManager.deleteMessage( this.$store, this.getMessage() )}

    // Reaction関係
    get starFlag(): boolean { return this.star?.userIdList.includes( this.viewer.directId ) || false; }
    get likeFlag(): boolean { return this.like?.userIdList.includes( this.viewer.directId ) || false; }
    get likeCount(): number { return this.like?.userIdList.length || 0; }

    // 権限
    get commentAvailable(): boolean {
        if( allowFeature( "deny-edit", this.$store ) == false ) return true;    // 未対応組織なので許可
        return AclManager.getCreateCommentAcl( this.$store, this.domainId, this.topicId, this.id ) != "deny";
    }
    get reactionAvailable(): boolean {
        if( allowFeature( "deny-edit", this.$store ) == false ) return true;    // 未対応組織なので許可
        return AclManager.getCreateReactionAcl( this.$store, this.domainId, this.topicId, this.id ) != "deny";
    }

    created(): void {
        if( this.search ) { this.showCommentList = false; } // 検索時はコメント[閉]
    }

    findUser( ownerId: string ): User {
        const user = this.users.find( user => user.id == ownerId );
        return user ? user : User.createNotFoundUser( ownerId );
    }

    openPhotoStream(): void {
        this.$emit('openModal', this.id)
    }

    onEditMessage(): void {
        this.$emit('toEditMessage', this.id);
    }

    onDeleteMessage(): void {
        this.$emit('toDeleteMessage', this.id);
    }

    onCommentLinkClick(): void {
        this.$emit('onLinkClicked', this.id);
    }

    // Urlコピー
    onUrlCopyMessage(): void {
        ( this.$refs.popover as BPopover).$emit('close'); // Close
        const domainId = this.$store.getters["domainId"];
        const topicId = this.$store.getters["topicId"];
        this.onUrlCopy({ domainId: domainId, topicId: topicId, messageId: this.id, commentId: "" });
    }

    onErrorModalEvent(event: string): void {
        this.$root.$emit(event);
    }

    // Reactionのクリック
    onStarClick(): void { this.onReactionClicked( IconReactionType.FAVORITE, this.starFlag ); }
    onLikeClick(): void { this.onReactionClicked( IconReactionType.LIKE, this.likeFlag ); }

    // Reactionの設定処理
    private onReactionClicked( reactionType: IconReactionType, currentValue: boolean ) {
        const ctl = new ReactionController( this.$store );
        const msg = ctl.findMessage( this.id );
        ctl.onReactionClicked( msg, reactionType, this.viewer, currentValue );
    }

    // コメント開閉
    clickedLink(): void {
        this.showCommentList = !this.showCommentList;
    }

    // いいね一覧表示
    showFavoriteList(): void {
        const userIdList = this.like?.userIdList || [];
        const favoriteUsers = userIdList.map( userId => {
            const user = this.users.find( u => u.directId == userId );
            return user ? user : User.createNotFoundUser( userId );
        })
        this.$root.$emit( 'on-favorite-modal', { userList: favoriteUsers, messageId: this.id, commentId: '' });
    }

    // タイトル省略時のツールチップを出すかどうかを判定する
    onTooltipShow( bvEvent: Event ): void {
        const target = this.$refs[ 'message-title' ];
        if( target instanceof HTMLElement ) {
            // webkit-line-clamp で省略された時、offsetHeight < scrollHeigt になる
            const oHeight = target.offsetHeight;
            const sHeight = target.scrollHeight;
            if( oHeight < sHeight ) return;
        }
        bvEvent.preventDefault(); // 出さない
    }

}
