import { Attachment, AttachmentFactory } from "@/model";

export const OFFICE_FILE_DICTIONARY = {
    doc: "application/msword",                                                            
    docm: "application/vnd.ms-word.document.macroEnabled.12",                      
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xls: "application/vnd.ms-excel",
    xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    csv: "text/csv",
    ppt: "application/vnd.ms-powerpoint",
    pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
    pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation"
};

export const OFFICE_TYPE_LIST = Object.values(OFFICE_FILE_DICTIONARY);

// mimeTypeがOffice系列か判定する
export const judgeOffice = ( type: string ) => {
    return OFFICE_TYPE_LIST.some( mimeType => type == mimeType );
}

// 添付ファイルの種別判定
export const getFileType = (file: File | Attachment) => {
    const type = AttachmentFactory.isAttachment( file ) ? file.mime : file.type;
    if( type.startsWith("image/") ) {
        return "image";
    } else if( type.startsWith("video/") ) {
        return "video";
    } else if(type == "application/pdf"){
        return "pdf";
    } else if( judgeOffice(type) ) {
        return "office";
    } else {
        return AttachmentFactory.isAttachment( file ) ? "file" : "";
    }
}

// 添付ファイルの種別デフォルトアイコンを設定
export const getDefaultIcon = (file: File | Attachment) => {
    const type = getFileType(file);
    switch( type ) {
        case "image":
        case "video":
        case "pdf": {
            return `/img/icon/icon_file_${type}.svg`;
        }
        case "office": {
            const mimeType = AttachmentFactory.isAttachment( file ) ? file.mime : file.type;
            const entry = Object.entries(OFFICE_FILE_DICTIONARY).find( entry => mimeType === entry[1] );
            const key = entry ? entry[0] : "file";
            return `/img/icon/icon_file_${key}.svg`
        }
        default: {
            return "";
        }
    }
}