/**
 * 取得日: 2021/12/10
 * コード取得元: https://github.com/lisb/albero-client-web/blob/299f4da3c84afa3ac77a6bede3fce0b42a52052b/packages/home/src/ts/services/links-regexp.ts
 * 該当チケット: https://lisb.myjetbrains.com/youtrack/issue/dxflow-433
 */

const ALPHA = '[a-z]'
const DIGIT = '[0-9]'
const HEXDIG = '[0-9a-f]'

// Reserved Characters: https://tools.ietf.org/html/rfc3986#section-2.2
const SUB_DELIMS = `[!$&'()*+,;=]`

// Unreserved Characters: https://tools.ietf.org/html/rfc3986#section-2.3
const UNRESERVED = `(${ALPHA}|${DIGIT}|[-._~])`

// Percent-Encoding: https://tools.ietf.org/html/rfc3986#section-2.1
const PCT_ENCODED = `%${HEXDIG}${HEXDIG}`

// Path: https://tools.ietf.org/html/rfc3986#section-3.3
const PCHAR = `(${UNRESERVED}|${PCT_ENCODED}|${SUB_DELIMS}|[:@])`

const REG_NAME = `(${UNRESERVED}|${PCT_ENCODED}|${SUB_DELIMS})*`

const SEGMENT = `(${PCHAR})*`

const SEGMENT_NZ = `(${PCHAR})+`

const PATH_EMPTY = `` // zero characters

const PATH_ROOTLESS = `${SEGMENT_NZ}(/${SEGMENT})*` // begins with a segment

const PATH_ABSOLUTE = `/(${SEGMENT_NZ}(/${SEGMENT})*)?` // begins with "/" but not "//"

const PATH_ABEMPTY = `(/${SEGMENT})*` // begins with "/" or is empty

const FRAGMENT = `(${PCHAR}|[/?])*`

class LinkRegExp {
  private _ireporter: RegExp | undefined
  private _direct: RegExp | undefined

  get ireporter() {
    if (this._ireporter !== undefined) {
      return this._ireporter
    }
    // NOTE: SCHEME は i-reporter で使うものに限定
    const SCHEME = `(jp\\.co\\.cimtops\\.)?ireporter(\\.(openreport|createreport|setcluster|downloadreport|downloaddefinition))?`

    // NOTE: HIER_PART は AUTORITY 部のみ簡略化
    const HIER_PART = `(//${REG_NAME}${PATH_ABEMPTY}|${PATH_ABSOLUTE}|${PATH_ROOTLESS}|${PATH_EMPTY})`

    // NOTE: QUERY, FRAGMENTは省略
    const URI = `${SCHEME}:${HIER_PART}`

    this._ireporter = new RegExp(`\\b(${URI})`, 'gi')
    return this._ireporter
  }

  get direct() {
    if (this._direct !== undefined) {
      return this._direct
    }
    // NOTE: SCHEME は direct で使うものに限定
    const SCHEME = `(direct4b|direct4b-rc|direct4b-dev|direct4b-ep|logochat)`

    // NOTE: HIER_PART は AUTORITY 部のみ簡略化
    const HIER_PART = `(//${REG_NAME}${PATH_ABEMPTY}|${PATH_ABSOLUTE}|${PATH_ROOTLESS}|${PATH_EMPTY})`

    // NOTE: QUERY は省略
    const URI = `${SCHEME}:${HIER_PART}(#${FRAGMENT})?`

    this._direct = new RegExp(`\\b(${URI})`, 'gi')
    return this._direct
  }
}

export const linkRegExp = new LinkRegExp()