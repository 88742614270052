var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message-side-menu pt-4" },
    [
      _vm.creatable
        ? _c(
            "div",
            { staticClass: "mb-3 px-4" },
            [
              _c(
                "b-button",
                {
                  staticClass: "create-btn",
                  attrs: { variant: "outline-secondary my-auto" },
                  on: { click: _vm.onMessageCreateClicked }
                },
                [
                  _c("b-icon-plus", { staticClass: "text-left mr-2" }),
                  _c("span", { staticClass: "mr-2" }, [_vm._v("新規投稿作成")])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("search-form", { ref: "search", staticClass: "px-4" }),
      _c(
        "div",
        { staticClass: "back-to-topiclist mt-4 px-4", on: { click: _vm.back } },
        [_c("b-icon-chevron-left"), _vm._v("話題一覧")],
        1
      ),
      _c("topic-list", {
        attrs: {
          domainId: _vm.domainId,
          categories: _vm.categories,
          topicList: _vm.topicList,
          activeTopicId: _vm.topicId,
          users: _vm.users,
          me: _vm.me,
          categoryFilter: _vm.categoryFilter,
          fab: false,
          "in-sidemenu": true
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }