var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-input-group",
    { staticClass: "search-window-group" },
    [
      _c("b-icon-search", { staticClass: "search-icon" }),
      _c("b-button", { staticClass: "d-none", attrs: { type: "submit" } }),
      _c("b-form-input", {
        staticClass: "search-window",
        attrs: { type: "search", size: "sm", placeholder: _vm.placeholder },
        on: { change: _vm.onChange, update: _vm.onUpdate },
        nativeOn: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.onChange.apply(null, arguments)
          }
        },
        model: {
          value: _vm.text,
          callback: function($$v) {
            _vm.text = $$v
          },
          expression: "text"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }