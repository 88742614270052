var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "navbar-menu-solutions" } }, [
    _c(
      "div",
      { class: _vm.listClass(), attrs: { id: "navbar-solution-list" } },
      _vm._l(_vm.appLinkItemsRef, function(appLinkItem) {
        return _c("NavbarMenuSolutionItem", {
          key: appLinkItem.url,
          attrs: { appLinkItem: appLinkItem, "domain-id": _vm.domainId }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }