var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "グループ_225",
        "data-name": "グループ 225",
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 14 14"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "長方形_128",
            "data-name": "長方形 128",
            transform: "translate(8)",
            fill: "#10c2cf",
            stroke: "#10c2cf",
            "stroke-linejoin": "round",
            "stroke-width": "1",
            opacity: "0.499"
          }
        },
        [
          _c("rect", { attrs: { width: "6", height: "6", stroke: "none" } }),
          _c("rect", {
            attrs: { x: "0.5", y: "0.5", width: "5", height: "5", fill: "none" }
          })
        ]
      ),
      _c(
        "g",
        {
          attrs: {
            id: "長方形_129",
            "data-name": "長方形 129",
            fill: "#10c2cf",
            stroke: "#10c2cf",
            "stroke-linejoin": "round",
            "stroke-width": "1"
          }
        },
        [
          _c("rect", { attrs: { width: "6", height: "6", stroke: "none" } }),
          _c("rect", {
            attrs: { x: "0.5", y: "0.5", width: "5", height: "5", fill: "none" }
          })
        ]
      ),
      _c(
        "g",
        {
          attrs: {
            id: "長方形_130",
            "data-name": "長方形 130",
            transform: "translate(8 8)",
            fill: "#10c2cf",
            stroke: "#10c2cf",
            "stroke-linejoin": "round",
            "stroke-width": "1"
          }
        },
        [
          _c("rect", { attrs: { width: "6", height: "6", stroke: "none" } }),
          _c("rect", {
            attrs: { x: "0.5", y: "0.5", width: "5", height: "5", fill: "none" }
          })
        ]
      ),
      _c(
        "g",
        {
          attrs: {
            id: "長方形_131",
            "data-name": "長方形 131",
            transform: "translate(0 8)",
            fill: "#10c2cf",
            stroke: "#10c2cf",
            "stroke-linejoin": "round",
            "stroke-width": "1",
            opacity: "0.499"
          }
        },
        [
          _c("rect", { attrs: { width: "6", height: "6", stroke: "none" } }),
          _c("rect", {
            attrs: { x: "0.5", y: "0.5", width: "5", height: "5", fill: "none" }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }