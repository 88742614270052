




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

type Action = {
  message: string
  action: () => void
}

@Component({})
export default class ActionSheet extends Vue {
    show: boolean = false;
    actions: Action[] = [];

    // storybook用
    @Prop({ default: () => [] }) readonly externalActions!: Action[];
    @Watch("externalActions", { immediate: true })
    onChange() {
        this.actions.splice(0, this.actions.length);
        this.actions.push(...this.externalActions);
    }

    // storybook用
    @Prop({ default: false }) readonly storybook!: boolean;

    closeActionSheet() {
        this.show = false;
    }

    runAction(action: () => void) {
        action();
        this.show = false;
    }

    created(): void {
        if( this.storybook ) {
            this.show = true;
        }
        this.$root.$on('open-action-sheet', (actions: Action[]) => {
            this.actions.splice(0, this.actions.length);
            this.actions.push(...actions);
            this.show = true;
        });
    }

    beforeDestroy(): void {
        this.$root.$off('open-action-sheet');
    }
}
