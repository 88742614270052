<template>
<svg id="コンポーネント_73_2" data-name="コンポーネント 73 – 2" xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 42 42">
  <rect id="長方形_1311" data-name="長方形 1311" width="42" height="42" rx="8" fill="#2e2e2e" stroke="#000"/>
  <g id="グループ_926" data-name="グループ 926" transform="translate(-2369.5 -1653.5)">
    <line id="線_105" data-name="線 105" x2="15" y2="15" transform="translate(2383.5 1667.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
    <line id="線_106" data-name="線 106" x1="15" y2="15" transform="translate(2383.5 1667.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
  </g>
</svg>

</template>
<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'IconPhotostreamCloseButton'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  height: 24px;
}
</style>
