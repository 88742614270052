






































































import { mixins } from "vue-class-component";
import { Acl } from "../model";
import type { Attachment } from "../model";
import { Component, Prop, Vue } from "vue-property-decorator";
import MessageEdit from "./MessageEdit.vue";
import EditDiscardMixin from "./mixin/EditDiscardMixin";
import LoadingUpload from './loading/LoadingUpload.vue';
import { AttachmentFileTypes, AttachmentFileTypesDefault, AttachmentFileTypesNone } from "@/suppport-attachment-types";

@Component({
    mixins: [
        EditDiscardMixin
    ],
    components: {
        MessageEdit, LoadingUpload
    }
})
export default class MessageEditModal extends mixins( Vue, EditDiscardMixin ) {
    name: string = "message-edit-modal";
     /* アップローディング関係 */
    isUploading: boolean = false;
    loadingMsg: string = "";

    @Prop({ default: '', required: true })
    readonly id!: string;

    @Prop({ default: '', required: true })
    readonly type!: string;

    @Prop({ default: '' })
    readonly domainId!: string;

    @Prop({ default: '' })
    readonly topicId!: string;

    @Prop({ default: '' })
    readonly messageId!: string;

    @Prop({ default: '' })
    readonly title!: string;

    @Prop({ default: '' })
    readonly message!: string;

    @Prop({ default: [] })
    readonly photos!: Attachment[];

    @Prop({ default: () => Acl.createDummy() })
    readonly acl!: Acl;

    @Prop({ default: () => Acl.createDummy() })
    readonly topicAcl!: Acl;

    // Direct組織設定
    @Prop({ default: () => AttachmentFileTypesDefault }) readonly allow_attachment_type!: AttachmentFileTypes;

    // 添付可能か
    get allow_attachment(): boolean { return this.allow_attachment_type != AttachmentFileTypesNone; }

    /* 投稿処理 */
    onEdit(closeEvent: ()=>void): void {
        closeEvent();
    }

    /* 中断処理(モーダルが消えた際に) */
    onInterruption(param: { id: string, path: string }) : void {
        console.log("interruption process");
        this.$emit("interruptionViaModal", param);
    }

    onUploading(param: { isUploading: boolean, loadingMsg: string }): void {
        this.isUploading = param.isUploading;
        this.loadingMsg = param.loadingMsg;
    }
}
