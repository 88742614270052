import { LowerUser, LowerListItem } from "./lower-list-type";
import { Department } from "./group-uesr-list/Departments/values/Department";
import Acl, { AclItem, RefidType } from "../../model/acl";
import store from "../../store";
import { User } from "@/model";
import { DirectDomainMembersType } from "@/direct-restapi-types";

type ExceptionParam = {
    owner: boolean,
    operator: boolean,
    admin: boolean,
}

type DataType = {
    type: "Department",
    name: string,
    content: Department, // 部署の情報
    lowerUsers: LowerUser[], // 部署に所属するユーザ情報
} | {
    type: "User",
    name: string,
    content: LowerUser, // ユーザの情報
}
/** 
 * key: 部署/ユーザのID
 * value: データ(部署情報のみ「部署に所属するユーザ情報」保持)
 */
export type AclControllerDataDictionary = Map<string, DataType>;

/**
 * 部署・ユーザ情報からツリーを形成し、
 * ツリー構造を元にACL制御を行う
 */
export default class AclController {
    /**
     * 部署/ユーザの情報を格納
     * key: 部署/ユーザのID
     * value: データ(部署情報のみ「部署に所属するユーザ情報」保持)
     */
    public dict: AclControllerDataDictionary;

    /* 部署情報 */
    public departments: Department[];

    /** 
     * 拒否アイテム のデータ
     * key: 部署/ユーザのID
     * value: (選択したものか)
     */
    private denyIdMap: Map<string, boolean>;

    /** 
     * 選択したアイテム のデータ
     * key: 部署/ユーザのID
     * value: LowerListtItem 部署/ユーザデータ
     */
    private selectedIdMap: Map<string, LowerListItem>; 

    /**
     * 初期状態から設定されたデータ
     * key: 部署/ユーザのID
     * value: LowerListtItem 部署/ユーザデータ
     */
    private setListMap: Map<string, LowerListItem>;

    /** 
     * 既に権限設定済み のデータ
     * key: 部署/ユーザのID
     * value: LowerListtItem 部署/ユーザデータ
     */
    private originalListMap: Map<string, LowerListItem>;

    /**
     * 権限設定操作から除外するユーザデータ (常時許可) 
     * key: ユーザID
     * value: 除外理由のパラメータ(設定対象の作成者, 設定ダイアログ操作主, 管理者)
     */
    private exceptionUserMap: Map<string, ExceptionParam>;

    public constructor() {
        this.departments = [];
        this.dict = new Map();
        this.denyIdMap = new Map();
        this.selectedIdMap = new Map();
        this.setListMap = new Map();
        this.originalListMap = new Map();
        this.exceptionUserMap = new Map();
    }

    /**
     * **************************************
     * List変換
     */
    public getDenyIdList(): string[] {
        return [...this.denyIdMap.keys()];
    }

    // 許可ユーザIDリスト
    public getAllowUserIdList(): string[] {
        return [...this.dict.keys()].filter( id => {
            return !this.getDenyIdList().includes(id) && this.isUser(id);
        })
    }

    public getSelectedList(): LowerListItem[] {
        return [...this.selectedIdMap.values()];
    }

    public getSetList(): LowerListItem[] {
        return [...this.setListMap.values()];
    }

    /** ************************************* */

    /**
     * **************************************
     * 条件判定
     */
    public isDeny(id: string): boolean {
        return this.denyIdMap.has(id);
    }

    public isAllowMe(): boolean {
        const me = store.getters["users/me"] as User;
        return !this.isDeny(me.directId);
    }

    public isExcept(id: string): boolean {
        return !!this.exceptionUserMap.get(id);
    }

    private isUser(id: string): boolean {
        const data = this.dict.get(id);
        return data !== undefined && data.type === 'User';
    }
    /** ************************************* */

    public init(domainId: string, users: User[]|DirectDomainMembersType, departments: Department[], acl: Acl): void {
        this.denyIdMap = new Map();
        this.selectedIdMap = new Map();
        this.setListMap = new Map();
        this.originalListMap = new Map();

        this.departments = departments;
        const generated = AclController.generateDict(domainId, users, departments);
        this.dict = generated;
        
        // ACL反映
        this.parseAcl(acl);
    }

    // 編集リセット
    public reset(acl: Acl): void {
        this.denyIdMap = new Map();
        this.selectedIdMap = new Map();
        this.setListMap = new Map();
        this.originalListMap = new Map();
        this.parseAcl(acl);
    }

    public static generateDict(domainId: string, users: User[]|DirectDomainMembersType, departments: Department[]): AclControllerDataDictionary {
        let dict: AclControllerDataDictionary = new Map();
        // 部署情報セット
        dict = this.departmentProcess(departments, dict);
        // ユーザ情報セット
        if( Array.isArray( users ) ) {
            // userテーブル
            dict = this.userProcess(domainId, users, dict);
        } else {
            // domain members API
            dict = this.domainUserProcess(users, dict);
        }
        return dict;
    } 

    // 部署を辞書登録
    private static departmentProcess(departments: Department[], dict: AclControllerDataDictionary): AclControllerDataDictionary {
        const updated = dict;
        departments.map( dep => {
            updated.set( dep.departmentIdStr, { type: "Department", name: dep.name, content: dep, lowerUsers: [] } );
        })
        return updated;
    }

    // ユーザを辞書登録
    private static userProcess(domainId: string, users: User[], dict: AclControllerDataDictionary): AclControllerDataDictionary {
        const updated = dict;
        
        // domain-members APIの結果より前に実行された際は、meの情報を利用する
        if( store && !users.length ) {
            const me = store.getters["users/me"] as User;
            const departments = me.departments[domainId] || [];
            const lowerUser: LowerUser = {
                id: me.directId,
                name: me.name,
                departments: departments.length ? departments : undefined,
            }
            updated.set( me.directId, { type: "User", name: lowerUser.name, content: lowerUser } );
            departments.map( id => {
                if( updated.has(id) ) {
                    const data = updated.get(id);
                    if( data && data.type === "Department" && !data.lowerUsers.find( user => user.id === lowerUser.id ) ) {
                        updated.set(id, { type: "Department", name: data.name, content: data.content, lowerUsers: [...data.lowerUsers, lowerUser]} );
                    }
                }
            })
            return updated;
        } else {
            users.map( content => {
                const departments = content.departments[domainId] || [];
                const lowerUser: LowerUser = {
                    id: String(content.directId),
                    name: String(content.name),
                    departments: departments.length ? departments : undefined,
                };
                updated.set( String(content.directId), { type: "User", name: lowerUser.name, content: lowerUser } );
                departments.map( id => {
                    if( updated.has(id) ) {
                        const data = updated.get(id);
                        if( data && data.type === "Department" && !data.lowerUsers.find( user => user.id === lowerUser.id ) ) {
                            updated.set(id, { type: "Department", name: data.name, content: data.content, lowerUsers: [...data.lowerUsers, lowerUser]} );
                        }
                    }
                })
            })
            return updated;
        }
    }

    // 組織メンバーからユーザ辞書登録
    private static domainUserProcess(users: DirectDomainMembersType, dict: AclControllerDataDictionary): AclControllerDataDictionary {
        const updated = dict;
        
        users.contents.map( content => {
            const departments = content.departments || [];
            const lowerUser: LowerUser = {
                id: String(content.user_id_str),
                name: String(content.name),
                departments: departments.length ? departments : undefined,
            };
            updated.set( String( content.user_id_str ), { type: "User", name: lowerUser.name, content: lowerUser } );
            departments.map( id => {
                if( updated.has(id) ) {
                    const data = updated.get(id);
                    if( data && data.type === "Department" && !data.lowerUsers.find( user => user.id === lowerUser.id ) ) {
                        updated.set(id, { type: "Department", name: data.name, content: data.content, lowerUsers: [...data.lowerUsers, lowerUser]} );
                    }
                }
            })
        })
        return updated;
    }

    // 除外リストの編集
    public editExceptionUser(userId: string, param: ExceptionParam, reset?: boolean): void {
        const id = userId.replace(/[^0-9]/g, '');
        
        const currentParam = this.exceptionUserMap.get(id);
        if( !currentParam ) {
            this.exceptionUserMap.set(id, param);
        } else {
            if( reset ) {
                this.exceptionUserMap.delete(id);
            } else {
                const mergedParam: ExceptionParam = {
                    owner: param.owner || currentParam.owner,
                    operator: param.operator || currentParam.operator,
                    admin: param.admin || currentParam.admin,
                }
                this.exceptionUserMap.set(id, mergedParam);
            }
        }
    }

    // ACLを初期設定として反映させる
    public parseAcl( acl: Acl ): void {
        const items = acl.items;
        const aclItems: LowerListItem[] = items
            .filter( item => item.refIdType === "USER_ID" || item.refIdType === "DEPARTMENT_ID" )
            .map( item => {
                const depOrUser = this.dict.get(item.refId);
                if( depOrUser ) {
                    // 部署IDがrefIdのパターン // 2023/09/01 以前
                    const id = item.refId;
                    const type = item.refIdType === "DEPARTMENT_ID" ? "Department" : "User";
                    const isDeny = !item.read;
                    const name = depOrUser.name;
                    return {
                        id: id,
                        type: type,
                        name: name,
                        isDeny: isDeny
                    }
                } else {
                    // User_Defined_Key(定義されたUser_Defined_Key)がrefIdに利用された場合
                    const department = this.getDepartmentByUserDefinedKey(item.refId);
                    const id = department ? department.departmentIdStr : item.refId; 
                    const type = "Department";
                    const isDeny = !item.read;
                    const name = department ? department.name : "";
                    return {
                        id: id,
                        type: type,
                        name: name,
                        isDeny: isDeny
                    }
                }                
            });

        aclItems.forEach( item => {
            // 辞書参照で何にも当てはまらない設定は反映させない(削除)
            if( !item.name ) { return; }

            const action = !item.isDeny;
            this.syncLowerList(item, action, true);
            this.controllSetItem(item, true);
            this.originalListMap.set(item.id, item);
        });
    }

    // LowerListItem への型変換
    public convertLowerListItem(id: string): LowerListItem | undefined {
        const item = this.dict.get(id);
        if( item ) {
            return { id: id, type: item.type, name: item.name, isDeny: this.denyIdMap.has(id) }
        } else {
            return undefined;
        }
    }

    // 設定したACLをDBに保存する型へ変換する
    public generateAcl(): AclItem[] {
        return [...this.setListMap.values()].map( value => {
            let refId = '';
            if( value.type === "Department" ) {
                // 部署
                const userDefinedKey = this.getUserDefinedKeyByDeparmentId(value.id);
                // User_Defined_Keyが該当しない場合は、部署IDで保存
                refId = userDefinedKey ? userDefinedKey : value.id;
            } else {
                // ユーザ
                refId = value.id;
            }
            return {
                refId: refId,
                refIdType: value.type === "Department" ? RefidType.DEPARTMENT_ID : RefidType.USER_ID,
                create: false,
                read: !value.isDeny,
                update: false,
                delete: false,
            } as AclItem;
        });
    }

    /**
     * 拒否/許可 状態の更新
     * @param item 更新対象
     * @param action 「許可」:true「拒否」:false 
     * @param selected 更新対象が選択したもの自体であるか
     */
    public syncLowerList( item: LowerListItem, action: boolean, selected?: boolean ): void {
        if( item.type === "Department") {
            // 更新対象の部署
            const departmentIdStr = item.id;
            this.controllStatus( item.id, action, !!selected );
            this.controllSelectItem(item, selected);
            const depOrUser = this.dict.get(departmentIdStr);
            if( depOrUser && depOrUser.type === "Department" ) {
                // 部署に所属するユーザ一覧
                const belongUsers = depOrUser.lowerUsers || [];
                belongUsers.map( user => {
                    const lowerListitem: LowerListItem = { id: user.id, type: "User", name: user.name, isDeny: this.denyIdMap.has(user.id) };
                    this.syncLowerList( lowerListitem, action );
                })
                // 子部署一覧
                if( depOrUser.type === "Department" ) {
                    const department = depOrUser.content;
                    const childIdStrList = department.childIdStrList;
                    childIdStrList.forEach( id => {
                        const item = this.convertLowerListItem(id);
                        if( item ) {
                            this.syncLowerList( item, action );
                        }
                    })
                }
            }
        } else if( item.type === "User" ) {
            if( this.exceptionUserMap.get(item.id) ) {
                this.controllStatus( item.id, true, false );
                this.controllSelectItem(item, false);
                return;
            }
            // 更新対象のユーザ
            const user = this.dict.get(item.id);
            if( user && user.type === "User" ) {
                const departments = user.content.departments || [];
                // 「拒否」するとき、「所属する部署が1つでも許可されている」かつ「更新対象が選択されたものでない」時、「拒否」への変更は行わない
                // => 所属部署が1つでも「許可」状態であればユーザも「許可」を継承
                if( departments.some( depId => !this.denyIdMap.has(depId) ) && !action && !selected ) return;
                this.controllStatus( item.id, action, !!selected );
                this.controllSelectItem(item, selected);
            }
        }
    }

    /**
     * 選択状態のコントロール 
     * @param item 更新対象
     * @param selected 更新対象が選択したもの自体であるか
     */
    private controllSelectItem( item: LowerListItem, selected?: boolean ): void {
        const itemId = item.id;
        if( selected ) {
            // isDenyに更新がない場合はスキップ
            if( item.isDeny === this.denyIdMap.has(itemId) ) return;

            // 既に選択状態の時は、取り除く
            if( this.selectedIdMap.has(itemId) ) {
                this.selectedIdMap.delete(itemId);
                this.controllSetItem(item, selected);
            // 選択状態でない時は、最新の許可/拒否状態に更新したのち追加
            } else {
                const updated: LowerListItem = {
                    id: item.id,
                    type: item.type,
                    name: item.name,
                    isDeny: this.denyIdMap.has(itemId)
                };
                this.selectedIdMap.set(itemId, updated);
                this.controllSetItem(updated, selected);
            }
        } else {
            // 選択していないアイテムは全て、取り除く
            // (選択状態は上階層のものに常に上書き統合を行う)
            if( this.selectedIdMap.has(itemId) ) {
                this.selectedIdMap.delete(itemId);
            }

            const origin = this.originalListMap.get(itemId); 
            if( origin ) {
                const updated: LowerListItem = {
                    id: item.id,
                    type: item.type,
                    name: item.name,
                    isDeny: this.denyIdMap.has(itemId)
                };
                if( updated.isDeny !== origin.isDeny ) {
                    this.selectedIdMap.set(itemId, updated);
                }
            }
            this.controllSetItem(item, selected);
        }
    }

    /**
     * denyIdMapのコントロール
     * @param key
     * @param action 「許可」:true「拒否」:false 
     * @param selected 更新対象が選択したもの自体であるか
     */
    private controllStatus( key: string, action: boolean, selected?: boolean ): void {
        if( !action ) {
            // 拒否
            this.denyIdMap.set(key, !!selected);
        } else {
            // 許可
            if( this.denyIdMap.has(key) ) {
                this.denyIdMap.delete(key);
            }
        }
    }

    /**
     * ACL設定済みアイテムのコントロール
     * @param item 更新対象
     * @param selected 更新対象が選択したもの自体であるか
     */
    private controllSetItem( item: LowerListItem, selected?: boolean ): void {
        const updated: LowerListItem = {
            id: item.id,
            type: item.type,
            name: item.name,
            isDeny: this.denyIdMap.has(item.id)
        }
        if( selected ) {
            if( this.setListMap.has(updated.id) ) {
                this.setListMap.delete(updated.id);
            } else {
                this.setListMap.set(updated.id, updated);
            }
        } else {
            if( this.setListMap.has(updated.id) ) {
                this.setListMap.delete(updated.id);
            }
        }
    }

    // UserDefinedKeyを利用して部署を取得する
    private getDepartmentByUserDefinedKey(user_defined_key: string): Department | undefined {
        return this.departments.find( dep => user_defined_key === dep.user_defined_key );
    }

    // 部署IDを利用してUserDefinedKeyを取得する
    private getUserDefinedKeyByDeparmentId(deparmtentId: string): string | undefined {
        const department = this.departments.find( dep => deparmtentId === dep.departmentIdStr );
        return department ? ( department.user_defined_key || undefined ) : undefined;
    }

    /**
     * ***********************************************
     * 部署ツリー処理
     */

    // 選択された部署の下階層の部署取得
    public getLowerDepartments( department: Department ): LowerListItem[] {
        const childIdStrList = department.childIdStrList;
        const results: LowerListItem[] = [];
        childIdStrList.map( id => {
            // 部署と断定して進める
            const department = this.dict.get(id);
            if( department ) {
                const result: LowerListItem = { id: id, type: "Department", name: department.name, isDeny: this.denyIdMap.has(id) };
                Object.freeze( result );
                results.push( result );
            }
        });
        return results;
    }

    // 選択された部署に所属するユーザ取得
    public getLowerUsers( department: Department ): LowerListItem[] {
        const departmentId = department.departmentIdStr;
        const depOrUser = this.dict.get(departmentId);
        if( depOrUser && depOrUser.type === "Department" ) {
            return depOrUser.lowerUsers.map( user => {
                return { id: user.id, type: "User", name: user.name, isDeny: this.isDeny(user.id) };
            });
        } else {
            return [];
        }
    }

    // 選択された部署より下階層のユーザ全取得
    public getLowerAllUsers(selectDepartmentIdStr: string): LowerListItem[] {
        const index = this.departments.findIndex( department => department.departmentIdStr === selectDepartmentIdStr );
        if( index < 0 ) {
            const results = [...this.dict.entries()].filter( value => {
                return value[1].type === "User";
            }).map( value => {
                return this.convertLowerListItem(value[0]);
            });
            const filtered = results.filter( user => user !== undefined ) as LowerListItem[];
            return filtered;
        }
        const selected = this.departments[index];
        const lowerDepartmentIdList: string[] = [selected.departmentIdStr];
        const targets = this.departments.slice(index+1);
        for( const target of targets ) {
            if( target.depth > selected.depth ) {
                lowerDepartmentIdList.push(target.departmentIdStr);
            } else {
                break;
            }
        }

        const lowerAllUsers: Map<string, LowerUser> = new Map();
        lowerDepartmentIdList.map( id => {
            const dpeOrUser = this.dict.get(id);
            if( dpeOrUser && dpeOrUser.type === "Department" ) {
                const lowerUsers = dpeOrUser.lowerUsers || []; 
                lowerUsers.map( user => {
                    lowerAllUsers.set( user.id, user );
                });
            }  
        })

        const lowUsers = [...lowerAllUsers.values()];
        const results = lowUsers.map( user => {
            return this.convertLowerListItem(user.id);
        }).filter( user => user !== undefined ) as LowerListItem[];
        return results;
    }

    // 検索 text:検索文字, type: フィルタリング
    public search(text: string, type?: "Department" | "User"): LowerListItem[] {
        const data = [...this.dict.entries()].filter( value => new RegExp(text).test(value[1].name) && ( type ? ( value[1].type === type ) : true ) );
        const results = data.map( value => {
            return this.convertLowerListItem(value[0]);
        }).filter( res => res !== undefined ) as LowerListItem[];
        return results;
    }

    /** ************************************************* */
    
}