
























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({})
export default class Dropdown extends Vue {
    name = "dropdown";
    index: number = 0;

    // 外部からの指定
    @Prop({ default: 0 }) readonly activeIndex!: number;
    @Watch("activeIndex")
    onChange() {
        if( this.index !== this.activeIndex ) {
            this.index = this.activeIndex;
        }
    }

    @Prop({ default: [] }) readonly options!: string[];

    onClick(index: number): void {
        this.index = index;
        this.$emit('click', index);
    }

}
