// 許可するimage拡張子
export const ALLOW_IMAGE_EXTENSIONS = [".jpg", ".jpeg", ".png", ".gif"];

// ファイル添付ポリシー
export type AttachmentPolicy = "ALLOW" | "DENY" | "FOLLOW_DIRECT"
export const AttachmentPolicyDefault = "FOLLOW_DIRECT";

// 添付できるファイル種別設定
export type AttachmentFileTypes = {
    image: boolean,
    movie: boolean,
    pdf: boolean,
    office: boolean,
}
// 添付ファイル種別の初期設定
export const AttachmentFileTypesDefault: AttachmentFileTypes = {
    image: true, movie: true, pdf: true, office: true,
}
// 添付ファイル不可
export const AttachmentFileTypesNone: AttachmentFileTypes = {
    image: false, movie: false, pdf: false, office: false,
}
// 添付ファイル（画像のみ）
export const AttachmentFileTypesImage: AttachmentFileTypes = {
    image: true, movie: false, pdf: false, office: false,
}
// 添付ファイル（画像と動画のみ）
export const AttachmentFileTypesImageMovie: AttachmentFileTypes = {
    image: true, movie: true, pdf: false, office: false,
}
// 添付ファイル（画像と動画とボイスのみ）
export const AttachmentFileTypesImageMovieVoice: AttachmentFileTypes = {
    image: true, movie: true, pdf: false, office: false,
}

export type AttachmentDeviceTypes = {
    android: number,
    bot: number,
    desktop: number,
    ios: number,
    oidc_client: number,
    web: number,
}
export const DefaultAllowAttachmentTypeByClient: AttachmentDeviceTypes = {
    android: 1,
    bot: 1,
    desktop: 1,
    ios: 1,
    oidc_client: 1,
    web: 1,
}

// ファイルダウンロードポリシー
export type DownloadPolicy = "ALLOW" | "DENY" | "FOLLOW_DIRECT"
export const DownloadPolicyDefault = "DENY";

// direct側の組織ダウンロードデバイス設定
export type DownloadDeviceTypes = {
    web: boolean;       // web版のファイル保存設定
    ios: boolean;       // ios版のファイル保存設定
    android: boolean;   // android端末のファイル保存設定
    desktop: boolean;   // desktopアプリ版のファイル保存設定
}
export const DownloadDeviceTypesDefault: DownloadDeviceTypes = {
    web: true,
    ios: true,
    android: true,
    desktop: true,
}
export const DownloadDeviceTypesNone: DownloadDeviceTypes = {
    web: false,
    ios: false,
    android: false,
    desktop: false,
}
