











import { Component, Vue } from "vue-property-decorator";
import { NavigationGuardNext, Route } from "vue-router";
import store from "../store";

Component.registerHooks([
  'beforeRouteEnter',
])
@Component({})
export default class DomainSelect extends Vue {
    name: string = 'domain-select';

    get isMobile(): boolean {
        return this.$store.getters["isMobile"];
    }

    beforeRouteEnter( to: Route, from: Route, next: NavigationGuardNext ): void {
        // パラメータのリセット
        store.dispatch("setTopicId", "");
        store.dispatch("setMessageId", "");

        const to_domainId = to.params.domainId || ( typeof to.query.domain_id == "string" ? to.query.domain_id : "" );
        store.dispatch('fetchData', { domainId: to_domainId, prevDomainId: from.params.domainId  } )
        next();
    }
}
