











import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class DomainSelect extends Vue {
    name: string = 'domain-select';

    get isMobile(): boolean {
        return this.$store.getters["isMobile"];
    }
}
