

































import "../icomoon/style.css";
import { Department } from './values/Department'
import {
  VK_UP,
  VK_DOWN,
  VK_LEFT,
  VK_RIGHT
} from './values/VirtualKey'
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class DepartmentsTreeNode extends Vue {
  name: string = 'departments-tree-node';
  clickCancel: number = 0;

  @Prop({ required: true }) readonly department!: Department;
  @Prop({ required: true }) readonly selectedDepartmentIdStr!: string;
  @Prop({ required: true }) readonly nextFocusHolder!: string;
  @Prop({ default: () => [] }) readonly denyIdList!: string[];

  get isLeaf(): boolean {
    return false;
  }

  get isDeny(): boolean {
    return this.denyIdList.includes(this.department.departmentIdStr);
  }

  expandDepartment(): void {
    this.$emit('expandDepartment', this.department.departmentIdStr)
  }

  collapseDepartment(): void {
    this.$emit('collapseDepartment', this.department.departmentIdStr)
  }

  selectDepartment(): void {
    this.$emit('selectDepartment', this.department.departmentIdStr)
  }

  onKeydown(event: KeyboardEvent): void {
    // Note: event.code はIE11非対応
    switch (event.keyCode) {
      case VK_RIGHT:
        if (!this.department.opened) {
          event.preventDefault();
          this.expandDepartment();
        }
        break
      case VK_LEFT:
        if (this.department.opened) {
          event.preventDefault();
          this.collapseDepartment();
        }
        break
      case VK_DOWN:
        event.preventDefault()
        this.$emit('focusNext');
        break
      case VK_UP:
        event.preventDefault()
        this.$emit('focusPrev')
        break
    }
  }

  onKeypress(): void {
    this.selectDepartment();
  }

  onDblclick(): void {
    this.clickCancel = 2
  }

  onClick(): void {
    window.setTimeout(() => {
      if (this.clickCancel <= 0) {
        // シングルクリック処理
        this.selectDepartment();
        return
      }
      if (this.clickCancel === 2) {
        // ダブルクリック処理
        if (this.department.opened) {
          this.collapseDepartment()
        } else {
          this.expandDepartment()
        }
      }
      this.clickCancel = this.clickCancel - 1
    }, 150)
  }
  onBlur(): void {
    this.$emit('blur');
  }
}

// 要注意: Haxe 経由での JQuery からのDOM変更が残っている
