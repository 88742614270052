import { Store } from "vuex";
import { PRODUCT_STAGE } from "./aws-config";
import Vue, { PluginObject } from "vue";
import { Solution } from "./model";

/** direct 2.0 アプリ名の一覧 */
export enum AppKeys {
    TALK = "talk",
    TASK = "task",
    SCHEDULE = "schedule",
    FORUM = "forum",
    AUTO = "auto",              // 自動判定
}

export type FeatureName = string;

const hosts: Record<string, Record<string, string >> = {
    DEV: {
        talk:       "https://directdev.feel-on.com",
        task:       "https://task.dev.direct4b.app",
        schedule:   "https://schedule.dev.direct4b.app",
        forum:      "https://forum.dev.direct4b.app",
    },
    STG: {
        talk:       "https://direct.feel-on.com",
        task:       "https://task.staging.direct4b.app",
        schedule:   "https://schedule.staging.direct4b.app",
        forum:      "https://forum.staging.direct4b.app",
    },
    PRD: {
        talk:       "https://direct4b.com",
        task:       "https://task.direct4b.app",
        schedule:   "https://schedule.direct4b.app",
        forum:      "https://forum.direct4b.app",
    },
}

const url = ( appName: AppKeys, domainId: string|undefined, query: string|undefined, solution?: Solution ): string => {
    const appHosts = hosts[ PRODUCT_STAGE ];
    const q = query ? `?${query}`: "";

    if( domainId ) {

        // トークだけ先処理
        if( appName == "talk" ) {
            const host = appHosts[ appName ];
            const _url = new URL( `/home#/${domainId}/talks`, host )
            return _url.toString();
        }

        // task/schedule/forum 判定
        const key = solution?.unique_key || appName;
        if( key == "task" || key == "schedule" || key == "forum" ) {
            const host = appHosts[ key ];
            const _url = new URL( `${domainId}/${q}`, host )
            return _url.toString();
        } else {
            const _url = new URL( `?domain_id=${domainId}&${query}`, solution?.url )
            return _url.toString();
        }
    } else {
        // トークだけ先処理
        if( appName == "talk" ) {
            const host = appHosts[ appName ];
            const _url = new URL( `/home#/`, host )
            return _url.toString();
        }

        // task/schedule/forum 判定
        const key = solution?.unique_key || appName;
        if( key == "task" || key == "schedule" || key == "forum" ) {
            const host = appHosts[ key ];
            const _url = new URL( `${q}`, host )
            return _url.toString();
        } else {
            const _url = new URL( `${query}`, solution?.url )
            return _url.toString();
        }
    }
}

// 指定されたアプリが有効かどうかを返す
export function appAvailable( app: AppKeys, store: Store<any> ): boolean {
    switch( app ) {
        case AppKeys.TALK:
        case AppKeys.FORUM:
            // direct と 掲示板 はいつでも有効とする
            return true;
        default: {
            const appKeys = store?.getters["domains/appKeys"] as AppKeys[] || [];
            return 0 <= appKeys.findIndex( key => key == app );
        }
    }
}

export function directAppUrl( appName: AppKeys, domainId: string|undefined, store: Store<any>, solution?: Solution ): string {
    const fromQuery = store ? store.getters["fromQuery"] : "";
    return url( appName, domainId, fromQuery, solution );
}

/** true: 話題作成OK */
export function allowCreateTopic( domainId: string, $store: Store<any> ): boolean {
    // @TODO: サーバ側で判定

    if( $store.getters["domains/isPro"] == true ) return true;

    // 話題全体数を取得
    const length = $store.getters["topics/getAllTopicsLength"]( domainId );
    return length < 5;
}

/** true: 投稿作成OK */
export function allowCreateMessage( domainId: string, topicId: string, $store: Store<any> ): boolean {
    // @TODO: サーバ側で判定

    if( $store.getters["domains/isPro"] == true ) return true;

    // 現在存在する投稿のみ取得
    const exists = true;
    const messages = $store.getters["messages/get"]( domainId, topicId, exists ) || [];
    return messages.length < 10;
}

/**
 * 指定されたキーワードの機能が有効かどうかを返す
 * keyword が機能管理リストに載っていない場合は true を返します
 * ※管理外になっている → 全組織で有効とみなす
 *
 * keyword が機能管理リストに載っている場合は、現在の組織設定から結果を返します
 * 一般的な組織では、α／β／有償機能は使えない
 * α版が有効な組織だからといって、β版機能も有効にはなりません
 * αもβも両方使いたい組織では、direct運用ツールでαとβ両方有効にしてください
 *
 * 例：
 * "test1"機能はα版リリース状態
 * "test2"機能はβ版リリース状態
 * "test3"機能は一般リリース状態
 * "test4"機能は有料版組織にリリース状態
 * 組織Aはα有効、組織Bはβ有効、組織Cはαβ、組織Dは設定無し、組織Fは有償組織
 *
 * |組織名| α | β |一般|有償|   |test1|test2|test3|test4|
 * |------|---|---|----|----|---|-----|-----|-----|-----|
 * |組織A | O |   | O  |    |   |true |false|true |false|
 * |組織B |   | O | O  |    |   |false|true |true |false|
 * |組織C | O | O | O  |    |   |true |true |true |false|
 * |組織D |   |   | O  |    |   |false|false|true |false|
 * |組織F |   |   | O  | O  |   |false|false|true |true |
 *
 * @param keyword 機能名
 * @param $storeOrGeggers  Vuex Store または getters
 * @return true   機能が現在の組織で有効
 */
export function allowFeature( keyword: string, $storeOrGeggers: Store<any>|Record<string, any> ): boolean {
    if( !$storeOrGeggers ) return true;
    const getters = $storeOrGeggers instanceof Store ? $storeOrGeggers.getters : $storeOrGeggers;
    const allowFeature = getters["allowFeature"];

    // 機能表にない場合は有効
    if ( !allowFeature || !(keyword in allowFeature) ) return true;

    switch ( allowFeature[keyword] ) {
        case "pro":
            return getters["domains/isPro"];
        case "alpha":
            return getters["domains/isAlpha"];
        case "beta":
            return getters["domains/isBeta"];
        case "dev": // dev値指定の場合は無効
            return false;
        default:
            return true;
    }
}

export const AllowFeaturePlugin: PluginObject<never> = {
    install(vue: typeof Vue): void {
        vue.mixin({
            methods: {
                allowFeature(keyword: string): boolean {
                    return allowFeature(keyword, this.$store);
                }
            }
        })
    }
}

/**
 * ログイン／ログアウト処理時のパスか判定する
 * @param path パス
 * @returns true: ログイン／ログアウト処理時のパス
 */
export function isSpecialPath( path: string ): boolean {
    if( !path ) return true;
    if( path == "/" ) return true;
    if( /\/?login/.test(path) ) return true;
    if( /\/?logout/.test(path) ) return true;
    return false;
}
