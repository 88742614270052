var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-overlay", {
        attrs: {
          show: _vm.show,
          "no-wrap": "",
          "no-center": "",
          variant: "dark",
          opacity: "0.15",
          "z-index": "1021"
        },
        on: { click: _vm.closeActionSheet },
        scopedSlots: _vm._u([
          {
            key: "overlay",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    class: {
                      actionList: true
                    }
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "actionListSection" },
                      _vm._l(_vm.actions, function(action) {
                        return _c(
                          "li",
                          {
                            key: action.message,
                            staticClass: "action",
                            on: {
                              click: function($event) {
                                return _vm.runAction(action.action)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(action.message) + " ")]
                        )
                      }),
                      0
                    ),
                    _c("ul", { staticClass: "actionListSection" }, [
                      _c(
                        "li",
                        {
                          staticClass: "action",
                          on: { click: _vm.closeActionSheet }
                        },
                        [_vm._v("キャンセル")]
                      )
                    ])
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }