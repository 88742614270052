var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "コンポーネント_73_2",
        "data-name": "コンポーネント 73 – 2",
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 42 42"
      }
    },
    [
      _c("rect", {
        attrs: {
          id: "長方形_1311",
          "data-name": "長方形 1311",
          width: "42",
          height: "42",
          rx: "8",
          fill: "#2e2e2e",
          stroke: "#000"
        }
      }),
      _c(
        "g",
        {
          attrs: {
            id: "グループ_926",
            "data-name": "グループ 926",
            transform: "translate(-2369.5 -1653.5)"
          }
        },
        [
          _c("line", {
            attrs: {
              id: "線_105",
              "data-name": "線 105",
              x2: "15",
              y2: "15",
              transform: "translate(2383.5 1667.5)",
              fill: "none",
              stroke: "#fff",
              "stroke-linecap": "round",
              "stroke-width": "2"
            }
          }),
          _c("line", {
            attrs: {
              id: "線_106",
              "data-name": "線 106",
              x1: "15",
              y2: "15",
              transform: "translate(2383.5 1667.5)",
              fill: "none",
              stroke: "#fff",
              "stroke-linecap": "round",
              "stroke-width": "2"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }