import { Vue } from 'vue-property-decorator';
import { Mixin } from "vue-mixin-decorator";
import { NavigationGuardNext } from "vue-router";

@Mixin
export default class EditDiscardMixin extends Vue {

    /**
     * 編集状態フラグ(navigationGuard)の操作
     * @param editMode 編集状態(画面遷移をブロックするかのフラグ)
     *
     * 使用箇所:
     * タイトル, 本文, ファイル, カテゴリ等に変更があった時(初期から変わっている時はset true)
     * 完了, 保存ボタンを押した時(set false)
     */
    changeEditMode(editMode: boolean): void {
        const navigationGuard = this.$store?.getters["navigationGuard"];
        if(navigationGuard != editMode) this.$root.$emit('on-set-navigation-guard', editMode);
    }

    /**
     * 編集を破棄するかのアラートを表示させる
     * @param nextFunc アラートでOKを押した後の遷移または後処理
     *
     * 使用箇所:
     * キャンセル, close クリック
     */
    showEditDiscardAlert( nextFunc?: ()=>void ): void {
        const navigationGuard = this.$store?.getters["navigationGuard"];
        if( !navigationGuard ) {
            if(nextFunc) nextFunc();
        } else {
            this.$root.$emit("edit-discard-alert");
            this.$root.$emit('on-set-next-route-event', nextFunc );
        }
    }


    /**
     * 掲示板内ページ離脱時にアラートを表示させる
     * @param next
     *
     * 使用箇所:
     * 投稿[一覧/編集], 話題[一覧/編集]のbeforeRouteLeave
     */
    routeLeaveEvent( next: NavigationGuardNext ): void {
        const navigationGuard = this.$store?.getters["navigationGuard"];
        if( !navigationGuard ) {
            next();
        } else {
            this.$root.$emit("edit-discard-alert");
            this.$root.$emit('on-set-next-route-event', () => next() );
            next(false);
        }
    }

}
