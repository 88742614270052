import { Department } from "@/components/acl/group-uesr-list/Departments/values/Department";
import { Module } from "vuex";
import { State } from "vuex-class";
import { AppKeys } from "../direct-app-config";
import { IndexdDB } from "@/indexddb";
import { AttachmentPolicy, AttachmentFileTypes, DownloadPolicy, DownloadDeviceTypes, AttachmentFileTypesNone, AttachmentPolicyDefault, AttachmentFileTypesDefault, DownloadPolicyDefault, DefaultAllowAttachmentTypeByClient, DownloadDeviceTypesNone } from "@/suppport-attachment-types";
import { OS_TYPE, isDesktopApp, isMobile, isIos, isAndroid, isBrowserApp } from "@/mobile";
import DomainDao from "./domain-dao";
import { Domain, Solution } from "@/model";
import { AuthorityGroupPolicy, CreateTopicAuthorityGroupPolicyDefault, UpdateTopicAuthorityGroupPolicyDefault } from "@/support-acl-types";
import { fetchSolutionLinks } from "@/components/solutions";
import Vue from "vue";
import sentry from "@/sentry";
import { Domains } from "@/direct-access";

export type DomainRoleType = {
    role_id: number,
    type: number,
    name: string,
}; 

export interface IDomainSetting {
    // 話題 作成/更新 権限
    create_topic_authority_group_policy: AuthorityGroupPolicy;
    update_topic_authority_group_policy: AuthorityGroupPolicy;

    // ファイル添付設定
    allow_attachment_type: number;              // direct側の組織添付設定
    attachment_policy: AttachmentPolicy;        // 組織のファイル添付ポリシー
    attachment_file_types: AttachmentFileTypes; // 組織のファイル添付設定
    upload_path: string;       // アップロード先のパス,
    allow_attachment_type_by_client: {
        android: number,
        bot: number,
        desktop: number,
        ios: number,
        oidc_client: number,
        web: number,
    },

    // ダウンロード設定
    download_policy: DownloadPolicy;
    allow_save_attachments: DownloadDeviceTypes; // direct側のダウンロード設定
    download_device_types: DownloadDeviceTypes; // 組織のダウンロード設定

    // 基本設定
    domainId: string,          // 組織ID
    domainName: string;        // 組織名
    pro: boolean;              // true: 有償版 false: 無償版
    alpha: boolean;            // true: αリリース
    beta: boolean;             // true: βリリース
    appKeys: AppKeys[];        // 有効なアプリはエントリーされている
    maxFileSize: number;       // ファイルサイズ制限
    domainRole?: DomainRoleType;
    departments?: Department[]
    solutions: Solution[];
    directApps: Solution[];
}

type State = { domains: Domains, domainSettings: Record<string, IDomainSetting> };
type RootState = { domainId: string, device: string, invokerapp: string, allowFeature: Record<string, string> };

export type SetPayload = {
    allow_attachment_type: number;
    allow_attachment_type_by_client: {
        android: number,
        bot: number,
        desktop: number,
        ios: number,
        oidc_client: number,
        web: number,
    },
    create_topic_authority_group_policy?: AuthorityGroupPolicy;
    update_topic_authority_group_policy?: AuthorityGroupPolicy;
    attachment_policy?: AttachmentPolicy;
    attachment_file_types?: AttachmentFileTypes;
    upload_path?: string;
    allow_save_attachments: DownloadDeviceTypes;
    download_policy?: DownloadPolicy;
    download_device_types?: DownloadDeviceTypes;
    domainId: string;
    domainName: string;
    domainRole?: DomainRoleType;
    solution_ids?: (number|null)[];
    direct_app_keys?: AppKeys[];
    maxFileSize?: number;
    solutions?: Solution[];
    directApps?: Solution[];
};

export type SetAttachmentPolicyPayload = {
    domainId: string;
    attachmentPolicy: AttachmentPolicy;
    attachmentFileTypes: AttachmentFileTypes;
    downloadPolicy: DownloadPolicy;
}
export type SetDomainPolicyPayload = {
    domainId: string;
    createTopicAuthorityGroupPolicy: AuthorityGroupPolicy;
    updateTopicAuthorityGroupPolicy: AuthorityGroupPolicy;
    attachmentPolicy: AttachmentPolicy;
    attachmentFileTypes: AttachmentFileTypes;
    downloadPolicy: DownloadPolicy;
    downloadDeviceTypes: DownloadDeviceTypes;
}

async function setIndexedDB( state: State, domainId: string ): Promise<void> {
    const domainSetting = getDominSetting(state, domainId);
    if( domainSetting.departments ){
        await IndexdDB.set("DEPARTMENT", domainId, domainSetting.departments);
    }
}

const unitConversion = ( size: number ): string => {
    let conversion = size;
    if( Math.floor(conversion / 1000) < 1 ) return `${conversion}B`;
    conversion = Math.floor(conversion / 1000);
    if( Math.floor(conversion / 1000) < 1 ) return `${conversion}kB`;
    conversion = Math.floor(conversion / 1000);
    if( Math.floor(conversion / 1000) < 1 ) return `${conversion}MB`;
    conversion = Math.floor(conversion / 1000);
    return `${conversion}GB`;
}

export type ClientType = "ios"|"android"|"web"|"desktop"|"unknown";

const isClientType = ( device: string, invoker: string ): ClientType => {
    if( isMobile(device) ) {
        if( isIos(device) ) {
            return "ios";
        } else if( isAndroid(device) ) {
            return "android";
        } else {
            return "unknown";
        }
    } else {
        if( isDesktopApp(invoker) ) {
            return "desktop";
        } else if( isBrowserApp(invoker) ) {
            return "web";
        } else {
            return "unknown";
        }
    }
}

// 組織設定として、ダウンロードが許可されているかどうかを返す
const allowDownload = ( state: State, getters: any, rootState: RootState, clientType: ClientType ): boolean => {
    const domainId = rootState.domainId;
    const isMobile = () => clientType === "ios" || clientType === "android";
    if( allowFeature( "mobile-download", getters, rootState ) ) {
        // モバイル時ダウンロード許可
    } else {
        if( isMobile() ) return false; // モバイルは現時点ではダウンロード不可
    }
    const domainSetting = getDominSetting(state, domainId);
    switch( domainSetting.download_policy ) {
        case "ALLOW": {
            switch( clientType ) {
                case "ios": {
                    // directと掲示板の両方でiosが許可された時のみ許可
                    return domainSetting.download_device_types.ios && domainSetting.allow_save_attachments.ios == true;
                }
                case "android": {
                    // directと掲示板の両方でandroidが許可された時のみ許可
                    return domainSetting.download_device_types.android && domainSetting.allow_save_attachments.android == true;
                }
                case "web": {
                    return domainSetting.download_device_types.web;
                }
                case "desktop": {
                    return domainSetting.download_device_types.desktop;
                }
                default: {
                    // デバイスが不明なので、何か設定がオフなら不許可
                    const deviceKeys = Object.keys( domainSetting.allow_save_attachments ) as ( keyof DownloadDeviceTypes )[]
                    return deviceKeys.every( ( key ) => {
                        return domainSetting.allow_save_attachments[key] == true;
                    })
                }
            }
        }
        case "DENY": return false;
        case "FOLLOW_DIRECT": {
            // directに沿うパターン
            switch( clientType ) {
                case "ios": {
                    return domainSetting.allow_save_attachments.ios == true;
                }
                case "android": {
                    return domainSetting.allow_save_attachments.android == true;
                }
                case "web": {
                    return domainSetting.allow_save_attachments.web == true;
                }
                case "desktop": {
                    return domainSetting.allow_save_attachments.desktop == true;
                }
                default: {
                    // デバイスが不明なので、何か設定がオフなら不許可
                    const deviceKeys = Object.keys( domainSetting.allow_save_attachments ) as ( keyof DownloadDeviceTypes )[]
                    return deviceKeys.every( ( key ) => {
                        return domainSetting.allow_save_attachments[key] == true;
                    })
                }
            }
        }
        default: return false;
    }

    
}
const downloadTypeList = (state: State, getters: any, rootState: RootState ): string[] => {
    const device = rootState.device;
    const invoker = rootState.invokerapp;
    const clientType = isClientType(device, invoker);
    if( allowDownload( state, getters, rootState, clientType ) ) {
        return ['image','pdf','movie','office']
    } else {
        return []
    }
}

const DefaultDomainSetting: Readonly<IDomainSetting> = {
    create_topic_authority_group_policy: CreateTopicAuthorityGroupPolicyDefault,
    update_topic_authority_group_policy: UpdateTopicAuthorityGroupPolicyDefault,
    allow_attachment_type: 1, // directのファイル添付設定
    allow_attachment_type_by_client: DefaultAllowAttachmentTypeByClient,
    attachment_policy: AttachmentPolicyDefault,
    attachment_file_types: AttachmentFileTypesDefault,
    upload_path: 'reads', // reads: ウイルスチェックを挟まない, uploads: ウイルスチェックを挟む
    allow_save_attachments: { web: true, ios: true, android: true, desktop: true }, // directの添付ファイル保存設定
    download_policy: DownloadPolicyDefault,
    download_device_types: DownloadDeviceTypesNone,
    domainId: '',
    domainName: '',
    domainRole: undefined,
    departments: undefined,
    pro: false,             //!< true: 有償版 false: 無償版
    alpha: false,           //!< true: αリリース
    beta: false,            //!< true: βリリース
    appKeys: [],
    maxFileSize: 10000000, // 初期値は10MB
    solutions: [],
    directApps: [],
}

// domainSettingを返す(該当するdomainIdがない場合はdefault値を返す)
const getDominSetting = (state: State, domainId: string): IDomainSetting => {
    const domainSetting = state.domainSettings[domainId];
    return domainSetting ?? DefaultDomainSetting;
}

// allowFeature の機能管理
// ※ direct-app-config.ts 関数allowFeatureはdomains-storeへのアクセスがあり、アクセスの仕方が蛇足になるので簡単にした処理を改めて定義
const allowFeature = ( keyword: string, getters: any, rootState: RootState ): boolean => {
    const allowFeature = rootState.allowFeature;
    // 機能表にない場合は有効
    if ( !allowFeature || !(keyword in allowFeature) ) return true;

    switch ( allowFeature[keyword] ) {
        case "pro":
            return getters["isPro"];
        case "alpha":
            return getters["isAlpha"];
        case "beta":
            return getters["isBeta"];
        case "dev": // dev値指定の場合は無効
            return false;
        default:
            return true;
    }
}

const domainModule: Module< State, RootState > = {
    namespaced: true,
    state: { domains: [], domainSettings: {} } as State,
    getters: {
        directDomains: ( state: State, getters: unknown, rootState: RootState ): Domains => { return state.domains },
        get: ( state: State, getters: unknown, rootState: RootState ): IDomainSetting => { return getDominSetting(state, rootState.domainId) },
        getCreateTopicAuthorityGroupPolicy: ( state: State, getters: unknown, rootState: RootState ): AuthorityGroupPolicy => getDominSetting(state, rootState.domainId).create_topic_authority_group_policy,
        getUpdateTopicAuthorityGroupPolicy: ( state: State, getters: unknown, rootState: RootState ): AuthorityGroupPolicy => getDominSetting(state, rootState.domainId).update_topic_authority_group_policy,
        getAttachmentPolicy: (state: State, getters: unknown, rootState: RootState) => getDominSetting(state, rootState.domainId).attachment_policy,
        getAttachmentFileTypes: (state: State, getters: unknown, rootState: RootState) => getDominSetting(state, rootState.domainId).attachment_file_types,
        getAllowAttachmentType: ( state: State, getters, rootState: RootState ): AttachmentFileTypes => {
            const domainSetting = getDominSetting(state, rootState.domainId);
            switch( domainSetting.attachment_policy ) {
                case "ALLOW": return domainSetting.attachment_file_types;
                case "DENY": return AttachmentFileTypesNone;
            }
            const device: string = rootState.device;
            const invoker: string = rootState.invokerapp;
            const client = isClientType(device, invoker);
            let allowAttachmentType = 1;
            // FeatureFlag 添付ファイル設定をclient別で判定
            if( allowFeature("attachments-setting-by-client", getters, rootState ) ) {
                switch( client ) {
                    case "ios": {
                        allowAttachmentType = domainSetting.allow_attachment_type_by_client.ios;
                        break;
                    }
                    case "android": {
                        allowAttachmentType = domainSetting.allow_attachment_type_by_client.android;
                        break;
                    }
                    case "web": {
                        allowAttachmentType = domainSetting.allow_attachment_type_by_client.web;
                        break;
                    }
                    case "desktop": {
                        allowAttachmentType = domainSetting.allow_attachment_type_by_client.desktop;
                        break;
                    }
                    default: {
                        allowAttachmentType = domainSetting.allow_attachment_type;
                        break;
                    }
                }
            } else {
                allowAttachmentType = domainSetting.allow_attachment_type;
            }
            
            switch( allowAttachmentType ) {
                case 0: return AttachmentFileTypesNone; // 不許可
                case 1: return { image: true,  movie: true,  pdf: true,  office: true, };  // 許可
                case 2: return { image: true,  movie: false, pdf: false, office: false, }; // 画像のみ
                case 3: return { image: true,  movie: true,  pdf: false, office: false, }; // 画像、動画のみ
                case 4: return { image: true,  movie: true,  pdf: false, office: false, }; // 画像、動画、ボイスメッセージのみ
                default:return { image: true,  movie: true,  pdf: true,  office: true,  }; // (設定不明は許可)
            }
        },
        getUploadPath: ( state: State, getters: unknown, rootState: RootState ): string => { return getDominSetting(state, rootState.domainId).upload_path },
        getDownloadPolicy: ( state: State, getters: unknown, rootState: RootState ) => getDominSetting(state, rootState.domainId).download_policy,
        getDownloadFileTypes: (state: State, getters: unknown, rootState: RootState) => getDominSetting(state, rootState.domainId).download_device_types,
        getAllowDownload: ( state: State, getters: unknown, rootState: RootState ): ReturnType<typeof allowDownload> => { 
            const device = rootState.device;
            const invoker = rootState.invokerapp;
            const clientType = isClientType(device, invoker);
            return allowDownload( state, getters, rootState, clientType ) 
        },
        getDownloadTypeList: ( state: State, getters: unknown, rootState: RootState ) => { return downloadTypeList(state, getters, rootState ) },
        getDomainId: ( state: State, getters: unknown, rootState: RootState ): string => { return getDominSetting(state, rootState.domainId).domainId },
        getDomainName: ( state: State, getters: unknown, rootState: RootState ): string => { return getDominSetting(state, rootState.domainId).domainName },
        getDomainRole: ( state: State, getters: unknown, rootState: RootState ): DomainRoleType|undefined => { return getDominSetting(state, rootState.domainId).domainRole },
        getDepartments: ( state: State, getters: unknown, rootState: RootState ): Department[]|undefined => { return getDominSetting(state, rootState.domainId).departments },
        isPro: ( state: State, getters: unknown, rootState: RootState ): boolean => { return getDominSetting(state, rootState.domainId).pro },
        isAlpha: ( state: State, getters: unknown, rootState: RootState ): boolean => { return getDominSetting(state, rootState.domainId).alpha },
        isBeta: ( state: State, getters: unknown, rootState: RootState ): boolean => { return getDominSetting(state, rootState.domainId).beta },
        appKeys: ( state: State, getters: unknown, rootState: RootState ): AppKeys[] => { return getDominSetting(state, rootState.domainId).appKeys },
        isEnable: ( state: State, getters: unknown, rootState: RootState ): ( ( domainId: string ) => boolean ) => {
            return ( domainId: string ) => {
                if( !domainId ) return false;
                const domainSetting = getDominSetting(state, domainId);
                const result = domainSetting.domainId == domainId
                        && 0 <= domainSetting.appKeys.findIndex( key => key == AppKeys.FORUM );
                return result;
            }
        },

        getClientType: ( state: State, getters: unknown, rootState: RootState ): ClientType => {
            const device: string = rootState.device;
            const invoker: string = rootState.invokerapp;
            return isClientType(device, invoker);
        },

        getAllowSaveAttachments: ( state: State, getters: unknown, rootState: RootState ): DownloadDeviceTypes => {
            // 掲示板の添付設定が禁止の場合、directの保存設定を全て禁止に上書き
            return {
                web: allowDownload(state, getters, rootState, "web"),
                ios: allowDownload(state, getters, rootState, "ios"),
                android: allowDownload(state, getters, rootState, "android"),
                desktop: allowDownload(state, getters, rootState, "desktop"),
            }
        },

        getMaxFileSize: ( state: State, getters: unknown, rootState: RootState ): number => { return getDominSetting(state, rootState.domainId).maxFileSize },
        // ファイルサイズのB表記
        getFileSizeUnit: ( state: State, getters: unknown, rootState: RootState ): string => { return unitConversion(getDominSetting(state, rootState.domainId).maxFileSize); },

        getSolutions: ( state: State ) => ( domainId: string) => getDominSetting(state, domainId).solutions || [],
        getDirectApps: ( state: State ) => ( domainId: string ) => getDominSetting(state, domainId).directApps || [],
    },
    mutations: {
        set( state, payload: SetPayload ): void {
            const domainId = payload.domainId;
            const domainSetting: IDomainSetting = {
                create_topic_authority_group_policy: payload.create_topic_authority_group_policy ?? CreateTopicAuthorityGroupPolicyDefault,
                update_topic_authority_group_policy: payload.update_topic_authority_group_policy ?? UpdateTopicAuthorityGroupPolicyDefault,
                allow_attachment_type: payload.allow_attachment_type,
                allow_attachment_type_by_client: payload.allow_attachment_type_by_client,
                attachment_policy: payload.attachment_policy ?? DefaultDomainSetting.attachment_policy,
                attachment_file_types: payload.attachment_file_types ?? DefaultDomainSetting.attachment_file_types,
                upload_path:  payload.upload_path ?? DefaultDomainSetting.upload_path,
                allow_save_attachments: payload.allow_save_attachments,
                download_policy: payload.download_policy ?? DefaultDomainSetting.download_policy,
                download_device_types: payload.download_device_types ?? DefaultDomainSetting.download_device_types,
                domainId: payload.domainId,
                domainName: payload.domainName,
                domainRole: payload.domainRole ?? DefaultDomainSetting.domainRole,
                pro: (payload.solution_ids || []).includes( 27 ),
                alpha: (payload.solution_ids || []).includes( 28 ),
                beta: (payload.solution_ids || []).includes( 29 ),
                appKeys: payload.direct_app_keys ?? DefaultDomainSetting.appKeys,
                maxFileSize: payload.maxFileSize ?? DefaultDomainSetting.maxFileSize,
                solutions: payload.solutions ?? DefaultDomainSetting.solutions,
                directApps: payload.directApps ?? DefaultDomainSetting.directApps,
            }

            const origin = getDominSetting(state, domainId);
            if( origin.domainId.length === 0 || origin.domainId.length > 0 && origin.domainId === domainId ) {
                Vue.set(state.domainSettings, domainId, domainSetting);
            }
        },

        setDomains( state, payload: Domains ): void {
            state.domains = payload;
        },

        setDepartments( state, payload: { domainId: string, departments: Department[]|undefined } ): void {
            if( payload.departments !== undefined ) {
                const origin = getDominSetting(state, payload.domainId);
                const domainSetting: IDomainSetting = { ...origin, departments: payload.departments };
                Vue.set(state.domainSettings, payload.domainId, domainSetting);
            }
        },

        // 複数組織分、部署データを一括でセットする処理
        // * mutaionを複数回呼び出すと処理が重くなるため別途用意
        setDepartmentsRecord( state, payload: Record<string, Department[]> ): void {
            for ( const [domainId, departments] of Object.entries(payload) ) {
                if( departments !== undefined ) {
                    const origin = getDominSetting(state, domainId);
                    const domainSetting: IDomainSetting = { ...origin, departments: departments };
                    Vue.set(state.domainSettings, domainId, domainSetting);
                }
            }
        },

        /** 組織設定項目を保存 */
        setDomainPolicy( state, payload: SetDomainPolicyPayload ): void {
            const create_topic_authority_group_policy = payload?.createTopicAuthorityGroupPolicy ?? CreateTopicAuthorityGroupPolicyDefault
            const update_topic_authority_group_policy = payload?.updateTopicAuthorityGroupPolicy ?? UpdateTopicAuthorityGroupPolicyDefault
            const attachment_policy = payload.attachmentPolicy ?? AttachmentPolicyDefault;
            const attachment_file_types = payload.attachmentFileTypes ?? AttachmentFileTypesDefault;
            const download_policy = payload.downloadPolicy ?? DownloadPolicyDefault;
            const download_device_types = payload.downloadDeviceTypes ?? DownloadDeviceTypesNone;
            const origin = getDominSetting(state, payload.domainId);
            const domainSetting: IDomainSetting = { ...origin, create_topic_authority_group_policy, update_topic_authority_group_policy, attachment_policy: attachment_policy, attachment_file_types: attachment_file_types, download_policy: download_policy, download_device_types: download_device_types };
            Vue.set(state.domainSettings, payload.domainId, domainSetting);
        },

        setSolutions(state: State, payload: { domainId: string, val: Solution[] } ): void {
            const sortedValue = payload.val.sort((l: Solution, r: Solution) => { return l.priority - r.priority })
            const origin = getDominSetting(state, payload.domainId);
            const domainSetting: IDomainSetting = { ...origin, solutions: sortedValue };
            Vue.set( state.domainSettings, payload.domainId, domainSetting );
        },
        setDirectApps(state: State, payload: { domainId: string, val: Solution[] } ): void {
            const sortedValue = payload.val.sort((l: Solution, r: Solution) => { return l.priority - r.priority })
            const origin = getDominSetting(state, payload.domainId);
            const domainSetting: IDomainSetting = { ...origin, directApps: sortedValue };
            Vue.set( state.domainSettings, payload.domainId, domainSetting );
        },
    },
    actions: {
        async fetchDomainSettings( { commit }, payload: { domainId: string } ): Promise<void> {
            const settings = await DomainDao.read( payload.domainId );
            commit( 'setDomainPolicy', settings )
        },

        /** directの組織設定を保存 */
        async setDomainSetting( { commit, dispatch, rootState }, payload: SetPayload ): Promise<void> {
            commit( 'set', payload );

            // 現在と同じ組織のみ掲示板の組織設定を取得
            if( rootState.domainId === payload.domainId ) {
                // その他の設定をDBリロード
                await dispatch( "fetchDomainSettings", { domainId: payload.domainId} )
            }
        },

        // /list-domains の結果を保存
        async setDomains({ commit, dispatch, state }, payload: Domains ): Promise<void> {
            commit( 'setDomains', payload );
        },

        async setDepartments( { commit, state, rootState }, payload: Department[]|undefined ): Promise<void> {
            commit( 'setDepartments', { domainId: rootState.domainId, departments: payload } );
            await setIndexedDB( state, rootState.domainId );
        },

        /** Solution情報を取得する */
        async fetchSolutions( { commit, state }, payload: { domainId: string } ): Promise<void> {
            const links = await fetchSolutionLinks( payload.domainId );
            if( links && typeof links == "object" ) {
                commit( 'setSolutions', { domainId: payload.domainId, val: links.solutions || [] } );
                commit( 'setDirectApps', { domainId: payload.domainId, val: links.direct_apps || null } );
            } else {
                console.error("API /list-api call error:%O", links)
                if( links != 401 ) {
                    sentry.sendSentryError( links );
                }
            }
        },

        async setDomainPolicy( { commit, state }, payload: SetDomainPolicyPayload ): Promise<void> {
            const item = Domain.create( {
                domainId: payload.domainId,
                createTopicAuthorityGroupPolicy: payload.createTopicAuthorityGroupPolicy,
                updateTopicAuthorityGroupPolicy: payload.updateTopicAuthorityGroupPolicy,
                attachmentPolicy: payload.attachmentPolicy,
                attachmentFileTypes: payload.attachmentFileTypes,
                downloadPolicy: payload.downloadPolicy,
                downloadDeviceTypes: payload.downloadDeviceTypes,
            } )
            const register = await DomainDao.upsert( item );
            if( register ) {
                commit( 'setDomainPolicy', register );
            }
        },

        async restore( { commit } ): Promise<void> {
            const departments = await IndexdDB.getAll("DEPARTMENT");
            if( departments ) {
                commit( 'setDepartmentsRecord', departments );
            }
        },
    }
}

export default domainModule;
