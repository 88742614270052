











































































import { mixins } from "vue-class-component";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Category, Topic, User } from "../model";
import ErrorModal from './ErrorModal.vue'
import Confirm from "./button/Confirm.vue";
import Cancel from "./button/Cancel.vue";
import CustomButton from "./button/CustomButon.vue";
import TextProcessMixin from './mixin/TextProcessMixin';
import EditDiscardMixin from './mixin/EditDiscardMixin';

// カテゴリータイトルの最大文字数
const CATEGORY_TITLE_MAX_LENGTH = 20;

@Component({
    mixins: [
        TextProcessMixin, EditDiscardMixin
    ],
    components: {
        Confirm, Cancel, CustomButton, ErrorModal,
    }
})
export default class CategoryTitleEdit extends mixins (Vue, TextProcessMixin, EditDiscardMixin ) {
    name: string = "category-title-edit";

    titleSource: string = "";
    errorTitles: string = ""; // カテゴリーがセットされた話題のタイトル郡

    @Prop({ default: '', required: true }) readonly id!: string;

    @Prop( { default: Category.createTemp(""), required: true }) readonly category!: Category;
    @Watch( 'category', { immediate: true } ) onCategoryChanged(value: Category): void { this.titleSource = value.title; }

    @Prop({ default: () => [], required: true }) readonly categories!: Category[];

    @Prop( { default: "" } ) readonly domainId!: string;

    // ログインユーザー情報
    @Prop({ default: () => User.createNotFoundUser() }) readonly me!: User;

    get duplicated(): boolean {
        if( this.category.title == this.titleSource ) return false;
        else { return this.categories.some( c => c.title == this.titleSource ); }
    }

    get disabled(): boolean {
        return this.titleSource.length == 0;
    }

    get disabledDelete(): boolean {
        return this.me.id != this.category.owner;
    }

    get titleState(): boolean {
        return 0 < this.getLength(this.titleSource) && this.getLength(this.titleSource) <= CATEGORY_TITLE_MAX_LENGTH && !this.duplicated;
    }

    get validTitleMessage(): string {
        if( this.duplicated ) return '既に同じものが登録されています';
        else { return this.getLength(this.titleSource)? `カテゴリー名は${CATEGORY_TITLE_MAX_LENGTH}文字以内です(${this.getLength(this.titleSource)}/${CATEGORY_TITLE_MAX_LENGTH})`:'カテゴリーを入力してください'; }
    }

    created(): void {
        this.titleSource = this.category.title;
    }

    onCancel(closeEvent: ()=>void): void {
        const close = () => {
            this.titleSource = this.category.title;
            closeEvent();
        }
        
        this.changeEditMode(this.titleSource !== this.category.title);
        this.showEditDiscardAlert(close);
    }

    onEdit(closeEvent: ()=>void): void {
        this.$root.$emit('edited-category', { id: this.category.id, title: this.titleSource });
        closeEvent();
    }

    onDelete(closeEvent: ()=>void): void {
        if(this.isSet()) {
            this.$bvModal.show("category-delete-error");
        } else {
            this.$root.$emit('deleted-category', { id: this.category.id });
            closeEvent();
        }
    }

    // このカテゴリーが話題に設定されているか
    isSet(): boolean {
        const topics = this.$store.getters["topics/get"](this.domainId, true) as Topic[]; // 削除されていない話題
        if( !topics ) return false;
        const setTopics = topics.filter( topic => {
            const category = topic.category;
            return category.id == this.category.id;
        });
        this.errorTitles = setTopics.map( t => { return t.title; }).join('\n');
        return setTopics.length > 0;
    }
}
