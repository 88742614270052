var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    attrs: {
      id: _vm.id,
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var close = ref.close
          return [
            _c("b-row", { staticClass: "d-flex m-1 justify-content-end" }, [
              _c(
                "div",
                {
                  on: {
                    click: function($event) {
                      return _vm.onCancel(close)
                    }
                  }
                },
                [_c("b-icon-x", { staticClass: "x-btn" })],
                1
              )
            ]),
            _c("b-row", { attrs: { "no-gutters": "" } }, [
              _c("div", { staticClass: "category-edit-title-label" }, [
                _vm._v("カテゴリー名編集")
              ])
            ]),
            _c(
              "b-row",
              { attrs: { "no-gutters": "" } },
              [
                _c("b-form-input", {
                  staticClass: "category-title-input",
                  attrs: {
                    input: "text",
                    "aria-describedby": "title-feedback",
                    required: ""
                  },
                  model: {
                    value: _vm.titleSource,
                    callback: function($$v) {
                      _vm.titleSource = $$v
                    },
                    expression: "titleSource"
                  }
                }),
                _c(
                  "b-form-text",
                  {
                    class: _vm.titleState ? "success" : "warning",
                    attrs: { id: "title-feedback" }
                  },
                  [_vm._v(" " + _vm._s(_vm.validTitleMessage) + " ")]
                )
              ],
              1
            ),
            _c(
              "b-row",
              {
                staticClass: "mt-5",
                attrs: { "align-h": "end", "no-gutters": "" }
              },
              [
                _c("cancel", {
                  staticClass: "col-3 mr-2",
                  attrs: { label: "キャンセル" },
                  on: {
                    cancel: function($event) {
                      return _vm.onCancel(close)
                    }
                  }
                }),
                _c("custom-button", {
                  staticClass: "col-3 mr-2",
                  attrs: { label: "削除", variant: "danger" },
                  on: {
                    confirm: function($event) {
                      return _vm.onDelete(close)
                    }
                  }
                }),
                _c("confirm", {
                  staticClass: "col-3",
                  attrs: { label: "保存", disabled: !_vm.titleState },
                  on: {
                    confirm: function($event) {
                      return _vm.onEdit(close)
                    }
                  }
                })
              ],
              1
            ),
            _c("b-modal", {
              attrs: {
                id: "category-delete-error",
                "hide-header": "",
                "hide-footer": "",
                centered: ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var ok = ref.ok
                      return [
                        _c(
                          "b-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c("div", [
                              _vm._v("下記話題で利用中のため削除できません")
                            ]),
                            _c("b-form-textarea", {
                              staticClass: "category-textarea",
                              attrs: { rows: "3", readonly: "" },
                              model: {
                                value: _vm.errorTitles,
                                callback: function($$v) {
                                  _vm.errorTitles = $$v
                                },
                                expression: "errorTitles"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          {
                            staticClass: "pt-2 flex-row-reverse",
                            attrs: { "no-gutters": "" }
                          },
                          [
                            _c("confirm", {
                              staticClass: "col-3",
                              attrs: { label: "OK" },
                              on: { confirm: ok }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }