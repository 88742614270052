var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    attrs: {
      id: _vm.id,
      "hide-footer": "",
      "hide-header": "",
      centered: "",
      "no-close-on-backdrop": "",
      "body-class": "position-relative"
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var close = ref.close
          return [
            _vm.isUploading
              ? _c("loading-upload", {
                  attrs: { msg: _vm.loadingMsg, fullscreen: false }
                })
              : _vm._e(),
            _c("b-row", { staticClass: "d-flex m-1 justify-content-end" }, [
              _c(
                "div",
                {
                  on: {
                    click: function($event) {
                      return _vm.showEditDiscardAlert(close)
                    }
                  }
                },
                [_c("b-icon-x", { staticClass: "x-btn" })],
                1
              )
            ]),
            _c("message-edit", {
              ref: "messageEdit",
              attrs: {
                type: _vm.type,
                domainId: _vm.domainId,
                topicId: _vm.topicId,
                messageId: _vm.messageId,
                title: _vm.title,
                message: _vm.message,
                photos: _vm.photos,
                allow_attachment_type: _vm.allow_attachment_type,
                acl: _vm.acl,
                topicAcl: _vm.topicAcl,
                isModal: true
              },
              on: {
                cancelToModal: close,
                confirmToModal: function($event) {
                  return _vm.onEdit(close)
                },
                interruption: _vm.onInterruption,
                uploading: _vm.onUploading
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }