import { User } from "@/model";
import { DB_STORE } from "./indexeddb_api";

const STORE_NAME = DB_STORE.USERS;
const DUMMY_NAME = 'DATA1';

export class UserIndexedDB {
    public static getStoreName(): DB_STORE {
        return STORE_NAME;
    }

    public static getDummyName(): string {
        return DUMMY_NAME;
    }

    // データを復元
    public static restore( users: Partial<User>[] ): User[] {
        const results: User[] = users.map( item => {
            const user = User.create(item);
            const domainRoles = item.domainRoles;
            if( domainRoles !== undefined ) {
                Object.keys(domainRoles).map(key => {
                    user.setDomainRole(key, domainRoles[key]);
                })
            }
            const departments = item.departments;
            if( departments !== undefined ) {
                Object.keys(departments).map( key => {
                    user.setDepartments(key, departments[key]);
                })
            }
            return user;
        })
        return results;
    }
}