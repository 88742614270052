// safari 13 が BigInt 対応していないため
import JSBI from "jsbi";
import GraphemeSplitter from "grapheme-splitter";

class Constants {
  public static defaultIconColors = [
      "#9bd182",
      "#6196ff",
      "#d0add8",
      "#ff7b7b",
      "#ffa9cc",
      "#bbbbbb",
      "#25c3e5",
      "#f99838",
      "#f4c811",
      "#59ad68",
      "#c9978d"
  ];
}

export default class DirectIconUtility {
  private static COLORS = Constants.defaultIconColors;

  /** アイコンのラベル文字を取得 */
  public static label( label: string ): string {
    return 0 < label.length ? new GraphemeSplitter().splitGraphemes(label)[0] : "";
  }

  /** アイコンの背景色を取得 */
  public static bgColor( id: string ): string {
    if( !id ) {
      return this.COLORS[0];
    } else {
      const remainder = JSBI.remainder( JSBI.BigInt(id), JSBI.BigInt( this.COLORS.length ) );
      const index = JSBI.toNumber( remainder );
      return this.COLORS[ index ];
    }
  }

  private static getBlankImage(): string {
    return "../assets/blank.gif";
  }

  public static cropImage( image: HTMLImageElement, frameWidth: number, frameHeight: number ): Record<string, string>|undefined {

    const src = image.src;
    if( src == this.getBlankImage() + "?cropped" ) {
      return;
    } else if( src == this.getBlankImage() ) {
      this.clearCrop( image );
      return;
    }

    const naturalSize = this.getNaturalSize( image );
    const scaleW = frameWidth / naturalSize.width;
    const scaleH = frameHeight / naturalSize.height;
    const scale = scaleW > scaleH ? scaleW : scaleH;

    const width = naturalSize.width * scale;
    const height = naturalSize.height * scale;

    let left = ( frameWidth - width ) / 2;
    let top = ( frameHeight - height ) / 2;

    if( left > 0 ) left = 0;
    if( top > 0 ) top = 0;

    return {
      width: `${width}px`,
      height: `${height}px`,
      "background-position": `${left}px ${top}px`,
      "background-size": `${width}px ${height}px`,
      "background-image": `url(${image.src})`,
    }
  }

  private static clearCrop( image: HTMLImageElement ) {
    image.style.width = "";
    image.style.height = "";
    image.style.backgroundPosition = "";
    image.style.backgroundSize = "";
    image.style.backgroundImage = "";
    image.style.backgroundRepeat = "";
  }

  private static getNaturalSize( image: HTMLImageElement & { runtimeStyle?: { width: string, height: string } } ) {
    let width, height;
    if( image.naturalWidth ) {
      // for Firefox, Safari, Chrome
      width = image.naturalWidth;
      height = image.naturalHeight;
    } else if( image.runtimeStyle ) {
      // for IE
      const run = image.runtimeStyle;
      const mem = { width: run.width, height: run.height };
      run.width = "auto";
      run.height = "auto";
      width = image.width;
      height = image.height;
      run.width = mem.width;
      run.height = mem.height;
    } else {
      // for Opera
      const mem = { w: image.width, h: image.height }; // keep original style
      image.removeAttribute("width");
      image.removeAttribute("height");
      width = image.width;
      height = image.height;
      image.width = mem.w;
      image.height = mem.h;
    }

    return { width: width, height: height };
  }

}
