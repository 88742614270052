import { Vue } from 'vue-property-decorator';
import { Mixin } from "vue-mixin-decorator";

export type RouterPath = {
    domainId: string,
    topicId: string,
    messageId: string,
    commentId: string,
}

@Mixin
export default class ClipboardCopyMixin extends Vue {
    name: string = "clipboard-copy-mixin";

    getTargetType(messageId: string, commentId: string): string {
        if( !messageId && !commentId ) {
            return "topic";
        } else if( messageId && !commentId ) {
            return "message";
        } else if( messageId && commentId ) {
            return "comment";
        } else {
            return "";
        }
    }

    getWritePath( path: RouterPath ): string {
        const type = this.getTargetType(path.messageId, path.commentId);
        switch( type ) {
            case "topic":
                return `${window.location.origin}/${path.domainId}/${path.topicId}`;
            case "message":
                return `${window.location.origin}/${path.domainId}/${path.topicId}/${path.messageId}`;
            case "comment":
                return `${window.location.origin}/${path.domainId}/${path.topicId}/${path.messageId}/${path.commentId}`;
            default:
                return "";
        }
    }

    enableUrlCopy(messageId: string, commentId: string): boolean {
        const type = this.getTargetType(messageId, commentId);
        switch( type ) {
            case "topic":
                return !!this.$store && !!navigator.clipboard;
            case "message":
                if( !this.$store || !navigator.clipboard ) return false;
                return !!this.$store.getters["domainId"] && !!this.$store.getters["topicId"];
            case "comment":
                if( !this.$store || !navigator.clipboard ) return false;
                return !!this.$store.getters["domainId"] && !!this.$store.getters["topicId"] && !!messageId;
            default:
                return false;
        }
    }

    onUrlCopy( path: RouterPath ) {
        if( !navigator.permissions || !navigator.clipboard ) {
            // Android + ios13.4 safariより下用
            // Android: navigator.permissions非対応
            // ios13.4より下: navigator.clipboard (navigator.permissionsはios safari非対応)
            const tempInput = document.createElement("input");
            tempInput.value = this.getWritePath(path);
            tempInput.setAttribute('style', "position: absolute; left: -1000px; top: -1000px;");
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);
            this.$bvToast.show('urlToast')
            return;
        } else if( !this.enableUrlCopy(path.messageId, path.commentId) ) {
            this.$root.$emit("failed-url-copy");
            return;
        }
        navigator.clipboard.writeText(
            this.getWritePath(path)
        ).then( _ => this.$bvToast.show('urlToast')
        ).catch( error => this.$root.$emit("failed-url-copy"))
    }
    
}