import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  BBadge, BBreadcrumb, BBreadcrumbItem, BButton, BButtonClose, BButtonGroup,
  BCard, BCol, BContainer, BDropdown, BDropdownDivider,
  BDropdownGroup, BDropdownItem, BDropdownText, BEmbed, BForm,
  BFormCheckbox, BFormCheckboxGroup, BFormFile, BFormGroup, BFormInput, BFormRadio,
  BFormRadioGroup, BFormSelect, BFormText, BFormTextarea, BIcon, BIconArrowLeft,
  BIconCaretDownFill, BIconChatLeft, BIconChevronDown, BIconChevronUp, BIconChevronLeft,
  BIconCircle, BIconDashCircleFill, BIconFolder, BIconInfoCircle, BIconPaperclip,
  BIconPersonCircle, BIconPlus, BIconSearch, BIconStar, BIconStarFill, BIconThreeDots,
  BIconX, BIconXCircleFill, BIconstack, BImg, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BListGroup, BListGroupItem, BModal, BNav, BNavForm, BNavItem, BNavItemDropdown,
  BNavText, BNavbar, BNavbarNav, BOverlay, BPagination, BPopover, BRow, BSpinner,
  BTable, BTableSimple, BTbody, BTd, BTh, BThead, BTr, BToast, BTooltip, ModalPlugin, ToastPlugin, VBModal, VBPopover,
} from "bootstrap-vue"
import sanitizeHTML from './sanitize'
import VueObserveVisibility from "vue-observe-visibility";
import { AllowFeaturePlugin } from "./direct-app-config";

import './assets/custom.scss'

// jaリソースのみを取り込む
import "moment/locale/ja";

import sentry from "./sentry";

// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)
Vue.component('BIcon', BIcon)
Vue.component('BIconstack', BIconstack)
Vue.component('BIconArrowLeft', BIconArrowLeft)
Vue.component('BIconCaretDownFill', BIconCaretDownFill)
Vue.component('BIconChatLeft', BIconChatLeft)
Vue.component('BIconChevronLeft', BIconChevronLeft)
Vue.component('BIconChevronDown', BIconChevronDown)
Vue.component('BIconChevronUp', BIconChevronUp)
Vue.component('BIconCircle', BIconCircle)
Vue.component('BIconDashCircleFill', BIconDashCircleFill)
Vue.component('BIconInfoCircle', BIconInfoCircle)
Vue.component('BIconPaperclip', BIconPaperclip)
Vue.component('BIconPlus', BIconPlus)
Vue.component('BIconSearch', BIconSearch)
Vue.component('BIconStar', BIconStar)
Vue.component('BIconStarFill', BIconStarFill)
Vue.component('BIconThreeDots', BIconThreeDots)
Vue.component('BIconX', BIconX)
Vue.component("BIconXCircleFill", BIconXCircleFill)
Vue.component("BIconFolder", BIconFolder)
Vue.component("BIconPersonCircle", BIconPersonCircle)

// component
Vue.component('b-badge', BBadge)
Vue.component('b-breadcrumb', BBreadcrumb)
Vue.component('b-breadcrumb-item', BBreadcrumbItem)
Vue.component('b-button', BButton)
Vue.component('b-button-close', BButtonClose)
Vue.component('b-button-group', BButtonGroup)
Vue.component('b-card', BCard)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-divider', BDropdownDivider)
Vue.component('b-dropdown-group', BDropdownGroup)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-dropdown-text', BDropdownText)
Vue.component('b-embed', BEmbed)
Vue.component('b-form', BForm)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
Vue.component('b-form-file', BFormFile)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-form-text', BFormText)
Vue.component('b-form-textarea', BFormTextarea)
Vue.component('b-form-radio', BFormRadio)
Vue.component('b-img', BImg)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-input-group-append', BInputGroupAppend)
Vue.component('b-input-group-prepend', BInputGroupPrepend)
Vue.component('b-list-group', BListGroup)
Vue.component('b-list-group-item', BListGroupItem)
Vue.component('b-modal', BModal)
Vue.component('b-nav', BNav)
Vue.component('b-nav-form', BNavForm)
Vue.component('b-nav-text', BNavText)
Vue.component('b-navbar', BNavbar)
Vue.component('b-navbar-nav', BNavbarNav)
Vue.component('b-nav-item', BNavItem)
Vue.component('b-nav-item-dropdown', BNavItemDropdown)
Vue.component('b-overlay', BOverlay)
Vue.component('b-pagination', BPagination)
Vue.component('b-popover', BPopover)
Vue.component('b-spiner', BSpinner)
Vue.component('b-table', BTable)
Vue.component('b-table-simple', BTableSimple)
Vue.component('b-tbody', BTbody)
Vue.component('b-td', BTd)
Vue.component('b-th', BTh)
Vue.component('b-thead', BThead)
Vue.component('b-tr', BTr)
Vue.component('b-toast', BToast)
Vue.component('b-tooltip', BTooltip)

// gridlayout
Vue.component('b-container', BContainer)
Vue.component('b-col', BCol)
Vue.component('b-row', BRow)

// directive
Vue.directive('b-modal', VBModal)
Vue.directive('b-popover', VBPopover)

Vue.use(ModalPlugin)
Vue.use(ToastPlugin)

// for LazyLoad
Vue.use(VueObserveVisibility);

// for vue-scroll-to
import VueScrollTo from "vue-scrollto";
Vue.use( VueScrollTo );

// for α／βの機能利用チェック
Vue.use( AllowFeaturePlugin );

Vue.config.productionTip = false
Vue.prototype.$sanitize = sanitizeHTML

sentry.init( router );

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
