


















import { Watch, Component, Prop, Vue } from "vue-property-decorator";
import { Category, User } from "../model";
import CategoryListItem from "./CategoryListItem.vue";

@Component({
    components: {
        CategoryListItem
    }
})
export default class CategoryList extends Vue {
    name: string = "category-list";
    selectedIndex: number = 0;
    users: User[] = []; // 組織内ユーザー情報

    @Prop( { default: "" } ) readonly domainId!: string;

    @Prop({ default: false, required: true }) readonly isMobile!: boolean;

    @Prop({ default: () => [], required: true }) readonly categories!: Category[];
    @Watch("categories", {immediate: true}) onChangedCategories(): void {
        if( !this.attachedCategories.length ) {
            this.selectedIndex = -1;
            return;
        }
        // 現在はカテゴリー1つの選択
        const selected = this.attachedCategories[0];
        const index = this.categories.findIndex( c => c.id == selected.id );
        this.selectedIndex = index;
    }

    // ログインユーザー情報
    @Prop({ default: () => User.createNotFoundUser() }) readonly me!: User;

    @Prop({ default: () => [] }) readonly attachedCategories!: Category[];
    @Watch("attachedCategories", { immediate: true }) onChanged(): void {
        if( !this.attachedCategories.length ) {
            this.selectedIndex = -1;
            return;
        }
        // 現在はカテゴリー1つの選択
        const selected = this.attachedCategories[0];
        const index = this.categories.findIndex( c => c.id == selected.id );
        this.selectedIndex = index;
    }

    selectedCategory(id: string): void {
        const index = this.categories.findIndex( c => c.id == id );
        this.selectedIndex = index;
        const category = this.categories[this.selectedIndex];
        if(category) { this.$emit("selected", {id: category.id}); }
    }

    findUser( ownerId: string ): User {
        const user = this.users.find( user => user.id == ownerId );
        return user ? user : User.createNotFoundUser( ownerId );
    }

    created(): void {
        const getByDomainId = this.$store?.getters["users/getByDomainId"];
        if(getByDomainId) { this.users = getByDomainId( this.domainId ) as User[]; }
    }
}
