var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "departments-area" }, [
    _c(
      "ul",
      { staticClass: "search-box" },
      [
        _c("DepartmentsSearchBox", {
          attrs: {
            localized: _vm.localized,
            disabled: _vm.disabled,
            "next-focus-holder": _vm.nextFocusHolder,
            keyword: _vm.keyword
          },
          on: {
            focusNext: _vm.focusNextFromSearchBox,
            focusPrev: _vm.focusPrevFromSearchBox,
            change: _vm.onChangeDepartmentsKeyword,
            blur: _vm.onBlur
          }
        })
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isTreeVisible,
            expression: "isTreeVisible"
          }
        ],
        ref: "tree",
        staticClass: "departments-tree",
        class: { "small-margin": _vm.useSmallMargin },
        on: { scroll: _vm.onScroll }
      },
      [
        _vm.isNotFiltered
          ? _c(
              "ul",
              { staticClass: "list-group list-for-not-filtered" },
              _vm._l(_vm.departments, function(department) {
                return _c(
                  "div",
                  { key: department.departmentIdStr },
                  [
                    _c("DepartmentsTreeNode", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: department.visibled,
                          expression: "department.visibled"
                        }
                      ],
                      attrs: {
                        department: department,
                        "selected-department-id-str":
                          _vm.selectedDepartmentIdStr,
                        "next-focus-holder": _vm.nextFocusHolder,
                        denyIdList: _vm.denyIdList
                      },
                      on: {
                        expandDepartment: _vm.expandDepartment,
                        collapseDepartment: _vm.collapseDepartment,
                        selectDepartment: _vm.selectDepartment,
                        blur: _vm.onBlur
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          : _c(
              "ul",
              { staticClass: "list-group list-for-filtered" },
              _vm._l(_vm.filteredDepartments, function(
                filteredDepartment,
                index
              ) {
                return _c("FilteredDepartmentsTreeNode", {
                  key: filteredDepartment.departmentIdStr,
                  attrs: {
                    "filtered-department": filteredDepartment,
                    "next-focus-holder": _vm.nextFocusHolder,
                    keyword: _vm.keyword,
                    "selected-department-id-str": _vm.selectedDepartmentIdStr,
                    denyIdList: _vm.denyIdList
                  },
                  on: {
                    selectDepartment: _vm.selectDepartment,
                    focusNext: function($event) {
                      return _vm.focusNext(index)
                    },
                    focusPrev: function($event) {
                      return _vm.focusPrev(index)
                    },
                    blur: _vm.onBlur
                  }
                })
              }),
              1
            )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }