var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "solution dropdown-item" }, [
    _c(
      "a",
      {
        attrs: {
          href: _vm.appLinkItem.url,
          target: _vm.isDirectApp() ? "direct-apps" : "direct-home",
          rel: !_vm.isDirectApp() ? "noopener" : undefined
        }
      },
      [
        _c("div", { staticClass: "image" }, [
          _c("img", {
            attrs: { src: _vm.iconUrl(), alt: _vm.appLinkItem.name },
            on: { error: _vm.onLoadingError }
          })
        ]),
        _c("div", {
          staticClass: "name",
          domProps: { textContent: _vm._s(_vm.appLinkItem.name) }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }