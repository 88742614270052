// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/sidepanel-selectlist-not-selected.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/sidepanel-selectlist-selected.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".category-content {\n  max-height: 80vh; }\n  .category-content .category-header .modal-title {\n    color: #888;\n    font-weight: 200; }\n  .category-content .category-body {\n    overflow: auto; }\n\n.category-selector {\n  -webkit-padding-start: 0px;\n          padding-inline-start: 0px;\n  list-style-type: none; }\n  .category-selector li {\n    position: relative;\n    padding-left: 2em;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: 1em;\n    background-position: left center;\n    height: 2em;\n    line-height: 2em;\n    font-size: 15pt;\n    font-weight: lighter;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap; }\n    .category-selector li.selected {\n      background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "); }\n    .category-selector li.category-all {\n      font-weight: normal; }\n    .category-selector li:before {\n      border-bottom: 1px solid #DDD;\n      content: \"\";\n      position: absolute;\n      display: inline-block;\n      width: calc( 100% - 2em);\n      bottom: 0px; }\n", ""]);
// Exports
module.exports = exports;
