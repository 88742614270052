




































import { BvModalEvent } from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Category } from "../model";
import "./category-modal-dialog.scss"

// カテゴリータイトルの最大文字数
const CATEGORY_TITLE_MAX_LENGTH = 20;

@Component({
    components: {
    }
})
export default class CategoryModalDialog extends Vue {
    name: string = 'category-modal-dailog';

    newCategory: Category = Category.createTemp("");
    filter: Category[] = [];

    @Prop( {} )
    id!: string;

    @Prop( { default: "表示するカテゴリー" } )
    title!: string;

    /** true: フッターを隠す */
    @Prop( { default: true } )
    hideFooter!: boolean;

    /** true: 複数選択モード */
    @Prop( { default: true } )
    selectMulti!: boolean;

    /** カテゴリーの新規作成UIを出す */
    @Prop( { default: false })
    withCreate!: boolean;

    /** true:「すべてのカテゴリー」選択肢を表示する */
    @Prop( { default: true })
    withAllCategory!: boolean;

    // カテゴリー一覧
    @Prop( { default: () => [] })
    categories!: Category[];

    // フィルタ設定されているカテゴリー一覧
    @Prop( { default: () => [] } )
    filter_init!: Category[];

    @Prop( { default: false } )
    show!: boolean;

    // 新規カテゴリー入力フォームの state
    get newCategoryInputState(): boolean | undefined {
        return this.isSelectedNewCategory() ? this.validate : undefined;
    }

    // 項目の選択状態
    // フィルタが空 → 「すべて」のみON
    // ファイルに何か設定 → 「すべて」OFF、該当項目がON
    selected( category: Category | "all" | "new" ): { [selector: string]: boolean } {
        if( this.filter.length === 0 ) {
            return { 'selected': category === 'all' }
        } else if( category == 'all' ) {
            return { 'selected': this.filter.length === 0 }
        } else if( category == 'new' ) {
            return { 'selected': this.isSelectedNewCategory() }
        } else {
            return { 'selected': 0 <= this.filter.findIndex( item => item.id == category.id ) }
        }
    }

    // カテゴリーの選択処理
    // 「すべて」が選択された場合、「すべて」のみになる → filter が空
    // その他の項目が選択された場合は、該当項目のON/OFF
    onClick( category: Category | "all" | "new" ): void {
        if( category === 'all' ) {
            if( 0 === this.filter.length ) {
                // 何もしない
            } else {
                this.filter.splice( 0, this.filter.length );
            }
            return;
        } else if( category === 'new' ) {
            // カテゴリーの新規作成 項目
            this._onClick( this.newCategory );
        } else {
            this._onClick( category );
        }
    }

    private _onClick( category: Category ): void {
        if( this.selectMulti ) {
            // 複数選択有り
            const index = this.filter.findIndex( item => item.id == category.id );
            if( 0 <= index ) {
                this.filter.splice( index, 1 );
            } else {
                this.filter.push( category );
            }
        } else {
            // 単一選択
            if( 0 < this.filter.length ) {
                const index = this.filter.findIndex( item => item.id == category.id );
                this.filter.splice( index, 1 );
                this.filter.push( category );
            } else {
                this.filter.push( category );
            }
        }
    }

    /** true: 新規作成カテゴリーが選択されている */
    private isSelectedNewCategory(): boolean {
        return 0 <= this.filter.findIndex( item => item.id == this.newCategory.id )
    }

    // ✗ボタンで閉じた時
    onHide(): void {
        if( this.hideFooter ) { // フッター非表示の場合はOKとみなす
            this.$emit('onCloseCategoryFilterDialog', this.filter );
        }
    }

    onOK( bvModalEvent:BvModalEvent ): void {
        // 新規作成の場合は、カテゴリー名としてOKかどうかチェックする
        if( this.validate == false ) {
            bvModalEvent.preventDefault();
            return;
        }

        this.$emit('onCloseCategoryFilterDialog', this.filter );
    }

    /** OK押して良い状態かどうかを返す */
    get validate(): boolean {
        if( this.isSelectedNewCategory() ) {
            const len = ( this.newCategory.title || "" ).trim().length;
            return 0 < len && len <= CATEGORY_TITLE_MAX_LENGTH;
        } else {
            return true;
        }
    }

    // Lifecycle

    created(): void {
        this.filter.push( ...this.filter_init )
    }

    mounted(): void {
        if( this.show ) {
            this.$bvModal.show(this.id)
        }
    }
}
