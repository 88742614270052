








import { Component, Prop, Vue } from "vue-property-decorator";

import Loading from "./Loading.vue";

@Component({
    components: { Loading, }
})
export default class LoadingAttachment extends Vue {
    name: string = 'loading-attachment';

    @Prop({default: true}) fullscreen!: boolean;

}
