var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    {
      staticClass: "domain-dropdown",
      attrs: {
        text: _vm.selectedDomainName,
        "toggle-class": "toggle-class",
        disabled: _vm.disabled,
        "no-caret": ""
      },
      on: {
        shown: function($event) {
          _vm.expanded = true
        },
        hidden: function($event) {
          _vm.expanded = false
        }
      },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function() {
            return [
              _c("span", { staticClass: "domain-dropdown-label" }, [
                _vm._v(_vm._s(_vm.selectedDomainName))
              ]),
              _vm.expanded
                ? _c("b-icon-chevron-up", {
                    staticClass: "domain-dropdown-toggle-icon"
                  })
                : _c("b-icon-chevron-down", {
                    staticClass: "domain-dropdown-toggle-icon"
                  })
            ]
          },
          proxy: true
        }
      ])
    },
    _vm._l(_vm.domains, function(domain) {
      return _c(
        "b-dropdown-item",
        {
          key: domain.domain_id,
          attrs: { disabled: _vm.isAppOn(domain) == false },
          on: {
            click: function($event) {
              return _vm.onDomainSelected(domain.domain_id_str)
            }
          }
        },
        [
          domain.domain_id_str == _vm.selectedDomainId
            ? _c("div", { staticClass: "selected-domain-label" }, [
                _vm._v(_vm._s(domain.domain_name))
              ])
            : _vm.isAppOn(domain)
            ? _c("div", [_vm._v(_vm._s(domain.domain_name))])
            : _c("div", { staticClass: "app-off" }, [
                _c("span", { staticClass: "app-off-label" }, [
                  _vm._v(_vm._s(domain.domain_name))
                ]),
                _c("span", { staticClass: "app-off-warning" }, [
                  _vm._v("掲示板の利用はありません")
                ])
              ])
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }