var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    attrs: {
      id: _vm.id,
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      size: "xl",
      scrollable: "",
      "dialog-class": "pc-adjust",
      "no-close-on-backdrop": ""
    },
    on: { show: _vm.resetParam },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var close = ref.close
          return [
            _vm.isMobile
              ? _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      { staticClass: "d-flex", attrs: { "no-gutters": "" } },
                      [
                        _c("div", { staticClass: "category-edit-title" }, [
                          _vm._v("カテゴリー設定")
                        ])
                      ]
                    ),
                    _c("category-list", {
                      attrs: {
                        categories: _vm.categorySource,
                        isMobile: _vm.isMobile,
                        attachedCategories: _vm.attachedCategories,
                        me: _vm.me,
                        domainId: _vm.domainId
                      },
                      on: { selected: _vm.selected }
                    }),
                    _c(
                      "b-row",
                      { staticClass: "pt-3", attrs: { "no-gutters": "" } },
                      [
                        _c("b-form-textarea", {
                          staticClass: "category-textarea",
                          attrs: {
                            rows: "3",
                            placeholder:
                              "追加するカテゴリー(改行区切りで複数入力可)"
                          },
                          model: {
                            value: _vm.categoriesStr,
                            callback: function($$v) {
                              _vm.categoriesStr = $$v
                            },
                            expression: "categoriesStr"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "pt-2", attrs: { "no-gutters": "" } },
                      [
                        _c("custom-button", {
                          attrs: { label: "カテゴリー追加" },
                          on: { confirm: _vm.addCategories }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      {
                        staticClass: "mt-5",
                        attrs: { "align-h": "between", "no-gutters": "" }
                      },
                      [
                        _c("cancel", {
                          staticClass: "col-5",
                          attrs: { label: "キャンセル" },
                          on: {
                            cancel: function($event) {
                              return _vm.showCancelAlert(close)
                            }
                          }
                        }),
                        _c("confirm", {
                          staticClass: "col-5",
                          attrs: { label: "保存" },
                          on: { confirm: _vm.onEditComplete }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      { staticClass: "d-flex m-1 justify-content-end" },
                      [
                        _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                return _vm.showCancelAlert(close)
                              }
                            }
                          },
                          [_c("b-icon-x", { staticClass: "x-btn" })],
                          1
                        )
                      ]
                    ),
                    _c(
                      "b-row",
                      { staticClass: "d-flex" },
                      [
                        _c(
                          "b-col",
                          { staticClass: "col-8 col-lg-7 col-xl-6" },
                          [
                            _c(
                              "b-row",
                              {
                                staticClass: "d-flex",
                                attrs: { "no-gutters": "" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "category-setting-label" },
                                  [_vm._v("カテゴリー設定")]
                                )
                              ]
                            ),
                            _c("category-list", {
                              attrs: {
                                categories: _vm.categorySource,
                                isMobile: _vm.isMobile,
                                attachedCategories: _vm.attachedCategories,
                                me: _vm.me,
                                domainId: _vm.domainId
                              },
                              on: { selected: _vm.selected }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "col-4 col-lg-5 col-xl-6" },
                          [
                            _c(
                              "b-row",
                              {
                                staticClass: "pt-4",
                                attrs: { "no-gutters": "" }
                              },
                              [
                                _c("b-form-textarea", {
                                  staticClass: "category-textarea",
                                  attrs: {
                                    rows: "5",
                                    placeholder:
                                      "追加するカテゴリー(改行区切りで複数入力可)"
                                  },
                                  model: {
                                    value: _vm.categoriesStr,
                                    callback: function($$v) {
                                      _vm.categoriesStr = $$v
                                    },
                                    expression: "categoriesStr"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              {
                                staticClass: "pt-2",
                                attrs: { "no-gutters": "" }
                              },
                              [
                                _c("custom-button", {
                                  attrs: { label: "カテゴリー追加" },
                                  on: { confirm: _vm.addCategories }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              {
                                staticClass: "mt-5",
                                attrs: {
                                  "align-h": "between",
                                  "no-gutters": ""
                                }
                              },
                              [
                                _c("cancel", {
                                  staticClass: "col-5",
                                  attrs: { label: "キャンセル" },
                                  on: {
                                    cancel: function($event) {
                                      return _vm.showCancelAlert(close)
                                    }
                                  }
                                }),
                                _c("confirm", {
                                  staticClass: "col-5",
                                  attrs: { label: "保存" },
                                  on: { confirm: _vm.onEditComplete }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
            _c("category-title-edit", {
              attrs: {
                id: "category-title-edit",
                category: _vm.editCategory,
                categories: _vm.categorySource,
                domainId: _vm.domainId,
                me: _vm.me
              }
            }),
            _c("b-modal", {
              attrs: {
                id: "category-add-alert",
                "hide-header": "",
                "hide-footer": "",
                centered: ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var ok = ref.ok
                      return [
                        _vm.duplicatedInput.length
                          ? _c(
                              "b-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c("div", [
                                  _vm._v("同じカテゴリーが入力されています")
                                ]),
                                _c("b-form-textarea", {
                                  staticClass: "category-textarea",
                                  attrs: { rows: "2", readonly: "" },
                                  model: {
                                    value: _vm.duplicatedInput,
                                    callback: function($$v) {
                                      _vm.duplicatedInput = $$v
                                    },
                                    expression: "duplicatedInput"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.duplicated.length
                          ? _c(
                              "b-row",
                              {
                                staticClass: "pt-2",
                                attrs: { "no-gutters": "" }
                              },
                              [
                                _c("div", [
                                  _vm._v("既に同じものが登録されています")
                                ]),
                                _c("b-form-textarea", {
                                  staticClass: "category-textarea",
                                  attrs: { rows: "2", readonly: "" },
                                  model: {
                                    value: _vm.duplicated,
                                    callback: function($$v) {
                                      _vm.duplicated = $$v
                                    },
                                    expression: "duplicated"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.overed
                          ? _c(
                              "b-row",
                              {
                                staticClass: "pt-2",
                                attrs: { "no-gutters": "" }
                              },
                              [
                                _c("div", [
                                  _vm._v(_vm._s(_vm.validTitleMessage))
                                ]),
                                _c("b-form-textarea", {
                                  staticClass: "category-textarea",
                                  attrs: { rows: "2", readonly: "" },
                                  model: {
                                    value: _vm.overed,
                                    callback: function($$v) {
                                      _vm.overed = $$v
                                    },
                                    expression: "overed"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-row",
                          {
                            staticClass: "pt-2 flex-row-reverse",
                            attrs: { "no-gutters": "" }
                          },
                          [
                            _c("confirm", {
                              staticClass: "col-3",
                              attrs: { label: "OK" },
                              on: { confirm: ok }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }