




























































import { Attachment } from '../model';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { DirectIdStr } from "../direct-utility";
import DirectIconUtility from "./direct-icon-utility";
import S3AccessUtility from './s3-acceess-utility';

type CssStyleObject = {
  [name: string]: string
}

enum Status {
    LOADING,    // ロード中
    LOADED,     // ロード終了
}

@Component({

})
export default class ProfileIcon extends Vue {
    name: string = "profile-icon";

    // image を S3上のリソースとして解決したもの
    // image が指定されない場合は ""
    // image に外部ファイルが指定された場合は、そのURL
    private s3image: string = "";

    // 初期はロード中
    private s3Status: Status = Status.LOADING;

    // 背景画像スタイル
    private bgImageStyleData: CssStyleObject | undefined = {};

    // true: 画像ファイルがロードされた false: まだロードされてない
    private bgImageLoaded: boolean = false;

    errorCounter = 10;      //!< ファイル読み込み時のエラーリトライ回数
    errorRetrySecond = 10;  //!< エラーリトライ回数の間隔(秒)
    retryedReload: boolean = false; // 画像取得のリトライ(一定インターバルによる取得)を行ったかのフラグ

    // v-observe-visibility の利用設定
    // １度だけ発火＆画面が100px以内になると実行
    vObserveVisibilityProps = {
        callback: this.visibilityChanged,
        once: true,
        intersection: {
            rootMargin: '100px',
        }
    }

    // 話題ID
    @Prop({ default: '' }) readonly topicId!: string;

    /** ユーザー名(この最初の１文字が描画に利用される) */
    @Prop({ default: '' }) readonly userName!: string;

    /** ユーザーID。アイコン無時の背景色算出用 */
    @Prop( { default: () => "0" } ) readonly id!: DirectIdStr;

    /** hover時のスタイリング処理を行う場合は true を指定する */
    @Prop( { default: false }) readonly useHoverStyle!: boolean;

    /**
     * 画像ファイル名
     * "" or "about:blank" : 画像指定無し
     * httpから始まる文字列: 外部リソース
     * その他              : S3上のリソース名
     */
    @Prop( { default: undefined } ) readonly image!: Attachment|string;
    @Watch( "image", { immediate: true } ) imageChanged( newImage: Attachment|string|undefined, oldImage: Attachment|string|undefined ): void {
        if( !this.bgImage ) {
            this.reloadImage();
        } else {
            const getUrl = ( image: Attachment|string|undefined ): string => {
                if( image == undefined ) return "";
                if( typeof( image ) == "string" ) return image;
                return image.url;
            }
            if( oldImage && getUrl( newImage ) != getUrl( oldImage ) ) {
                // 旧画像があり、別のもの(無しでもOK)に切り替わる場合のみリロードする
                this.reloadImage();
            }
        }
    }

    // コンポーネントサイズ
    @Prop( { default: 35 }) readonly size!: number;

    // アイコン画像が無い場合に出す、プロフィール名のフォントサイズ
    @Prop( { default: "" }) readonly fontSize!: string;

    // ラベル
    get label(): string {
        return DirectIconUtility.label( this.userName );
    }

    // 背景色
    get bgColor(): string {
        return DirectIconUtility.bgColor( this.id );
    }

    // 背景画像として表示するもの
    get bgImage(): string {
        // S3からファイル情報ロード中の場合は this.image から判定。ロード後は this.s3image
        if( this.s3Status == Status.LOADING ) {
            if( !this.image ) {
                return "";
            } else if( typeof this.image == "string" ) {
                if( this.image == "" || this.image == "about:blank" ) {
                    return ""
                } else {
                    return this.image;
                }
            } else {
                return this.image.url;
            }
        } else {    // Loaded の場合、s3image が解決済み
            return this.s3image;
        }
    }

    // 背景画像スタイル
    get bgImageStyle(): CssStyleObject {
        return this.bgImageStyleData ? this.bgImageStyleData : this.style;
    }

    // アイコン画像が無い場合のスタイル
    get style(): CssStyleObject {
        const css: CssStyleObject = {
            "background-color": this.bgColor,
            width: `${this.size}px`,
            height: `${this.size}px`,
        }
        if( this.fontSize ) css["font-size"] = this.fontSize;
        return css;
    }

    // アイコン画像がある場合のスタイル
    get profileIconSize(): CssStyleObject {
        return {
            width: `${this.size}px`,
            height: `${this.size}px`,
        }
    }

    // アイコン画像を持つかどうか。true: 持つ false: 持たない(or S3ファイル情報ロード中)
    get hasIcon(): boolean {
        if( this.s3Status == Status.LOADING ) return false;
        return this.s3image !== "";
    }

    // 背景画像URLがロードされたかどうか。true: ロードされた false: されてない(or S3ファイル情報ロード中)
    get bgImageLoad(): boolean {
        if( this.s3Status == Status.LOADING ) return false;
        return this.bgImageLoaded;
    }

    // true: 画像ロードエラー
    get isLoadError(): boolean { return this.errorCounter < 0; }

    // 画像ロードエラー処理
    private loadError() {
        this.s3image = "";   // エラー
        this.s3Status = Status.LOADED;
        this.bgImageLoaded = true;
        this.errorCounter -= 1;

        // ロードエラーになった回数が規定を下回り、まだ一定インターバルのリロードを行っていない時
        if( this.isLoadError == false && !this.retryedReload ) {
            this.retryedReload = true;

            // 一定インターバルで画像をリロードを行う
            setTimeout( this.reloadImage, this.errorRetrySecond * 1000 );
        }
    }

    // imgタグを内部で作成、画像ロードしてデータ取得
    private imageLoad( file: string ): void {
        // 実ロード
        const img = new Image();
        img.onload = () => {
            const style = DirectIconUtility.cropImage( img, this.size, this.size );
            this.bgImageStyleData = style;
            this.bgImageLoaded = true;
        }
        img.onerror = this.loadError
        img.src = file;
    }

    // プロフィール画像のリロード処理
    private async reloadImage(): Promise<void> {
        this.s3image = "";
        this.s3Status = Status.LOADING;
        this.bgImageStyleData = {};
        this.bgImageLoaded = false;

        if( !this.bgImage ) {
            // アイコン無し → S3ロード終わり
            this.s3image = this.bgImage;
            this.s3Status = Status.LOADED;
            this.bgImageLoaded = true;
        } else if( this.bgImage.startsWith("blob") ) {
            // blobなのでロード済み
            this.s3image = this.bgImage
            this.s3Status = Status.LOADED;
            this.imageLoad( this.s3image );
        } else if( this.bgImage.startsWith("http") ) {
            // 外部のURL(direct の profile画像など) → S3ロード終わり
            this.s3image = this.bgImage;
            this.s3Status = Status.LOADED;

            this.imageLoad( this.s3image );
        } else {
            // ファイル名だけが書かれている場合は S3 で解決してみる
            const s3 = S3AccessUtility.getInstance();
            const file = await s3.getThumbFile( this.bgImage, { topicId: this.topicId, messageId: "", commentId: "" } );

            if( typeof file == "string" ) {
                this.s3image = file;
                this.s3Status = Status.LOADED;

                this.imageLoad( this.s3image );
            } else {
                this.loadError();
            }
        }
    }

    // v-observer-visibility の処理
    visibilityChanged( isVisible: boolean/*, entry: unknown*/ ): void {
        if( isVisible == true ) {
            // 画面内に入るときだけロード処理に入る(event が invoke:once なので再度 true に入った場合は処理されない)
            this.reloadImage();
        }
    }
}
