







































import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import './topic-list-item.scss'
import IconPinned from './icon/IconPinned.vue'
import ProfileIcon from "./ProfileIcon.vue";
import TextProcessMixin from "./mixin/TextProcessMixin";
import moment from 'moment'
import { Category, User } from "../model";
import type { Attachment } from "../model";
import { COLOR_PALETTE, DEFAULT_COLOR } from "./color-palette";
import sanitizeHTML from '../sanitize';
import type { IconReaction } from "../API";
import { IconReactionType } from "../API";
import { ReactionController } from "../model/reaction-controller";
import S3AccessUtility from "./s3-acceess-utility";
import Acl from "../model/acl";
import { allowFeature, FeatureName } from "../direct-app-config";
import '../icomoon/style.css'
Vue.prototype.$sanitize = sanitizeHTML;

@Component({
    mixins: [
        TextProcessMixin
    ],
    components: {
        IconPinned,
        ProfileIcon,
    }
})
export default class TopicListItem extends mixins( Vue, TextProcessMixin ) {
    name: string = 'topic-list-item';
    selectionText: string = "";

    @Prop( { default: "error" } ) readonly domainId!: string;

    @Prop( { default: "", required: true })
    readonly id!: string;

    @Prop( { default: undefined })
    readonly icon!: Attachment;

    @Prop( { default: "カテゴリー", required: true })
    readonly category!: Category;

    @Prop( { default: "タイトル", required: true } )
    readonly title!: string;

    @Prop( { default: "本文" } ) readonly desc!: string;

    @Prop( { default: 999 } )
    readonly messages!: number;

    @Prop( { default: () => new Date(), required: true } )
    readonly updatedAt!: Date;

    @Prop( { default: false } )
    readonly pinned!: boolean;

    @Prop( { default: undefined } )
    readonly notification?: string;

    @Prop({ default: '' })
    readonly search?: string;

    // true: サイドメニューの中
    @Prop({ default: false }) readonly inSidemenu!: boolean;

    // ユーザー情報
    @Prop({ default: () => [] }) readonly users!: User[]; // 組織内ユーザー情報
    @Prop({ default: () => ( User.createNotFoundUser() ) }) readonly viewer!: User; // ログインユーザ情報

    @Prop({ required: true }) readonly acl!: Acl;

    // Reaction関係
    @Prop( { default: undefined } ) readonly star?: IconReaction;
    get starFlag(): boolean { return this.star?.userIdList.includes( this.viewer.directId ) || false }
    get watches(): number   { return this.star?.userIdList.length || 0 }

    get classes (): { [selector: string]: boolean } {
        return {
            'topic-list-item': true,
            'search': !!this.search,
        }
    }

    get categoryLabel (): string {
        const title = this.category.title;
        if( !title ) return "(指定無し)";
        if (window.innerWidth <= 320) {
            if (title.length > 8) return title.substr(0, 7) + '...'
        }
        if (window.innerWidth <= 420) {
            if (title.length > 12) return title.substr(0, 11) + '...'
        }
        return title || "(指定無し)";
    }

    get categoryColor (): string {
        // カテゴリーなし
        if( !this.category ) return "";

        const color = this.category.color;
        const index = COLOR_PALETTE.findIndex( c => c == color );
        return index < 0 ? DEFAULT_COLOR : color;
    }

    // タイトルラベル
    get titleLabel (): string { return this.highLightStr(this.title, this.search); }

    // タイトルラベル（sanitize済み）
    get normalizedTitleLabel(): string {
      return sanitizeHTML( this.titleLabel, { allowedAttributes: { span: [ 'class' ], } })
    }

    // 詳細
    get desciption(): string { return this.highLightStr(this.desc, this.search) }

    // icon url
    get iconUrl(): string {
        return this.icon?.url || "";
    }

    get updatedAtLabel (): string {
        const dt = moment(this.updatedAt)
        if (moment().startOf('day') < dt) {
            return dt.format('HH:mm')
        } else if (moment().startOf('year') < dt) {
            return dt.format('MM/DD')
        } else {
            return dt.format('YYYY/MM/DD')
        }
    }

    /** true: ゲスト許可 */
    get allowedGuest(): boolean {
        if( !this.acl ) return false;
        else return this.acl.guest.read;
    }
    hasFeature( keyword: FeatureName ): boolean { return allowFeature( keyword, this.$store ); }

    // ★クリック
    onStarClick(): void {
        if( this.$store ) {
            const ctl = new ReactionController( this.$store );
            const topic = ctl.findTopic( this.id );
            if( !topic ) return;
            ctl.onReactionClicked( topic, IconReactionType.FAVORITE, this.viewer, this.starFlag );
        } else if( this.star ) {
            const index = this.star.userIdList.findIndex( id => id == this.viewer.directId );
            console.log("index:", index, this.viewer.directId );
            console.log("index:", this.star.userIdList.join(","));
            if( 0 <= index ) {
                this.star.userIdList.splice( index, 1 );
            } else {
                this.star.userIdList.push( this.viewer.directId );
            }
        }
    }

    onClick( event: MouseEvent ): void {
        const selection = window.getSelection();
        const text = selection ? selection.toString() : "";
        if( 0 < text.length ) {
            // 文字選択
            event.preventDefault();
            event.stopPropagation();
            this.selectionText = text;
        } else if( 0 < this.selectionText.length ) {
            // 前回選択中だった -> Clearするだけ
            event.preventDefault();
            event.stopPropagation();
            this.selectionText = "";
        } else {
            // クリック処理(ここでは何もしない)
        }
      }

    // タイトル省略時のツールチップを出すかどうかを判定する
    onTooltipShow( bvEvent: Event ): void {
        const target = this.$refs[ 'topic-title' ];
        if( target instanceof HTMLElement ) {
            // webkit-line-clamp で省略された時、offsetHeight < scrollHeigt になる
            const oHeight = target.offsetHeight;
            const sHeight = target.scrollHeight;
            if( oHeight < sHeight ) return;
        }
        bvEvent.preventDefault(); // 出さない
    }

    linkClicked(): void {
        if( this.$router && this.$route ) {
            const path = `/${this.domainId}/${this.id}`
            if( path == this.$route.path ) return; // 遷移先が同じ
            this.$store.dispatch('setDomainId', this.domainId);
            this.$store.dispatch('setTopicId', this.id);
            // s3-accesse-utility の topicId をセット
            const s3AccessUtility = S3AccessUtility.getInstance();
            s3AccessUtility.setTopicId(this.id);

            this.$router.push( path );
        }
    }

}
