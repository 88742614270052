
























































import { Component, Prop, Vue } from "vue-property-decorator";
import '../../assets/custom.scss';
@Component({})
export default class SearchWindow extends Vue {
    name = "search-window";
    text: string = '';
    lock: boolean = false;

    @Prop({ default: '' }) readonly placeholder!: string;

    onUpdate(): void {
        console.log("text", this.text);
        this.$emit("update", this.text);
    }

    onChange(): void {
        if( this.lock ) { return }
        this.$emit("change", this.text);
        // 高速入力を防ぐ
        this.lock = true;
        setTimeout(() => this.lock = false, 100 );
    } 
}
