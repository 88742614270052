


































































import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class CustomButton extends Vue {
    name: string = "custom-button";

    @Prop({ default: "確認", required: true })
    readonly label!: string;

    @Prop({ default: false }) readonly disabled!: boolean;

    @Prop({ default: "info" }) readonly variant!: string;

    // "sm" or "lg"
    @Prop({ default: "sm" }) readonly size!: string;

    // false  diplay: inline-block;
    // true   display: flex;
    @Prop({ default: false }) readonly isFlex!: boolean;

    // border-radius: 10px; のクラス radius付与
    @Prop({ default: false }) readonly rounded!: boolean;

    get isSecondary(): boolean {
        return this.variant === 'secondary';
    }

    onClick(): void {
        this.$emit('confirm');
    }

}
