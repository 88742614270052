import { Vue } from "vue-property-decorator";

// .ts から App.vueのイベントモーダルへの橋渡しの役割を担う

const EVENT_CT: number = 5000; // イベントのクールタイム

/** イベント管理クラス */
export class EventManager {
    private static $root?: Vue;
    private static events: { [key: string]: { callable: boolean } } = {};

    public static init( $root: Vue ): void {
        this.$root = $root;
    }

    private static executable(key: string): boolean {
        if( !this.events[key] ) {
            this.events[key] = { callable: true };
        }
        return this.events[key].callable;
    }

    private static restrictEvent(key: string) {
        if( !this.events[key] ) {
            this.events[key] = { callable: false };
        } else {
            this.events[key].callable = false;
        }

        const refresh = () => this.events[key].callable = true
        setTimeout(refresh, EVENT_CT);
    }

    // 無料版の制限に関するアラート
    public static freeAlertEvent(): void {
        this.$root?.$emit("free-alert");
    }

    // 強制的にログアウトに遷移させるイベント
    public static logoutEvent(): void {
        const eventKey = "logout";
        if( !this.executable(eventKey) ) {
            return;
        }
        const escapePassList = [ "/logout", "/login", ]
        const isEscaped = escapePassList.some( pass =>  this.$root?.$route.path.match(pass) );
        if( !isEscaped ) {
            this.$root?.$emit("logout");
            this.restrictEvent(eventKey);
        }
    }

    // アクセス権がない時のイベント
    public static accessDeinedAlert(msg: string): void {
        const args = { msg: "アクセス権がありません。",   afterProcess: () => { return }};
        this.$root?.$emit("show-error-modal", args);
    }

    // 有効な組織かどうかのイベント
    public static allowedDomainEvent(show: boolean): void {
        this.$root?.$emit("on-not-allowed-modal", { show });
    }

    /** 再ログインに流す */
    public static relogin(): void {
        this.$root?.$emit("on-relogin");
    }
}
