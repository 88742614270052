var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      class: _vm.navbarClass,
      attrs: {
        type: "dark",
        variant: "info",
        sticky: true,
        topic: !!_vm.topicId ? _vm.topicId : false
      }
    },
    [
      _vm.search == false
        ? [
            _c("nav-bar-left", {
              attrs: {
                user: _vm.user,
                back: _vm.back,
                domainId: _vm.domainId,
                domains: _vm.domains,
                cancel: _vm.cancel,
                "left-items": _vm.leftItems
              },
              on: {
                "on-back": _vm.doBack,
                "on-cancel": function($event) {
                  return _vm.$emit("on-cancel")
                }
              }
            }),
            _c(
              "b-navbar-nav",
              {
                staticClass: "mx-auto",
                attrs: { visible: 0 < _vm.title.length }
              },
              [
                _c("b-nav-text", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.title) + _vm._s(_vm.subTitle))
                ])
              ],
              1
            ),
            _c("nav-bar-right", {
              staticClass: "mr-0",
              attrs: {
                user: _vm.user,
                ok: _vm.ok,
                complete: _vm.complete,
                close: _vm.close,
                appClose: _vm.appClose,
                preset: _vm.preset,
                items: _vm.items,
                domainId: _vm.domainId
              },
              on: {
                "on-ok": function($event) {
                  return _vm.$emit("on-ok")
                },
                "on-complete": function($event) {
                  return _vm.$emit("on-complete")
                },
                "on-close": function($event) {
                  return _vm.$emit("on-close")
                },
                "on-app-close": _vm.doAppClose,
                "on-menu-open": _vm.doMenuOpen
              }
            })
          ]
        : [
            _c(
              "b-navbar",
              { staticClass: "navbar1st py-0 pl-2 pr-2" },
              [
                _c("nav-bar-left", {
                  attrs: {
                    user: _vm.user,
                    back: _vm.back,
                    domainId: _vm.domainId,
                    brand: _vm.brand,
                    domains: _vm.domains,
                    cancel: _vm.cancel,
                    "left-items": _vm.leftItems
                  },
                  on: {
                    "on-back": _vm.doBack,
                    "on-cancel": function($event) {
                      return _vm.$emit("on-cancel")
                    }
                  }
                }),
                _c(
                  "b-navbar-nav",
                  {
                    staticClass:
                      "mx-auto nav-title flex-grow-1 justify-content-center",
                    attrs: { visible: 0 < _vm.title.length }
                  },
                  [
                    _c("b-nav-text", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.title) + _vm._s(_vm.subTitle))
                    ])
                  ],
                  1
                ),
                _c("nav-bar-right", {
                  staticClass: "ml-auto mr-0",
                  attrs: {
                    user: _vm.user,
                    ok: _vm.ok,
                    complete: _vm.complete,
                    close: _vm.close,
                    appClose: _vm.appClose,
                    preset: _vm.preset,
                    items: _vm.items,
                    domainId: _vm.domainId,
                    "solution-links": _vm.solutionItems
                  },
                  on: {
                    "on-ok": function($event) {
                      return _vm.$emit("on-ok")
                    },
                    "on-complete": function($event) {
                      return _vm.$emit("on-complete")
                    },
                    "on-close": function($event) {
                      return _vm.$emit("on-close")
                    },
                    "on-app-close": _vm.doAppClose,
                    "on-menu-open": _vm.doMenuOpen
                  }
                })
              ],
              1
            ),
            _c(
              "b-overlay",
              {
                staticClass: "navbar2nd d-md-none d-lg-none d-xl-none",
                attrs: {
                  show: _vm.isInvalidDomainId,
                  "bg-color": "black",
                  opacity: "0.5"
                },
                scopedSlots: _vm._u([
                  {
                    key: "overlay",
                    fn: function() {
                      return [_c("div")]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _c(
                  "b-navbar",
                  { staticClass: "navbar2nd d-md-none d-lg-none d-xl-none" },
                  [_c("search-form", { ref: "search" })],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }