
























import { FilteredDepartment } from './values/FilteredDepartment'
import { VK_UP, VK_DOWN } from './values/VirtualKey'
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class FilteredDepartmentsTreeNode extends Vue {
  name: string = "filtered-departments-tree-node";

  link: HTMLAnchorElement | null = null;

  @Prop({ required: true }) readonly filteredDepartment!: FilteredDepartment;
  @Prop({ required: true }) readonly keyword!: string;
  @Prop({ required: true }) readonly selectedDepartmentIdStr!: string;
  @Prop({ required: true }) readonly nextFocusHolder!: string;
  @Prop({ default: () => [] }) readonly denyIdList!: string[];

  get splittedNames(): string[] {
    return this.filteredDepartment.name.split(this.keyword);
  }

  get isDeeperThenRootChild(): boolean {
    return this.filteredDepartment.depth > 1;
  }

  get isSelected(): boolean {
    return this.selectedDepartmentIdStr === this.filteredDepartment.departmentIdStr;
  }

  get isDeny(): boolean {
    return this.denyIdList.includes(this.filteredDepartment.departmentIdStr);
  }

  selectDepartment(): void {
    this.$emit('selectDepartment', this.filteredDepartment.departmentIdStr);
  }

  onKeydown(event: KeyboardEvent): void {
    // Note: event.code はIE11非対応
    switch (event.keyCode) {
      case VK_DOWN:
        event.preventDefault()
        this.$emit('focusNext')
        break
      case VK_UP:
        event.preventDefault()
        this.$emit('focusPrev')
        break
    }
  }

  onKeypress(): void {
    this.selectDepartment();
  }

  onClick(): void {
    this.selectDepartment();
  }

  onBlur = () => {
    this.$emit('blur');
  }
}
// 要注意: Haxe 経由での JQuery からのDOM変更が残っている
