











































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { RestrictType } from "@/domain-setting-types";

type Option = {
    text: string;
    value: string;
};

const defaultFileTypes: Option[] = [
    { text: "画像", value: "image" },
    { text: "動画", value: "movie" },
    { text: "PDF", value: "pdf" },
    { text: "Office系ファイル", value: "office" },
];

const DEFAULT_DESCRIPTION = "設定の変更は、所有者へご依頼ください。";

@Component({
    components: {}
})
export default class RestrictTypeSetting extends Vue {
    name: string = "restrict-type-setting";
    editAuth: "owner"|"admin" = "owner"; 
    restrictType: RestrictType = RestrictType.omit;
    ALL_CUSTOM_CHECKBOX_LIST: string[] = defaultFileTypes.map( option => option.value );
    customFileType: string[] = [];

    @Prop({ required: true }) formLabelName!: string;
    @Prop({ default: "" }) desc!: string;
    @Prop({ required: true }) defaultRestrictType!: RestrictType;
    @Prop({ default: [], required: true }) defaultCustomFileType!: string[];
    @Prop({ default: "禁止" }) omitLabelName!: string;
    @Prop({ default: "許可" }) permitLableName!: string;
    @Prop({ default: "directの設定に準ずる" }) directLabelName!: string;
    @Prop({ default: false }) readonly isOwner!: boolean; // ユーザがオーナーか
    @Prop({ default: false }) isPermitCustom!: boolean; // 「許可」の詳細設定を行うか
    @Prop({ default: () => defaultFileTypes }) readonly filetypes!: Option[];
    @Watch('filetypes', { immediate: true }) onChangeFiletypes() {
        this.ALL_CUSTOM_CHECKBOX_LIST = this.filetypes.map( option => option.value );
    }
    @Prop({ default: "許可するファイル種類を選択してください" }) customOptionAlert!: string;

    // 設定権のラベル
    get authlityBadgeLabel(): string {
        return this.editAuth === "owner" ? "所有者のみ" : "管理者以上";
    }
    // 設定の説明 (管理者: 「設定の変更は、所有者へご依頼ください。」)
    get settingDescription(): string {
        return this.isOwner ? this.desc : DEFAULT_DESCRIPTION;
    }
    // 許可(permit)ラジオボタンがinvalidであるか
    get isInvalidPermit(): boolean {
        // 「許可」の詳細設定が存在 & 許可ラジオボタンを選択 & 詳細のチェックボックスが何もチェックされていない
        return this.isPermitCustom && this.restrictType === 1 && !this.customFileType.length;
    }

    mounted() {
        this.restrictType = this.defaultRestrictType;
        // 「許可」の詳細設定あり & 許可選択
        if( this.isPermitCustom && this.restrictType === 1 ) {
            // 詳細設定にチェックが 1つ以上存在する場合: それを継承 / 0の場合: 全てにチェック
            this.customFileType = this.defaultCustomFileType.length ? this.defaultCustomFileType : this.ALL_CUSTOM_CHECKBOX_LIST;
        }
    }

    updateRadioValue() {
        if( !this.isPermitCustom ) return;
        // ラジオボタン:「許可」(permit) + カスタムが何も選択されていない時に全選択肢を選択
        if( this.restrictType === RestrictType.permit && !this.customFileType.length ) {
            this.customFileType = this.ALL_CUSTOM_CHECKBOX_LIST;
        }
    }

    updateValue() {
        this.$emit("change", {
            restrictType: this.restrictType,
            customFileType: this.customFileType
        });
    }
}
