


















import { Component, Prop, Vue } from "vue-property-decorator";
import Loading from "./Loading.vue";

@Component({
    components: { Loading, }
})
export default class LoadingUpload extends Vue {
    name: string = 'loading-upload';

    @Prop({default: true}) fullscreen!: boolean;
    @Prop({ default: "" }) readonly msg!: string;
}
