var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.attachmentBase,
      staticStyle: { position: "relative" },
      attrs: { id: _vm.id }
    },
    [
      !_vm.mimeType
        ? [
            _c("loading-attachment", {
              ref: "attachment",
              attrs: { fullscreen: false }
            })
          ]
        : _vm.isPdf
        ? [
            _vm.photostream
              ? _c("div", { staticClass: "pdf-grey-box" }, [
                  _c(
                    "div",
                    { class: { "my-3": !_vm.pdfFront, "px-3": true } },
                    [
                      _vm.pdfFront
                        ? [
                            _vm.photostream
                              ? _c("preview-pdf-view", {
                                  staticClass: "pdf-viewer",
                                  attrs: {
                                    id: _vm.id,
                                    file: _vm.path,
                                    isMobile: _vm.isMobile,
                                    saveAllowed: _vm.downloadable
                                  },
                                  on: { load: _vm.onLoadImage }
                                })
                              : _vm._e()
                          ]
                        : [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-column justify-content-center align-items-center"
                              },
                              [
                                _c(
                                  "b-overlay",
                                  {
                                    class: _vm.isPdfThumbnail
                                      ? "loaded-tmb"
                                      : "default-tmb",
                                    attrs: {
                                      "no-center": "",
                                      show: true,
                                      opacity: 0
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "overlay",
                                          fn: function() {
                                            return [
                                              _c("img", {
                                                staticClass: "w-100 dummyCover",
                                                attrs: {
                                                  src: require("../assets/blank.gif")
                                                }
                                              })
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3253813810
                                    )
                                  },
                                  [
                                    _c("img", {
                                      class: {
                                        "mx-auto": true,
                                        "pdf-tmb-preview": _vm.isPdfThumbnail,
                                        "frame-border": _vm.isPdfThumbnail,
                                        "preview-icon-size": !_vm.isPdfThumbnail
                                      },
                                      attrs: {
                                        loading: "lazy",
                                        src: _vm.thumbnailPath
                                      },
                                      on: {
                                        load: _vm.onLoadImage,
                                        error: _vm.setDefaultIcon
                                      }
                                    }),
                                    _vm.isPdfThumbnail
                                      ? _c(
                                          "p",
                                          { staticClass: "pdf-tmb-label" },
                                          [_vm._v("PDF")]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c("div", { staticClass: "file-name" }, [
                                  _vm._v(_vm._s(_vm.imageFileName))
                                ]),
                                !_vm.loading
                                  ? _c(
                                      "div",
                                      {
                                        class: {
                                          "d-flex": true,
                                          "flex-row": true,
                                          "pt-2": true,
                                          "justify-content-center": true
                                        }
                                      },
                                      [
                                        _c("custom-button", {
                                          staticClass: "mx-1",
                                          staticStyle: { height: "42px" },
                                          attrs: {
                                            isFlex: true,
                                            label: "プレビュー",
                                            variant: ""
                                          },
                                          on: { confirm: _vm.pdfIconClick }
                                        }),
                                        _vm.allowDownload
                                          ? _c("custom-button", {
                                              staticClass: "mx-1",
                                              staticStyle: { height: "42px" },
                                              attrs: {
                                                isFlex: true,
                                                label: "ダウンロード",
                                                variant: ""
                                              },
                                              on: { confirm: _vm.downloadFile }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.downloadNote
                                  ? _c(
                                      "div",
                                      { staticClass: "attention-label" },
                                      [_vm._v(_vm._s(_vm.downloadNote))]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                    ],
                    2
                  )
                ])
              : [
                  _c(
                    "div",
                    { class: { "my-3": true, "w-100": true, "px-3": true } },
                    [
                      _c(
                        "b-overlay",
                        {
                          staticClass:
                            "pdf-grey-box d-flex flex-column justify-content-center align-items-center",
                          attrs: { "no-center": "", show: true, opacity: 0 },
                          scopedSlots: _vm._u([
                            {
                              key: "overlay",
                              fn: function() {
                                return [
                                  _c("img", {
                                    staticClass: "w-100 dummyCover",
                                    attrs: {
                                      src: require("../assets/blank.gif")
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("div", { staticClass: "loaded-tmb" }, [
                            _c("img", {
                              class: {
                                "mx-auto": true,
                                "pdf-icon": _vm.isPdfThumbnail,
                                "frame-border": _vm.isPdfThumbnail,
                                "list-icon-size": !_vm.isPdfThumbnail
                              },
                              attrs: {
                                loading: "lazy",
                                src: _vm.thumbnailPath
                              },
                              on: {
                                load: _vm.onLoadImage,
                                error: _vm.setDefaultIcon
                              }
                            }),
                            _vm.isPdfThumbnail
                              ? _c("p", { staticClass: "pdf-tmb-label" }, [
                                  _vm._v("PDF")
                                ])
                              : _vm._e()
                          ]),
                          _c("div", { staticClass: "file-name dark" }, [
                            _vm._v(_vm._s(_vm.imageFileName))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]
          ]
        : _vm.isMovie
        ? [
            _vm.photostream
              ? _c(
                  "div",
                  { staticClass: "d-flex flex-column movie-grey-box" },
                  [
                    _c(
                      "div",
                      { class: { "my-3": !_vm.movieFront, "px-3": true } },
                      [
                        _vm.movieFront
                          ? [
                              _c("b-embed", {
                                attrs: {
                                  type: "video",
                                  src: _vm.path,
                                  controls: "",
                                  playsinline: "",
                                  controlslist: _vm.downloadable
                                    ? ""
                                    : "nodownload",
                                  oncontextmenu: "return false;"
                                }
                              })
                            ]
                          : [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "movie-grey-box d-flex flex-column justify-content-center align-items-center"
                                },
                                [
                                  _c("div", { staticClass: "default-tmb" }, [
                                    _c("img", {
                                      staticClass: "preview-icon-size",
                                      attrs: {
                                        loading: "lazy",
                                        src: "/img/icon/icon_file_movie.svg"
                                      },
                                      on: { load: _vm.onLoadImage }
                                    })
                                  ]),
                                  _c("div", { staticClass: "file-name" }, [
                                    _vm._v(_vm._s(_vm.imageFileName))
                                  ]),
                                  !_vm.loading
                                    ? _c(
                                        "div",
                                        {
                                          class: {
                                            "d-flex": true,
                                            "flex-row": true,
                                            "pt-2": true,
                                            "justify-content-center": true
                                          }
                                        },
                                        [
                                          _c("custom-button", {
                                            staticClass: "mx-1",
                                            staticStyle: { height: "42px" },
                                            attrs: {
                                              isFlex: true,
                                              label: "プレビュー",
                                              variant: ""
                                            },
                                            on: { confirm: _vm.movieIconClick }
                                          }),
                                          _vm.allowDownload
                                            ? _c("custom-button", {
                                                staticClass: "mx-1",
                                                staticStyle: { height: "42px" },
                                                attrs: {
                                                  isFlex: true,
                                                  label: "ダウンロード",
                                                  variant: ""
                                                },
                                                on: {
                                                  confirm: _vm.downloadFile
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.downloadNote
                                    ? _c(
                                        "div",
                                        { staticClass: "attention-label" },
                                        [_vm._v(_vm._s(_vm.downloadNote))]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                      ],
                      2
                    )
                  ]
                )
              : [
                  _c("div", { staticClass: "movie-grey-box" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "my-3 px-3 d-flex flex-column justify-content-center align-items-center"
                      },
                      [
                        _c("div", { staticClass: "loaded-tmb" }, [
                          _c("img", {
                            staticClass: "list-icon-size",
                            attrs: {
                              loading: "lazy",
                              src: "/img/icon/icon_file_movie.svg"
                            },
                            on: { load: _vm.onLoadImage }
                          })
                        ]),
                        _c("div", { staticClass: "file-name dark" }, [
                          _vm._v(_vm._s(_vm.imageFileName))
                        ])
                      ]
                    )
                  ])
                ]
          ]
        : _vm.isOffice
        ? [
            _vm.photostream
              ? _c("div", { staticClass: "d-flex-column pdf-grey-box" }, [
                  _c(
                    "div",
                    { class: { "my-3": true, "px-3": true } },
                    [
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pdf-grey-box d-flex flex-column justify-content-center align-items-center"
                          },
                          [
                            _c("div", { staticClass: "default-tmb" }, [
                              _c("img", {
                                staticClass: "preview-icon-size",
                                attrs: {
                                  loading: "lazy",
                                  src: _vm.officeThumnail
                                },
                                on: { load: _vm.onLoadImage }
                              })
                            ]),
                            _c("div", { staticClass: "file-name" }, [
                              _vm._v(_vm._s(_vm.imageFileName))
                            ]),
                            !_vm.loading
                              ? _c(
                                  "div",
                                  {
                                    class: {
                                      "d-flex": true,
                                      "flex-row": true,
                                      "pt-2": _vm.allowDownload,
                                      "justify-content-center": true
                                    }
                                  },
                                  [
                                    _vm.allowDownload
                                      ? _c("custom-button", {
                                          staticClass: "mx-1",
                                          staticStyle: { height: "42px" },
                                          attrs: {
                                            isFlex: true,
                                            label: "ダウンロード",
                                            variant: ""
                                          },
                                          on: { confirm: _vm.downloadFile }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.downloadNote
                              ? _c("div", { staticClass: "attention-label" }, [
                                  _vm._v(_vm._s(_vm.downloadNote))
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    ],
                    2
                  )
                ])
              : [
                  _c("div", { staticClass: "pdf-grey-box" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "my-3 px-3 d-flex flex-column justify-content-center align-items-center"
                      },
                      [
                        _c("div", { staticClass: "loaded-tmb" }, [
                          _c("img", {
                            staticClass: "list-icon-size",
                            attrs: { loading: "lazy", src: _vm.officeThumnail },
                            on: { load: _vm.onLoadImage }
                          })
                        ]),
                        _c("div", { staticClass: "file-name dark" }, [
                          _vm._v(_vm._s(_vm.imageFileName))
                        ])
                      ]
                    )
                  ])
                ]
          ]
        : [
            _c(
              "div",
              { class: { "w-100": true, "image-grey-box": _vm.isLoadError } },
              [
                _vm.photostream
                  ? _c(
                      "div",
                      { class: { "my-3": _vm.isLoadError } },
                      [
                        _vm.isLoadError == false
                          ? _c(
                              "b-overlay",
                              {
                                class: {
                                  "w-100": true,
                                  "position-relative": true,
                                  loading: _vm.loading
                                },
                                attrs: {
                                  show: true,
                                  "no-center": "",
                                  opacity: _vm.loading ? 0.85 : 0
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "overlay",
                                      fn: function() {
                                        return [
                                          _vm.loading
                                            ? _c("loading-attachment", {
                                                ref: "attachment",
                                                attrs: { fullscreen: false }
                                              })
                                            : _c("img", {
                                                staticClass: "w-100 dummyCover",
                                                attrs: {
                                                  src: require("../assets/blank.gif")
                                                }
                                              })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3910698319
                                )
                              },
                              [
                                _c("img", {
                                  ref: "attachment",
                                  staticClass: "preload",
                                  attrs: {
                                    loading: "lazy",
                                    src: _vm.imagePath
                                  },
                                  on: {
                                    load: _vm.onLoadImage,
                                    error: _vm.reload
                                  }
                                })
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "image-grey-box position-relative"
                              },
                              [
                                _c("img", {
                                  staticClass: "w-100 dummyCover",
                                  attrs: { src: require("../assets/blank.gif") }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "default-tmb" }, [
                                  _c("img", {
                                    attrs: {
                                      src: "/img/icon/icon_file_image.svg"
                                    },
                                    on: { load: _vm.onLoadImage }
                                  })
                                ]),
                                _c("div", { staticClass: "file-name" }, [
                                  _vm._v(_vm._s(_vm.imageFileName))
                                ])
                              ]
                            ),
                        !_vm.loading
                          ? _c(
                              "div",
                              {
                                class: {
                                  "d-flex": true,
                                  "pt-2": true,
                                  "justify-content-center": true,
                                  "image-grey-box": _vm.isLoadError
                                }
                              },
                              [
                                _vm.allowDownload
                                  ? _c("custom-button", {
                                      staticStyle: { height: "42px" },
                                      attrs: {
                                        label: "ダウンロード",
                                        isFlex: true,
                                        variant: ""
                                      },
                                      on: { confirm: _vm.downloadFile }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.downloadNote
                          ? _c("div", { staticClass: "attention-label" }, [
                              _vm._v(_vm._s(_vm.downloadNote))
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "h-100" },
                      [
                        _vm.isLoadError == false
                          ? _c(
                              "b-overlay",
                              {
                                staticClass: "w-100 h-100",
                                attrs: {
                                  show: true,
                                  "no-center": "",
                                  opacity: _vm.loading ? 0.85 : 0
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "overlay",
                                      fn: function() {
                                        return [
                                          _vm.loading
                                            ? _c("loading-attachment", {
                                                ref: "attachment",
                                                attrs: { fullscreen: false }
                                              })
                                            : _c("img", {
                                                staticClass: "w-100 dummyCover",
                                                attrs: {
                                                  src: require("../assets/blank.gif")
                                                }
                                              })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3910698319
                                )
                              },
                              [
                                _c("img", {
                                  ref: "attachment",
                                  staticClass: "preload",
                                  attrs: {
                                    loading: "lazy",
                                    src: _vm.imagePath
                                  },
                                  on: {
                                    load: _vm.onLoadImage,
                                    error: _vm.reload
                                  }
                                })
                              ]
                            )
                          : _c("img", {
                              staticClass: "mx-auto pdf-icon list-icon-size",
                              attrs: { src: "/img/icon/icon_file_image.svg" },
                              on: { load: _vm.onLoadImage }
                            })
                      ],
                      1
                    )
              ]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }