var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "_288",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.2",
        height: "15",
        viewBox: "2 3.515 17.199 15"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "_288",
          d:
            "M 3.23749566078186 18.44009208679199 L 18.66199111938477 11.82833099365234 C 19.37796974182129 11.51895713806152 19.37796974182129 10.51128196716309 18.66199111938477 10.20190811157227 L 3.23749566078186 3.590147495269775 C 2.654104709625244 3.33380913734436 2.00883960723877 3.766932725906372 2.00883960723877 4.394519329071045 L 2 8.469415664672852 C 2 8.911376953125 2.327052354812622 9.291465759277344 2.769015073776245 9.344501495361328 L 15.25887870788574 11.0151195526123 L 2.769015073776245 12.67689895629883 C 2.327052354812622 12.73877334594727 1.99999988079071 13.11886215209961 1.99999988079071 13.56082534790039 L 2.00883936882019 17.63571929931641 C 2.00883936882019 18.2633056640625 2.654104709625244 18.69643020629883 3.23749566078186 18.44009208679199 Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }