var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    attrs: {
      id: _vm.id,
      "hide-header": "",
      "hide-footer": "",
      size: "sm",
      centered: ""
    },
    on: {
      show: function($event) {
        _vm.shown = true
      },
      hide: function($event) {
        _vm.shown = false
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var close = ref.close
          return [
            _c("b-row", { staticClass: "mx-2" }, [
              _c("div", { staticClass: "favorite-label" }, [
                _vm._v(
                  _vm._s(
                    _vm.sortedUserList.length
                      ? "いいね！"
                      : "まだいいね！されていません"
                  )
                )
              ]),
              _c(
                "div",
                { staticClass: "d-flex ml-auto", on: { click: close } },
                [_c("b-icon-x", { staticClass: "x-btn" })],
                1
              )
            ]),
            _c(
              "b-row",
              { staticClass: "mx-2" },
              [_c("user-list", { attrs: { userList: _vm.sortedUserList } })],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }