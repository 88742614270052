var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "list-group-item searched-department",
      attrs: {
        "data-original-title": _vm.filteredDepartment.fullPathWithoutRoot,
        "data-department-id-str": _vm.filteredDepartment.departmentIdStr
      }
    },
    [
      _c(
        "a",
        {
          ref: "link",
          class: {
            selected: _vm.isSelected,
            deny: _vm.isDeny,
            allow: !_vm.isDeny
          },
          attrs: { href: "#" },
          on: {
            keydown: _vm.onKeydown,
            keypress: _vm.onKeypress,
            click: function($event) {
              $event.preventDefault()
              return _vm.onClick.apply(null, arguments)
            },
            blur: _vm.onBlur
          }
        },
        [
          _vm.isDeeperThenRootChild ? _c("span", [_vm._v("...")]) : _vm._e(),
          _vm._l(_vm.splittedNames, function(splittedName, index) {
            return [
              index !== 0
                ? _c("em", {
                    key: "keyword_" + index,
                    staticStyle: { background: "yellow" },
                    domProps: { textContent: _vm._s(_vm.keyword) }
                  })
                : _vm._e(),
              _c("span", {
                key: "name_" + index,
                domProps: { textContent: _vm._s(splittedName) }
              })
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }