// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/dicon.ttf?doe2am");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/dicon.woff?doe2am");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/dicon.svg?doe2am");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___, { hash: "#dicon" });
// Module
exports.push([module.id, "@font-face {\n  font-family: 'dicon';\n  src:\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('svg');\n  font-weight: normal;\n  font-style: normal;\n  font-display: block;\n}\n\n.dicon {\n  /* use !important to prevent issues with browser extensions that change fonts */\n  font-family: 'dicon' !important;\n  speak: never;\n  font-style: normal;\n  font-weight: normal;\n  font-variant: normal;\n  text-transform: none;\n  line-height: 1;\n\n  /* Better Font Rendering =========== */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.dicon-speaker-on:before {\n  content: \"\\e946\";\n}\n.dicon-speaker-mention:before {\n  content: \"\\e947\";\n}\n.dicon-speaker-off:before {\n  content: \"\\e948\";\n}\n", ""]);
// Exports
module.exports = exports;
