import { Route } from "vue-router";

type ForumState = {
    inForum: boolean;
}

export default class HistoryUtility {
    public static init(): void {
        const history = window.history;
        const state: ForumState = history.state;

        switch( state && state.inForum ) {
            case null:
            case undefined: {
                const newState = state == null ? { inForum: false } : Object.assign({}, state);
                newState.inForum = false;
                history.replaceState( newState, "" );
            }
        }
    }

    public static afterEach( from: Route ): void {
        if( from.path == "/" ) return;  // "/" アクセスからのreplace時は無視

        const state: ForumState = window.history.state;
        if( state && state.inForum === undefined ) {
            const newState = Object.assign( {}, state );
            newState.inForum = true;
            window.history.replaceState( newState, "" );
        }
    }

    /** true: 掲示板内履歴のトップ */
    public static isTopHistory(): boolean {
        const state: ForumState = window.history.state;
        if( state && state.inForum === false ) return true;
        return false;
    }
}
