/**
 * 取得日: 2021/12/10 (escapeについてはesape-htmlを使用)
 * コード参照元: https://github.com/lisb/albero-client-web/blob/299f4da3c84afa3ac77a6bede3fce0b42a52052b/packages/home/src/ts/services/links.ts
 * 該当チケット: https://lisb.myjetbrains.com/youtrack/issue/dxflow-433
 * twitter-textについて: https://github.com/twitter/twitter-text/tree/master/js
 */

import twitter from "twitter-text";
import { linkRegExp } from "./links-regexp";
const escape = require('escape-html');

const isUrlEntity = (
    entity: twitter.EntityWithIndices
): entity is twitter.UrlWithIndices => {
    return 'url' in entity
}

class Linking {
    private _text: string

    constructor(text: string) {
        this._text = text
    }

    get text() {
        return this._text
    }

    linkHttps(): Linking {
        const options: twitter.AutoLinkOptions = {
            htmlEscapeNonEntities: true,
            linkAttributeBlock: (
                entity: twitter.EntityWithIndices,
                attributes: twitter.Attributes
            ) => {
                if (isUrlEntity(entity)) {
                    const url = entity.url
                    if (isDirectAppsUrl(url)) {
                        // 業務アプリのURLである場合
                        attributes.target = 'direct-apps'
                        attributes.rel = null
                        // NOTE: rel='noopener' をつけると開くウィンドウを固定できない
                    } else if (isDirectHomeUrl(url)) {
                        // direct(talk)のURLである場合
                        attributes.target = 'direct-home'
                        attributes.rel = null
                    } else {
                        // それ以外のURLである場合
                        attributes.target = '_blank'
                        attributes.rel = 'noopener'
                    }
                }
            },
        }
        return new Linking(twitter.autoLinkUrlsCustom(this.text, options))
    }

    linkIreporter(): Linking {
        const newText = this.text.replace(linkRegExp.ireporter, (url) => {
        return `<a href="${url}">${escape(url)}</a>`
        })
        return new Linking(newText)
    }

    linkDirect(): Linking {
        const newText = this.text.replace(linkRegExp.direct, (url) => {
        return `<a href="${url}">${escape(url)}</a>`
        })
        return new Linking(newText)
    }
}

/**
 *  URL とみなすことができる部分をリンクにした、HTML文字列を生成する関数
 *
 *  @param text:String 自動リンクされる前の文字列
 *  @return URLをリンクにしたHTML文字列
 **/
export function autoLink(text: string): string {
    return new Linking(text).linkHttps().linkIreporter().linkDirect().text
}

/**
 *  渡された url が direct Apps のアプリのURLかどうかを判定する
 *
 *  @param url
 **/
export function isDirectAppsUrl(url: string): boolean {
    const regex = /^https:\/\/.*\.direct4b.app/;
    return regex.test(url);
}

/**
 *  渡された url が direct の home パスURLかどうかを判定する
 *
 *  @param url
 **/
export function isDirectHomeUrl(url: string): boolean {
    const regex = /^https:\/\/direct[a-zA-Z0-9_-]*(\.[a-zA-Z0-9_-]+)?\.com\/home/;
    return regex.test(url);
}
